import axios from "axios"
import { useState, useEffect } from "react"
import {  private_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetFileExtension, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"

const useAddBusinessModal = ({ open, language, content, profile, master_actions, owner}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [image, setImage] = useState(null)
    const [preview, setPreview] = useState(null)
    const [file, setFile] = useState(null)


    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
            setImage(null)
            setPreview(null)
            setFile(null)
        }
        
    }, [open])
    
    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form, null,  content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImage: async(_file) =>{
            try {
                setImage(_file)
                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    // convert image file to base64 string
                    setPreview(reader.result)
                }, false);
                if (_file) reader.readAsDataURL(_file);
                
            } catch (error) {
                
            }
        },
        onChangeFile: async(_file) =>{
            setFile(_file)
        },
        onResetError: () => {
            setError(null)
        },
        onResetImage: () => {
            setImage(null)
            setPreview(null)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)
            const {id_user} = profile

            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            if(!owner) data2send.user_id = id_user
            //data2send.user_id = id_user

            setSending(true)

            let _business
            let business_url = owner ? '/business/me' : '/business'
            // Create first the event
            try {
                updateAuthorizationHeader(private_server)
                const business_request = await private_server.post(business_url, data2send)
                _business = business_request.data.data.business
                setError(null)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }

            // Check if image is available
            if(image && _business){
                try {
                    updateAuthorizationHeader(private_server)
                    let imageExt = onGetFileExtension(image.name)
                    if(imageExt) imageExt = imageExt.toLowerCase()
                    const image_request = await private_server.post(`/business/${_business.id_business}/image${owner?'/me':''}`, {name:'image', ext:imageExt})
                    const images3path = image_request.data.data.url
                    const options = {headers: {'Content-Type': image.type}}
                    delete axios.defaults.headers.common["Authorization"]
                    await axios.put(images3path, image, options);
                } catch (error) {
                    setSending(false)
                    const _error = onGetErrorMessage(error)
                    setError(_error ? _error.message : null)
                }
            }
            

            await master_actions.onGetBusinesses()
            await master_actions.onUpdateModalStatus('add', false)

            setSending(false)

        }
    }

    const view_data = {preview, file}
    const system = {loading, sending, error}

    return {system, form, actions, view_data}
}

export default useAddBusinessModal

const form_data = {
    name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },
    date:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'date',
            type:'date',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    },
    link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
        }
    },
    description:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'description',
            type:'text',
            fullWidth: true,
            multiline:true,
            rows:8
        },
        rules:{
            type:'distance',
            min:1, max:512
        }
    },
    position:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'position',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },
}
