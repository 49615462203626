import React, {  useEffect } from 'react';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import SectionA from './sections/SectionA/SectionA';
import SectionB from './sections/SectionB/SectionB';
import SectionC from './sections/SectionC/SectionC';
import SectionD from './sections/SectionD/SectionD';
import SectionF from './sections/SectionF/SectionF';
import SectionG from './sections/SectionG/SectionG';
import SectionH from './sections/SectionH/SectionH';
import SectionI from './sections/SectionI/SectionI';
import SectionJ from './sections/SectionJ/SectionJ';
import system_translations from '../../texts/system_translations';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import Footer from '../../components/Structure/Navigation/Footer';
import useAuth from '../../shared/useAuth';
import ContactModal from '../../modals/ContactModal/ContactModal';
import qs from 'query-string'

import { EVENT_TYPES, private_server, public_server } from '../../config'
import { updateAuthorizationHeader, onGetCatalogs, onGetErrorMessage } from '../../shared/utility'

const HomeView = ({user, history, language, onUpdateUserData}) => {

    const content = system_translations[language].layouts.home
    const general_content = system_translations[language].general
    const {authenticated} = useAuth({onUpdateUserData, history})

    const onChangePage = (url) => {
        history.push(url)
    }

    useEffect(() => {
        window.scrollTo(0,0)
        const args = qs.parse(history.location.search)
        //history.push(`/dash/account?view=2&show_facebook_instragram_modal=true`)
        if(args.code){
            localStorage.setItem('facebook_code', args.code)
            onFacebookIntegrateAuthentification(args.code)
            //history.push(`/dash/account?code=${args.code}&social_media=facebook_instagram&view=2`)
        }
    },[history.location])


    const onFacebookIntegrateAuthentification = async(_code) => {
//
        try {
            updateAuthorizationHeader(private_server)
            const data2send = {
                access_code:_code
            }
            try {
                const fb_rq = await private_server.post('/integration/me/facebook/connect', data2send)
            } catch (error) {
            }
            history.push(`/dash/account?view=2&show_facebook_instragram_modal=true`)
        } catch (error) {
        }
    }

    let contentRedirect = null
    if(authenticated){
        //contentRedirect = <Redirect to='/dash'/>
    }

    return ( 
        <div style={{minHeight:'100vh'}}>
            {contentRedirect}
            <ContactModal />
            <PublicTopbar content={content.topbar} onChangePage={onChangePage}/>
            <SectionA content={content.sectionA} history={history}/>
            <SectionF content={content.sectionF}/>
            <SectionG content={content.sectionG} history={history}/>
            <SectionB content={content.sectionB}/>
            <SectionH content={content.sectionH}/>
            <SectionI content={content.sectionI}/>
            <SectionD content={content.sectionD}/>
            <SectionJ content={content.sectionJ}/>
            <Footer content={general_content.footer}/>
        </div>
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        cart:state.cart,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart}),
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(HomeView);