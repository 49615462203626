import React, { useState, useEffect } from "react"
import { updateAuthorizationHeader } from "../../shared/utility"
import { public_server, USER_TYPES } from "../../config"
import { onGetErrorMessage } from "../../shared/utility"
import queryString from 'query-string'


const useInvitationsCard = () => {
  const [loading, setLoading] = useState(false)
  const [invitationUserTypes, setInvitationUserTypes] = useState([])
  const [modals, setModals] = useState({
    accept_invitation: false,
    reject_invitation: false,
    cancel_invitation: false
  })
  const [invitationUserCategories, setInvitationUserCategories] = useState([])
  const [invitationUser, setInvitationUser] = useState('')
  const [invitationUserReview, setInvitationUserReview] = useState(0.0)
  const [filter, setFilter] = useState({
    limit:20, offset:0, order:1, order_by:'invitation_date'})

  const actions = {
    getUser: async(id) => {
      try {
        setLoading(true)
        updateAuthorizationHeader(public_server)

        setLoading(true)
        const url = `/user/${id}/public/ns`
        updateAuthorizationHeader(public_server)
        const response = await public_server.get(url)
        setInvitationUser(response.data.data.user)

        const filter = {"AND":[{field:'user_id', operator:'=',value:id}]}
        const user_categories_req = await public_server.get(`/influencercategory/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        const user_categoires = user_categories_req.data.data.influencer_categories || []
        setInvitationUserCategories(user_categoires)

        const user_types_figure_req = await public_server.get(`/influencertypes/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        const user_types_figure = user_types_figure_req.data.data.influencer_types || []
        setInvitationUserTypes(user_types_figure)
        console.log(loading)

        try{
          const review_url = `/user/${id}/reviewaverages/public/ns`
          const avg_review_rq = await public_server.get(review_url);
          const _avgReview = avg_review_rq.data.data.review_averages;
          setInvitationUserReview(_avgReview)
          setLoading(false)
        }catch(e){
          console.log('No existen reviews')
          setLoading(false)
      }
      } catch (error) {
        console.log(error)
      }
    },
    onUpdateModalStatus: (_key, _value) => {
      setModals({...modals, [_key]:_value})
    }
  }

  const system = { loading, modals }
  const view_data = { invitationUserTypes, invitationUserCategories, invitationUserReview, invitationUser }
  return { actions, system, view_data }
}

export default useInvitationsCard