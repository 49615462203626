import { Grid, Icon, IconButton } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { useEffect } from "react"
import logo from '../../assets/logo.png'

const CookiesPolicies = ({history}) => {

    const { classes } = useStyles()

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className={classes.root}>
            <div className={classes.logo_container}>
                <Grid container spacing={1} alignItems='center' >
                    <Grid item>
                        <IconButton onClick={()=>history.goBack()} size="large"><Icon>arrow_back_ios</Icon></IconButton>
                    </Grid>
                    <Grid item>
                        <img src={logo} width={300} />
                    </Grid>
                </Grid> 
            </div>
            <div className={classes.subroot}>
                <p style={{textAlign:'center', marginBottom:40}} ><strong>POLÍTICA DE COOKIES</strong></p>
                <p>
                    En InPager, cuidamos en todo momento la seguridad de los Datos Personales que nos
                    proporcionas; por ello, los Datos Personales obtenidos a través de <a href="http://www.inpager.app">HTTP://WWW.INPAGER.APP</a> 
                    son utilizados para crear tu usuario, brindar un mejor servicio, mejorar tu experiencia en la
                    plataforma. Para obtener más información de cómo obtenemos, utilizamos, almacenamos, y en
                    general, tratamos tus datos personales, te pedimos consultar nuestro Aviso de Privacidad en el
                    siguiente <a href="https://inpager.app/noticeprivacy">enlace</a>. En caso de que desees limitar el uso de tus datos personales, te pedimos que
                    revises el Aviso de Privacidad antes mencionado.
                </p>
                <p>
                    Nuestra plataforma utiliza una tecnología llamada Cookies y otras tecnologías similares, para
                    recopilar y utilizar datos como parte de nuestros Servicios, tal y como se definen en nuestro
                    Aviso de Privacidad.
                </p>
                <p>
                    Las cookies son archivos de texto que son descargados automáticamente y almacenados en el
                    disco duro del equipo de cómputo del usuario al navegar en un sitio específico, que permiten
                    recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, tus preferencias
                    para la visualización de las páginas en ese servidor, nombre y contraseña.
                </p>
                <p>
                    Si ya se ha registrado en nuestra plataforma estas Cookies:
                </p>
                <p className={classes.list_container} >
                    <ul>
                        <li className={classes.list} >
                            Nos serán necesarias para poder acceder a su información de cuenta
                            (almacenada en nuestros sitios, como lo son los tokens de acceso temporal a la
                            plataforma, así como datos indispensables de acceso rápido del usuario que se
                            registra)
                        </li>
                        <li className={classes.list}>
                            De igual manera, toda carga de imágenes se hace uso de este tipo de recurso
                            para acelerar la carga y navegación de la plataforma con respecto a media
                            (videos, imágenes, documentos, etc).
                        </li>
                        <li className={classes.list}>
                            Nos proporcionarán a nosotros información que utilizaremos para personalizar
                            nuestros sitios de acuerdo con sus preferencias. Tú podrás ajustar tu buscador
                            de internet para que éste le notifique cuando reciba una Cookie. 
                        </li>
                    </ul>
                </p>
                <p>
                    Los web beacons son imágenes visibles u ocultas insertadas en una página de Internet
                    o correo electrónico que se utilizan para monitorear tu información, para obtener tu
                    dirección IP (dato que en algunas circunstancias pudiera identificarte), información
                    sobre el tiempo de interacción con la página, el tipo de navegador, entre otras
                    cuestiones.
                </p>
                <p>
                    En caso que te registres en nuestra plaraforma, los Datos Personales que obtenemos de
                    estas tecnologías de rastreo son los siguientes: nombre, nombre de usuario, contraseña,
                    dirección IP, preferencias, horario y tiempo de navegación en la página de Internet, la
                    dirección IP, el sistema operativo, las secciones consultadas, y las páginas de Internet
                    accedidas antes y después de la nuestra.
                </p>
                <p>
                    En cualquier momento puedes deshabilitar las cookies y los web beacon o
                    tracer tags, a través de las herramientas que para tal efecto dispone el
                    navegador que utilices.
                </p>
                <p>
                    Última actualización: 15 de junio de 2023.
                </p>
            </div>
            <div className={classes.bottom_deco}>

            </div>
        </div>
    );
}

export default CookiesPolicies

const useStyles = makeStyles()(theme => ({
    root:{
        width:1000, 
        margin:'auto',
        [theme.breakpoints.down('lg')]:{
            width:'100%', 
        }
    },
    subroot:{
        padding:'0px 100px',
        fontFamily:theme.typography.fontFamily,
        textAlign:'justify',
    },
    logo_container:{
        padding:'40px 0px 0px',
    },
    bottom_deco:{
        width:'100%',
        height:40,
        background:theme.palette.primary.main,
        marginTop:60
    },
    list:{
        paddingLeft:20
    }, 
    list_container:{
        marginLeft:60
    },
    link:{
        //marginTop:40,
        color:theme.palette.primary.main
    }
}));