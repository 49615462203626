import { useState } from 'react';
import { useStyles } from 'tss-react/mui';
import { Grid, Select, MenuItem, TextField, IconButton, Typography, Button } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import catalogs from '../../../texts/esp/catalogs';
import { ruleValidation } from '../../../components/Forms/customFunctions';

const SocialMediaInput = ({onChangeSocialMediaList, handleRemoveSocialMedia, socialMediaList}) => {
    const socialMediaList_temp = socialMediaList || [];
    const [followers, setFollowers] = useState('');
    const [profileLink, setProfileLink] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState('');
    // filter all socialmedia alredy selected
    const socialMediaPlatforms = catalogs.social_media.filter( e => !socialMediaList.some(l => l.platform === e.value));
    const validFollowers = ruleValidation(followers, {type: 'numeric', min: 0});
    const validPofileLink = ruleValidation(profileLink, {type: 'link'});
    const dataValid = validFollowers && validPofileLink && selectedPlatform !== '';
    const { css, theme } = useStyles();
    const handleAddSocialMedia = () => {
        if (dataValid) {
            const newSocialMedia = {
                platform: selectedPlatform,
                followers: followers,
                profileLink: profileLink,
            };
            onChangeSocialMediaList([...socialMediaList_temp, newSocialMedia]);
            setFollowers('');
            setProfileLink('');
            setSelectedPlatform('');
        }
    };

    return (
        <Grid container spacing={2} alignContent='top' alignItems="center" justifyContent='space-around' style={{ width: '100%' }}>
            <Grid item xs={12}>
                <Typography variant='subtitle'> Agrega los links de redes sociales</Typography>
            </Grid>
            <Grid item xs={3}>
                <Select
                    label="Social Media Platform"
                    variant="outlined"
                    fullWidth
                    value={selectedPlatform}
                    onChange={(e) => setSelectedPlatform(e.target.value)}
                >
                    {socialMediaPlatforms.map((platform) => (
                        <MenuItem key={platform.value} value={platform.value}>
                            {platform.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Profile Link"
                    variant="outlined"
                    fullWidth
                    value={profileLink}
                    error={!validPofileLink && profileLink !== ''}
                    onChange={(e) => setProfileLink(e.target.value)}
                    helperText={(!validPofileLink && profileLink !== '') ? 'e.g: https://instagra/inpager_' : ''}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Followers"
                    variant="outlined"
                    fullWidth
                    value={followers}
                    inputProps={{type:'number'}}
                    error={!validFollowers && followers !== ''}
                    onChange={(e) => setFollowers(e.target.value)}
                />
            </Grid>
            <Grid item xs={1}>
                <Button
                    className={css({
                        color: theme.palette.primary.light,
                        background: dataValid ? theme.palette.primary.main : theme.palette.grey,
                        '&:hover': {color: theme.palette.primary.main}})}
                    aria-label="Add Social Media"
                    onClick={handleAddSocialMedia}
                    disabled={!dataValid}
                >
                    Agregar
                </Button>
            </Grid>
            {socialMediaList_temp.map((element, index) =>
                <>
                    <Grid item xs={3}>
                        {element.platform}
                    </Grid>
                    <Grid item xs={4}>
                        <a href={element.profileLink}>{element.profileLink}</a>
                    </Grid>
                    <Grid item xs={3}>
                        {element.followers}
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="primary"
                            aria-label="Add Social Media"
                            onClick={() => handleRemoveSocialMedia(index)}
                        >
                            <RemoveCircleOutline />
                        </IconButton>
                    </Grid>
                </>)}
        </Grid>
    )
};

export default SocialMediaInput;