import { useState, useEffect } from "react"
import { private_server, public_server } from "../../config"
import { isFormValid, onGetCurrentUserProfile, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility"
import queryString from 'query-string'
import { generic_errors } from "../../texts/esp/genericErrors"

const useAccountInvitationLayout = ({ language, content, onUpdateAuthStatus, onUpdateUserData, history}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [referal_code, setReferalCode] = useState('asdfas')
    const [user_type, setUserType] = useState()
    const [token, setToken] = useState()
    const [view, setView] = useState(1)
    const [user, setUser] = useState(null)

    useEffect(() => {
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            try {
               const urlArgs = queryString.parse(history.location.search)
               const _token = urlArgs.token
               setToken(_token)
               const data2send = {
                   token: _token,
                   device:'web'
               }

               //const confirmation_request = await public_server.post('/user/confirm', data2send)
               //const _user = confirmation_request.data.data
               //const user_req = await public_server.get(`/user/url/public/ns?url=${_user.username}`)
               //const user = user_req.data.data.user
               //setReferalCode(user.referal_code)
               //setUserType(user.user_type_id)
               setError(true)
                

            } catch (error) {
                setError(true)
            }
            //const _form = onInitForm(form, null,  content.form)
            //setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(id === 'password'){
                temp.password_confirmation.value2 = data.value
                temp.password_confirmation.isValid = data.value === temp.password_confirmation.value
            }
            if(error) setError(null)
            setForm(temp)
        },
        onKeyPress: (event) => {
            if(event.key === 'Enter'){
              actions.onSubmit()
            }
        },
        onChangeView:(value) => {
            setView(value)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                let _form = onSetErrorsToForm(form, errors)
                
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            data2send.token = token
            delete data2send.password_confirmation
            data2send.device = "web"


            setSending(true)

            try {
                const response = await public_server.post('/user/confirm/invite', data2send);
                setUser(response.data.data)
                // device, emial, exp, first_name, last_name, token, user_id, user_type_id, username
                actions.onChangeView(2)
                setError(null)
            } catch (error) {
                setError(true)
                actions.onChangeView(2)
            }

            setSending(false)

        }
    }
    const view_data = {referal_code, user_type, view}
    
    const system = {loading, sending, error}

    return {system, form, actions, view_data}
}

export default useAccountInvitationLayout

const form_data = {
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
            label:'Usuario',
            placeholder:'Usuario',
            helperText:'El usuario debe contener al menos 4 caracteres'
        },
        rules:{
            type:'distance',
            min:4, max:256
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password',
            type:'password',
            fullWidth: true,
            label:'Contraseña',
            placeholder:'Contraseña',
            helperText:'La contraseña debe tener al menos 10 caracteres'
        },
        rules:{
            type:'distance',
            min:10, max:256
        }
    },
    password_confirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password_confirmation',
            type:'password',
            fullWidth: true,
            label:'Confirmar contraseña',
            placeholder:'Confirmar contraseña',
            helperText:'Las contraseñas no coinciden'
        },
        rules:{
            type:'equals',
            min:1, max:256
        }
    },
}
