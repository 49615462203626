import React from 'react'
import { MenuItem, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles(TextField, {
    root: {
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  });

const InputSelectForm = props => {

    const {data, onChange, startAdornment, endAdornment} = props

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited, options} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''

    const _options = options ? options : []

    return(
        <div>
            <CssTextField  value={value} variant='outlined' 
            error={isError} {...interConfig} select onChange={onInnerChange} 
            InputProps={
                {
                    startAdornment:startAdornment ? startAdornment : undefined,
                    endAdornment:endAdornment ? endAdornment : undefined,
                }}
            >
                {_options.map(item => {
                    return(
                        <MenuItem key={item.value.toString()} value={item.value}>
                            {item.label}
                        </MenuItem>
                    )
                })}
            </CssTextField>
        </div>
    )
}

export default InputSelectForm