import { useState, useEffect } from "react"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../../../shared/utility"
import {  private_server } from "../../../../../../../config"
import { generic_errors } from "../../../../../../../texts/esp/genericErrors"

const useFacebookPagesModal = ({ open, language, content, onCompleted, onClose, profile, owner, master_actions}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [busy, setBusuy] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))


    const [modals, setModals] = useState({
        edit_profile:false,
        setup_facebook_pages:false
    })

    useEffect(() => {
        if(open){
            actions.onInitModule()
        } 
    }, [open])

    useEffect(()=>{window.scrollTo(0,0)}, [])


    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                
                // This part is with token
                updateAuthorizationHeader(private_server)
                const accounts_url = owner ? '/integration/me/facebook/accounts' : `/integration/${profile.id_user}/facebook/accounts`
                const accounts_rq = await private_server.get(accounts_url)
                const fb_accounts = onGetCatalogs(accounts_rq.data.data.accounts, 'id', 'name')
                let _form = onInitForm(form_data, null, content.form)
                _form.facebook_page_id.options = [...fb_accounts]

                
                //_form.event_mode_id.options = [...catalogs.event_modes]
                setForm(_form)

            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
            setLoading(false)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            //data2send.event_type_id = EVENT_TYPES.PRESS

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)   
                
                const facebook_url = owner ? '/integration/me/facebook/setpage' : `/integration/${profile.id_user}/facebook/setpage`
                const setfacebook_rq = await private_server.post(facebook_url, {account_id:data2send.facebook_page_id})
                master_actions.onUpdateIntegration(setfacebook_rq.data.data.integration)
                setError(null)
                await master_actions.getSocialMedia(profile.id_user)
                setSending(false)
                //onCompleted()
                onClose()
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        },

        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
    }

    const system = {loading, sending, error}
    const view_data = {}

    return {system, view_data, actions, modals, form}
}

export default useFacebookPagesModal

const form_data = {
    facebook_page_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'facebook_page_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select'}
    },
}