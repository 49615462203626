export const ERROR_CODES = {
    101: 'La solicitud no tiene el encabezado de autorización',
    102:'Token expirado',
    103:'Token inválido',
    104:'Credenciales inválidas',
    105:'Usuario no autorizado para ejecutar esta acción',
    106:'Contraseña incorrecta',
    107:'Error en autorización',
    108:'Usuario deshabilitado',
    109:'Revisión del token es requerida',
    201:'La solicitud tiene información inválida',
    301:'Error en la base de datos',
    302:'Error al desencriptar contraseña',
    303:'Error al encriptar contraseña',
    304:'Error al cargar la imagen',
    305:'Error al cargar el archivo',
    306:'Error al enlistar archivos',
    307:'Error al eliminar archivo',
    308:'Error al descargar archivo',
    309:'Archivo faltante',
    310:'Error en el servicio de mailing',
    311:'Archivo no encontrado',
    312:'Error en el servicio de facturación',
    401:'Usuario no encontrado',
    402:'Correo electrónico no disponible',
    403:'Nombre de usuario no disponible',
    404:'Usuario no es público',
    405:'Tipo de usuario no encontrado',
    406:'URL no disponible',
    407:'Correo electrónico no disponible',
    408:'Género no encontrado',
    409:'País no encontrado',
    410:'Tipo de influencer no encontrado',
    411:'Tamañao de influencer no encontrado',
    412:'Cliente de influencer no encontrado',
    413:'Archivo no encontrado',
    414:'Influencer no encontrado',
    415:'Cliente no encontrado',
    416:'Influencer no pertence a este cliente',
    417:'Usuario no es un influencer',
    418:'Categoría no encontrada',
    419:'Categoría ya existente',
    420:'Archivo no encontrado',
    421:'Categoría de influencer no encontrada',
    422:'Categoría de influencer ya existente',
    423:'Categoría de influencer no pertenece a este usuario',
    424:'Revisión no encontrada',
    425:'Revisión no pertenece a este usuario',
    426:'Evento no encontrado',
    427:'Tipo de evento no encontrado',
    428:'Modo de evento no encontrado',
    429:'Tag no encontrado',
    430:'Tag de evento no encontrado',
    431:'Negocio no encontrado',
    432:'Negocio no pertenece a este usuario',
    433:'Evento no pertence a este usuario',
    434:'Tag del evento no pertenece a este usuario',
    435:'Producto no encontrado',
    436:'Producto no pertenece a este usuario',
    437:'Código de referencia ya existe',
    438:'Código de referencia no existe',
    439:'Usuario no está confirmado',
    440:'Usuario está deshabilitado',
    441:'Revisión no está aprobada',
    442:'El usuario ingresado no es un manager',
}