import React, { useState, useEffect, useRef } from 'react';
import { Grid, Icon, IconButton, InputBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { blueGrey } from '@mui/material/colors';
import { DASHBOARD_SETTINGS } from '../../config';


const GenericSearchBar = ({onRequestSearch, customStyle}) => {

    const { classes } = useStyles()
    const [value, setValue] = useState('')
    const inputRef = useRef()
    const timerRef = useRef()

    const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => onRequestSearch(value), 300);
    }
    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onUpdateSearchValue = (val) => {
        setValue(val.currentTarget.value)
    }

    return (
        <div className={customStyle ? customStyle : classes.searchbox} >
            <Grid container wrap='nowrap' alignItems='center'>
                <Grid item xs>
                    <div className={classes.input}>
                        <InputBase ref={inputRef} fullWidth value={value} 
                        onKeyUp={onStartCountDown} onKeyDown={onStopCountDown}
                        onChange={onUpdateSearchValue} placeholder='Buscar ...' />
                    </div>
                </Grid>
                <Grid item>
                    <IconButton className={classes.search_btn} size="large">
                        <Icon>search</Icon>
                    </IconButton>    
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles()(theme => ({
    searchbox:{
        //border:`1px solid ${blueGrey[400]}`,
        paddingLeft:16,
        borderRadius:12,
        background:'white',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    }
}));

export default GenericSearchBar;