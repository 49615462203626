import { useState, useEffect } from "react"
import { private_server } from "../../../config"
import { updateAuthorizationHeader } from "../../../shared/utility"
import queryString from 'query-string'

const useReviewsView = ({ user,  match, history}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [reviews, setReviews] = useState([])
    const [filter, setFilter] = useState({
        limit:10, offset:0, order:1, order_by:'date'
    })
    const [query, setQuery] = useState({
        search:''
    })

    const [modals, setModals] = useState({
        send_review_invitation:false,
    });

    useEffect(() => {
        if(user) actions.onInitModule()
    }, [user, filter, query])

    useEffect(()=>{window.scrollTo(0,0)}, [])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                
                await actions.onUpdateData()
                
            } catch (error) {
            }
            setLoading(false)
        },
        onApproveReview: async(idreview, data2send) => {
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                const url = `/review/${idreview}`
                await private_server.patch(url, data2send)
                await actions.onUpdateData()
            } catch (error) {
            }
            setSending(false)
        },
        onUpdateData: async() => {
            const args = onGetParams()
            const url = `/review/all/me${args}`
            updateAuthorizationHeader(private_server)
            const request_users = await private_server.get(url)
            setReviews(request_users.data.data.reviews)
        },
        onRequestSearch: async(word) => {
            let _query = {...query}
            _query.search = word
            setQuery(_query)
        },  
        onGetProfileDetails: async() => {
            try {
                updateAuthorizationHeader(private_server)
            } catch (error) {
            }
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        

    }

    const onGetParams = () => {
        let response = ''
        response = `?${queryString.stringify(filter)}`
        return response
    }

    const system = {loading, sending, error}
    const view_data = {reviews}

    return {system, view_data, actions, modals}
}

export default useReviewsView
