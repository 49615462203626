import { useState } from 'react'
import apple_podcast_icon from '../../../../../assets/socialmedia/icons/apple_podcast_icon.png'
import be_real_icon from '../../../../../assets/socialmedia/icons/bereal_icon.png'
import facebook_icon from '../../../../../assets/socialmedia/icons/facebook_icon.png'
import instagram_icon from '../../../../../assets/socialmedia/icons/instagram_icon.png'
import linkedin_icon from '../../../../../assets/socialmedia/icons/linkedin_icon.png'
import only_fans_icon from '../../../../../assets/socialmedia/icons/onlyfans_icon.png'
import pinterest_icon from '../../../../../assets/socialmedia/icons/pinterest_icon.png'
import reddit_icon from '../../../../../assets/socialmedia/icons/reddit_icon.png'
import sher_icon from '../../../../../assets/socialmedia/icons/sher_icon.png'
import snapchat_icon from '../../../../../assets/socialmedia/icons/snapchat_icon.png'
import spotify_icon from '../../../../../assets/socialmedia/icons/spotify_icon.png'
import tik_tok_icon from '../../../../../assets/socialmedia/icons/tik_tok_icon.png'
import tumblr_icon from '../../../../../assets/socialmedia/icons/tumblr_icon.png'
import twitch_icon from '../../../../../assets/socialmedia/icons/twitch_icon.png'
import twitter_icon from '../../../../../assets/socialmedia/icons/twitter_icon.png'
import youtube_icon from '../../../../../assets/socialmedia/icons/youtube_icon.png'
import { private_server, public_server } from '../../../../../config'
import { onInitForm, isFormValid, onSetErrorsToForm, onGetSelectedFormData, updateAuthorizationHeader, onGetErrorMessage } from "../../../../../shared/utility"
import { useHistory } from 'react-router'

const useCardSocialMedia = ({language, id_user}) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const history = useHistory()

  const actions = {
    getCardIcon: (type) => {
      switch (type) {
        case 'podcasts':
          return apple_podcast_icon

        case 'be_real':
          return be_real_icon

        case 'facebook':
          return facebook_icon

        case 'instagram':
          return instagram_icon

        case 'linkedin':
          return linkedin_icon

        case 'only_fans':
          return only_fans_icon

        case 'pinterest':
          return pinterest_icon

        case 'reddit':
          return reddit_icon

        case 'sher':
          return sher_icon

        case 'snapchat':
          return snapchat_icon

        case 'spotify':
          return spotify_icon

        case 'tiktok':
          return tik_tok_icon

        case 'tumblr':
          return tumblr_icon

        case 'twitch':
          return twitch_icon

        case 'twitter':
          return twitter_icon

        case 'youtube':
          return youtube_icon

        default:
          return null
      }
    },
    getCardName: (type) => {
      switch (type) {
        case 'podcasts':
          return 'Apple Podcasts'

        case 'be_real':
          return 'BeReal'

        case 'facebook':
          return 'Facebook'

        case 'instagram':
          return 'Instagram'

        case 'linkedin':
          return 'LinkedIn'

        case 'only_fans':
          return 'OnlyFans'

        case 'pinterest':
          return 'Pinterest'

        case 'reddit':
          return 'Reddit'

        case 'sher':
          return 'Sher'

        case 'snapchat':
          return 'Snapchat'

        case 'spotify':
          return 'Spotify'

        case 'tiktok':
          return 'TikTok'

        case 'tumblr':
          return 'Tumblr'

        case 'twitch':
          return 'Twitch'

        case 'twitter':
          return 'Twitter'

        case 'youtube':
          return 'YouTube'

        default:
          return null
      }
    },
    getIconWidth: (type) => {
      switch (type) {
        case 'podcasts':
          return 31

        case 'be_real':
          return 63

        case 'facebook':
          return 19

        case 'instagram':
          return 30

        case 'linkedin':
          return 28

        case 'only_fans':
          return 33

        case 'pinterest':
          return 31

        case 'reddit':
          return 32

        case 'sher':
          return 25

        case 'snapchat':
          return 30

        case 'spotify':
          return 31

        case 'tiktok':
          return 26

        case 'tumblr':
          return 30

        case 'twitch':
          return 29

        case 'twitter':
          return 31

        case 'youtube':
          return 33

        default:
          return null
      }
    },
    getSocialMediaData: async (type, id_user) => {
      switch (type) {
        case 'podcasts':
          return {}

        case 'bereal':
          return {}

        case 'facebook':
          try {
            setLoading(true)
            const response = await private_server.get(`/integration2/full_data/?type=${type}&id=${id_user}`)

            setLoading(false)

            return response.data.data
          } catch (error) {
            console.error(error)
          }

          return

        case 'instagram':
          try {
            setLoading(true)
            const response = await private_server.get(`/integration2/full_data/?type=${type}&id=${id_user}`)
            setLoading(false)

            return response.data.data
          } catch (error) {
            console.error(error)
          }

          return
        case 'linkedin':
          return {}

        case 'onlyfans':
          return {}

        case 'pinterest':
          return {}

        case 'reddit':
          return {}

        case 'sher':
          return {}

        case 'snapchat':
          return {}

        case 'spotify':
          return {}

        case 'tiktok':
          try {
            setLoading(true)
            const response = await private_server.get(`/integration2/full_data/?type=${type}&id=${id_user}`)

            setLoading(false)

            return response.data.data
          } catch (error) {
            console.error(error)
          }

          return

        case 'tumblr':
          return {}

        case 'twitch':
          return {}

        case 'twitter':
          return {}

        case 'youtube':
          return {}

        default:
          return null
      }
    },
    onChangeForm: (data) => {
      let temp = { ...form }
      const id = data.config.id
      temp[id] = { ...data }
      if (error) setError(null)
      setForm(temp)
    },
    onSubmit: async (type) => {

      const errors = isFormValid(form)

      if (errors && errors.length) {
        const _form = onSetErrorsToForm(form, errors)
        setForm(_form)
        setError(onGetErrorMessage(error, language))
        return
      }

      const form_data = onGetSelectedFormData(form, origin)
      if (form_data.quote === '') form_data.quote = '@null!!'
      const dict2send = {follower_count: {}, user_id: {}}
      dict2send.follower_count[type] = form_data.follower_count
      dict2send.user_id = id_user
      setSending(true)
      try {
        updateAuthorizationHeader(private_server)
        await private_server.post('/integration/modifyfollowercount', dict2send)
        setError(null)
      } catch (error) {
        setSending(false)
        setError(onGetErrorMessage(error, language))
      }
      history.go(0);
    }
  }

  const system = { loading }
  return { actions, system, form }
}

export default useCardSocialMedia;

const form_data = {
  follower_count: {
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config: {
      id: 'follower_count',
      type: 'number',
    },
    rules: {
      type: 'numeric',
      min: 0, max: 1000000000
    }
  },
}