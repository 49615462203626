import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AnalyticsProgressBar from '../../../../../../components/Platform/AnalyticsProgressBar';
import NoAnalyticsMessage from '../../../../../../components/Platform/Placeholder/NoAnalyticsMesage';
import PageMenu from '../../../../../../components/Structure/Navigation/PageMenu';


const FacebookAgeChart = ({data}) => {

    const [chartdata, setChartData] = useState([])  

    const [total, setTotal] = useState(0)
    const [total_men, setTotalMen] = useState(0)
    const [total_women, setTotalWomen] = useState(0)

    const menu = [{id:1, label:'Todos'},{id:2, label:'Hombres'},{id:3, label:'Mujeres'},]
    const [view, setView] = useState(1)

    useEffect(() => {
        if(data){
            let temp = (typeof data === 'object') ? data : JSON.parse(data)
            let _chartdata = [...basedata]
            Object.keys(temp).map(item => {
                const value = temp[item]
                let rangeAge, posx
                if(item.includes('F.')){
                    rangeAge = item.replace('F.','')
                    posx = _chartdata.findIndex(el => el.name === rangeAge)
                    if(posx !== -1) _chartdata[posx].F = _chartdata[posx].F + parseInt(value)
                }
                if(item.includes('M.')){
                    rangeAge = item.replace('M.','')
                    posx = _chartdata.findIndex(el => el.name === rangeAge)
                    if(posx !== -1) _chartdata[posx].M = _chartdata[posx].M + parseInt(value)
                }
                if(item.includes('U.')){
                    rangeAge = item.replace('U.','')
                    posx = _chartdata.findIndex(el => el.name === rangeAge)
                    if(posx !== -1) _chartdata[posx].U = _chartdata[posx].U + parseInt(value)                  
                }
            })
            setChartData(_chartdata)

            const _total_men =  _chartdata.map(item => item.M).reduce((prev, curr) => prev + curr, 0)
            setTotalMen(_total_men)

            const _total_women =  _chartdata.map(item => item.F).reduce((prev, curr) => prev + curr, 0)
            setTotalWomen(_total_women)

            const _total_u =  _chartdata.map(item => item.U).reduce((prev, curr) => prev + curr, 0)

            const _total = _total_men + _total_women + _total_u
            setTotal(_total)

        }
    }, [data])

    let contentView = <NoAnalyticsMessage />
    if(data){
        switch(view){
            case 1:
                contentView = <Grid container spacing={2} style={{marginTop: 10}}>
                                {chartdata.map( (item, key) => {
                                    return(
                                        <Grid key={`progress-bar-${key}`} item xs={12}>
                                            <AnalyticsProgressBar label={item.name} units={item.U + item.M +  item.F} total={total} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                break
            case 2:
                contentView = <Grid container spacing={2} style={{marginTop: 10}}>
                                {chartdata.map( (item, key) => {
                                    return(
                                        <Grid key={`progress-bar-${key}`} item xs={12}>
                                            <AnalyticsProgressBar label={item.name} units={item.M } total={total_men} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                break
            case 3:
                contentView = <Grid container spacing={2} style={{marginTop: 10}}>
                                {chartdata.map( (item, key) => {
                                    return(
                                        <Grid key={`progress-bar-${key}`} item xs={12}>
                                            <AnalyticsProgressBar label={item.name} units={item.F} total={total_women} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                break
            default:
                break
        }
    
    }
    

    return ( 
        <div style={{ width: '100%', marginBottom: 10}}>
            <div>
                <PageMenu options={menu} selected={view} onChange={(id)=>setView(id)} />
                {contentView}
            </div>
        </div>
     );
}
 
export default FacebookAgeChart;

const basedata = [
    {
        name:'13-17',
        F:0,
        M:0,
        U:0
    },
    {
        name:'18-24',
        F:0,
        M:0,
        U:0
    },
    {
        name:'25-34',
        F:0,
        M:0,
        U:0
    },
    {
        name:'35-44',
        F:0,
        M:0,
        U:0
    },
    {
        name:'45-54',
        F:0,
        M:0,
        U:0
    },
    {
        name:'55-64',
        F:0,
        M:0,
        U:0
    },
    {
        name:'65+',
        F:0,
        M:0,
        U:0
    },
]