import React from 'react'
import { CircularProgress, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { blue, green } from '@mui/material/colors'

const LoadingContainer = ({loading, children, sending, error, onCloseError}) => {
    const { classes } = useStyles()
    return(
        <div>
            {loading ? (
                <div style={{paddingTop:80}}>
                    <Grid container justifyContent='center'><Grid item><CircularProgress /></Grid></Grid>
                </div>
            ) : children}
        </div>
    )
}

const useStyles = makeStyles()(theme => ({
    root:{
        padding:0,
        [theme.breakpoints.down('md')]:{
            padding:16,
        }
    },
    container:{
        padding:'32px 0px'
    },
    success:{
        background:green[700]
    },
    sending:{
        background:blue[700]
    }
}));

export default LoadingContainer