import React from 'react'
import SimpleModal from '../../../components/Modals/SimpleModal'
import {
  Grid,
  IconButton,
  Typography,
  Icon,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import useAddManagerModal from './useAddManagerModal'
import translations from '../../../texts/system_translations'
import InputForm from '../../../components/Forms/InputForm'
import RoundedButton from '../../../components/Actions/RoundedButton'
import { useEffect } from 'react'
import InfluencersSearch from '../../../layouts/WebsiteLayout/components/InfluencersSearch'

const AddManagerModal = ({open, onClose, language, master_actions, onUpdateUserData,user,history}) => {

    const { classes } = useStyles()
    const content = translations[language].modals.add_manager_modal
    const theme = useTheme()
    
    const { form, actions, system } = useAddManagerModal(language, open, content, master_actions, onUpdateUserData)
    const ismobile = useMediaQuery(theme.breakpoints.down('md'))

    useEffect( () => {
      system.setSend(false)
    }, [open])

    const completePreValue = (data,pre_value) => {
        let temp = {...data};
        temp.value = pre_value;
        temp.isValid = true
        return temp
    }

    const customActionSearch = (user_item) => {
      if(user_item){

        let _form = {...form}
        const username = user_item.username
        const email = user_item.email

        _form.username_manager = completePreValue(form.username_manager,username)
        _form.email = completePreValue(form.email,email)

        /*
        const username_manager = form.username_manager
        let temp = {...username_manager}
        temp.value = username
        temp.isValid = true
        actions.onChangeForm(temp)

        const email_manager = form.email
        temp = {...email_manager}
        temp.value =email
        temp.isValid = true
        actions.onChangeForm(temp)
        */
       actions.onChangeForm(_form,true)
      }
    }

  return (
    <SimpleModal open={open} onClose={onClose} maxWidth='sm' className={classes.modal}>
      <Typography variant='h6' className={classes.title}>{content.title}</Typography>

      <Grid spacing={3} container>
        <Grid xs={12} item>
          <Grid xs={12}>
            <Typography variant='subtitle2'>{content.username}</Typography>
            <InputForm hidden = {true} data={form.username_manager} onChange={actions.onChangeForm}/>
            <InfluencersSearch onlyManagers = {true} customActionSearch = {customActionSearch} mono={false}  ismobile={ismobile} user={user} history={history} />
          </Grid>
        </Grid>

        <Grid xs={12} hidden item>
          <Grid xs={12}>
            <Typography variant='subtitle2'>{content.email_manager}</Typography>
            <InputForm data={form.email} onChange={actions.onChangeForm}/>
          </Grid>
        </Grid>
      </Grid>

      {system.error && 
      <Grid xs={12} className={classes.error}>
        <Typography variant='subtitle2'>{system.error}</Typography>
      </Grid>}

      {system.send
      ? <Grid xs={12} className={classes.success}>
          <Typography variant='subtitle2' color='primary'>Invitación enviada correctamente</Typography>
        </Grid>
      : null}

      <Grid xs={12} container>
        <Grid xs={9}></Grid>
        <Grid xs={3} container className={classes.button}>
          <RoundedButton loading={system.sending} onClick={() => actions.onSubmit()}>
            <Typography variant='subtitle2'>{content.add_button}</Typography>
          </RoundedButton>
        </Grid>
      </Grid>

    </SimpleModal>
  )
}

export default AddManagerModal

const useStyles = makeStyles()(theme => ({
  title:{
      marginBottom:'25px'
  },
  button:{
    position:'relative',
    top:'18px',
    justifyContent:'end'
  },
  error:{
    textAlign:'center',
    marginTop:'20px',
    color:'red'
  },
  success:{
    textAlign:'center',
    marginTop:'20px',
  }
}));