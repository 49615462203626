import { useEffect } from 'react';
import { Typography, Divider, Icon, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { CONFIG_SETTINGS } from '../../config';
import system_translations from '../../texts/system_translations';
import ContactModal from '../../modals/ContactModal/ContactModal';
import Footer from '../../components/Structure/Navigation/Footer';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import { actionTypes } from '../../store/actions';
import PodcastCard from './components/PodcastCard';
import usePodcasts from './usePodcasts'
import PodcastModal from './components/PodcastModal';
import usePodcastModal from './components/usePodcastModal';
import GenericSearchBar from '../../components/Platform/GenericSearchBar';

const Podcasts = ({ history, language, user, onChangePage }) => {

    const content = system_translations[language].layouts.podcasts
    const { classes } = useStyles({banner: content.banner})
    const { actions, podcastsFiltered, modalOpen, editModalMode } = usePodcasts()
    const { actionsModal, form, error, sending, sent, creatorLinks } = usePodcastModal(user, language, actions.setModalOpen, editModalMode)
    useEffect(() => {
        actions.onInitModule()
        actionsModal.onInitModule()
    }, [])

    useEffect(() => {
        if (sent) {
            actions.onInitModule()
            actionsModal.onInitModule()
        }
    }, [sent])

    const editPodcast = (index) => {
        const podcast = podcastsFiltered[index]
        actionsModal.setFormValues(podcast)
        actions.setModalEditMode(true)
        actions.setModalOpen(true)
    }

    return (
        <div className={classes.root}>
            < ContactModal />

            <PodcastModal open={modalOpen} onClose={actions.onCloseModal} actions={actionsModal} form={form} error={error} sending={sending} creatorLinks={creatorLinks} />

            <PublicTopbar lightTheme={false} content={content.topbar} onChangePage={onChangePage} />
            <div className={classes.bannerContainer}>
                <img src={content.social_trenders_logo} className={classes.social_trenders}></img>
            </div>
            <div className={classes.titleContainer}>
                <Typography variant="h2" className={classes.subtitle}>{content.subtitle}</Typography>
                <Typography variant="h1" className={classes.title}>{content.title}</Typography>
            </div>
            <Divider />
            <div className={classes.toolBar}>
                {user?.user_type_id === 1 &&
                    <IconButton className={classes.addButton} size='medium' onClick={() => actions.setModalOpen(true)}><Icon>add</Icon></IconButton>
                }
                <GenericSearchBar customStyle={classes.serchBar} onRequestSearch={actions.onRequestSearch}></GenericSearchBar>
            </div>
            {
                podcastsFiltered.map((element, index) =>
                    <PodcastCard onEdit={() => editPodcast(index)} onCompleted={actions.onCompletedEditPicture} edit_permission={user?.user_type_id === 1} key={element.number} title={element.title} creator={element.creator} description={element.description} image={`${CONFIG_SETTINGS.S3_SERVER_URL}${element.image}`} number={element.number} />
                )
            }
            <Footer history={history} />
        </div>
    )
}


const useStyles = makeStyles()((theme, { banner }) => ({
    root: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        overflow: 'hidden'
    },
    bannerContainer: {
        position: 'relative',
        width: '100%',
        minHeight: 700,
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: '70%',
        backgroundSize: 'cover'
    },
    social_trenders: {
        position: 'absolute',
        top: '75%',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '30%',
        zIndex: 2,
        [theme.breakpoints.down('lg')]: {
            minWidth: '95%'
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        margin: '50px 80px 50px',
        fontFamily: 'Raleway',
        [theme.breakpoints.down('lg')]: {
            margin: '50px 10px',
        },
    },
    subtitle: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 16
        },
        fontSize: 20,
        marginBottom: 38,
        fontWeight: 400,
    },
    title: {
        fontFamily: 'Raleway',
        fontWeight: 700,
        color: theme.palette.primary.main,
        fontSize: 36,
        [theme.breakpoints.down('lg')]: {
            fontSize: 15,
        }
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '100px 0'
    },
    addButton: {
        border: '2px solid #E3E3E3',
        background: 'rgba(120, 41, 159, 0.00)',
        width: 'auto',
        height: '100%',
        aspectRatio: '1 / 1',
        padding: '23px'
    },
    serchBar: {
        display: 'flex',
        fontFamily: 'Raleway',
        width: 530,
        height: 34,
        padding: '18px 32px',
        gap: 10,
        borderRadius: 50,
        border: '2px solid #E3E3E3',
        background: 'rgba(120, 41, 159, 0.00)',
        marginLeft: 20,
        [theme.breakpoints.down('lg')]: {
            marginLeft: 10,
            width: '50%',
        }
    }
}));

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: state.language,
        modals: state.modals
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateModal: (modal) => dispatch({ type: actionTypes.SYS_UPDATE_MODAL, modal }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Podcasts)