import { useState } from 'react';
import { Icon , Typography, Popover} from '@mui/material';

const PopoverIcon = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <Icon fontSize='small' style={{ marginTop: 2 }}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={handlePopoverOpen}
                onMouseEnter={handlePopoverOpen}
                >info</Icon>
            <Popover
                id="mouse-over-Popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }} style={{ padding: 4 }}>
                    Las vistas y engagment para historias pueden mostrar estadisticas incorrectas
                </Typography>
            </Popover>
        </>
    )
}

export default PopoverIcon;