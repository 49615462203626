import React, { useState } from 'react';
import axios from 'axios'
import system_translations from '../../../../../texts/system_translations';
import PictureModal from '../../../../../components/Modals/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob, updateAuthorizationHeader } from '../../../../../shared/utility'
import { private_server } from '../../../../../config';
import PictureHDModal from '../../../../../components/Modals/PictureHDModal';

const EditProfileBanner = (props) => {

    const {open, onClose, language, profile, owner, actions} = props
    const content = system_translations[language].modals.edit_profile_banner

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        try {
            setLoading(true)
            let request_url = owner ? `/user/me/image` : `/user/${profile.id_user}/image`
            const _file = await onGetImageURL2Blob(file)
            const data = {name:'banner_image', ext:onGetFileExtension(_file.name)}
            updateAuthorizationHeader(private_server)
            const image_req =  await private_server.post(request_url, data)
            const options = {headers: {'Content-Type': file.type}}
            delete axios.defaults.headers.common["Authorization"]
            await axios.put(image_req.data.data.url, _file, options);
            await actions.onGetUserData()
            await actions.onUpdateModalStatus('edit_profile_banner', false)
            //onCompleted()
            //onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
        setLoading(false)
    }

    const onDelete = async() => {
        try {
            setError(null)
            setLoading(true)
            let request_url = owner ? `/user/me/image?name=banner_image` : `/user/${profile.id_user}/image?name=banner_image`
            await private_server.delete(request_url)
            await actions.onGetUserData()
            await actions.onUpdateModalStatus('edit_profile_profile', false)
        } catch (error) {
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
        setLoading(false)
        
    }


    return ( 
        <PictureHDModal open={open} onClose={onClose} loading={loading} error={error} language={language} max aspect_ratio={4}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} onDeleteFile={onDelete}/>
     );
}
 
export default EditProfileBanner;