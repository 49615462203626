import { Grid, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import InputSelectForm from "../../../../../../../components/Forms/InputSelectForm"
import SimpleModal from "../../../../../../../components/Modals/SimpleModal"
import system_translations from "../../../../../../../texts/system_translations"
import DisplayError from "../../../../../../../components/Structure/DisplayData/DisplayError"
import useInstagramPagesModal from './useInstagramPagesModal'
import RoundedButton from "../../../../../../../components/Actions/RoundedButton"



const InstagramPagesModal = ({open, onClose, language, owner, onCompleted, profile, master_actions}) => {

    const { classes } = useStyles()
    const content = system_translations[language].modals.add_facebook_instragram_pages
    const {system, sending, error, actions, form} = useInstagramPagesModal({open, language, owner, content, onCompleted, onClose, profile, master_actions})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Typography variant='h6'>
                Instagram
            </Typography>
            <Typography>
                Por favor seleccione una página para Instagram que quiera vincular con <strong className={classes.highlight}>InPager</strong>. Se revisará si tiene una cuenta asociada a Instagram
            </Typography>
            <div style={{marginTop:32}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputSelectForm data={form.facebook_page_id} onChange={actions.onChangeForm} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.button_container}>
                {system.error ? <Typography align="center" color='error' style={{marginBottom:16}}>{system.error}</Typography>: null}
                <RoundedButton disabled={Boolean(system.error)} onClick={actions.onSubmit} loading={system.sending} >{content.add_button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default InstagramPagesModal

const useStyles = makeStyles()(theme => ({
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    pic_container:{
        position:'relative'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    },
    highlight:{
        color:theme.palette.primary.main
    }
    
}));