import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Collapse, CardHeader } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ExpandMore } from '@mui/icons-material';

const QuestionCard = ({ question, answer }) => {
    const { classes } = useStyles()

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    return (
        <Card classes={{'& .MuiPaper-elevation1': {boxShadow: '0px'}}} className={classes.root}>
            <CardHeader
                className={classes.cardHeader}
                title={<Typography className={classes.question}>{question}</Typography>}
                action={
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        className={classes.icon}
                        size="large">
                        <ExpandMore />
                    </IconButton>
                }
                sx={{fontSize: '1em'}}
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.content}>
                <CardContent>
                    <Typography paragraph>{answer}</Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
};

const useStyles = makeStyles()(theme => ({
    root: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        marginBottom: theme.spacing(2),
        paddingBottom: 5,
        margin: 'auto',
        maxWidth: 870,
        width: '70%',
        boxShadow: 'none',
        [theme.breakpoints.down('lg')]:{
            width: '80%',
        }
    },
    icon: {
        position: 'absolute',
        top: '50%',
        marginTop: -17,
        marginLeft: -51,
        height: 34,
        width: 34,
        [theme.breakpoints.down('lg')]:{
            marginTop: -17,
            marginLeft: -51,
            height: 34,
            width: 34,
        },
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    content: {
        width: '95%',
        margin:'auto',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '0px 0px 14px 14px',
        boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.13)' 
    },
    cardHeader: {
        position: 'relative',
        backgroundColor: theme.palette.primary.light,
        fontWeight: 30,
        height: 100,
        borderRadius: 14,
        boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.13)'
    },
    question:{
        fontWeight: 700,
        fontSize: '1.6em',
        [theme.breakpoints.down('lg')]:{
            fontSize: '1em',
            marginRight: 40
        }
    }
}));

export default QuestionCard;
