import React from 'react'
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import { Grid, Typography } from '@mui/material';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import CategoryChip from '../../../../../components/Platform/CategoryChip';
import useAddTypeFigureModal from './useAddTypeFigureModal';
import MultiSelectOption from '../../components/MultiSelectOption';

const AddTypeFigureModal = ({open, onClose, user_types, user_id, owner, master_actions}) => {

    const { actions, system, view_data } = useAddTypeFigureModal({open, user_types, user_id, owner, master_actions})

  return (
    <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
        <div>
            <Typography variant='h6'>Tipos de figura pública</Typography>
            <Typography>Selecciona hasta 3 tipos para agregar a tu perfil:</Typography>
            <div>
                <Grid container spacing={1}>
                    {view_data.selected_types.map(item =>{
                        return(
                            <Grid item>
                                <CategoryChip {...item}  editable
                                    onRemove={() => actions.onRemoveType(item)}/>
                            </Grid>

                            )
                        })}
                </Grid>
            </div>
            <MultiSelectOption loading={system.loading} items={view_data.types} onSelected={actions.onTypeSelected}/>
                <div style={{marginTop:32}}>
                    <ActionModalBar btnLabel={'Guardar'} loading={system.sending} error={system.error} onSubmit={actions.onSubmit} onCloseError={actions.onResetError}  />
                </div>

            </div>
    </SimpleModal>
  )
}

export default AddTypeFigureModal
