import { alpha, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import RoundedButton from '../../../../../../components/Actions/RoundedButton';
import { EVENT_TYPES } from '../../../../../../config';
import { blueGrey, grey } from '@mui/material/colors';

const ConferenceToolbar = ({selected, modify, actions}) => {

    const { classes, cx } = useStyles()

    return ( 
        <div className={classes.root}>
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs>
                    <Grid container alignItems='center' spacing={2}>
                        {menu.map(item=>{
                            return(
                                <Grid item>
                                    <div className={cx({
                                        [classes.option]:true,
                                        [classes.option_selected]:item.id === selected,
                                    })} onClick={() => actions.onChangeView(item.id)}>
                                        {item.label}
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                {modify ? <Grid item>
                    <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('add_event', true)}>Agregar</RoundedButton>
                </Grid> : null}
            </Grid>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily
    },
    option:{
        fontSize:24,
        fontWeight:600,
        color:blueGrey[300],
        paddingBottom:4,
        borderBottom:'1px solid transparent',
        cursor:'pointer',
        '&:hover':{
            //background:alpha(theme.palette.primary.main, 0.25)
        }
    },
    option_selected:{
        color:'black',
        borderBottom:`4px solid ${theme.palette.primary.main}`
    }
    
}));

export default ConferenceToolbar;

const menu = [
    {id:EVENT_TYPES.CONFERENCE, label:'Conferencias'},
    {id:EVENT_TYPES.COURSE, label:'Cursos'},
]