import React from 'react'
import { Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors'
import { DASHBOARD_SETTINGS } from '../../../config'

const useStyles = makeStyles()(theme => ({
    root:{
        borderRadius:14,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        padding:24,
        position:'relative',
        [theme.breakpoints.down('md')]:{
            padding:16,
        }
    },
    clickable:{
        '&:hover':{
            cursor:'pointer',
            background: grey[100]
        }
    }
}));

const SimpleCard = ({children, padding, onClick, style}) => {
    const { classes, cx } = useStyles()

    let styles = {}
    if(padding === 'small') styles = {...styles, padding:12}
    if(padding === 'none') styles = {...styles, padding:0}

    return(
        <Card className={cx({
            [classes.root]:true,
            [classes.clickable]: onClick ? true: false
        })} style={{...styles, ...style}} onClick={onClick} >
            {children}
        </Card>
    )
}

export default SimpleCard