import React from 'react';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard';

const IMAGE_SIZE = 60

const SocialMediaCard = (props) => {

    const { classes } = useStyles()
    const {id, label, url, icon} = props

    return ( 
        <div style={{position:'relative'}}>
            {!url? <div className={classes.disabled}/> : null}
            <SimpleCard padding='small'>
                
                <a href={url !== '' ? url : undefined} target='_blank'>
                    <div className={classes.image_container}>
                        <img src={icon} alt='' className={classes.icon} />
                    </div>
                </a>
                
            </SimpleCard>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    image_container:{
        width:IMAGE_SIZE,
        height:IMAGE_SIZE,
        cursor:'pointer',
        
    },
    icon:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    },
    disabled:{
        position:'absolute',
        top:0, left:0,
        width:'100%', height:'100%',
        background:alpha("#FFF",0.7),
        zIndex:5,
        borderRadius:16,
    }
}));

export default SocialMediaCard;