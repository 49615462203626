import React from 'react';
import { Icon, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';


const GenericDeleteButton = (props) => {

    const {onClick} = props
    const { classes } = useStyles()

    return ( 
        <IconButton size='small' className={classes.edit_btn} onClick={onClick}>
            <Icon color='inherit' fontSize='small'>delete</Icon>
        </IconButton>
     );
}

const useStyles = makeStyles()(theme => ({
    edit_btn:{
        color:grey[500],
        padding:4
    }
}));

export default GenericDeleteButton;