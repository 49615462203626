import React, { useState } from 'react';
import { Typography, Icon, IconButton, Popover } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';
import SVGIcon from "../../../components/Structure/DisplayData/SVGIcon";
import RoundedButton from '../../../components/Actions/RoundedButton';
import { DASHBOARD_SETTINGS } from '../../../config'
import useInvitations from '../modals/useInvitations';
import personSearchIcon from '../../../assets/personSearch.svg'
import InvitationCard from './InvitationCard';
import SendInvitationRosterModal from '../modals/SendInvitationRosterModal';
import { useHistory } from 'react-router';


const ManagerInvitations = ({user, language}) => {
  
  const [anchorEl, setAnchorEl] = useState(null)
  const [sortInvitations, setSortInvitations] = useState('Más reciente')
  const { actions, system, view_data } = useInvitations(user); 
  const { classes } = useStyles()
  const history = useHistory()
  const onChangeSort = (_value) => setSortInvitations(_value)
  const onInnerItemSelected = (name) => {
    setAnchorEl(null)
    onChangeSort(name)
    system.setHidden(true)
    setTimeout( () => {
      system.setHidden(false)
    }, 10)
  }

  const sortMode = ['Más reciente', 'Más lejano']

  const onInnerClose = () => {
    setAnchorEl(null)
  }

  let invitations

  if(sortInvitations === 'Más reciente'){
    invitations = view_data.invitations.sort(function(a, b) {
        if(a.created_at > b.created_at)
            return -1
        if(a.created_at < b.created_at)
            return 1
        return 0
    })
  } else {
    invitations = view_data.invitations.sort(function(a, b) {
        if(a.created_at > b.created_at)
            return 1
        if(a.created_at < b.created_at)
            return -1
        return 0
    })
  }

  return (
    <Grid container spacing={2} xs={12} className={classes.option}>
      <SendInvitationRosterModal open={system.modals.send_invitation} onClose={() => actions.onUpdateModalStatus('send_invitation', false)} language={language} user={user} history={history} master_actions={actions} />
      <Grid container xs={12} className={classes.title}>
        <Typography variant='h6'>Invitaciones de Roster</Typography>
        <Grid>
          {user?.user_type_id === 3 && <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('send_invitation', true)}><Icon>add_icon</Icon>Enviar invitación</RoundedButton>}
          {view_data.invitations.length !== 0 && 
          <>
            <RoundedButton size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>Ordenar por</RoundedButton>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onInnerClose} anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
              transformOrigin={{vertical: 'top',horizontal: 'left'}} classes={{paper: classes.paper}}>
              <Grid style={{padding:'10px 0px'}}>
                {sortMode.map(sort => {
                  return(
                    <Grid key={sort}>
                      <OptionSortComponent label={sort} onItemSelected={onInnerItemSelected}/>
                    </Grid>
                  )
                })}
              </Grid>
            </Popover>
          </>
          }
          {invitations.length !== 0 &&
            <IconButton
              color='primary'
              className={classes.iconButtonDownReviews}
              onClick={() => {
                actions.changeHidden(!system.hidden)
                system.setChange(!system.change)
              }}
              size="large">
              { system.hidden && system.change
              ? <Icon style={{fontSize:'1.7em'}}>expand_circle_down</Icon> 
              : <Icon style={{fontSize:'1.7em'}}>expand_less_outlined</Icon>}
            </IconButton>
          }
        </Grid>
      </Grid>
      <Grid container xs={12}>
          {invitations.length === 0
          ? <Grid xs={12} container className={classes.noInvitations}>
              <Grid container xs={12} style={{justifyContent:'center'}}>
                <SVGIcon size={91} src={personSearchIcon}></SVGIcon>
              </Grid>
              <Grid container xs={12} style={{justifyContent:'center'}}>
                <Typography variant='subtitle2'>No tienes invitaciones pendientes en InPager</Typography>
              </Grid>
            </Grid>
          : null }
          {!system.hidden &&
            invitations.map(invitation => {
            return(
              <Grid xs={12}>
                <InvitationCard invitation={invitation} user={user} invitationUser={view_data.invitationUser} master_actions={actions} history={history} />
              </Grid>
            )
            })}
      </Grid>
    </Grid>
  );
}

export default ManagerInvitations

const useStyles4Option = makeStyles()(theme=>({
  item:{
    height:'20px',
    padding:'10px 15px',
    transition:theme.transitions.create(['background'],{
      duration: theme.transitions.duration.standard,
      easing:theme.transitions.easing.easeIn
    }),
    '&:hover':{
      cursor:'pointer',
      fontWeight:700,
      background:theme.palette.primary.light,
      transition:theme.transitions.create(['background','font-weight'],{
        duration: theme.transitions.duration.standard,
        easing:theme.transitions.easing.easeOut
    }),
  }}
}));

const OptionSortComponent = props => {

  const {label, onItemSelected} = props
  const { classes } = useStyles4Option()

  return(
      <div onClick={() => onItemSelected(label)} className={classes.item}>
          {label}
      </div>
  )
}

const useStyles = makeStyles()(theme => ({
  title:{
    justifyContent: 'space-between',
    alignItems:'center'
  },
  option:{
    background:'white',
    boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
    marginBottom: '30px',
    padding: '15px 25px',
    borderRadius: '15px',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    alignText:'center',
  },
  noInvitations:{
    textAlign:'center',
    justifyContent:'center',
    display:'flex',
    flexDirection:'row'
  },
  paper:{
    borderRadius:12,
    marginTop:5,
    fontFamily:theme.typography.fontFamily,
    width:140
  }
}));