import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    conference:{
        title:'Conferencia',
        message:'Por favor ingrese los datos solicitados para agregar el contenido',
        add_button:'Agregar',
        edit_button:'Guardar',
        form:{
            title:{placeholder:'Título de la publicación', label:'Título de la publicación', helperText:'Campo requerido'},
            date:{placeholder:'Fecha de publicación', label:'Fecha de publicación', helperText:'Campo requerido'},
            link:{placeholder:'URL del sitio', label:'URL del sitio', helperText:''},
            description:{placeholder:'Resumen de la nota', label:'Resumen de la nota', helperText:'Campo requerido'},
        },
    },
    course:{
        title:'Curso',
        message:'Por favor ingrese los datos solicitados para agregar el contenido',
        add_button:'Agregar',
        edit_button:'Guardar',
        form:{
            title:{placeholder:'Título de la publicación', label:'Título de la publicación', helperText:'Campo requerido'},
            date:{placeholder:'Fecha de publicación', label:'Fecha de publicación', helperText:'Campo requerido'},
            link:{placeholder:'URL del sitio', label:'URL del sitio', helperText:''},
            description:{placeholder:'Resumen de la nota', label:'Resumen de la nota', helperText:'Campo requerido'},
        },
    },
    nopicture:nopicture
}

export default translations