import { useState } from 'react';
import { public_server } from '../../config';

const usePodcastsPage = (number) => {
    const [podcast, setPodcast] = useState([])

    const actions = {
        onInitModule: async () => {
            const pocasts_response = await public_server.get(`podcast/${number}`);
            const podcast_temp = pocasts_response.data.data.podcast
            const re = new RegExp(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/);
            try {
                const id_ = re.exec(podcast_temp.youtube_url)[6]
            }
            catch {
                podcast_temp.youtube_url = `https://youtu.be/${podcast_temp.youtube_url}`
            }

            try {
                podcast_temp.creator_links = JSON.parse(podcast_temp.creator_links)
            }
            catch {
                podcast_temp.creator_links = null
            }

            setPodcast(podcast_temp);
        }
    }
    return { actions, podcast }
}

export default usePodcastsPage;