import { Grid, Icon, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import { nodata } from '../../config';
import { isValidDocument, onAdjustDatetime, onGetDateFormat, onGetFullResourcePath } from '../../shared/utility';
import GenericDeleteButton from '../Actions/GenericDeleteButton';
import GenericEditButton from '../Actions/GenericEditButton';
import RoundedButton from '../Actions/RoundedButton';
import ImageContainer from '../Ecommerce/ImageContainer';
import SimpleCard from '../Structure/Cards/SimpleCard';

const MediaKitCard = ({image, title, date, description, modify, link,
    onEdit, onDownload, onDelete}) => {

    const { classes } = useStyles()
    const mdate = onGetDateFormat(date, 'DD MMM, YYYY')

    let isValidImg = isValidDocument(image)
    let imgSrc = isValidImg ? onGetFullResourcePath(image) : null


    return ( 
        <SimpleCard>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <div style={{width:100}}>
                                <ImageContainer src={image} alt='' ratio='1/1' borderRadius={12}/>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant='subtitle1' style={{lineHeight:1.5}}>{title}</Typography>
                                </Grid>
                                {modify ? (
                                    <Grid item>
                                        <GenericEditButton onClick={onEdit}/>
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Typography variant='subtitle2' color='textSecondary'>{mdate}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.description}>{description}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent='flex-end' alignItems='center'>
                                {modify ? <Grid item >
                                    <GenericDeleteButton onClick={onDelete}/>
                                </Grid> : null}
                                <Grid item xs/>
                                <Grid item>
                                    <RoundedButton disabled={!Boolean(link)} size='small' onClick={onDownload}>Ver más </RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}

const useStyles = makeStyles()(theme => ({
    description:{
        minHeight:80,
    },
}));

export default MediaKitCard;