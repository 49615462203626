import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import useEditProfileDataModal from './useEditProfileDataModal';
import translations from '../../../../../texts/system_translations';
import InputForm from '../../../../../components/Forms/InputForm';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import { grey } from '@mui/material/colors';
import { Scrollbars } from 'react-custom-scrollbars-2';
import InputPhoneCodeForm from '../../../../../components/Forms/InputPhoneCodeForm';

const EditProfileDataModal = (props) => {

    const { classes } = useStyles()
    const {language, open, onClose} = props
    const content =  translations[language].modals.edit_profile

    const {system, form, actions } = useEditProfileDataModal({...props, content})
 
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <Typography variant='h6' style={{marginBottom:16}}>{content.title}</Typography>
                <div className={classes.container}>
                    <Scrollbars autoHeight autoHeightMax={600}>
                        <div className={classes.content}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='subtitle2'>Nombre completo</Typography>
                                    <InputForm data={form.first_name} onChange={actions.onChangeForm}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='subtitle2'>Ciudad de residencia</Typography>
                                    <InputForm data={form.zone} onChange={actions.onChangeForm}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>Frase</Typography>
                                    <InputForm data={form.quote} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{background:grey[400], height:1, marginTop:'8px'}}/>
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <Typography variant='subtitle1' style={{marginBottom:8}}>Informacion de contacto</Typography>
                                    <Grid container xs={12}>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2'>Telefono de contacto</Typography>
                                            <Grid container xs={12}>
                                                <div item style={{width:100, marginRight:'10px'}}>
                                                    <InputPhoneCodeForm data={form.mobile_country_code} onChange={actions.onChangeForm} />
                                                </div>
                                                <Grid item xs style={{marginTop:'3px'}}>
                                                    <InputForm data={form.mobile} onChange={actions.onChangeForm} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} style={{marginTop:42.5}}>
                                    <Typography variant='subtitle2'>WhatsApp Business</Typography>
                                    <InputForm data={form.whatsapp_link} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='subtitle2'>Correo electrónico de contacto</Typography>
                                    <InputForm data={form.email_public} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='subtitle2'>Sitio web</Typography>
                                    <InputForm data={form.website_link} onChange={actions.onChangeForm} />
                                </Grid>           
                                
                            </Grid>
                        </div>
                    </Scrollbars>
                    <div style={{marginTop:16}}>
                    <Grid container spacing={3}>                  
                        <Grid item xs>
                            <ActionModalBar btnLabel={content.edit_button} error={system.error} loading={system.sending}
                            onSubmit={actions.onSubmit} onCloseError={actions.onResetError}  />
                        </Grid>
                    </Grid>
                    </div>

                </div>
                
                
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16,
        maxHeight:400,
        paddingRight:16
    }
}));



export default EditProfileDataModal;