import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import { alpha } from '@mui/material';

const MultiSelectItem = (props) => {
    const { classes, cx } = useItemStyles()
    const {name, onSelected, selected} = props

    return(
        <div className={cx({
            [classes.root]:true,
            [classes.root_selected]:selected,
        })} onClick={() => onSelected(props) }>
            {name}
        </div>
    )
}

const useItemStyles = makeStyles()(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        padding:'16px 16px',
        border:`2px dotted ${grey[300]}`,
        marginBottom:8,
        cursor:'pointer',
        borderRadius:6,
        '&:hover':{
            //border:`2px solid ${theme.palette.primary.main}`,
            background:alpha(theme.palette.primary.light, 0.4)
        }
    },
    root_selected:{
        border:`2px solid ${theme.palette.primary.main}`,
        background:alpha(theme.palette.primary.light, 0.4),
        fontWeight:600
    }
}));

export default MultiSelectItem;