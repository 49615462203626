import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Agregar manager',
    add_button:'Guardar',
    username:'Usuario de Manager en InPager*',
    email_manager:'Correo electrónico*',
    form:{
        username_manager:{helperText:'Campo requerido'},
        email:{placeholder:'colaboraciones@agencia.com', helperText:'Campo requerido'},
    },
    nopicture:nopicture
}

export default translations