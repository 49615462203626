import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ImageDropZone from '../../../../../components/Forms/ImageDropZone';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import translations from '../../../../../texts/system_translations';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import ImageContainer from '../../../../../components/Ecommerce/ImageContainer';
import Scrollbars from 'react-custom-scrollbars-2';

const SeeAllUserImagesModal = (props) => {

    const { classes } = useStyles()
    const {language, open, onClose, profile, master_actions, owner, origin, images, onAdd, onEdit, modify} = props
    const content =  translations[language].modals.add_user_image

    //const {system, form, actions, view_data} = useAddUserImageModal({...props, content})

    let _images = images ? images : []
 
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div >

                <Typography variant='h6'>Todas las imágenes</Typography>   
                <div className={classes.content}>
                    <Scrollbars  >
                        <div  >
                            <Grid container >

                                {_images.map((item) => 
                                  
                                {
                                 return (
                                        <Grid item md={4} sm={6} xs={12}>
                                            <div style={{padding:4}}>
                                                <ImageContainer download_path = {item.download_path}  title={item.title} src={item.image} borderRadius={8} onEdit={()=>onEdit(item)} modify={modify} withFullscreen = {true}/>
                                                
                                            </div>
                                        </Grid>
                                    )
                                }
                                
                                )}
                                {modify ? <Grid item md={4} sm={6} xs={12}>
                                    <div style={{padding:4}}>
                                        <ImageContainer onUpload={onAdd} />
                                    </div>
                                </Grid> : null}
                                {/* <Grid item xs={12} md={4}>
                                    <ImageDropZone language={language} onChange={actions.onChangeImage} preview={view_data.preview} onErase={actions.onResetImage}/>
                                </Grid> */}
                                {/* <Grid item xs={12}>
                                    <ActionModalBar btnLabel={content.add_button} error={system.error} loading={system.sending}
                                    onSubmit={actions.onSubmit} onCloseError={actions.onResetError}  
                                    onDelete={actions.onPreDelete}/>
                                </Grid> */}
                            </Grid>
                        </div>
                    </Scrollbars>
                </div>
                
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16,
        height:660,
        //padding:2
    }
}));



export default SeeAllUserImagesModal;