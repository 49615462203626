import { useState, useEffect } from "react"
import { private_server, public_server, USER_TYPES } from "../../../config"
import { updateAuthorizationHeader } from "../../../shared/utility"
import queryString from 'query-string'

const useManagersView = ({ user,  match, history}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [influencers, setInfluencers] = useState([])
    const [owner, setOwner] = useState(false)
    const [filter, setFilter] = useState({
        limit:9, offset:0, order:0, order_by:'first_name'
    })
    const [query, setQuery] = useState({
        search:''
    })

    const [modals, setModals] = useState({
        edit_profile:false,
        edit_profile_picture:false,
        edit_profile_banner:false,
        add_media_kit:false,
        edit_media_kit:false,
        category_filter:false,
        influencer_type_filter:false,
        influencer_size_filter:false
    });

    const [filters, setFilters] = useState([

    ])

    useEffect(() => {
        //if(user) 
        actions.onInitModule()
    }, [user, filter, query, filters])

    useEffect(()=>{window.scrollTo(0,0)}, [])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const args = onGetParams()
                const url = `/user/all/public/ns${args}`
                const request_users = await public_server.get(url)
                setInfluencers(request_users.data.data.users)
            } catch (error) {
            }
            setLoading(false)
        },
        onSelectedUser: (_user) => {
            history.push(`/dash/managers/${_user.url}`)
        },
        onRequestSearch: async(word) => {
            let _query = {...query}
            _query.search = word
            setQuery(_query)
        },  
        onGetProfileDetails: async() => {
            try {
                updateAuthorizationHeader(private_server)
                // Get profile categories 
                //const filter = {"AND":[{field:'user_id', operator:'=',value:profile.id_user}]}
                //const user_categories_req = await public_server.get(`/influencercategory/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                //const user_categoires = onGetCatalogs(user_categories_req.data.data.influencer_categories, 'category_id', 'category_name')
                //setInfluencerCategories(user_categoires)
            } catch (error) {
            }
        },
        onOpenFilterModal: (_data) => {
            actions.onUpdateModalStatus(_data.id, true)
        },
        onUpdateFilters: (data, type) => {
            let _filters = []
            filters.forEach(item => {
                if(item.type !== type) _filters.push(item)
            })
            _filters = [..._filters, ...data]
            setFilters(data)
        },
        onRemoveSingleFilter: (_data) => {
            let _filters = []
            const temp = JSON.stringify(_data)
            filters.forEach(item => {
                if(JSON.stringify(item) !== temp) _filters.push(item)
            })
            setFilters(_filters)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        

    }

    const onGetParams = () => {
        let response = ''
        response = `?${queryString.stringify(filter)}`
        let specialFilter = [{field:'user_type_id', operator:'=',value:`${USER_TYPES.MANAGER}`}]
        const {search} = query
        if(search){
            let ORSegmentsFilters = [
                {field:'first_name', operator:'LIKE',value:`%${search}%`},
                {field:'zone', operator:'LIKE',value:`%${search}%`}
            ]
            specialFilter.push({"OR":[...ORSegmentsFilters]})
        }
        if(filters.length){
            filters.forEach(item => {
                switch(item.type){
                    case 'category_filter':
                        specialFilter.push({field:'category_id', operator:'=',value:`${item.id}`})
                        break;
                    case 'influencer_type_filter':
                        specialFilter.push({field:'influencer_type_id', operator:'=',value:`${item.id}`})
                        break;    
                    case 'influencer_size_filter':
                        specialFilter.push({field:'influencer_size_id', operator:'=',value:`${item.id}`})
                        break;
                    default:
                        break;
                }
            })
        }
        specialFilter = {"AND":specialFilter}
        response = `${response}&filter=${encodeURIComponent(JSON.stringify(specialFilter))}`
        return response
    }

    const system = {loading, sending, error}
    const view_data = {influencers, filters}

    return {system, view_data, actions, modals}
}

export default useManagersView
