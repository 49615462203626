import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import NoContent from '../../../../../components/Platform/NoContent';
import LoadingContainer from '../../../../../components/Structure/Layouts/LoadingContainer';
import AddBusinessModal from '../../modals/AddBusinessModal/AddBusinessModal';
import AddProductModal from '../../modals/AddProductModal/AddProductModal';
import DeleteBusinessModal from '../../modals/DeleteBusinessModal';
import EditBusinessModal from '../../modals/EditBusinessModal/EditBusinessModal';
import BusinessCard from './components/BusinessCard';
import useBusiness from './useBusiness';


const Business = (props) => {

    //const content = translations_esp
    const { classes } = useStyles()

    const {system, view_data, actions, modals} = useBusiness({...props})
    const {language, content, master_view_data} = props

    return ( 
        <div>
            <AddBusinessModal open={modals.add} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} 
            onClose={()=>actions.onUpdateModalStatus('add', false)} master_actions={actions} />
            <EditBusinessModal open={modals.edit} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.business}
            onClose={()=>actions.onUpdateModalStatus('edit', false)} master_actions={actions} />
            <DeleteBusinessModal open={modals.delete} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.business}
            onClose={()=>actions.onUpdateModalStatus('delete', false)} master_actions={actions} />
       

            <div style={{marginBottom:16}}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='h6'>Empresas</Typography>
                    </Grid>
                    {master_view_data.modify ? <Grid item>
                        <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('add', true)}>Agregar</RoundedButton>
                    </Grid> : null}
                </Grid>
            </div>
            <LoadingContainer loading={system.loading}>
                
                
                <Grid container spacing={3}>
                    {view_data.businesses.map((item) => {
                        return(
                            <Grid item xs={12} key={item.id_business}>
                                <BusinessCard {...item} key={item.id_business} modify={master_view_data.modify} origin={item} 
                                profile={master_view_data.profile} owner={master_view_data.owner} language={language} 
                                onGetProducts={actions.onGetProducts} 
                                onEdit={() => actions.onEditBusiness(item)} 
                                onDelete={() => actions.onDeleteBusiness(item)}
                                />
                            </Grid>
                        )
                    })}
                    
                </Grid>
                {view_data.businesses.length === 0 ? (
                        <div style={{paddingBottom:32}}>
                            <Grid container justifyContent='center'>
                                <Grid item xs={12}>
                                    <NoContent />
                                </Grid>
                                {master_view_data.modify ? <Grid item>
                                    <RoundedButton onClick={()=>actions.onUpdateModalStatus('add', true)}>
                                        Agregar
                                    </RoundedButton>
                                </Grid> : null}
                            </Grid>
                            
                        </div>
                ) : null}
            </LoadingContainer>

           
        </div>
        
     );
}

const useStyles = makeStyles()(theme => ({
    topbar:{
        marginBottom:40
    }
}));



export default Business;