import { connect } from 'react-redux';
import { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { actionTypes } from '../../../store/actions';
import InputForm from '../../../components/Forms/InputForm';
import CustomSelectForm from '../../../components/Forms/CustomSelectForm';
import system_translations from '../../../texts/system_translations';
import Page from '../../../components/Structure/Layouts/Page';
import useNewInfuencerView from './useNewInfuencerView';


const NewInfuencerView = ({ user, language, history }) => {
    const content = system_translations[language].layouts.signup
    const { classes } = useStyles()
    const { system, form, actions, view_data } = useNewInfuencerView({ language, content, history })

    useEffect(() => {
        actions.onInitModule();
        actions.onUpdateUserType(6);
        //this is hacky
        const data = {
            value: user?.id_user,
            error: false,
            isVisited: true,
            isRequired: false,
            isValid: true,
            config:{
                id:'influencer_client_id',
                type:'test',
            },
            rules:{
                type:'none'
            }
        }
        if(user?.user_type_id === 3){
            actions.onChangeForm(data)
        }
    }, [user])


    return (
        <Page loading={system.loading}>
            <Grid className={classes.container} container direction="column" spacing={3}>
                <Grid item xs={12}>
                    <InputForm data={form.first_name} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={12}>
                    <InputForm data={form.email} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={12}>
                    <InputForm data={form.username} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={12}>
                    <Button className={classes.button} 
                    onClick={() => actions.onSubmit()} >{content.overview_section.next_button}</Button>
                    <Typography className={classes.error} >{system.error}</Typography>
                    {system.finish &&
                    <>
                        <Typography className={classes.finish}>{content.overview_section.message}</Typography>
                        { user?.user_type_id !== 1 && 
                            <Typography className={classes.finish}>{content.overview_section.submessage} {view_data?.user?.email}</Typography>    
                        }
                        <Typography className={classes.finish}>{content.overview_section.submessage_admin}
                            <a href={`/dash/influencers/${view_data?.user?.url}`}>{content.overview_section.button}</a>
                        </Typography>
                    </>
                    }
                </Grid>
            </Grid>
        </Page>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({ type: actionTypes.ECO_UPDATE_CART, cart })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInfuencerView);

const useStyles = makeStyles()(theme => ({
    container: {
        padding: 25,
        borderRadius: 16,
        boxShadow: '1px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 7%), 0px 3px 14px 2px rgb(0 0 0 / 6%)'
    },
    button: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.light,
        '&:hover':{
            color: theme.palette.primary.dark
        }
    },
    error: {
        color: theme.palette.error.main
    },
    finish: {
        textAlign: 'center',
        color: theme.palette.primary.main
    }
}));