import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useState } from 'react';
import { ReactSVG } from 'react-svg'

const SVGIcon = ({src, size, color, width, height, style}) => {

    const { classes } = useStyles()
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)

    const _size = 32
    const icon_size = size ? size : _size

    return ( 
        <div style={{width: width ? width : icon_size, height:  height ? height : icon_size, ...style}}>
            <div className={classes.root}>
                <ReactSVG 
                    src={src}
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', `fill: ${color ? color : '#000'}`)
                    }}
                    afterInjection={(error, svg) => {
                        if (error) {
                            setCount(2)
                            return
                        }
                        setCount(1)
                    }}
                />
            </div>
        </div>
        
    );
}

const useStyles = makeStyles()(theme => ({
    root:{
        width:'100%',
        height:'100%',
    }   
}));
 
export default SVGIcon;