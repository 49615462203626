import { Grid, Typography } from '@mui/material';
import { Rating } from '@mui/material';
import React from 'react';
import { ruleValidation } from './customFunctions';

const InputRating = (props) => {

    const {onChange, data, size} = props
    const {value, isVisited, isValid, disabled, isRequired} = props.data
    const {label, helper} = props.data.config

    const onInnerChange = (event, value) => {
        let temp = {...data};
        temp.value = value;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    }

    const error = isVisited && !isValid

    return ( 
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography variant='subtitle2'  color={error ? 'error' : 'inherit'} style={{paddingBottom:6}}>{label}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Rating max={5} size={size ? size : 'large'}  onChange={onInnerChange} value={value} readOnly={disabled}/>
            </Grid>
            <Grid item xs={12}>
                {error ? <Typography variant='caption' color='error'>{helper}</Typography> : null}
            </Grid>
        </Grid>
     );
}

export default InputRating;