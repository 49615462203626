import { useRef, useState } from "react"
import { public_server, USER_TYPES } from "../../../config"
import { onAddRemoveProductToCart } from "../../../shared/utility"

const useInfluencersSearch = ({user, cart, onUpdateCart, onlyManagers, both}) => {

    const [selected, setSelected] = useState(1)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [busy, setBusy] = useState(false)
    const [selected_product, setSelectedProduct] = useState(null)
    const [influencers, setInfluencers] = useState([])

    const inputRef = useRef()
    const timerRef = useRef()

    const actions = {
        onStartCountDown: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
            timerRef.current = setTimeout(actions.onRequestSearch, 300);
        },
        onStopCountDown: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
        },
        onRequestSearch: async() => {
            if(timerRef.current) clearTimeout(timerRef.current)
            // Start de search
            setLoading(true)
            try {
                const args = onGetParams(onlyManagers)
                const url = `/user/all/public/ns${args}`
                const request_users = await public_server.get(url)
                setInfluencers(request_users.data.data.users)

            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onUpdateSearchValue: (val) => {
            setValue(val.currentTarget.value)
        },
        onClickAway: (value) => {
            if(typeof value === 'string'){
                setValue(value)
            }else{
                setValue('')
            }
            setInfluencers([])
        },
        onSelectedProduct: (_product) => {
        },
        onChangeQuantity: async(_selected_product, _quantity, line_item) => {
            if(busy) return
            try {
                setBusy(true)
                setSelectedProduct(_selected_product)
                const _updatedCart = await onAddRemoveProductToCart(cart, _selected_product, _quantity,line_item, user, 'id_product')      
                onUpdateCart(_updatedCart)    
                setSelectedProduct(null)
                setBusy(false) 
            } catch (error) {
            }
        }

    }

    const onGetParams = (onlyManagers) => {
        let response = ''
        let specialFilter

        let type_user = USER_TYPES.INFLUENCER
        if(onlyManagers){
            type_user = USER_TYPES.MANAGER
        }
        //response = `?${queryString.stringify(filter)}`
        if(both) {
            specialFilter = [
                {field:'email', operator:'LIKE', value:`%%`},
                {"OR": [
                    {field:'user_type_id', operator:'=', value:`${USER_TYPES.MANAGER}`},
                    {field:'user_type_id', operator:'=', value:`${USER_TYPES.INFLUENCER}`}
                ]}
            ]
        } else {
            specialFilter = [{field:'user_type_id', operator:'=',value:`${type_user}`}]
        }

        if(value){
            if(both) {
                specialFilter = [
                    {field:'email', operator:'LIKE', value:`%%`},
                    {"OR": [
                        {field:'user_type_id', operator:'=', value:`${USER_TYPES.MANAGER}`},
                        {field:'user_type_id', operator:'=', value:`${USER_TYPES.INFLUENCER}`}
                    ]},
                    {"OR": [
                        {field:'first_name', operator:'LIKE',value:`%${value}%`},
                        {field:'username', operator:'LIKE',value:`%${value}%`}
                    ]}
                ]
            } else {
                specialFilter = [
                    {field:'user_type_id', operator:'=',value:`${type_user}`},
                    {"OR":[
                        {field:'first_name', operator:'LIKE',value:`%${value}%`},
                        {field:'username', operator:'LIKE',value:`%${value}%`}
                    ]}
                ]
            }

            //specialFilter.push({field:'first_name', operator:'LIKE',value:`%${value}%`})
           
        } 
        /* if(filters.length){
            filters.forEach(item => {
                switch(item.type){
                    case 'category_filter':
                        specialFilter.push({field:'category_id', operator:'=',value:`${item.id}`})
                        break;
                    case 'influencer_type_filter':
                        specialFilter.push({field:'influencer_type_id', operator:'=',value:`${item.id}`})
                        break;    
                    case 'influencer_size_filter':
                        specialFilter.push({field:'influencer_size_id', operator:'=',value:`${item.id}`})
                        break;
                    default:
                        break;
                }
            })
        } */
        specialFilter = {"AND":specialFilter}
        response = `?${response}&filter=${encodeURIComponent(JSON.stringify(specialFilter))}`
        return response
    }

    return {inputRef, value, influencers, selected_product, loading, actions}
}

export default useInfluencersSearch