import { Typography, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react'
import InputForm from '../../../components/Forms/InputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import translations from '../../../texts/system_translations'
import RoundedButton from '../../../components/Actions/RoundedButton'
import useSendInvitationReview from './useSendInvitationReview'
import { useEffect } from 'react'


const SendInvitationReviewModal = ({open, onClose, language}) => {

    const { classes } = useStyles()
    const content = translations[language].modals.send_review_invitation_modal
    const { actions, form, system } = useSendInvitationReview(open, content, language)

    useEffect( () => {
        system.setSuccess(null)
        system.setError(null)
    }, [open])

  return (
    <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
        <Grid spacing={2} container xs={12}>
            <Grid item>
                <Typography variant='h6' xs={12}>{content.title}</Typography>
            </Grid>
            
            <Grid item xs={12}>
                <Typography>{content.message}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='subtitle2'>{content.subtitle1}</Typography>
                <InputForm data={form.reviewer} onChange={actions.onChangeForm}/>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='subtitle2'>{content.subtitle2}</Typography>
                <InputForm data={form.business} onChange={actions.onChangeForm}/>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='subtitle2'>{content.subtitle3}</Typography>
                <InputForm data={form.email} onChange={actions.onChangeForm}/>
            </Grid>

            {system.error && 
                <Grid item xs={12} className={classes.alert}>
                    <Typography variant='subtitle2' color='red'>{system.error}</Typography>
                </Grid>
            }
            
            {system.success && 
                <Grid item xs={12} className={classes.alert}>
                    <Typography variant='subtitle2' color='primary'>{system.success}</Typography>
                </Grid>
            }

            <Grid container item xs={12} className={classes.button}>
                <Grid xs={10}></Grid>
                <Grid xs={2}>
                    <RoundedButton onClick={actions.onSubmit} loading={system.sending}>
                        {content.submit_button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </Grid>
    </SimpleModal>
  )
}

export default SendInvitationReviewModal

const useStyles = makeStyles()(theme => ({
    button:{
      position:'relative',
      top:'8px',
      justifyContent:'center'
    },
    alert:{
        textAlign:'center',
        marginTop:'10px'
    }
  }));