import { Grid, Typography } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const SectionH = ({ content }) => {
    const { classes } = useStyles();

    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.container}>
            <Grid item xs={12} md={6}>
                <img className={classes.image} src={content.image}></img>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h3" className={classes.text}>
                    {content.text}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default SectionH;

const useStyles = makeStyles()(theme => ({
    container: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.light,
        [theme.breakpoints.up('lg')]: {
            padding: '70px 10%'
        },
        padding: '70px 132px',
        [theme.breakpoints.down('lg')]: {
            padding: '30px 20px'
        }
    },
    text: {
        textAlign: 'right',
        fontSize: 42,
        fontWeight: 700,
        [theme.breakpoints.down('lg')]: {
            fontSize: 30,
            textAlign: 'center'
        }
    },
    image:{
        height: 325,
        widht: 325,
        [theme.breakpoints.down('lg')]: {
            position: 'absolute',
            height: '50%',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            opacity: '15%'
        }
    }
}));