import { Grid, Typography, Tabs, Tab, IconButton, Icon, Collapse } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { connect } from 'react-redux';
import GenericSearchBar from '../../../components/Platform/GenericSearchBar';
import SearchProfileCard from '../../../components/Platform/SearchProfileCard';
import NoContent from '../../../components/Platform/NoContent';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import Page from '../../../components/Structure/Layouts/Page';
import { DASHBOARD_SETTINGS, USER_TYPES } from '../../../config';
import { actionTypes } from '../../../store/actions';
import useNewSearch from './useNewSearch';
import background from '../../../assets/influencers/background_search.png'
import logo from '../../../assets/influencers/logo.png'
import SideLabelInput from '../../../components/Forms/SideLabelInput';
import DropdownSelect from '../../../components/Forms/DropdownSelect';
import useUserFilters from '../../../shared/useUserFilters';


const InfluencersView = (props) => {

    const { user, history, language, match } = props
    const { classes } = useStyles()

    const { filters } = useUserFilters();

    const { system, view_data, actions, modals } = useNewSearch({ ...props })
    const parentprops = { history, language, match, user }

    let contentView = view_data.query.search !== '' ? <NoContent /> : null


    if (view_data.influencers && view_data.influencers.length && view_data.query.search !== '') {
        contentView = (
            <div style={{ marginTop: 32 }}>
                <Grid container spacing={2}>
                    <Typography variant='subtitle2'>Resultado de busqueda</Typography>
                    {view_data.influencers.map(item => {
                        return (
                            <SearchProfileCard {...item} onSelectedUser={actions.onSelectedUser} key={`influencer-${item.id_user}`} />
                        )
                    })}
                </Grid>
            </div>
        )
    }

    const filter = () => {
        return;
        return <>
            <Collapse in={!system.hidden} timeout="auto" >
                {!system.hidden && system.tabValue === 0 &&
                    <Grid item container justifyContent="space-around">
                        <Grid item className={classes.filters}>
                            <Typography variant='subtitle1'>Sobre el Influencer</Typography>
                            <SideLabelInput label={'Tipo de figura pública'}>
                                <DropdownSelect filterType='influencer_type_filter' onUpdateFilter={actions.onUpdateFilters} options={filters.types} />
                            </SideLabelInput>
                            <SideLabelInput label={'Categorias de contenido'} >
                                <DropdownSelect filterType='category_filter' onUpdateFilter={actions.onUpdateFilters} options={filters.categories} />
                            </SideLabelInput>
                            {/* <MultipleSelectCheckmarks label={'Ciudad'} options={[{ id: 1, name: 'hola' }, { id: 2, name: 'chau' }, { id: 3, name: 'texto muy largo nose que onda' }]} /> */}
                            <SideLabelInput label={'País'}>
                                <DropdownSelect filterType='country_filter' onUpdateFilter={actions.onUpdateFilters} options={filters.countries} />
                            </SideLabelInput>
                            {/* <MultipleSelectCheckmarks label={'Red social'} options={[{ id: 1, name: 'facebook' }, { id: 2, name: 'instagram' }, { id: 3, name: 'texto muy largo nose que onda' }]} /> */}
                            <SideLabelInput label={'Rango de seguidores'} >
                                <DropdownSelect filterType='influencer_size_filter' onUpdateFilter={actions.onUpdateFilters} options={filters.sizes} />
                            </SideLabelInput>
                        </Grid>
                        {/* Pending filters about audience
                <Grid item className={classes.filters}>
                <Typography variant='subtitle1'>Sobre la audiencia</Typography>
                <MultipleSelectCheckmarks label={'Ciudad principal'} options={[{ id: 1, name: 'hola' }, { id: 2, name: 'chau' }, { id: 3, name: 'texto muy largo nose que onda' }]} />
                <MultipleSelectCheckmarks label={'País principal'} options={filters.countries} />
                <MultipleSelectCheckmarks label={'Sexo'} options={filters.genders} />
                <MultipleSelectCheckmarks label={'Edades'} options={[{ id: 1, name: 'hola' }, { id: 2, name: 'chau' }, { id: 3, name: 'texto muy largo nose que onda' }]} />
            </Grid> */}
                    </Grid>
                }
                {!system.hidden && system.tabValue === 1 &&
                    <Grid item container justifyContent="space-around" alignItems='center'>
                        <Grid item className={classes.filters}>
                            <Typography variant='subtitle1'>Sobre Managers</Typography>
                            {/* <MultipleSelectCheckmarks label={'Especialidad de figura pública'} options={filters.types} />
                <MultipleSelectCheckmarks label={'Epecialidad de contenido'} options={filters.categories} />
                <MultipleSelectCheckmarks label={'Ciudad'} options={[{ id: 1, name: 'hola' }, { id: 2, name: 'chau' }, { id: 3, name: 'texto muy largo nose que onda' }]} /> */}
                            <SideLabelInput label={'País'}>
                                <DropdownSelect filterType='country_filter' onUpdateFilter={actions.onUpdateFilters} options={filters.countries} />
                            </SideLabelInput>
                        </Grid>
                    </Grid>
                }
            </Collapse>
            <Grid item container justifyContent="flex-end">
                <IconButton
                    color='primary'
                    style={{ marginLeft: 'auto' }}
                    onClick={actions.handleHidenChange}
                    size="large">
                    {system.hidden ? <Icon>expand_circle_down</Icon> : <Icon>expand_less_outlined</Icon>}
                </IconButton>
            </Grid>
        </>;
    }

    return (
        <Page >
            <div>
                <Grid container direction="column" alignItems="center" spacing={2} className={classes.searchContainer}>
                    <Grid item>
                        <img src={logo} className={classes.logo} />
                    </Grid>
                    <Grid item>
                        <Tabs value={system.tabValue} onChange={actions.handleTabChange}>
                            <Tab label="Influencers" />
                            <Tab label="Managers" />
                        </Tabs>
                    </Grid>
                    <Grid item className={classes.search}>
                        <GenericSearchBar onRequestSearch={actions.onRequestSearch} />
                    </Grid>
                    {filter()}
                </Grid>

                <LoadingContainer loading={system.loading}>
                    <div className={classes.content}>
                        {contentView}
                    </div>
                </LoadingContainer>

            </div>
        </Page>

    );
}

const useStyles = makeStyles()(theme => ({
    card: {
        boxShadow: DASHBOARD_SETTINGS.BOXSHADOW
    },
    content: {
        marginTop: 16
    },
    strongword: {
        color: theme.palette.primary.main
    },
    back_container: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            height: 420,
        }

    },
    back: {
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            height: '100%',
            objectFit: 'cover'
        }
    },
    logo: {
        width: 280,
        [theme.breakpoints.down('md')]: {
            width: 200
        },
        [theme.breakpoints.only('xs')]: {
            width: 180
        }
    },
    info_container: {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '1.5rem',
        width: '85%',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.2rem'
        }
    },
    searchContainer: {
        borderRadius: '30px',
        backgroundColor: '#F4EFF6'
    },
    search: {
        width: '90%',
        marginBottom: 15
    },
    filters: {
        margin: 10
    }
}));

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({ type: actionTypes.ECO_UPDATE_CART, cart })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InfluencersView);