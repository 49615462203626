import React from 'react';
import { Drawer, alpha, Grid, Badge } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import { grey } from '@mui/material/colors';
import { withRouter } from 'react-router';
import { DASHBOARD_SETTINGS } from '../../../config';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';
import { onClearMemory } from '../../../shared/utility';

const Sidebar = ({user, drawer_status, history, ismobile, content, onUpdateDrawerStatus, onUpdateModal,onUpdateUserData }) => {
    const { classes } = useStyles()

    const isURLSelected = (path) => {
        return history.location.pathname.split('/dash')[1].includes(path.split('/dash')[1]);
    }

    const onChangeView = async (url) => {
        switch (url) {
            case '/logout':
                onUpdateUserData(null)
                onClearMemory()
                history.push('/signin')
                break;
            default:
                history.push(url)
                break;
        }

    }

    return ( 
        <div>
            <Drawer open={drawer_status} 
                variant={ismobile ? 'temporary' : 'persistent'}
                classes={{paper:classes.drawer}}
                onClose={() => onUpdateDrawerStatus(false)}
            >
                <div className={classes.container}>
                    <div className={classes.menu}>
                        <Grid container spacing={2}>
                            {content.list.map((item,key)=>{

                                if(item.auth && !user) return null

                                const isactive = isURLSelected(item.path)
                                let enabled = true  
                                let modal = false
                                if(item.permissions.length && user){
                                    const findpermission = item.permissions.find(el => el === user.user_type_id)
                                    
                                    if(!findpermission){
                                        enabled = false
                                    }
                                }
                                if(!enabled) return null

                                return(
                                    <Grid item xs={12} key={key.toString()}>
                                        <SidebarItem active={isactive} {...item} onChangeView={onChangeView} onUpdateModal={onUpdateModal}  user={user} />
                                        {item.name === 'Blog'
                                        ? <div style={{background:grey[400], height:1, marginTop:'15px'}} />
                                        : null}
                                    </Grid>
                                )
                            })} 
                        </Grid>
                        
                    </div>
                </div>
                
            </Drawer>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    drawer:{
        //background:'transparent',
        background:'white',
        //borderTopRightRadius:24,
        //borderBottomRightRadius:24,
        border:`2px solid ${alpha(theme.palette.primary.main, 0.15)}`,
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        zIndex:theme.zIndex.appBar-1,
        [theme.breakpoints.down('md')]:{
            background:'white'
        },
    },
    logo:{
        width:'100%',
        height:'100%',
        padding:8,
        boxSizing:'border-box',
        //objectFit:'cover',
        position:'absolute',
        top:0, left:0
    },
    logo_container:{
        width:84,
        height:84,
        margin:'auto',
        borderRadius:'50%',
        boxSizing:'border-box',
        background:'white',
        position:'relative'
    }, 
    container:{
        width:DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        padding:'32px 0px'
    },
    title:{
        color:'white',
        marginTop:8
    },
    menu:{
        margin:'32px 0px',
        marginTop:80, 
        padding:'0px 8px',
        boxSizing:'border-box'
    }
}));

const mapStateToProps = state => {
    return {
        user:state.user,
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));


const SidebarItem = ({active, name, path, icon, src, onChangeView,  onUpdateModal, user}) => {

    const { classes, cx } = useStylesItem()

    return (
        <div className={cx(classes.root,
            {
                [classes.active]: active
            })} onClick={!user ? () => onUpdateModal({ 'dealhunter': true }) : () => onChangeView(path)}>
            <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={1} wrap='nowrap' >
                <Grid item>
                    <SVGIcon src={src} size={24} color={active ? 'white' : "#363636"} />
                </Grid>
                <Grid item xs>
                    {name}
                </Grid>
            </Grid>
        </div>
    )
}

const useStylesItem = makeStyles()(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        color:"#363636",
        fontWeight:500,
        padding:'16px 20px',
        borderRadius:10,    
        //filter:'blur(1px)',
        '&:hover':{
            background:alpha(theme.palette.primary.main, 0.2),
            
            cursor:'pointer',
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        
    },
    active:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.main,
            cursor:'pointer'
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }
}));