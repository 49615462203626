import { useState, useEffect } from "react"
import { public_server, private_server, USER_TYPES } from "../../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../../shared/utility"
import { catalogs } from "../../../texts/esp/catalogs"
import { generic_errors } from "../../../texts/esp/genericErrors"

const useNewInfuencerView = ({ language, content, history, onUpdateUserData, onUpdateAuthStatus }) => {
    const { REGISTER_STEPS } = content
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [step, setStep] = useState(REGISTER_STEPS.METHOD_SELECTION)
    const [user_type, setUserType] = useState(null)
    const [isValid, setIsValid] = useState(false)
    const [user, setUser] = useState(null)
    const [send, setSend] = useState(false)
    const [finish, setFinish] = useState(false)
    const [preData, setPreData] = useState(false)

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async () => {
            const _form = onInitForm(form, null, {
                ...content.personal_information_section.form,
                ...content.profile_section.form
            })
            setForm(_form)
        },
        onChangeForm: (data, full = false) => {

            if (!full) {
                let temp = { ...form }

                const id = data.config.id
                temp[id] = { ...data }
                if (error) setError(null)
                setForm(temp)
            } else {
                setForm(data)
            }

        },
        onChangeForwardStep: async (_step) => {
            try {
                switch (_step) {

                }

                setStep(_step)
            } catch (error) {

            }
        },

        onSubmit: async () => {

            const errors = isFormValid(form)

            if (errors && errors.length) {
                let _form = onSetErrorsToForm(form, errors)

                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            data2send.user_type_id = user_type;
            data2send.url = data2send.username

            setSending(true)

            data2send.last_name = ' '

            try {
                const response = await private_server.post('/user/preregister_infuencer', data2send);
                setUser(response.data.data.user)
                setError(null)
                setFinish(true)
            } catch (error) {
                const _errror = onGetErrorMessage(error)
                setFinish(false)
                setError(_errror.message)
            }

            setSending(false)

        },
        onGoToView: (_step) => {
            setStep(_step)
            setSend(false)
        },
        onUpdateUserType: (_data, next_step) => {
            setUserType(_data)
            setStep(next_step)
        },
        onChangePage: (url) => history.push(url),
        onUpdateModalStatus: (value) => setModal(value),
        onSetPreData: (value) => setPreData(value),
        onCheckMail: async () => {
            form.username.isValid = true

            const errors = isFormValid(form)

            if (errors && errors.length) {
                let _form = onSetErrorsToForm(form, errors)

                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)


            form.username.isValid = false
            form.username.isRequired = true

            setSending(true)

            try {
                const response = await public_server.post('/user/check/email', data2send)
                setSending(false)
                setSend(true)
                setError(null)

            } catch (error) {
                const _errror = onGetErrorMessage(error)
                setSending(false)
                setSend(false)
                setError(_errror.message)
            }
        }
    }

    const system = { loading, sending, error, send, setSend, finish }
    const view_data = { step, user_type, isValid, user, modal, preData }

    return { system, form, actions, view_data }
}

export default useNewInfuencerView

const form_data = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    username: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'username',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'noSpaces',
            minS: 1, maxS: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    }
}


const managers_coupons = [
    'ADLER122',
    'AEME222',
    'ATHLETE122',
    'BAMA322',
    'BLOM222',
    'BLU222',
    'DECIBEL222',
    'DRIVERS122',
    'DW322',
    'FCO222',
    'GAZER222',
    'INFLUYE222',
    'INKE222',
    'INNGLOBAL222',
    'LT222',
    'MCINCO222',
    'MIDAS222',
    'MPV122',
    'PEACH222',
    'PLAYBOOK222',
    'RANDY222',
    'RAWT222',
    'SM122',
    'SOS222',
    'TACTIC222',
    'TALENTAGENCY122',
    'TALENTOTR222',
    'TDRECORDS222',
    'TRENSETTERS222',
    'TRSOCIAL122',
    'TSM222',
    'WARP322',
    'TNG322',
    'LALO222',
    'ASSAEL222',
    'MARCE222',
    'GUS322',
    'LUIS322',
]