import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import NoContent from '../../../../../components/Platform/NoContent';
import LoadingContainer from '../../../../../components/Structure/Layouts/LoadingContainer';
import AddPressModal from '../../modals/AddPressModal/AddPressModal';
import DeletePressModal from '../../modals/DeletePressModal';
import EditPressModal from '../../modals/EditPressModal/EditPressModal';
import PressCard from './components/PressCard';
import usePress from './usePress';


const Press = (props) => {

    //const content = translations_esp
    const { classes } = useStyles()

    const {system, view_data, actions, modals} = usePress({...props})
    const {language, content, master_view_data} = props


    return ( 
        <div>
            <AddPressModal open={modals.add_event} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} 
            onClose={()=>actions.onUpdateModalStatus('add_event', false)} master_actions={actions} />
            <EditPressModal open={modals.edit_event} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.event}
            onClose={()=>actions.onUpdateModalStatus('edit_event', false)} master_actions={actions} />
            <DeletePressModal open={modals.delete_event} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.event}
            onClose={()=>actions.onUpdateModalStatus('delete_event', false)} master_actions={actions} />

            <div style={{marginBottom:16}}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='h6'>Prensa</Typography>
                    </Grid>
                    {master_view_data.modify ? <Grid item>
                        <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('add_event', true)}>Agregar</RoundedButton>
                    </Grid> : null}
                </Grid>
            </div>
            <LoadingContainer loading={system.loading}>
                
                
                <Grid container spacing={3}>
                    {view_data.events.map((item) => {
                        return(
                            <Grid item xs={12} key={item.id_event}>
                                <PressCard {...item} key={item.id_event} modify={master_view_data.modify}
                                onEdit={() => actions.onEditEvent(item)} 
                                onDelete={() => actions.onDeleteEvent(item)}
                                onDownload={() => actions.onDownloadEvent(item)}/>
                            </Grid>
                        )
                    })}
                    
                </Grid>
                {view_data.events.length === 0 ? (
                        <div style={{paddingBottom:32}}>
                            <Grid container justifyContent='center'>
                                <Grid item xs={12}>
                                    <NoContent />
                                </Grid>
                                {master_view_data.modify ? <Grid item>
                                    <RoundedButton onClick={()=>actions.onUpdateModalStatus('add_event', true)}>
                                        Agregar
                                    </RoundedButton>
                                </Grid> : null}
                            </Grid>
                            
                        </div>
                ) : null}
            </LoadingContainer>

           
        </div>
        
     );
}

const useStyles = makeStyles()(theme => ({
    topbar:{
        marginBottom:40
    }
}));



export default Press;