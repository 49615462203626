import { Grid, Typography } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const Card = ({ content, color }) => {
    const { classes } = useStyles(color, {
        props: color
    });

    return (
        <Grid container direction="column" justifyContent='space-between' xs={12} className={classes.container}>
            <Grid className={classes.title} style={{backgroundColor: color}}>
                <img src={content.icon} className={classes.center}></img>
                <Typography variant='subtitle1' className={classes.center}>
                    {content.title}
                </Typography>
            </Grid>
            <Grid className={classes.content}>
                {content.text}
            </Grid>
            <Grid className={classes.bottom}>
                {content.bottom_text}
            </Grid>
        </Grid>
    )
}

export default Card;

const useStyles = makeStyles()(theme => ({
    container: {
        position:'relative',
        background: 'linear-gradient(210deg, #F1F1FA 99.99%, #C1C1C1 100%)',
        borderRadius: 14,
        marginBottom: 30,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.3)',
        fontSize: 24,
        height: 470,
        maxWidth: 335,
        fontFamily: 'Raleway'
    },
    title: {
        color: '#FFFFFF',
        height: 109,
        borderRadius: '14px 14px 0 0',
        textAlign: 'center',
        display: 'inline-block',
        fontWeight: 700
    },
    center:{
        display: 'inline-block',
        verticalAlign: 'middle',
        textAlign: 'center',
        marginTop: 30,
        marginLeft: 10,
        fontSize: 28,
    },
    content: {
        position:'absolute',
        top: 111,
        padding: 21,
        fontSize: 24
    },
    bottom:{
        padding: 20,
        fontWeight: 'bold',
        textAlign: 'center'
    }
}));