import { Grid, LinearProgress, Typography } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const AnalyticsProgressBar = ({label, units, total}) => {

    const { classes } = useStyles()

    const percent = parseFloat(((units*100)/total).toFixed(2))

    return(
        <div>
            <Typography variant='caption' style={{fontWeight:500}}>{label}</Typography>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs>
                    <LinearProgress className={classes.bar} value={percent} variant='determinate' />
                </Grid>
                <Grid item>
                    <div style={{width:75}}>
                        <Typography align="right" style={{fontWeight:600}} >{percent} %</Typography>
                    </div>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default AnalyticsProgressBar

const useStyles = makeStyles()(theme => ({
    bar:{
        height: 10,
        borderRadius: 5,
        '&.MuiLinearProgress-colorPrimary':{
            backgroundColor: '#DADADA'
        },
        '& .MuiLinearProgress-bar':{
            borderRadius: 5,
            backgroundColor:theme.palette.primary.main,
        }
        /* [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        }, */
    }
}));