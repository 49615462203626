import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useRegisterInfluencer from './useRegisterInfluencer';
import ResetPasswordForm from '../ResetPassword/components/ResetPasswordForm'


const RegisterInfluencer = (props) => {

    const {user, history, language, match} = props

    const {system, view_data, actions, modals} = useRegisterInfluencer({history});

    return ResetPasswordForm(view_data, actions, system, history);

}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterInfluencer);