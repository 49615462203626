import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import { Typography, Divider, Button, Link, Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useParams } from "react-router-dom";
import { CONFIG_SETTINGS } from '../../config'
import usePodcastsPage from './usePodcastPage';
import system_translations from '../../texts/system_translations';
import ContactModal from '../../modals/ContactModal/ContactModal';
import Footer from '../../components/Structure/Navigation/Footer';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';

import spotify_icon from '../../assets/socialmedia/icons/spotify_icon.png'
import youtube_icon from '../../assets/socialmedia/icons/youtube_icon.png'
import apple_podcast_icon from '../../assets/socialmedia/icons/apple_podcast_icon.png'

const PodcastsPage = ({ history, language, user, onChangePage }) => {
    const { number } = useParams();
    const { actions, podcast } = usePodcastsPage(number)
    const props = { image_: podcast.image }
    const { classes } = useStyles({ props })
    const content = system_translations[language].layouts.podcasts

    const creator_links = podcast.creator_links
    const tolink = (obk) => obk ? obk.map(test => {
        return <a className={classes.creator_link} key={test.link} href={test.link}><img className={classes.creatorLinksIcons} src={content.icons[test.type]}></img> {test.tag}</a>
    }) : null

    useEffect(() => {
        window.scrollTo(0, 0)
        actions.onInitModule()
    }, [])

    return (
        <div className={classes.root}>
            < ContactModal />
            <PublicTopbar lightTheme={false} content={content.topbar} onChangePage={onChangePage} />
            <div className={classes.titleContainer}>
                <Typography className={classes.title} variant='h1'> EP. {podcast.number} {podcast.title}</Typography>
            </div>
            <div className={classes.linksContainer}>
                Disponible en
                <div className={classes.buttonsContainer}>
                    {podcast.spotify_url && podcast.spotify_url !== '' &&
                        <Button component={Link} className={classes.links} href={podcast.spotify_url} target='_blank'>
                            <img className={classes.linksIcons} src={spotify_icon} alt='spotify_icon'></img> <span className={classes.linksText}>Spotify</span>
                        </Button>
                    }
                    <Button component={Link} className={classes.links} href={podcast.youtube_url} target='_blank'>
                        <img className={classes.linksIcons} src={youtube_icon} alt='youtube_icon'></img>  <span className={classes.linksText}>Youtube</span>
                    </Button>
                    {podcast.apple_url && podcast.apple_url !== '' &&
                        <Button component={Link} className={classes.links} href={podcast.apple_url} target='_blank'>
                            <img className={classes.linksIcons} src={apple_podcast_icon} alt='apple_podcast_icon'></img> <span className={classes.linksText}>Apple Podcast</span>
                        </Button>
                    }


                </div>
            </div>
            <Divider />
            <div className={classes.contentContainer}>
                <div className={classes.content}>
                    <iframe className={classes.iframe} src={`https://www.youtube.com/embed/${podcast.youtube_id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <Typography className={classes.footerText} variant='body1'>{podcast.description}</Typography>
                    <div className={classes.creator_links}>
                        <Typography className={classes.linksGuest}>{content.creator_links}</Typography>
                        {tolink(creator_links)}
                    </div>
                    <div className={classes.podcastFooter}>
                        <Typography className={classes.footerText} variant='body1' >
                            {content.footer[0]} <a href='https://www.instagram.com/inpager_/' className={classes.color}>{content.footer[1]}</a>
                        </Typography>
                        <Typography className={classes.footerText} variant='body1' >
                            {content.footer[2]} <a href='https://www.instagram.com/marceayalac/' className={classes.color}>{content.footer[3]}</a>
                        </Typography>
                        <Typography className={classes.footerText} variant='subtitle2'> {content.follow_us}</Typography>
                        <Typography className={classes.footerText} variant='body1' >
                            {content.links[0]} <a href='https://www.instagram.com/socialtrenders_/' className={classes.color}>{content.links[1]}</a>
                        </Typography>
                        <Typography className={classes.footerText} variant='body1' >
                            {content.links[2]} <a href='https://twitter.com/SocialTrenders' className={classes.color}>{content.links[3]}</a>
                        </Typography>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    )
}

const useStyles = makeStyles()((theme, { props }) => ({
    root: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        overflow: 'hidden'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        position: 'relative',
        width: '100%',
        height: 969,
        [theme.breakpoints.down('lg')]: {
            height: 300
        },
        backgroundImage: `linear-gradient(rgba(200, 145, 242, 0.3), rgba(200, 145, 242, 0.3)), url(${CONFIG_SETTINGS.S3_SERVER_URL}${props.image_})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#FFF',
        fontFamily: 'Raleway',
        fontSize: 64,
        fontWeight: 700,
    },
    title: {
        fontFamily: 'Raleway',
        width: '80%',
        padding: 70,
        [theme.breakpoints.down('lg')]: {
            fontSize: 30,
            padding: 20
        },
    },
    linksContainer: {
        fontFamily: 'Raleway',
        fontSize: 40,
        fontWeight: 700,
        marginTop: 60,
        marginLeft: 120,
        marginBottom: 60,
        [theme.breakpoints.down('lg')]: {
            marginLeft: 20
        },
    },
    buttonsContainer: {
        marginTop: 60,
        fontFamily: 'Raleway'
    },
    links: {
        fontFamily: 'Raleway',
        width: 147,
        height: 61,
        borderRadius: 27,
        background: 'rgba(132, 59, 168, 0.79)',
        marginLeft: 70,
        [theme.breakpoints.down('lg')]: {
            width: 70,
            height: 70,
            marginLeft: 10,
            borderRadius: '100vh',
        },
        fontSize: 13,
        lineHeight: 1,
        padding: 15
    },
    linksText: {
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        },
    },
    linksIcons: {
        filter: 'brightness(0)',
        marginRight: 5,
        [theme.breakpoints.down('lg')]: {
            marginRight: 0
        },
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1067,
        lineHeight: 'normal',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '90%',
        },
    },
    iframe: {
        marginTop: 60,
        marginBottom: 60,
        maxWidth: '100%',
        height: 600,
        aspectRatio: '16/9',
        [theme.breakpoints.down('lg')]: {
            height: 200,
        },
    },
    podcastFooter: {
        width: '100%',
        margin: '60px 0px 100px'
    },
    color: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 28,
        display: 'inline-flex',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.5em',
        },
    },
    linksGuest: {
        color: '#000',
        fontFamily: 'Raleway',
        fontSize: 28,
        fontWeight: 700,
        textAlign: 'left',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.5em',
        },
    },
    footerText: {
        fontFamily: 'Raleway',
        fontSize: 28,
        marginBottom: 30,
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.5em',
        },
    },
    creator_links: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    creator_link: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 28,
        display: 'inline-flex',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.5em',
        },
        margin: '20px 0px',
    },
    creatorLinksIcons: {
        filter: 'brightness(0)',
        marginRight: 20,
    }
}));

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: state.language,
        modals: state.modals
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateModal: (modal) => dispatch({ type: actionTypes.SYS_UPDATE_MODAL, modal }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastsPage)