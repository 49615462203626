import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import system_translations from '../../texts/system_translations';
import ContactModal from '../../modals/ContactModal/ContactModal';
import Footer from '../../components/Structure/Navigation/Footer';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';


const Glosario = ({ language, iframeLink }) => {

    const { classes } = useStyles()
    const content = system_translations[language].layouts.glosario

    return (
        <div className={classes.root}>
            < ContactModal />
            <PublicTopbar content={content.topbar} />
            <div className={classes.titleContainer}>
                <Typography variant="h1" className={classes.title1}></Typography>
            </div>
            <div className={classes.iframeContainer}>
                <iframe src={iframeLink} className={classes.iframe} title="Iframe"></iframe>
            </div>
            <Footer />
        </div>
    )
}


const useStyles = makeStyles()(theme => ({
    root: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        overflow: 'hidden'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        margin: '100px 80px 50px'
    },
    title1: {
        color: theme.palette.primary.main,
        fontSize: 72,
        [theme.breakpoints.down('lg')]: {
            fontSize: 50,
        }
    },
    iframeContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: '100vh'
    },
    iframe: {
        width: '80%',
        height: '100%',
        border: 'none'
    }
}));

const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

export default connect(mapStateToProps)(Glosario)