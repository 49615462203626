import { useState, useRef, useEffect } from 'react';
import { Grid, Icon, Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const DropdownSelect = ({ options, onUpdateFilter, filterType, limit=0 }) => {
  const _options = options ? options : [];
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const selectedIndex = value.indexOf(option);
    let _value = [];
    if (selectedIndex === -1) {
      if(limit !== 0 && value.length >= limit) return
      _value = [...value, option];
      setValue(_value);
    } else {
      _value = value.filter((item) => item !== option)
      setValue(_value);
    }
    onUpdateFilter(_value.map(element => (
      {id:element.id, type:filterType}
   )), filterType)
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { classes } = useStyles();

  return (
    <Grid container direction='row' alignItems="center" className={classes.dropdown} ref={dropdownRef}>
      <Grid sx={9} md={9} item className={classes.header} onClick={toggleDropdown}>
        {value.map(element => element.name).join(', ')}
      </Grid>
      {isOpen && (
        <Grid item className={classes.options}>
          {_options.map((option) => (
            <FormControlLabel label={option.name}  className={classes.option} key={option.id} control={
              <Checkbox checked={value.indexOf(option) !== -1} id={option.id}
              icon={<Icon>add_circle_outlined</Icon>}
              checkedIcon={<Icon>check_circle_rounded</Icon>}
              onClick={() => handleOptionClick(option)} />
            }/>
          ))}
        </Grid>
      )}
      <Grid sx={1} md={1} item>
        <Icon className={classes.arrow} onClick={toggleDropdown}>expand_circle_down</Icon>
      </Grid>

    </Grid>
  );
}

export default DropdownSelect;

const useStyles = makeStyles()(theme => ({
  dropdown: {
    width: 200,
    position: 'relative',
  },
  header: {
    minWidth: '80%',
    marginLeft: 10,
    height: '1.5em',
    overflow: 'hidden',
    borderRadius: 4,
    cursor: 'pointer'
  },
  options: {
    minWidth: '80%',
    maxHeight: 200,
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 4,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: 4,
    overflowY: 'auto',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    cursor: 'pointer'
  },
  arrow: {
    cursor: 'pointer'
  }
}));