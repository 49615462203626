import React from 'react';
import { alpha, AppBar, Button, Grid, useMediaQuery, useScrollTrigger, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import system_translations from '../../../texts/system_translations';
import { actionTypes } from '../../../store/actions';
import { withRouter } from 'react-router';
import UserButton from '../../Actions/UserButton';
import { onClearMemory } from '../../../shared/utility';
import useAuth from '../../../shared/useAuth';

const PublicTopbar = ({history, language, user, onUpdateModal, onUpdateUserData, lightTheme=true}) => {


    const { classes, cx } = useStyles()
    const isXs = useMediaQuery(theme => theme.breakpoints.down('md'));
    const general_content = system_translations[language].general
    const {authenticated} = useAuth({onUpdateUserData, history})
    
    const content = JSON.parse(JSON.stringify(general_content.topbar))
    const logo = lightTheme ? content.logo : content.logo_white
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('md'))

    const trigger = useScrollTrigger({disableHysteresis:true, threshold:100}) 

    const forceTrigger = history.location.pathname.includes('/dealhuntermarcas') ||
    history.location.pathname.includes('/dealhunterinfluencers') ||
    history.location.pathname.includes('/aboutus') 
    const onChangePage = (url) => {
        history.push(url)
    }

    const onItemSelected = async(url) => {
        switch (url) {
            case '/logout':
                onUpdateUserData(null)
                //onUpdateAuthStatus(false)
                onClearMemory()
                history.push('/signin')
                break;
            default:
                history.push(url)
                break;
        }
    }

    return (
        <AppBar className={cx({
            [classes.bar]:true,
            [classes.bar_shadow]:trigger || forceTrigger
        })}>
            <div className={classes.root}>
                <Grid container spacing={3} alignItems='center' justifyContent='center'>
                    <Grid item>
                        { user ? <img src={(trigger || forceTrigger) ? content.logo : logo} className={classes.logo}/> :
                        <a href='/'><img src={(trigger || forceTrigger) ? content.logo : logo} className={classes.logo}/></a>}
                    </Grid>
                    {!isXs && <>
                        <Grid item xs/>
                        {!user ? <Grid item>
                            <CustomButton mono={!(trigger || forceTrigger)} noborder={true} onClick={() => onChangePage('/signup')}>
                                {content.register}
                            </CustomButton>
                        </Grid> : null}
                        {!user ? <Grid item>
                            <CustomButton mono={!(trigger || forceTrigger)} onClick={() => onChangePage('/signin')}>
                                {content.login}
                            </CustomButton>
                        </Grid> : null}
                        </>}
                    {user ? <Grid item xs></Grid> : null}
                    {user ? <Grid item>
                        <UserButton ismobile={ismobile} user={user} content={content.user_button} onItemSelected={onItemSelected}
                            onUpdateModal={onUpdateModal} mono={!(trigger || forceTrigger)} mode='dark'/>
                    </Grid> : null}
                </Grid>
            </div>
        </AppBar>
    );
}

const useStyles = makeStyles()(theme => ({
    bar:{
        background:'transparent',
        boxShadow:'none'
    },
    bar_shadow:{
        boxShadow:'0px 4px 5px rgba(0, 0, 0, 0.13)',
        background:'white'
    },
    root:{
        width:1400,
        margin:'auto',
        boxSizing:'border-box',
        padding:'16px 24px',
        [theme.breakpoints.only('lg')]:{
            width:1200,
            //height:300
        },
        [theme.breakpoints.down('xl')]:{
            width:'100%',
            //height:300
        },
        [theme.breakpoints.down('md')]:{
            width:'100%',
            padding:'8px 24px'
        }
    },
    logo:{
        width:150,
        
    }
}));

const mapStateToProps = (state) => {
    return {
        language:state.language,
        user:state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateModal: (modal) => dispatch({type:actionTypes.SYS_UPDATE_MODAL, modal  }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicTopbar));

const useCustomButtonStyles = makeStyles()(theme => ({
    button:{
        background:'transparent',
        color:theme.palette.primary.main,
        textTransform:'none',
        fontFamily:theme.typography.fontFamily,
        fontSize:16,
        fontWeight:700,
        border:`1px solid ${theme.palette.primary.main}`,
        borderRadius:32,
        padding:'8px 24px'
    },
    button_mono:{
        color:'white',
        border:`1px solid white`,
    }
}));

const CustomButton = ({children, onClick, mono, noborder}) => {

    const { classes, cx } = useCustomButtonStyles()

    return(
        <Button onClick={onClick} className={cx({
            [classes.button]:true,
            [classes.button_mono]:mono
        })} style={noborder ? {border:`1px solid ${alpha('#FFF',0.0001)}`} : undefined}>
            {children}
        </Button>
    )
}