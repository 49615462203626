import { useEffect, useState } from 'react';
import { alpha, Card, Fade, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS } from '../../../../../../config';
import nopicture from '../../../../../../assets/image_placeholder.png'
import { isValidDocument, onGetDateFormat } from '../../../../../../shared/utility';
import GenericEditButton from '../../../../../../components/Actions/GenericEditButton';
import GenericDeleteButton from '../../../../../../components/Actions/GenericDeleteButton';
import catalogs from '../../../../../../texts/esp/catalogs';
import ProductItem from './ProductItem';
import { grey } from '@mui/material/colors';
import AddProductModal from '../../../modals/AddProductModal/AddProductModal';
import EditProductModal from '../../../modals/EditProductModal/EditProductModal';
import DeleteProductModal from '../../../modals/DeleteProductModal';
import SmallLink from '../../../../../../components/Actions/SmallLink';
import Scrollbars from 'react-custom-scrollbars-2';


const BusinessCard = ({id_business, name, description, date, image, position, onEdit, link, onDelete, modify, event_mode_id, onGetProducts,
                     actions, language, profile, owner, origin}) => {

    const { classes } = useStyles()
    const [error, setError] = useState(false)
    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    const [selected, setSelected] = useState(0)
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState(null)

    const [modals, setModals] = useState({
        add_product:false,
        edit_product:false,
        delete_product:false
    });

    let selectedImage = nopicture
    const isValid = isValidDocument(image)
    selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : nopicture

    let event_mode

    if(event_mode_id) {
        let temp = catalogs.event_modes.find(el => el.value === event_mode_id)
        if(temp) event_mode = temp.label
    }


    useEffect(async() => {
        await onUpdateProducts()
    }, [])

    const onUpdateProducts = async() =>{
        const _products = await onGetProducts(id_business)
        setProducts(_products)
    }

    const onUpdateModalStatus = (_key, _value) => setModals({...modals, [_key]:_value})

    const onEditProduct = (item) =>{
        setProduct(item)
        onUpdateModalStatus('edit_product', true)
    }

    const onCloseAllModals = () => setModals({
        add_product:false,
        edit_product:false,
        delete_product:false})


    return(
        <div className={classes.root}>
            <AddProductModal open={modals.add_product}  language={language} master_actions={actions} id_business={id_business}
            profile={profile} owner={owner} onClose={()=>onUpdateModalStatus('add_product', false)} onUpdateProducts={onUpdateProducts}  />
            <EditProductModal open={modals.edit_product}  language={language} master_actions={actions} id_business={id_business} origin={product}
            profile={profile} owner={owner} onClose={()=>onUpdateModalStatus('edit_product', false)} onUpdateProducts={onUpdateProducts} 
            onPreDelete={()=>onUpdateModalStatus('delete_product', true)} modify={modify} />
            <DeleteProductModal open={modals.delete_product}  language={language} onCloseEdit={()=>onUpdateModalStatus('edit_product', false)} origin={product}
            profile={profile} owner={owner} onClose={()=>onUpdateModalStatus('delete_product', false)} onCloseAll={onCloseAllModals} onUpdateProducts={onUpdateProducts} />
            <Card className={classes.card}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container >
                            <Grid md={3} xs={12} sm={3} >
                                <div className={classes.image_container} >
                                    {isValid ? 
                                        <Fade in>
                                                <img alt='' src={selectedImage} className={classes.image} key={`img-press-${id_business}`}
                                                onLoad={onImageLoad} 
                                                onError={onError} />  
                                        </Fade> 
                                    : <div className={classes.image_wrapper}>
                                        <img src={nopicture}  alt='' className={classes.noimage} key={`noimg-press-${id_business}`} />
                                    </div>}
                                </div>
                            </Grid>
                            <Grid md={9} xs={12} sm={9} >
                                <div className={classes.info_container} >
                                    <Grid container justifyContent='space-between'  >
                                        <Grid item>
                                            <div>
                                                <Typography variant='subtitle1' >{name}</Typography>
                                                <Typography className={classes.position} >{position}</Typography>
                                                <Typography className={classes.date} >{onGetDateFormat(date, 'll')}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div style={{textAlign:'right'}} >
                                                <div style={{width:'100%'}} >
                                                    {/* <Button className={classes.button} disabled={!link} >
                                                        <a href={link} target='_blank' className={classes.link} >Ir al sitio</a>
                                                    </Button> */}
                                                    { link && 
                                                        <a href={link} target='_blank' className={classes.link} >Ir al sitio</a>
                                                    }
                                                </div>
                                                {modify ? 
                                                <div style={{display:'flex', float:'right'}} >
                                                    <div style={{marginTop:8, marginRight:8}} ><GenericEditButton onClick={onEdit} /></div>
                                                    <div style={{marginTop:8}} ><GenericDeleteButton onClick={onDelete}/></div>
                                                </div>: null}        
                                            </div>
                                        </Grid>                           
                                    </Grid>
                                    <div className={classes.description_container} >
                                        <Typography className={classes.description} >{description}</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.products_container} >
                            <Grid container justifyContent='space-between' alignItems='center' style={{marginBottom:20}} >
                                <Grid item>
                                    <Typography style={{color:'#808080'}} variant='subtitle2' >Servicios/Productos</Typography>
                                </Grid>
                                {modify ? <Grid item>
                                    {/* <AddButton onClick={()=>onUpdateModalStatus('add_product', true)} /> */}
                                    <SmallLink onClick={()=>onUpdateModalStatus('add_product', true)}>Agregar</SmallLink>
                                </Grid> : null}
                            </Grid>
                            {/* <Grid container >              
                                    {products.length >= 1 ? 
                                        
                                        <Grid item md={4} sm={4} xs={12}>
                                            <ProductItem {...products[selected]} onClick={modify ? ()=>onEditProduct(products[selected]) :undefined }/>
                                        </Grid>                       
                                    : null}
                                    {products.length >= 2 ? 
                                        
                                            <Grid item md={4} sm={4} xs={12} >
                                                <ProductItem {...products[selected+1]} onClick={modify ? ()=>onEditProduct(products[selected+1]) :undefined} />
                                            </Grid>                           
                                    : null}
                                    {products.length >= 3 ? 
                                        
                                            <Grid item md={4} sm={4} xs={12}>
                                                <ProductItem {...products[selected+2]} onClick={()=>onEditProduct(products[selected+2])} />
                                            </Grid>                             
                                    : null}   
                                    {products.length === 0 ?
                                   
                                        <Grid container justifyContent='center'>
                                            <Grid item >
                                                <NoContent />
                                            </Grid>
                                        </Grid>        
                                    
                                    : null}                   
                            </Grid> */}
                            <div>
                                <Scrollbars autoHeight autoHeightMax={700}>
                                    <div className={classes.scroll_container}>
                                        <Grid container wrap='nowrap' >
                                            {products && products.length ? products.map((item, key) => {
                                                let last = false
                                                if(key === products.length-1) last = true
                                                return(
                                                    <Grid item key={`link-${item.id}`}>
                                                        <ProductItem {...item} last={last} modify={modify} onClick={()=>onEditProduct(item)} />
                                                    </Grid>
                                                )
                                            }) : null}
                                        </Grid>
                                    </div>
                                </Scrollbars>
                            </div>
                            {/* {products.length > 3 && selected <= products.length -4 ? <div className={classes.product_overflow} style={{right:0}} >
                                <IconButton className={classes.product_overflow_button_r}  onClick={()=>setSelected(selected+1)}  ><Icon>navigate_next</Icon></IconButton>
                            </div> : null }  
                            {selected > 0 ? <div className={classes.product_overflow} style={{left:0, transform:'rotate(180deg)'}}>
                                <IconButton className={classes.product_overflow_button_l} onClick={()=>setSelected(selected-1)}  ><Icon>navigate_next</Icon></IconButton>
                            </div> : null } */}         
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )

}

export default BusinessCard

const useStyles = makeStyles()(theme => ({
    root:{
        boxShadow:'0px 4px 5px rgba(0, 0, 0, 0.13)',
        borderRadius:14
    },
    card:{
        borderRadius:16,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        padding:24
    },
    image_container:{
        width:'100%',
        paddingTop:'100%',
        background:alpha(theme.palette.primary.main, 0.25),
        position:'relative',
        borderRadius:16
    },
    image:{
        position:'absolute',
        top:0, left:0,
        objectFit:'contain',
        width:'100%',
        height:'100%',
        //borderTopLeftRadius:8,
        //borderBottomLeftRadius:8,
        objectPosition:'center',
        zIndex:2,
        borderRadius:16
    },
    noimage:{
        position:'absolute',
        top:'50%', left:'50%',
        objectFit:'cover',
        width:160,
        height:160,
        transform:'translate(-50%, -50%)',
        borderRadius:8,
        objectPosition:'center',
        zIndex:2
    },
    scroll_container:{
        paddingBottom:30,
        [theme.breakpoints.only('xs')]:{
            paddingBottom:50
        }
    },
    info_container:{
        marginLeft:24,
        [theme.breakpoints.only('xs')]:{
            marginLeft:0,
            marginTop:40
        }
    },
    button:{
        background:theme.palette.primary.main,
        padding:'8px 24px',
        textTransform:'none',
        color:'white',
        borderRadius:16,
        '&:hover':{
            background:theme.palette.primary.dark
        },
        '&:disabled':{
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    link:{
        textDecoration:'none',
        //color:'white'
        color:theme.palette.primary.main,
        fontWeight:500,
        fontSize:14,
        cursor:'pointer',
        padding:4,
        borderRadius:4,
        '&:hover':{
            //borderBottom:`1px solid ${theme.palette.primary.main}`,
            transition:'all 0.4s linear',
            background:alpha(theme.palette.primary.main, 0.1)
        }
    },
    position:{
        color:'#A5A5A5',
        
    },
    date:{
        color:'#7F7F7F',
        fontSize:'0.8rem',
        marginTop:4,
        fontWeight:500
    },
    description_container:{
        marginTop:12,
        paddingTop:12,
        borderTop:'1px solid #F3F3F3',
        position:'relative'
    },
    description:{
        fontSize:'0.8rem',
        color:'#686868'
    },
    products_container:{
        //background:'red',
        paddingTop:10,
        borderTop:'1px solid #DADADA',
        marginTop:20,
        //paddingBottom:10,
        position:'relative'
    },
    product_overflow:{
        position:'absolute',
        background:'linear-gradient(270deg, #FFFFFF -14.86%, rgba(255, 255, 255, 0) 94.71%)',
        //background:'red',
        height:'100%',
        width:180,
        top:0,
        //right:0,
        [theme.breakpoints.only('xs')]:{
            width:'100%',
            height:100,
            top:'auto',
            bottom:0,
            //background:'red'
        }

    },
    product_overflow_button_l:{    
        //top:'50%',
        //left:120,
        background:'white',
        '&:hover':{
            background:'white',
        },
        boxShadow:'0px 0px 8px rgba(0, 0, 0, 0.2)',
        color:theme.palette.primary.main,
        width:40,
        height:40,
        top:'30%',
        right:-130,
        [theme.breakpoints.only('xs')]:{
            top:'30%',
            right:'-50%',
            //transform:'rotate(90deg)',
            //left:'50%',
            transform:'translate(-50%, 0px) rotate(90deg)'
            //background:'red'
        }
    },
    product_overflow_button_r:{    
        //top:'50%',
        //left:120,
        background:'white',
        '&:hover':{
            background:'white',
        },
        boxShadow:'0px 0px 8px rgba(0, 0, 0, 0.2)',
        color:theme.palette.primary.main,
        width:40,
        height:40,
        top:'50%',
        left:120,
        [theme.breakpoints.only('xs')]:{
            //transform:'rotate(90deg)',
            left:'50%',
            transform:'translate(-50%, 0px) rotate(90deg)'
            //background:'red'
        }
    }
    
}));