import React from 'react'
import SimpleModal from '../../../../components/Modals/SimpleModal'
import QRCode from 'react-qr-code'
import { Grid, Typography } from '@mui/material';


import { makeStyles } from 'tss-react/mui';


const QrCodeModal = ({open, onClose, url}) => {

    const { classes } = useStyles()

  return (
    <SimpleModal open={open} onClose={onClose}>
        <Grid xs={12} className={classes.container} spacing={2}>
            <Typography item variant='h6'>Escanea el código para visitar el perfil</Typography>
            <Grid xs={12} className={classes.qr_code}>
                <QRCode item size={256} value={url}/>
            </Grid>
        </Grid>
    </SimpleModal>
  )
}

export default QrCodeModal

const useStyles = makeStyles()(theme => ({
    qr_code:{
        marginTop:'15px'
    }, 
    container:{
        textAlign:'center',
        padding:'35px'
    }
}));