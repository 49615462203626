const translations = {
  es:{
    title:'Invita a un influencer a ser parte de tu roster',
    subtitle1:'Usuario de un influencer en InPager*',
    send_button:'Enviar',
    edit_button:'Guardar',
    form:{
        username_influencer:{placeholder:'Nombre de usuario', helperText:'Campo requerido'},
  },
  }
}

export default translations