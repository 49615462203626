import { makeStyles } from 'tss-react/mui';
import { Grid, Typography, IconButton, Icon, alpha, Button, CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';
import { USER_TYPES } from '../../../config';
import InputForm from '../../../components/Forms/InputForm';
import DropdownSelect from '../../../components/Forms/DropdownSelect';
import useUserPublicFilters from '../../../shared/useUserPublicFilters';
import SideLabelInput from '../../../components/Forms/SideLabelInput';
import SocialMediaInput from '../components/SocialMediaInput';
import InputPhoneCodeForm from '../../../components/Forms/InputPhoneCodeForm';

const CreateUsername = ({content, form, actions, view_data, system}) => {

    const { classes } = useStyles();
    const { filters } = useUserPublicFilters();
    const {REGISTER_STEPS} = content
    const { user_type, preData, socialMediaList, categories, infuencer_types} = view_data
    const _content = content.create_username
    const {onChangeSocialMediaList, onChangeCategories, onChangeInfuencerTypes, handleRemoveSocialMedia} = actions;
    const validInfuencer = (user_type !== USER_TYPES.INFLUENCER || (socialMediaList?.length !== 0 && categories?.length !== 0 && infuencer_types?.length !== 0))
    const valid = form.username.isValid && form.email_public.isValid && validInfuencer

    return(
        <div>
            <div className={classes.content_data}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                        <IconButton size='small' className={classes.return_button} onClick={() => {

                            let pre_step = REGISTER_STEPS.CREATE_YOUR_PROFILE
                            if (preData != false) {
                                pre_step = REGISTER_STEPS.USER_TYPE_SELECTION
                            }
                            actions.onGoToView(pre_step)
                            system.setSend(false)
                        }}
                        ><Icon fontSize='large'>arrow_back_ios_new</Icon></IconButton>
                    </Grid> 
                    <Grid item xs>
                        <Typography variant='h4' style={{fontWeight:800, fontSize:36}}>
                            {_content.title}
                        </Typography>
                        <Typography variant='h4' style={{fontWeight:700, fontSize:36}}>
                            {_content.subtitle}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputForm data={form.username} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.email_public} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <SideLabelInput label={'Tipo de figura pública'}>
                                <DropdownSelect limit={3} filterType='influencer_type_filter' onUpdateFilter={(data) => onChangeInfuencerTypes(data)} options={filters.types} />
                            </SideLabelInput>
                        </Grid>
                        <Grid item xs={12}>
                            <SideLabelInput label={'Tipo de categorias'}>
                                <DropdownSelect limit={3} filterType='category_filter' onUpdateFilter={(data) => onChangeCategories(data)} options={filters.categories} />
                            </SideLabelInput>
                        </Grid>
                        <Grid item xs={12}>
                            <SocialMediaInput onChangeSocialMediaList={onChangeSocialMediaList} handleRemoveSocialMedia={handleRemoveSocialMedia} socialMediaList={socialMediaList}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.quote} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.description} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item container xs={12}>
                            <div item style={{width:100, marginRight:'10px'}}>
                                <InputPhoneCodeForm data={form.mobile_country_code} onChange={actions.onChangeForm} />
                            </div>
                            <Grid item xs style={{marginTop:'3px'}}>
                                <InputForm data={form.mobile} onChange={actions.onChangeForm} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.website_link} onChange={actions.onChangeForm} />
                        </Grid>
                    </Grid>
                </div>
                <div>
                <Button disabled={!valid} className={classes.submit_button} fullWidth onClick={ !system.finish ? () => {actions.onSubmit(false)} : actions.onGoToView(REGISTER_STEPS.OVERVIEW) }>
                    {_content.next_button}
                    {system.sending ? <CircularProgress size={32} style={{marginLeft:12, color:'white'}}/> : null}
                </Button>
                    {system.error ? <Typography style={{marginTop:16}} align='center' color='error' variant='subtitle1'>{system.error}</Typography> : null}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles()(theme => ({
    content_data:{
        marginTop:25,
    },
    form:{
        margin:'60px auto',
        boxSizing:'border-box',
    },
    card:{
        width:'100%',
        paddingTop:'118%',
        border:`2px solid ${grey[300]}`,
        borderRadius:9,
        cursor:'pointer',
        transition:'all 0.1s linear',
        fontFamily:theme.typography.fontFamily,
        fontWeight:600,
        '&:hover':{
            transition:'all 0.4s linear',
            background:alpha("#000",0.01),
            border:`2px solid ${theme.palette.primary.main}`,
        },
        position:'relative'
    },
    card_selected:{
        border:`2px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main
    },
    card_content:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    return_button:{
        color:theme.palette.primary.main,
        '&:hover':{
            background:'none'
        }
    },
    label:{
        color:'#6B6B6B',
        fontSize:18,
        fontWeight:700,
        marginBottom:12
    },
    submit_button:{
        fontSize:18,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
        '&:disabled':{
            background:grey[300]
        }
    },
}));

export default CreateUsername