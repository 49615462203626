import { useState } from 'react';
import { alpha, Card, Fade, Grid, Icon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment'
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS } from '../../../../../../config';
import nopicture from '../../../../../../assets/image_placeholder.png'
import { isValidDocument } from '../../../../../../shared/utility';
import GenericEditButton from '../../../../../../components/Actions/GenericEditButton';
import GenericDeleteButton from '../../../../../../components/Actions/GenericDeleteButton';
import catalogs from '../../../../../../texts/esp/catalogs';

const EventCard = ({id_event, title, description, date, image, onEdit, link, onDelete, modify, event_mode_id}) => {

    const { classes } = useStyles()
    const [error, setError] = useState(false)
    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let selectedImage = nopicture
    const isValid = isValidDocument(image)
    selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : nopicture

    let event_mode

    if(event_mode_id) {
        let temp = catalogs.event_modes.find(el => el.value === event_mode_id)
        if(temp) event_mode = temp.label
    }

    /*const onGetTags = async() =>{
        try {
            updateAuthorizationHeader(private_server)
            // Get profile basic info
            const params = {order_by:'id_event_tag', order:1}
            const filter={filter:{
                'AND':[
                    {"field":"event_id","operator":"=","value":id_event},
                ]  
            }}
            const request = await private_server.get(`/eventtag/all`, {params:{...params, ...filter}})
            setTags(request.data.data.event_tags)
         
        } catch (error) {
            setError(onGetErrorMessage(error, language))
        }
    }*/

    return(
        <div className={classes.root}>
            <Card className={classes.card}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.image_container}>
                            <div className={classes.overlay}>
                                <div className={classes.chip}>
                                    <Icon color='inherit' style={{marginRight:4}}>sensors</Icon>
                                    {event_mode}
                                </div>
                            </div>
                            {isValid ? 
                                <Fade in>
                                        <img alt='' src={selectedImage} className={classes.image} key={`img-press-${id_event}`}
                                        onLoad={onImageLoad} 
                                        onError={onError} />  
                                </Fade> 
                            : <div className={classes.image_wrapper}>
                                <img src={nopicture}  alt='' className={classes.noimage} key={`noimg-press-${id_event}`} />
                            </div>} 
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.info_container}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant='subtitle1' style={{lineHeight:1.5}}>{title}</Typography>
                                </Grid>
                                {modify ? <Grid item>
                                    <GenericEditButton onClick={onEdit} />
                                </Grid> : null}
                                <Grid item xs={12}>
                                    <Typography variant='body2' color='textSecondary'>{moment(date).format('ll')}</Typography>
                                </Grid>
                            </Grid>
                            <Typography variant='body1' style={{fontWeight:400, marginTop:16}}>
                                {description}
                            </Typography>
                            <div className={classes.link_container} >
                                <Grid container justifyContent='flex-end' alignItems='center'>
                                    {modify ? <Grid item >
                                        <GenericDeleteButton onClick={onDelete}/>
                                    </Grid> : null}
                                    <Grid item xs/>
                                    <Grid item>
                                        { link &&
                                            <a className={classes.link} href={link} target="_blank">Ir al evento</a>
                                        }
                                    </Grid>
                                </Grid>
                                
                            </div>
                            
                        </div>
                    </Grid>
                </Grid>
                
                
            </Card>
        </div>
    )

}

export default EventCard

const useStyles = makeStyles()(theme => ({
    root:{
        boxShadow:'0px 4px 5px rgba(0, 0, 0, 0.13)',
        borderRadius:14
    },
    card:{
        borderRadius:16,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    container:{
        display:'flex'
    },
    image_container:{
        width:'100%',
        paddingTop:'56.25%',
        background:alpha(theme.palette.primary.main, 0.25),
        position:'relative'
    },
    image_wrapper:{

    },
    overlay:{
        background:alpha("#000",0.2),
        position:'absolute',
        top:0, left:0,
        width:'100%',
        height:'100%',
        zIndex:4
    },
    chip:{
        position:'absolute',
        top:8, right:8,
        color:'white',
        fontFamily:theme.typography.fontFamily,
        background:alpha('#000',0.75),
        padding:'8px 16px',
        fontSize:14,
        borderRadius:32,
        display:'flex',
        alignItems:'center'
    },
    image:{
        position:'absolute',
        top:0, left:0,
        objectFit:'cover',
        width:'100%',
        height:'100%',
        //borderTopLeftRadius:8,
        //borderBottomLeftRadius:8,
        objectPosition:'center',
        zIndex:2
    },
    noimage:{
        position:'absolute',
        top:'50%', left:'50%',
        objectFit:'cover',
        width:160,
        height:160,
        transform:'translate(-50%, -50%)',
        borderRadius:8,
        objectPosition:'center',
        zIndex:2
    },
    info_container:{
        width:'100%',
        boxSizing:'border-box',
        padding:20,
        position:'relative',
    },
    date_container:{
        padding:'8px 0px 16px',
        borderBottom:'1px solid #F3F3F3',
        marginBottom:16,
        color:'#7F7F7F'
    },
    button_container:{
        position:'absolute',
        bottom:20,
        width:'100%',
        textAlign:'center'
    },
    link_container:{
        marginTop:16,
        //display:'flex',
        //justifyContent:'flex-end'
    },
    link:{
        textDecoration:'none',
        color:theme.palette.primary.main,
        padding:8,
        borderRadius:4,
        fontWeight:500,
        '&:hover':{
            background:alpha(theme.palette.primary.main,0.15)
        }
    },
    edit_button_container:{
        position:'absolute',
        top:20,
        right:20
    }
}));