import React, { useState } from 'react';
import { Grid, Popover, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';

const SmallMenuButton = ({value, options, disabled, onChange, disabledMenu}) => {

    const { classes } = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerClose = () => {
        setAnchorEl(null)
    }

    const onInnerItemSelected = (item) => {
        setAnchorEl(null)
        if(item.value !== value) onChange(item)
    }


    let label = ''
    let color = undefined
    if(options && options.length){
        const temp = options.find(el => el.value === value)
        label = temp.label
        color = temp.color
    }

    return ( 
        <div className={classes.root}>
             {!disabledMenu ? <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onInnerClose} 
             classes={{paper:classes.paper}}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                <div className={classes.popper_container}>
                    <Grid container direction='column'>
                        {options && options.map(item => {
                            return(
                                <Grid item key={item.value.toString()}>
                                    <OptionComponent {...item} onItemSelected={onInnerItemSelected}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </Popover> : null}
            <div onClick={!disabledMenu ? (e) => setAnchorEl(e.currentTarget) : undefined} className={classes.button} style={{background:color}}>
                {label}
            </div>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily
    },
    button:{
        cursor:'pointer',
        fontSize:12,
        background:grey[300],
        padding:'4px 6px',
        borderRadius:32,
        fontWeight:600
    }

}));

export default SmallMenuButton;


const OptionComponent = props => {

    const {label, onItemSelected, top_line} = props
    const { classes, cx } = useStyles4Option()

    return(
        <div className={cx({
            [classes.root]:true,

        })} onClick={() => onItemSelected(props)}>
            <div className={cx({
            [classes.label_container]:true,
            [classes.border]:top_line,
        })}>
                {label}
            </div>
        </div>
    )
}

const useStyles4Option = makeStyles()(theme=>({
    root:{
        position:'relative',
        fontSize:12,
        paddingLeft:theme.spacing(1),
        paddingRight:theme.spacing(1),
        fontFamily:theme.typography.fontFamily,
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            fontWeight:700,
            background:alpha("#000",0.05),
            transition:theme.transitions.create(['background','font-weight'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    label_container:{
        padding:'12px 0px',
        
    },
    border:{
        borderTop:`1px solid ${grey[200]}`
    },
    icon:{color:theme.palette.primary.main}
}));
