import React, { useState, useEffect } from 'react';
import { Fade, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import noimage from '../../assets/image_placeholder.png'
import { CONFIG_SETTINGS } from '../../config';
import SVGIcon from '../Structure/DisplayData/SVGIcon';

const image_ratio = {
    '1/1':'100%',
    '16/9':'56.25%',
    '4/3':'75%',
}

const ProductImage = ({src, size, noborder, ratio}) => {

    const { classes } = useStyles()
    const theme = useTheme()
    const [error, setError] = useState(true)

    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    const imgSrc = src ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : null

    const _ratio = ratio ? ratio : '1/1'

    let image_height = image_ratio[_ratio] 

    return ( 
        <div className={classes.image_container} style={{paddingTop:image_height}}>
            {error ? <div className={classes.image_wrapper}><img src={noimage}  alt='' className={classes.image} /></div>  : null}
            <Fade in={!error}>
                <div className={classes.image_wrapper}>
                    <img alt='' src={imgSrc} className={classes.image} onLoad={onImageLoad} onError={onError} />
                </div>
                
            </Fade>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    image_container:{
        position:'relative',
        margin:'auto',
        width:'100%',
        paddingTop:'100%',
        boxSizing:'border-box',
        borderRadius:8,
        background:'white'
    },
    image_wrapper:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'contain',
    },
    svg_container:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        padding:8,
        boxSizing:'border-box'
    }
}));

export default ProductImage;