import { Grid, Icon, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import moment from 'moment'
import 'moment/locale/es'
import Scrollbars from 'react-custom-scrollbars-2';
import GenericEditButton from '../../../../../../components/Actions/GenericEditButton';
import { onGetDateFormat } from '../../../../../../shared/utility';
import ProfileCategories from '../../../../../../components/Platform/ProfileCategories';


const SpecificDescriptionCard = ({content, description, updated_at, onEdit, modify, manager, onAddCategory, onRemoveCategory, categories}) =>{

    const { classes } = useStyles()
    const current_date = onGetDateFormat(moment.utc(updated_at).format(), 'LLL')

    const dates = categories.map(type => new Date(type.updated_at))
    const maxDate = new Date(Math.max.apply(null, dates))
    const dateUpdate = onGetDateFormat(moment.utc(maxDate).format(), 'LLL')

    return(
        <SimpleCard>
            <div >
                <div>
                    <Grid container wrap='nowrap'>
                        <Grid xs>
                            <Grid item xs className={classes.categories}>
                                <Typography variant='subtitle1'>Categorías de Contenido</Typography>
                                {dateUpdate &&
                                    <Typography variant='body2' color='textSecondary'>Actualizado el {dateUpdate}</Typography>
                                }
                                <ProfileCategories categories={categories} modify={modify} 
                                onAddCategory={onAddCategory} onRemoveCategory={onRemoveCategory}/>
                              </Grid> 
            
                            <Grid container item xs justifyContent='space-between'>
                                <Typography variant='subtitle1'>Descripción</Typography>
                                {modify ? <Grid item>
                                    <GenericEditButton  onClick={onEdit} />
                                </Grid>: null}
                            </Grid> 
                            <Typography variant='body2' color='textSecondary'>Actualizado el {current_date}</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.description}>
                    <Scrollbars >
                        <div >
                            {description}
                        </div>
                        
                    </Scrollbars>
                </div>
                
                
            </div>
        </SimpleCard>
    )

}

const useStyles = makeStyles()(theme => ({
    card:{
        border:'none',
        
    },
    description:{
        marginTop:16,
        fontFamily:theme.typography.fontFamily,
        //height:218,
        height:300,
        whiteSpace:'pre-line'
        
    },
    categories:{
        marginBottom:'15px'
    }
}));

export default SpecificDescriptionCard