import React, { useState, useEffect } from "react"
import { updateAuthorizationHeader, onGetErrorMessage } from "../../../../../shared/utility"
import { public_server, private_server } from "../../../../../config"

const MAX_TYPES = 3

const useAddTypeFigureModal = ({open, user_types, user_id, owner, master_actions}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [types, setTypes] = useState([])
    const [selected_types, setSelectedTypes] = useState([])

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
        }
        
    }, [open])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                // Request all available types
                const type_request = await public_server.get("/typefigure/all/ns")
                updateAuthorizationHeader(private_server)
                const _types = type_request.data.data.types
                let modified_types = []
                setSelectedTypes([...user_types])
                for(let i=0; i<_types.length; i++){
                    const temp = _types[i]
                    let data2add = {...temp, selected:false}
                    const elCat = user_types.find(el => el.type_figure_id === temp.id_influencer_type)
                    data2add.type_figure_id = temp.type_figure_id // add to match in ids
                    if(elCat) data2add.selected = true
                    modified_types.push(data2add)
                }
                modified_types.sort((a,b)=>{
                    if(a.name > b.name) return 1
                    if(a.name < b.name) return -1
                    return 0
                })
                setTypes(modified_types)

            } catch (error) {
            }
            setLoading(false)
        },
        onRemoveType: (data) => {
            let _types = [...types]
            let _selectedTypes = [...selected_types]
            //Remove from category
            const posItem = types.findIndex(el => 
                el.id_influencer_type === data.type_figure_id
                ||  el.influencer_type_id === data.id_type_figure
                || el.name === data.name
            )
            _types[posItem].selected = false

            //remove from selected_Catagory
            const _sCat = selected_types.findIndex(el => el.type_figure_id === data.type_figure_id || el.influencer_type === data.influencer_type)
            _selectedTypes.splice(_sCat, 1)
            setTypes(_types)
            setSelectedTypes(_selectedTypes)
        },
        onTypeSelected: (item) => {
            let _types = [...types]
            let _selectedTypes = [...selected_types]
            const posItem = types.findIndex(el => el.id_influencer_type === item.id_influencer_type)

            if(item.selected){
                //Remove itemx
                _types[posItem].selected = false
                const _sCat = selected_types.findIndex(el => el.type_figure_id === item.id_influencer_type)
                _selectedTypes.splice(_sCat, 1)
            }else{
                //Add item
                if(selected_types.length < MAX_TYPES){
                    _selectedTypes.push(item)
                    _types[posItem].selected = true
                }
                
            }
            setTypes(_types)
            setSelectedTypes(_selectedTypes)
        },
        onSubmit: async() => {

            setSending(true)

            let types2add = []
            let types2remove = []
            
            for(let i=0; i<user_types.length; i++){
                const temp = user_types[i]
                const findEl = selected_types.find(el => el.type_figure_id === temp.type_figure_id)
                if(!findEl) types2remove.push(temp)
            }

            for(let i=0; i<selected_types.length; i++){
                const temp = selected_types[i]
                const findEl = user_types.find(el => el.type_figure_id === temp.id_influencer_type)

                if(!findEl) types2add.push({
                    user_id:user_id,
                    type_id:temp.id_influencer_type
                })
            }

            const add_type_url = owner ? '/influencertypes/me' : '/influencertypes'

            try {
                updateAuthorizationHeader(private_server)
                if(types2add.length){
                    for(let i=0; i<types2add.length; i++){
                        const data2send = types2add[i]
                        try {
                            await private_server.post(add_type_url, data2send)
                        } catch (error) {
                        }
                        
                    }
                }

                if(types2remove.length){
                    for(let i=0; i<types2remove.length; i++){
                        const data2send = types2remove[i]
                        const remove_type_url = owner 
                        ? `/influencertypes/${data2send.id_type_influencer}/me` 
                        : `/influencertypes/${data2send.id_type_influencer}`
                        try {
                            await private_server.delete(remove_type_url)
                        } catch (error) {
                        }
                    }
                }
                setError(null)
                setSending(false)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            

            await master_actions.onGetProfileDetails()
            await master_actions.onUpdateModalStatus('add_type_public_figure', false)

            setSending(false)

        },
    }

    const view_data = { types, selected_types }
    const system = { loading, sending, error }

    return { actions, view_data, system }
}

export default useAddTypeFigureModal