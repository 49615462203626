import { useState, useEffect } from "react"
import { public_server,private_server, USER_TYPES } from "../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm ,onSetTokenSession, updateAuthorizationHeader} from "../../shared/utility"
import { catalogs } from "../../texts/esp/catalogs"
import { generic_errors } from "../../texts/esp/genericErrors"

const current_code = 'INPAGER2022'

const useSignUpLayout = ({ language, content, history,onUpdateUserData,onUpdateAuthStatus}) => {
    const {REGISTER_STEPS} = content
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [step, setStep] = useState(REGISTER_STEPS.METHOD_SELECTION)
    const [user_type, setUserType] = useState(null)
    const [isValid, setIsValid] = useState(false)
    const [user, setUser] = useState(null)
    const [send, setSend] = useState(false)
    const [finish, setFinish] = useState(false)
    const [preData,setPreData] = useState(false)
    const [socialMediaList, setSocialMediaList] = useState([])
    const [influencer_types, setInfuencerTypes] = useState(null)
    const [categories, setCategories] = useState(null)

    useEffect(() => {
        actions.onInitModule()
    }, [])    

    const actions = {
        onInitModule: async() => {
            const _form = onInitForm(form, null, {
                ...content.personal_information_section.form,
                ...content.profile_section.form,
                ...content.create_username.form
            })
            _form.gender_id.options = [...catalogs.genders]
            _form.country_id.options = [...catalogs.countries]
            if(user_type === USER_TYPES.AGENCY || user_type === USER_TYPES.BRAND){
                _form.country_id.isRequired = true
                _form.zone.isRequired = true
            }else{
                _form.country_id.isRequired = false
                _form.zone.isRequired = false
            }
            setForm(_form)
        },
        onChangeForm: (data,full = false) => {

            if(!full){
                let temp = { ...form }
            
                const id = data.config.id
                temp[id] = { ...data }
                if(id === 'password'){
                    temp.password_confirmation.value2 = data.value
                    temp.password_confirmation.isValid = data.value === temp.password_confirmation.value
                }
                if(id === 'email'){
                    temp.email_confirmation.value2 = data.value
                    temp.email_confirmation.isValid = data.value === temp.email_confirmation.value
                }
                if(error) setError(null)
                setForm(temp)
            }else{
                setForm(data)
            }

        },            
        onChangeForwardStep:async(_step) => {
            try {
                switch(_step){

                }

                setStep(_step)
            } catch (error) {
                
            }
        },
        
        onSubmit: async(google_facebook = false,object_google = false,object_facebook = false) => {

            //If register with facebook or google, first try login directly

            if(google_facebook){
                let data2send = {device:'web',object_google:false,object_facebook:false}
                data2send.username = 'google_facebook'
                data2send.password = 'google_facebook'

                if(object_google) data2send.object_google = object_google
                
                if(object_facebook) data2send.object_facebook = object_facebook

                setSending(true)

                try {
                    const response = await public_server.post(`/login`, data2send)
    
                    const token_data = response.data.data
                    await onSetTokenSession(token_data)
                    updateAuthorizationHeader(private_server)
                    const user_request = await private_server.get('/user/me')
                    onUpdateUserData(user_request.data.data.user)
                    onUpdateAuthStatus(true)
                    if(user_request.data.data.user.user_type_id === USER_TYPES.AGENCY || user_request.data.data.user.user_type_id === USER_TYPES.BRAND ){
                        history.push('/dash/influencers')
                    }else{
                        history.push('/dash/account')
                    }
                    setError(null)
                } catch (error) {
                    setSending(false)
                    const _error = onGetErrorMessage(error)
    
                    //If has error 401(user not found) and the login is with google or facebook, redirect to register
                    if(_error.code == 401 && google_facebook){
                        return {openModal:true,details:_error.details}                        
                    }
                    setError(_error ? _error.message : null)
                }
            }



            if(user_type === USER_TYPES.BRAND || user_type === USER_TYPES.AGENCY){
                //TODO : ProfileSectionAgency.jsx
                //form.email_confirmation.isRequired = true
            }

            const errors = isFormValid(form)

            if(errors && errors.length){
                let _form = onSetErrorsToForm(form, errors)
                
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            data2send.user_type_id = user_type;
            data2send.url = data2send.username
            delete data2send.email_confirmation

            setSending(true)

            if(user_type === USER_TYPES.MANAGER){
                delete data2send.reference_code
                if(data2send.coupon !== undefined && data2send.coupon !== null && data2send.coupon !== ""){
                    //Validate coupon
                    const coupon_db = managers_coupons.find(el => el === data2send.coupon)

                    

                    if(!coupon_db){
                        let _form = {...form}
                        _form.coupon.value = ''
                        _form.coupon.isValid = false
                        _form.coupon.isVisited = false
                        setForm(_form)
                        setError('CUPÓN NO VÀLIDO')
                        setSending(false)
                        return
                    }
                }else{
                    delete data2send.reference_code
                }
            }

            if(user_type === USER_TYPES.INFLUENCER){
                delete data2send.coupon
                if(data2send.reference_code === "") delete data2send.reference_code
                /*if(data2send.referal_code){
                    //Validate referal code
                    // Get profile categories 
                    let temp = data2send.referal_code.split('2022')
                    let _url = ''
                    if(temp.length > 1){
                        _url = temp[0]
                        try {
                            const user_req = await public_server.get(`/user/url/public/ns?url=${_url}`)
                        } catch (error) {
                            let _form = {...form}
                            _form.referal_code.value = ''
                            _form.referal_code.isValid = false
                            _form.referal_code.isVisited = false
                            setForm(_form)
                            setError('CÒDIGO DE REFERENCIA NO VÀLIDO')
                            setSending(false)
                            return
                        }
                        
                    }else{
                        let _form = {...form}
                        _form.referal_code.value = ''
                        _form.referal_code.isValid = false
                        _form.referal_code.isVisited = false
                        setForm(_form)
                        setError('CÒDIGO DE REFERENCIA NO VÀLIDO')
                        setSending(false)
                        return
                    }
                }else{
                    delete data2send.referal_code
                }*/
            }

            if(user_type === USER_TYPES.AGENCY || user_type === USER_TYPES.BRAND){
                delete data2send.reference_code
            }

            
            data2send.type_figure = influencer_types;
            data2send.categories = categories;
            data2send.social_media = socialMediaList;
            data2send.last_name = ' '

            try {
                const response = await public_server.post('/user/register', data2send);
                setUser(response.data.data.user)
                setError(null)
                setFinish(true)
            } catch (error) {
                const _errror = onGetErrorMessage(error)
                setFinish(false)
                setError(_errror.message)
            }

            setSending(false)

        },
        onGoToView:(_step) => {
            setStep(_step)
            setSend(false)
        },
        onUpdateUserType: (_data,next_step) => {
            setUserType(_data)
            setStep(next_step)
        },
        onChangePage: (url) => history.push(url),
        onUpdateModalStatus: (value) => setModal(value),
        onSetPreData : (value) => setPreData(value),
        onCheckMail: async () => {
            form.username.isValid = true

            const errors = isFormValid(form)

            if(errors && errors.length){
                let _form = onSetErrorsToForm(form, errors)
                
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)


            form.username.isValid = false
            form.username.isRequired = true

            setSending(true)

            try{
                const response = await public_server.post('/user/check/email', data2send)
                setSending(false)
                setSend(true)
                setError(null)

            } catch(error) {
                const _errror = onGetErrorMessage(error)
                setSending(false)
                setSend(false)
                setError(_errror.message)
            }
        },
        handleRemoveSocialMedia: (index) => {
            const temp = [...socialMediaList];
            temp.splice(index,1);
            setSocialMediaList([...temp]);
        },
        onChangeSocialMediaList: (newValue) => {
            setSocialMediaList(newValue);
            return
        },
        onChangeCategories: (object) => {
            setCategories(object);
            return
        },
        onChangeInfuencerTypes: (object) => {
            setInfuencerTypes(object);
            return
        }
    }

    const system = {loading, sending, error, send, setSend, finish}
    const view_data = {step, user_type, isValid, user, modal, preData, socialMediaList, categories, influencer_types}

    return {system, form, actions, view_data}
}

export default useSignUpLayout

const form_data = {
    birth_date:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'birth_date',
            type:'date',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    },
    gender_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'gender_id',
            type:'select',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    },
    first_name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'first_name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'noSpaces',
            minS:1, maxS:256
        }
    },
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'email',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email',
        }
    },
    email_public:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'email_public',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email',
        }
    },
    email_confirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'email_confirmation',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'equals',
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password',
            type:'password',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:10, max:256
        }
    },
    password_confirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password_confirmation',
            type:'password',
            fullWidth: true,
        },
        rules:{
            type:'equals',
            min:1, max:256
        }
    },
    coupon:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'coupon',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'none',
            min:1, max:256
        }
    },
    country_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'country_id',
            type:'select',
            fullWidth: true,
        },
        rules:{
            type:'select',
            //min:1, max:256
        }
    },
    zone:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'zone',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    quote:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'quote',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:1024
        }
    },
    description:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'description',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:1024
        }
    },
    website_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'website_link',
            type:'link',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:0, max:256
        }
    },
    mobile_country_code:{
        value: '52',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        config:{
            id:'mobile_country_code',
            type:'select',
            fullWidth: true,
        },
        rules:{
            type:'select',
        }
    },
    mobile:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'mobile',
            type:'number',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:0, max:45
        }
    }
}


const managers_coupons = [
    'ADLER122',
    'AEME222',
    'ATHLETE122',
    'BAMA322',
    'BLOM222',
    'BLU222',
    'DECIBEL222',
    'DRIVERS122',
    'DW322',
    'FCO222',
    'GAZER222',
    'INFLUYE222',
    'INKE222',
    'INNGLOBAL222',
    'LT222',
    'MCINCO222',
    'MIDAS222',
    'MPV122',
    'PEACH222',
    'PLAYBOOK222',
    'RANDY222',
    'RAWT222',
    'SM122',
    'SOS222',
    'TACTIC222',
    'TALENTAGENCY122',
    'TALENTOTR222',
    'TDRECORDS222',
    'TRENSETTERS222',
    'TRSOCIAL122',
    'TSM222',
    'WARP322',
    'TNG322',
    'LALO222',
    'ASSAEL222',
    'MARCE222',
    'GUS322',
    'LUIS322',
]