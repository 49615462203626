import React from 'react'
import Page from '../../components/Structure/Layouts/Page'
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_SETTINGS } from '../../config'
import { connect } from 'react-redux'
import { isValidDocument, onGetFullResourcePath } from '../../shared/utility'
import image_profile from '../../assets/nouser.png'
import useDashboard from './useDashboard'
import { useHistory } from 'react-router'
import { useEffect } from 'react'
import ProfileData from './components/ProfileData'
import Manager from './components/Manager'
import AddManagerModal from './modals/AddManagerModal'
import Review from './components/Review'
import { actionTypes } from '../../store/actions'
import SendInvitationReviewModal from './modals/sendInvitationReviewModal'
import DeleteManagerModal from './modals/DeleteManagerModal'
import DeleteReviewModal from './modals/DeleteReviewModal'
import Invitations from './components/Invitations'


const Dashboard = ({user, language, onUpdateUserData}) => {

    const { classes } = useStyles()
    const history = useHistory()
    const { actions, view_data, system } = useDashboard(user, onUpdateUserData)
    
    let isValidProfileImg = isValidDocument(user ? user.image : null)
    let profileSrc = isValidProfileImg ? onGetFullResourcePath(user.image) : image_profile

    let isValidManagerImg = isValidDocument(view_data.manager_data ? view_data.manager_data.image : null)
    let managerSrc = isValidManagerImg ? onGetFullResourcePath(view_data.manager_data.image) : null

    useEffect( () => {
        window.scrollTo(0,0)

        if(user !== null){
            actions.onGetUserData()
            actions.onGetProfileDetails()
            actions.getVisits(user.id_user)
        }
        actions.onUpdateData()
        actions.onGetProfileDetailsManager()
        view_data.setHidden(true)
    }, [user])

    return(
        <Page>  
                {system.loading
                ? <CircularProgress size={80} style={{position:'relative', right:-360}}></CircularProgress>
                : <Grid className={classes.container}>
                    <AddManagerModal user = {user} history = {history} open={system.modals.add_manager} onClose={() => actions.onUpdateModalStatus('add_manager', false)} language={language} master_actions={actions} onUpdateUserData={onUpdateUserData}/>

                    <SendInvitationReviewModal open={system.modals.send_invitation_review} onClose={() => actions.onUpdateModalStatus('send_invitation_review', false)} language={language}/>

                    <DeleteManagerModal open={system.modals.delete_manager} onClose={() => actions.onUpdateModalStatus('delete_manager', false)} language={language} actions={actions} sending={system.sending} success={system.success} error={system.error} setSuccess={system.setSuccess}/>

                    <DeleteReviewModal open={system.modals.delete_review} onClose={() => actions.onUpdateModalStatus('delete_review', false)} deleteId={system.deleteId} master_actions={actions}/>
                    
                    <ProfileData user={user} profileSrc={profileSrc} history={history} visits={view_data.visits} influencer_categories={view_data.influencer_categories} influencer_types={view_data.influencer_types}/>

                    {user?.user_type_id === 6 && <Manager manager_data={view_data.manager_data} system={system} actions={actions} managerSrc={managerSrc} average_ratingManager={view_data.average_ratingManager} influencers={view_data.influencers} history={history} manager_types={view_data.manager_types}/>}

                    {user?.user_type_id !== 5 && user?.user_type_id !== 4 && <Invitations user={user} language={language} invitations={view_data.invitations} />}

                    <Review average_rating={view_data.average_ratingUser} hidden={view_data.hidden} master_actions={actions} reviews={system.reviews} setHidden={view_data.setHidden}/>

            </Grid>}
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

const useStyles = makeStyles()(theme => ({
    container:{
        width: '100%',
        padding:'32px 10px',
    }
}));