import { Grid, Icon, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import system_translations from '../../../../../texts/system_translations'
import DisplayError from '../../../../../components/Structure/DisplayData/DisplayError'
import useRejectInvitationModal from './useRejectInvitationModal'
import { green, red } from "@mui/material/colors"


const RejectInvitationModal = ({open, onClose, invitation, onCompleted, master_actions}) => {

    const { classes } = useStyles()
    const {system, actions} = useRejectInvitationModal({open, onCompleted, onClose, invitation, master_actions})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div className={classes.root}>
                <Grid container direction='column' justifyContent='center' alignItems='center' spacing={0}>
                    <Grid item>
                        <div className={classes.iconContainer}>
                            <Icon className={classes.icon}>close</Icon>
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle1' style={{paddingTop:8}}>¿Estás seguro de <strong className={classes.highlight}>rechazar</strong> esta invitación?</Typography>
                    </Grid>
                    <Grid item>
                        <div style={{width:'100%', maxWidth:500, margin:'auto'}}>
                            <Typography variant='body1' align="center">Al rechazar esta invitación ya no podrás revertir el proceso</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        {invitation.direction === 0
                        ? <Typography style={{marginTop:16}}><strong className={classes.highlight}>Mánager: </strong> {invitation?.client}</Typography>
                        : <Typography style={{marginTop:16}}><strong className={classes.highlight}>Influencer: </strong> {invitation?.influencer}</Typography>
                        }
                    </Grid>
                </Grid>
                <div className={classes.button_container}>
                    {system.error ? <DisplayError>{system.error}</DisplayError> : null}
                    <RoundedButton onClick={actions.onSubmit} loading={system.sending} color='red'>Rechazar</RoundedButton>
                </div>
            </div>
            
        </SimpleModal>
    )

}

export default RejectInvitationModal

const useStyles = makeStyles()(theme => ({
    message:{
        marginTop:8,
        fontFamily:theme.typography.fontFamily,
        fontSize:'1.125rem',
        '& strong':{
            color:theme.palette.primary.main
        }
    },
    form:{
        margin:'32px 0px'
    },
    button_container:{
        textAlign:'center',
        marginTop:32
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    },
    iconContainer:{
        width:100, 
        height:100, 
        border:`1px solid ${red[700]}`,
        borderRadius:'50%',
        position:'relative'
    },
    icon:{
        fontSize:64,
        width:64, 
        height:64,
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        color:red[700]
    },
    highlight:{
        color:theme.palette.primary.main
    }
    
}));