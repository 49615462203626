import { Grid, Typography, Button, alpha } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import React from "react";

const SectionA = ({content, history}) => {

    const { classes } = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.subroot}>
                <div className={classes.positionerInfo}>
                    <div style={{maxWidth:500}}>
                        <Typography className={classes.title}>{content.title}</Typography>
                        <div className={classes.subtitle_container}>
                            <Typography style={{fontSize:20, color:'#636363', marginTop:16}}>{content.text}</Typography>
                        </div>
                        <div style={{width:'100%'}}>
                        <Button className={classes.button} onClick={() => history.push('/signup')}>{content.button}</Button>
                        </div>
                        <Button className={classes.login_button} onClick={() => history.push('/signin')}>{content.login_button}</Button>
                    </div>
                </div>
            </div>
            <div className={classes.overlay}/>
            <div className={classes.imageContaniner}>
                <img alt='' src={content.image} className={classes.image} />   
            </div>           
        </div>
    )
}

export default SectionA

const useStyles = makeStyles()(theme => ({
    root:{
        position:'relative'
    },
    subroot:{
        width:1400,
        margin:'auto',
        height:'100vh',
        //background:'red',
        boxSizing:'border-box',
        [theme.breakpoints.only('lg')]:{
            width:1200,
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            padding:'0px 18px'
            //padding:'12px 0px',
            //height:300
        },
        /* [theme.breakpoints.down('sm')]:{
            height:'70vh'
        } */
        
    },
    overlay:{
        position:'absolute',
        left:'50%',
        width:'50%',
        height:'100%',
        zIndex:3,
        top:0,
        background: alpha('#000',0.3),
        position:'absolute',
        [theme.breakpoints.down('md')]:{
            //display:'none'
            width:'100%',
            left:0
        }
    },
    positionerInfo:{
        //width:'40%', 
        display:'flex',
        alignItems:'center',
        height:'100%',
        [theme.breakpoints.down('md')]:{
            //position:'absolute',
            //top:'50%',
            //transform:'translate(0px, -50%)',
            width:'100%',
            //background:'rgb(0,0,0,0.5)',
            padding:'20px 0px' ,
            //height:'65vh',
            zIndex:7,
            position:'relative',
            textAlign:'center',
            color:'white'
            //background:'red' 
        }
    },
    title:{
        fontWeight:900, fontSize:50,
        [theme.breakpoints.down('md')]:{
            fontSize:36
        }
    },
    subtitle_container:{
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    },
    imageContaniner:{
        position:'absolute',
        left:'50%',
        width:'50%',
        height:'100%',
        top:0,
        [theme.breakpoints.down('md')]:{
            //display:'none'
            width:'100%',
            left:0
        }
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    button:{
        
        background:theme.palette.primary.main,
        borderRadius:40,
        color:'white',
        textTransform:'none',
        padding:'8px 24px',
        marginTop:40,
        fontWeight:600,
        fontSize:24,
        '&:hover':{
            background:theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]:{
            fontSize:20
        }
    },
    login_button:{
        display: { md: 'none', xs: 'default' },
        background:'transparent',
        borderRadius:40,
        color:'white',
        textTransform:'none',
        padding:'8px 24px',
        marginTop:40,
        fontWeight:600,
        fontSize:24,
        border:'1px solid white',
        '&:hover':{
            background:theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]:{
            fontSize:20
        }
    },

   

}));