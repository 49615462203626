import { useState, useEffect } from "react"
import { EVENT_TYPES, private_server, public_server, USER_TYPES } from "../../../config"
import { updateAuthorizationHeader } from "../../../shared/utility"

//Icons
import mail_icon from '../../../assets/icons/contact/mail.svg'
import phone_icon from '../../../assets/icons/contact/phone.svg'
import web_icon from '../../../assets/icons/contact/web.svg'
import whatsapp_icon from '../../../assets/icons/contact/whatsapp.svg'
import queryString from 'query-string'
import { useHistory } from "react-router"
import qs from 'query-string'
const useAccountView = ({ user,  match}) => {

    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState('')
    const [profile, setProfile] = useState(null)
    const [influencer_categories, setInfluencerCategories] = useState([])
    const [influencer_types, setInfluencerTypes] = useState([])
    const [facebook_access_code, setFacebookAccessCode] = useState(null)
    const [links, setLinks] = useState([
        {id:1, value:'', prefix:'tel:', label:'phone', key:'mobile', target:'_blank', icon:phone_icon},
        {id:2, value:'', prefix:'', label:'whatsapp', key:'whatsapp_link', target:'_blank', icon:whatsapp_icon},
        {id:3, value:'', prefix:'mailto:', label:'mail', key:'email_public', target:'_blank', icon:mail_icon},
        {id:4, value:'', prefix:'', label:'website', key:'website_link', target:'_blank', icon:web_icon}
    ])
    const [permissions, setPermissions] = useState()
    const [page, setPage] = useState(1)
    const [modify, setModify] = useState(false)
    const [owner, setOwner] = useState(false)
    const [manager, setManager] = useState(false)
    const [manager_data, setManagerData] = useState()
    const [influencers, setInfluencers] = useState([])
    const [average_rating, setAverageRating] = useState(0.0)
    const [menu, setMenu] = useState([
        {id:1, total:1, label:'Inicio', isclient:true},
        {id:2, total:1, label:'Redes sociales', isclient:false},
        {id:3, total:0, label:'Prensa', isclient:true},
        {id:4, total:0, label:'Conferencias', isclient:true},
        {id:5, total:0, label:'Eventos', isclient:true},
        {id:6, total:0, label:'Empresas', isclient:true},
    ])

    const [modals, setModals] = useState({
        add_category:false,
        edit_profile:false,
        delete_profile:false,
        edit_profile_picture:false,
        edit_profile_banner:false,
        add_media_kit:false,
        edit_media_kit:false,
        invitation_request:false,
        add_type_public_figure: false,
        qr_code: false
    });

    useEffect(() => {
        const args = qs.parse(history.location.search)
        if(args.view){
            setPage(parseInt(args.view))
        }
    },[history.location])

    useEffect(() => {
        //if(user)
        actions.onInitModule()
    }, [user, history.location])

    useEffect(()=>{window.scrollTo(0,0)}, [])


    useEffect(() => {
        if(profile){
            actions.onGetProfileDetails()
            actions.onGetSections()
            
            if(profile?.username === user?.username){
                setModify(true)
                setOwner(true)
            }else{
                if(profile.influencer_client_id !== null){
                    setModify(profile.influencer_client_id === user?.id_user)
                }else{
                    setModify(false)
                }
                setOwner(false)
                // Falta habilitar si es de tipo manager y si es propietario 
            }

            if(user?.user_type_id === 1) {
                setModify(true)
            }
            return
        }
        setModify(false)
        setOwner(false)

        const args = queryString.parse(history.location.search)

        if(args.code){
            setFacebookAccessCode(args.code)
            setPage(2)
        }

    }, [profile])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                await actions.onGetUserData()
            } catch (error) {
            }
            setLoading(false)
        },
        onUpdateMasterUser: (data) => {
            setProfile(data)
        },
        onGetSections: async() => {
            try {
                const press_total = await actions.onGetEventsNumber(EVENT_TYPES.PRESS)
                const conference_total = await actions.onGetEventsNumber(EVENT_TYPES.CONFERENCE)
                const event_total = await actions.onGetEventsNumber(EVENT_TYPES.EVENT)
                const business_total = await actions.onGetBusinessNumber()
                let _menu = [...menu]
                menu[2].total = press_total
                menu[3].total = conference_total
                menu[4].total = event_total
                menu[5].total = business_total

                setMenu(_menu)


            } catch (error) {
            }
        },
        onGetUserData: async() => {
            const {id} = match.params
            let url = '/user/me'
            if(Boolean(id)) url = `/user/url/public/ns?url=${id}`
            let user_request
            if(url === '/user/me'){
                user_request = await private_server.get(url)
            }else{
                user_request = await public_server.get(url)
            }
            const _profile = user_request.data.data.user
            let _links = [...links]
            for(let i=0; i<links.length; i++) _links[i].value = _profile[_links[i].key]
            setLinks(_links)

            // Is manager
            const ismanager = _profile.user_type_id === USER_TYPES.MANAGER
            setManager(ismanager)

            // Search for influencers
            if(ismanager){
                
                const parameters = {offset:0, limit:100, order:0, order_by:'first_name'}
                let queryParams = `?${queryString.stringify(parameters)}`
                let specialFilter = [
                    {field:'user_type_id', operator:'=',value:`${USER_TYPES.INFLUENCER}`},
                    {field:'influencer_client_id', operator:'=',value:`${_profile.id_user}`},
                ]
                queryParams = `${queryParams}&filter=${encodeURIComponent(JSON.stringify({"AND":specialFilter}))}`
                const url = `/user/all/public/ns${queryParams}`
                const request_users = await public_server.get(url)
                setInfluencers(request_users.data.data.users)

            }else{
                    
                if(_profile.influencer_client_id){
                    const manager_data = await public_server.get(`/user/${_profile.influencer_client_id}/public/ns`)
                    setManagerData(manager_data.data.data.user)
                }else{
                    setManagerData(false)
                }
                
            }

            setProfile(_profile)
            //onUpdateUserData(_profile)
            return _profile
        },
        onRemoveCategory: async(data) => {
            const remove_category_url = owner 
            ? `/influencercategory/${data.id_influencer_category}/me` 
            : `/influencercategory/${data.id_influencer_category}`
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.delete(remove_category_url)
                await actions.onGetProfileDetails()
            } catch (error) {
            }
            setSending(false)
        },
        onUpdateMasterProfile: (_data) => {
            setProfile(_data)
        },
        onGetProfileDetails: async() => {
            try {
                updateAuthorizationHeader(private_server)
                // Get profile categories 
                const filter = {"AND":[{field:'user_id', operator:'=',value:profile.id_user}]}
                const user_categories_req = await public_server.get(`/influencercategory/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                const user_categoires = user_categories_req.data.data.influencer_categories || []
                setInfluencerCategories(user_categoires)

                const user_types_figure_req = await public_server.get(`/influencertypes/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                const user_types_figure = user_types_figure_req.data.data.influencer_types || []
                setInfluencerTypes(user_types_figure)

                // Get average review
                //const review_url = owner ? '/user/me/reviewaverages' : `/user/${profile.id_user}/reviewaverages/public/ns`
                try{
                    const review_url = `/user/${profile.id_user}/reviewaverages/public/ns`
                    const avg_review_rq = await public_server.get(review_url);
                    const _avgReview = avg_review_rq.data.data.review_averages;
                    setAverageRating(_avgReview)
                }catch(e){
                }
                

            } catch (error) {
            }
        },
        onEditProfile: () => {
            actions.onUpdateModalStatus('edit_profile', true)
        },
        onDeleteProfile: () => {
            actions.onUpdateModalStatus('delete_profile', true)
        },
        onEditProfilePicture: () => {
            actions.onUpdateModalStatus('edit_profile_profile', true)
        },
        onEditProfileBanner: () => {
            actions.onUpdateModalStatus('edit_profile_banner', true)
        },
        onSendInvitationRequest: () => {
            actions.onUpdateModalStatus('invitation_request', true)
        },
        onChangePage: (value) => setPage(value),
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onGetEventsNumber: async(event_type) => {
            const filter = {'AND':[{field:'event_type_id', operator:'=', value:event_type},{field:'user_id', operator:'=', value:profile.id_user}]}
            const pagination = {limit:1, offset:0}
            const params = `?${queryString.stringify(pagination)}&filter=${encodeURIComponent(JSON.stringify(filter))}`
            const events_request = await public_server.get(`/event/all/ns${params}`)
            const total = events_request.data.data.count
            return total
        },
        onGetBusinessNumber: async() => {
            const filter = {'AND':[{field:'user_id', operator:'=', value:profile.id_user}]}
            const pagination = {limit:1, offset:0}
            const params = `?${queryString.stringify(pagination)}&filter=${encodeURIComponent(JSON.stringify(filter))}`
            const request = await public_server.get(`/business/all/ns${params}`)
            const total = request.data.data.count
            return total
        },
        onCompleteInvitationRequest: () => {
            setSuccess('Invitación enviada exitósamente')
        },
        onCleanSuccess: () => {
            setSuccess('')
        },
        addVisit: async (data2send) => {
            try {
                updateAuthorizationHeader(public_server)
                const response = await public_server.post('/user/visit', data2send)
            } catch (error) {
            }
        },
        onRemoveType: async(data) => {
            const remove_category_url = owner 
            ? `/influencertypes/${data.id_type_influencer}/me` 
            : `/influencertypes/${data.id_type_influencer}`
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.delete(remove_category_url)
                await actions.onGetProfileDetails()
            } catch (error) {
            }
            setSending(false)
        },
        onHideMetrics : () => {
            const new_profile = {...profile}
            new_profile.hide_metrics = !profile.hide_metrics
            setProfile(new_profile)
        }
    }

    const system = {loading, sending, error, success}
    const view_data = {profile, influencer_categories, links, page, owner, modify, manager, manager_data,
        influencers, facebook_access_code, menu, average_rating, influencer_types}

    return {system, view_data, actions, modals}
}

export default useAccountView
