import { useState, useEffect } from "react"
import { CONFIG_SETTINGS, private_server, public_server } from "../../../../../config"
import { updateAuthorizationHeader, onGetErrorMessage } from "../../../../../shared/utility"
import instagramIcon from '../../../../../assets/socialmedia/instagram.png'
import facebookIcon from '../../../../../assets/socialmedia/facebook.png'
import linkedinIcon from '../../../../../assets/socialmedia/linkedin.png'
import pinterestIcon from '../../../../../assets/socialmedia/pinterest.png'
import podcastIcon from '../../../../../assets/socialmedia/podcast.png'
import snapchatIcon from '../../../../../assets/socialmedia/snapchat.png'
import tiktokIcon from '../../../../../assets/socialmedia/tik-tok.png'
import twitchIcon from '../../../../../assets/socialmedia/twitch.png'
import twitterIcon from '../../../../../assets/socialmedia/twitter.png'
import youtubeIcon from '../../../../../assets/socialmedia/youtube.png'
import spotifyIcon from '../../../../../assets/socialmedia/spotify.png'
import onlyfansIcon from '../../../../../assets/socialmedia/onlyfans.png'
import redditIcon from '../../../../../assets/socialmedia/reddit.png'
import qs from 'query-string'
import { useHistory } from "react-router"

const useSocialMedia = ({ language, user, match, master_view_data}) => {

    const history = useHistory()
    const [listSocialMedia, setListSocialMedia] = useState([]);
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [busy, setBusuy] = useState(false)
    const [error, setError] = useState(null)
    const [media_data, setMediaData] = useState(JSON.parse(JSON.stringify(mediadata)))
    const [integration, setIntegration] = useState()
    const [force_facebook_button, setForceFacebookButton] = useState(false)
    const [last_fb_register, setLastFBRegister] = useState({...facebook_mockdata})
    const [last_insta_register, setLastInstaRegister] = useState({...instagram_mockdata})
    const [fb_historical, setFBHistorical] = useState([])
    const [insta_historical, setInstaHistorical] = useState([])


    const [modals, setModals] = useState({
        edit_social_media:false,
        setup_facebook_pages:false,
        delete_facebook_pages: false,
        hide_metrics:false
    });

    useEffect(() => {
        const args = qs.parse(history.location.search)
        if(args.show_facebook_instragram_modal) actions.onUpdateModalStatus('setup_facebook_pages', true)
    },[history.location])

    useEffect(() => {
        /* if(master_view_data.profile && user){
            actions.onInitModule()

            let _mediadata = JSON.parse(JSON.stringify(mediadata))
            media_data.forEach((item, key) => {
                const current = master_view_data.profile[item.id]
                if(current) _mediadata[key].url = current
            })
            setMediaData(_mediadata)

        } */
        if(master_view_data.profile ){
            actions.onInitModule()

            let _mediadata = JSON.parse(JSON.stringify(mediadata))
            media_data.forEach((item, key) => {
                const current = master_view_data.profile[item.id]
                if(current) _mediadata[key].url = current
            })
            setMediaData(_mediadata)

        } 
    }, [master_view_data.profile, user])

    

    useEffect(() => {
        const {facebook_access_code} = master_view_data
        if(facebook_access_code){
            if(!busy) actions.onFacebookIntegration()
        }
    }, [master_view_data.facebook_access_code])

    useEffect(() => {
        if(integration){
            const {facebook_page_id, facebook_page, facebook_instagram_page_id, facebook_instagram} = integration
            if(facebook_page_id) actions.onRequestFacebookAnalytics()
            if(facebook_instagram_page_id) actions.onRequestInstagramAnalytics()
        }
    }, [integration])

    useEffect(()=>{window.scrollTo(0,0)}, [])




    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                // This part is with token
                updateAuthorizationHeader(private_server)

                const integration_url = master_view_data.owner ? '/integration/me' : `/integration/${master_view_data.profile.id_user}`
                const integration_rq = await private_server.get(integration_url)
                const _integration = integration_rq.data.data.integration

                
                setIntegration(_integration)
                // Setup facebook
                /* if(_integration.facebook_page === null){
                    actions.onUpdateModalStatus('setup_facebook_pages', true)
                } */
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
            setLoading(false)
        },
        onDeleteIntegration: async() => {
            setLoading(true)
            try {
                // This part is with token
                updateAuthorizationHeader(private_server)

                const integration_url = '/integration/me'
                const integration_rq = await private_server.delete(integration_url)
                const result = integration_rq.data.success
                if(result){
                    window.location.reload();
                }
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
        },
        onRequestFacebookAnalytics: async() => {
            try {
                const single_facebook_url = `/integration/${master_view_data.profile.id_user}/facebook/current`
                
                const fb_analytic_last = await private_server.post(single_facebook_url)
                //const current_fb_analytics = fb_analytic_last.data.data.facebook_analytics.length > 0 ?  fb_analytic_last.data.data.facebook_analytics[0] : facebook_mockdata
                setLastFBRegister(fb_analytic_last.data.data.facebook_data.data)

                const facebook_url = master_view_data.owner ? '/facebookanalytic/user/me' : `/facebookanalytic/user/${master_view_data.profile.id_user}`
                updateAuthorizationHeader(private_server)
                const last_params = {
                    limit:1,
                    offset:0,
                    order:1,
                    order_by:'date'
                }

                

                const historical_params = {
                    limit:50,
                    offset:0,
                    order:1,
                    order_by:'date'
                }
                const fb_analytic_hist = await private_server.get(facebook_url, {params:{...historical_params}})
                const historical_fb_analytics = fb_analytic_hist.data.data.facebook_analytics
                setFBHistorical(historical_fb_analytics)

            } catch (error) {
                if(error?.response?.data?.error?.code === 313){
                    setForceFacebookButton(true)
                    //let _integration = {...integration}
                    //_integration.facebook_valud_token = 0;
                    //setIntegration(_integration)
                    
                }
                
            }
        },
        onRequestInstagramAnalytics: async() => {
            try {
                const single_instagram_url = `/integration/${master_view_data.profile.id_user}/instagram/current`
                updateAuthorizationHeader(private_server)
                
                const last_params = {
                    limit:1,
                    offset:0,
                    order:1,
                    order_by:'date'
                }


                const insta_analytic_last = await private_server.post(single_instagram_url)
                //const current_fb_analytics = insta_analytic_last.data.data.instagram_analytics.length > 0 ?  insta_analytic_last.data.data.instagram_analytics[0] : instagram_mockdata
                setLastInstaRegister(insta_analytic_last.data.data.instagram_data.data)
                const instagram_url = master_view_data.owner ? '/instagramanalytic/user/me' : `/instagramanalytic/user/${master_view_data.profile.id_user}`
                const historical_params = {
                    limit:50,
                    offset:0,
                    order:1,
                    order_by:'date'
                }
                const insta_analytic_hist = await private_server.get(instagram_url, {params:{...historical_params}})
                const historical_insta_analytics = insta_analytic_hist.data.data.instagram_analytics
                setInstaHistorical(historical_insta_analytics)

            } catch (error) {
            }
        },
        onFacebookGetAccessCode: () => {
            //const url = "https://www.facebook.com/v12.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=https%3A%2F%2Flocalhost%3A3000&state=facebook_instagram_integration&scope=email%2Cpages_read_engagement%2Cpages_read_user_content%2Cpages_show_list%2Cpublic_profile%2Cread_insights%2Cinstagram_basic%2Cinstagram_manage_insights"
            const url = `https://www.facebook.com/v12.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${CONFIG_SETTINGS.DOMAIN}&state=123456&scope=email,pages_read_engagement,pages_show_list,public_profile,read_insights,instagram_basic,instagram_manage_insights`
            window.open(url, "_self")
        },
        onFacebookButtonClick: (data) => {
        },
        onFacebookIntegration: async() => {

            try {
                updateAuthorizationHeader(private_server)
                const data2send = {
                    access_code:master_view_data.facebook_access_code
                }
                try {
                    const fb_rq = await private_server.post('/integration/me/facebook/connect', data2send)
                } catch (error) {
                }
                
            } catch (error) {
            }

        },
        onUpdateIntegration: (_data) => {
            setIntegration(_data)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        getSocialMedia: async (id_user) => {
            setLoading(true);
            try {
                //updateAuthorizationHeader(private_server)
                const response = await private_server.get(`/integration2/list/${id_user}`);
                setLoading(false);
                setListSocialMedia(response.data.data);
            }
            catch {
                setLoading(false);
            }
        },
        saveIntegrationsOrder : async (new_order, id_user) => {
            let request_url = '/integration/modifyorder'
            let response = await private_server.post(request_url,{order:new_order, user_id: id_user})
        }
    }

    const system = {loading, sending, error}
    const view_data = {media_data, integration, last_fb_register, fb_historical, last_insta_register, insta_historical, force_facebook_button, listSocialMedia}

    return {system, view_data, actions, modals}
}

export default useSocialMedia

const mediadata = [
    {id:'instagram_link', label:'Instagram', url:null, icon:instagramIcon}, //
    {id:'facebook_link', label:'Facebook', url:null, icon:facebookIcon}, //
    {id:'tiktok_link', label:'Tik Tok', url:null, icon:tiktokIcon}, 
    {id:'youtube_link', label:'Youtube', url:null, icon:youtubeIcon}, //
    {id:'linkedin_link', label:'LinkedIn', url:null, icon:linkedinIcon}, //
    {id:'twitter_link', label:'Twitter', url:null, icon:twitterIcon}, //
    {id:'podcasts_link', label:'Podcasts', url:null, icon:podcastIcon},
    {id:'spotify_link', label:'Snapchat', url:null, icon:spotifyIcon}, //
    {id:'twitch_link', label:'Twitch', url:null, icon:twitchIcon}, //
    {id:'pinterest_link', label:'Pinterest', url:null, icon:pinterestIcon}, //
    {id:'snapchat_link', label:'Snapchat', url:null, icon:snapchatIcon}, //
    {id:'reddit_link', label:'Snapchat', url:null, icon:redditIcon}, //
    {id:'only_fans_link', label:'Snapchat', url:null, icon:onlyfansIcon}, //
]

const facebook_mockdata = {
    date: "2022-03-11 18:03:45",
    end_time: "0000-00-00 00:00:00",
    fan_count: 0,
    id_facebook_analytic: -1,
    page_consumptions: 0,
    page_engaged_users: 0,
    //page_impressions_by_age_gender_unique: "{\"M.55-64\":1,\"F.45-54\":8,\"M.35-44\":20,\"M.18-24\":80,\"F.25-34\":8,\"M.25-34\":54,\"F.65+\":1,\"F.13-17\":17,\"F.55-64\":2,\"M.65+\":2,\"F.35-44\":7,\"M.13-17\":32,\"M.45-54\":13,\"F.18-24\":26}",
    //page_impressions_by_city_unique: "{\"Ciudad Ho Chi Minh, Vietnam\":1,\"General Zuazua, Nuevo León, México\":1,\"Santa Ana, Sonora, México\":1,\"Leoncio Prado, Peru\":1,\"Nindirí, Nicaragua\":1,\"Puerto Cabezas, Nicaragua\":1,\"Barranquilla, Colombia\":1,\"Ricaurte, Ecuador\":1,\"San Mateo Eloxochitlán, Oaxaca, Mexico\":1,\"Puerto Vallarta, Jalisco, México\":1,\"Sanford, Carolina del Norte, Estados Unidos\":1,\"Sicuani, Peru\":1,\"Usumatlán, Guatemala\":1,\"Ensenada, Península de Baja California, México\":2,\"Saltillo, México\":2,\"Angostura, Sinaloa, México\":2,\"Ayacucho, Perú\":2,\"Mocorito, Sinaloa, México\":2,\"Armenia, El Salvador\":2,\"Tacna, Perú\":2,\"Managua, Nicaragua\":2,\"San Pablo de las Salinas, Estado de México, México\":2,\"Guayaquil, Ecuador\":2,\"San Borja, Bolivia\":2,\"Zapopan, Jalisco, México\":2,\"Chiclayo, Perú\":2,\"Culiacán, Sinaloa, México\":2,\"Cancún, México\":2,\"Santa Tecla, El Salvador\":2,\"Austin, Texas, Estados Unidos\":2,\"Chimalhuacán, Estado de México, México\":2,\"Tijuana, Península de Baja California, México\":2,\"Oruro, Bolivia\":2,\"La Habana, Cuba\":2,\"Phoenix, Arizona, Estados Unidos\":3,\"Puebla de Zaragoza, México\":4,\"La Paz, Bolivia, Bolivia\":4,\"Guatemala, Guatemala\":6,\"Santa Cruz de la Sierra, Bolivia\":6,\"Ciudad de México, México\":7,\"Lima, Perú\":7,\"Guamúchil, Sinaloa, México\":7,\"Hermosillo, Sonora, México\":8,\"Cochabamba, Bolivia\":8,\"Heroica Guaymas, Sonora, México\":39}",
    //page_impressions_by_country_unique: "{\"EG\":1,\"SV\":9,\"IN\":1,\"SY\":1,\"HN\":2,\"PY\":1,\"CL\":2,\"MX\":140,\"CO\":13,\"GT\":7,\"BO\":25,\"VE\":1,\"BR\":1,\"AR\":3,\"CU\":2,\"MA\":1,\"PE\":28,\"VN\":1,\"NI\":6,\"EC\":6,\"US\":19}",
    page_post_engagements: 0,
    page_posts_impressions: 0,
    pending: 0,
    period: "week"
}

const instagram_mockdata = {
    //audience_city: "{\"Espinar, Cusco Region\":1,\"Ciudad Guayana, Bolívar (state)\":2,\"Santiago de Cali, Valle del Cauca\":2,\"Algiers, Algiers Province\":1,\"San Juan, San Juan\":1,\"Caracas, Capital District\":4,\"Villa Krausse, San Juan\":1,\"Salto de Agua, Tabasco\":1,\"Guatire, Miranda (state)\":2,\"Mérida, Yucatán\":1,\"Opichén, Yucatán\":1,\"Tucupita, Delta Amacuro\":2,\"Porlamar, Nueva Esparta\":2,\"Bogotá, Distrito Especial\":3,\"Chicoloapan, State of Mexico\":1,\"Jose Luis Bustamante y Rivero, Arequipa Region\":1,\"Madrid, Comunidad de Madrid\":2,\"Barranquilla, Atlantico\":3,\"Belgorod, Belgorod Oblast\":1,\"Managua, Managua Department\":1,\"Monterrey, Nuevo León\":2,\"Coro, Falcón\":1,\"Lima, Lima Region\":5,\"Villa de Mayo, Buenos Aires\":2,\"Castilla, Piura Region\":1,\"Mexico City, Distrito Federal\":10,\"Calkiní, Campeche\":1,\"Naucalpan, State of Mexico\":1,\"Tequisquiapan, Querétaro Arteaga\":1,\"Guatemala City, Guatemala Department\":1,\"Santiago, Santiago Metropolitan Region\":2,\"Guadalajara, Jalisco\":1,\"Maturín, Monagas\":2,\"Garcia, Nuevo León\":2,\"Toluca, State of Mexico\":3,\"Puerto Salgar, Cundinamarca\":1,\"Potrerillos Arriba, Chiriquí Province\":1,\"Isidro Casanova, Buenos Aires\":1,\"Ciudad Victoria, Tamaulipas\":1,\"Bösingfeld, Nordrhein-Westfalen\":1,\"Maracaibo, Zulia\":4,\"Puerto Ayacucho, Amazonas\":1,\"Soledad, Atlantico\":2,\"Valencia, Carabobo\":3,\"San Baltasar Temaxcalac, Puebla\":1}",
    //audience_country: "{\"DE\":1,\"RU\":2,\"HN\":1,\"BO\":1,\"BR\":2,\"DZ\":1,\"EC\":2,\"US\":3,\"SV\":1,\"UY\":1,\"IN\":3,\"CI\":1,\"IR\":1,\"CL\":2,\"MX\":48,\"MY\":1,\"CO\":21,\"GT\":2,\"ES\":2,\"CR\":1,\"VE\":35,\"PA\":2,\"AR\":15,\"PE\":9,\"NG\":1,\"NI\":1}",
    //audience_gender_age: "{\"F.13-17\":14,\"F.18-24\":12,\"F.25-34\":4,\"F.35-44\":1,\"F.45-54\":1,\"M.13-17\":26,\"M.18-24\":52,\"M.25-34\":24,\"M.35-44\":3,\"U.13-17\":5,\"U.18-24\":8,\"U.25-34\":7,\"U.35-44\":3}",
    created_at: "2022-05-09 09:00:03",
    date: "2022-05-09 09:00:01",
    end_time: "0000-00-00 00:00:00",
    facebook_instagram: "Darkvaz",
    facebook_instagram_page_id: "17841444086304508",
    followers_count: 0,
    id_instagram_analytic: 15,
    impressions: 0,
    pending: 0,
    period: "week",
    profile_views: 0,
    reach: 0,
    updated_at: "2022-05-09 09:00:03",
    user: "Daniel Gutierrez",
    user_id: 35,
}