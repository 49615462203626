import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AboutUs from '../layouts/AboutUs/AboutUs';
import AccountInvitationLayout from '../layouts/AccountInvitationLayout/AccountInvitationLayout';
import AccountVerificationLayout from '../layouts/AccountVerificationLayout/AccountVerificationLayout';
import BlogsView from '../layouts/Blog/BlogsView';
import CookiesPolicies from '../layouts/CookiesPolicies/CookiesPolicies';
import HomeView from '../layouts/HomeView/HomeView';
import NoticePrivacy from '../layouts/NoticePrivacy/NoticePrivacy';
import ReviewInvitationLayout from '../layouts/ReviewInvitationLayout/ReviewInvitationLayout';
import SignInLayout from '../layouts/SignInLayout/SignInLayout';
import SignUpLayout from '../layouts/SignUpLayout/SignUpLayout';
import TermsAndConditions from '../layouts/TermsAndConditions/TermsAndConditions';
import FrequentlyAskedQuestions from '../layouts/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import Glosario from '../layouts/Glosario/Glosario';
import WebsiteLayout from '../layouts/WebsiteLayout/WebsiteLayout';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import RegisterInfluencer from '../views/RegisterInfluencer/RegisterInfluencer';
import ChangeEmail from '../views/Account/AccountView/views/ChangeEmail/ChangeEmail';
import Podcasts from '../layouts/Podcasts/Podcasts';
import PodcastPage from '../layouts/Podcasts/PodcastPage';
import system_translations from '../texts/system_translations';

const MainRouter = ({ history }) => {
    const content = system_translations['es']
    return (
        <Router history={history}>
            <Switch>
                <Route path={'/passwordchange'} component={ResetPassword} />
                <Route path={'/registerinfuencer'} component={RegisterInfluencer} />
                <Route path={'/emailchange'} component={ChangeEmail} />
                <Route path={'/blog'} component={BlogsView} />
                <Route path={'/cookies-policies'} component={CookiesPolicies} />
                <Route path={'/terms-and-conditions'} component={TermsAndConditions} />
                <Route path={'/faqs'} component={FrequentlyAskedQuestions} />
                <Route path={'/glosario'}>
                    <Glosario iframeLink={content.layouts.glosario.link}/>
                </Route>
                <Route path={'/versions'}>
                    <Glosario iframeLink={content.general.footer.version_page}/>
                </Route>
                <Route path={'/manual'}>
                    <Glosario iframeLink={content.general.footer.manual_page}/>
                </Route>
                <Route exact path={'/podcasts'} component={Podcasts} />
                <Route path={'/podcasts/:number'} component={PodcastPage} />
                <Route path={'/noticeprivacy'} component={NoticePrivacy} />
                <Route path={'/aboutus'} component={AboutUs} />
                <Route path={'/influencer/review'} component={ReviewInvitationLayout} />
                <Route path={'/confirmation'} component={AccountVerificationLayout} />
                <Route path={'/invite'} component={AccountInvitationLayout} />
                <Route path={'/signin'} component={SignInLayout} />
                <Route path={'/signup'} component={SignUpLayout} />
                <Route path={'/dash'} component={WebsiteLayout} />
                <Route exact path={'/'} component={HomeView} />
                <Redirect to='/' />
            </Switch>
        </Router>
    )
}

export default MainRouter