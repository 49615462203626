import { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router"
import { TOKEN_STATUSES } from "../config"
import { onClearTokenSession, onGetCurrentUserProfile, onGetTokenFromMemory, onReAuthToken, onValidateToken } from "./utility"

const useAuth = ({onUpdateUserData}) => {

    const history = useHistory()
    const [authenticated, setAuthenticated] = useState(false)
    const [done, setDone] = useState(false)
    const [busy, setBusy] = useState(false)

    
    useInterval(() => {
        onCheckTokenExpirationDate()
    }, 300000)
    

    useEffect(() => {
        onCheckTokenExpirationDate()
    },[])


    useEffect(() => {
        const initModule = async() => {
            try {
                // Get token
                //setLoading(true)
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                let _user
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                    case TOKEN_STATUSES.NOT_VALID:
                        onClearTokenSession()
                        onUpdateUserData(null)
                        
                        setAuthenticated(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        await onReAuthToken()
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        setAuthenticated(true)
                        break;
                    default:
                        _user = await onGetCurrentUserProfile()
                        if (_user){
                            onUpdateUserData(_user)
                            setAuthenticated(true)
                        }
                        else{
                            onClearTokenSession()
                            onUpdateUserData(null)
                            setAuthenticated(false)
                        }                        
                        //history.push('/dash')
                        break;
                }             
                
            } catch (error) {
            }
            setDone(true)
        }
        if(!done) initModule();
    }, [])

    const onCheckTokenExpirationDate = async() => {
        if(busy) return
        setBusy(true)
        try {
            // Get token
            const session_data = onGetTokenFromMemory()
            const token_status = onValidateToken(session_data)
            let _user
            switch (token_status) {
                case TOKEN_STATUSES.EXPIRED:
                case TOKEN_STATUSES.NOT_VALID:
                    onClearTokenSession()
                    onUpdateUserData(null)
                    setAuthenticated(false)
                    if(
                        history.location.pathname.includes('/dash/account')
                        || history.location.pathname.includes('/dash/reviews')
                        || history.location.pathname.includes('/dash/invitations')
                    ){
                        history.push('/')
                    }
                    
                    break;
                case TOKEN_STATUSES.REAUTH:
                    await onReAuthToken()
                    //_user = await onGetCurrentUserProfile()
                    //onUpdateUserData(_user)
                    setAuthenticated(true)
                    break;
                default:
                    // _user = await onGetCurrentUserProfile()
                    //onUpdateUserData(_user)
                    //setAuthenticated(true)
                    //history.push('/dash')

                    _user = await onGetCurrentUserProfile()

                    if (_user){
                        onUpdateUserData(_user)
                        setAuthenticated(true)
                    }
                    else{
                        onClearTokenSession()
                        onUpdateUserData(null)
                        setAuthenticated(false)
                    }         
                    break;
            }                
        } catch (error) {
        }
        setBusy(false)
    }

    return {
        authenticated, done
    }
}

export default useAuth

const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}