import React from 'react';
import { makeStyles } from 'tss-react/mui';

const SectionContainer = ({classes, children}) => {

    const { classes: _classes, cx } = useStyles()

    return ( 
        <div className={cx(_classes.root, classes ? classes.root : null)}>
            {children}
        </div>
    );
}

const useStyles = makeStyles()(theme => ({
    root:{
        margin:'auto',
        width:1800,
        padding:'0px 80px',
        boxSizing:'border-box',
        [theme.breakpoints.down('xl')]:{
            width:'100%',
            margin:0,
        },
        [theme.breakpoints.down('lg')]:{
            padding:'0px 40px',
        },
        [theme.breakpoints.down('sm')]:{
            padding:'0px 16px',
        }
    }
}));

export default SectionContainer;