import { Typography, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import system_translations from '../../texts/system_translations';
import ContactModal from '../../modals/ContactModal/ContactModal';
import Footer from '../../components/Structure/Navigation/Footer';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import { actionTypes } from '../../store/actions';

import QuestionCard from './components/QuestionCard';

const FrequentlyAskedQuestions = ({ history, language, user, onUpdateAuthStatus, onUpdateUserData, onChangePage }) => {

    const { classes } = useStyles()
    const content = system_translations[language].layouts.faqs


    return (
        <div className={classes.root}>
            < ContactModal />
            <PublicTopbar content={content.topbar} onChangePage={onChangePage} />
            <div className={classes.titleContainer}>
                <Typography variant="h1" className={classes.title1}> {content.title1}</Typography>
                <Typography variant="h1" className={classes.title2}> {content.title2}</Typography>
                <Typography variant="body1" className={classes.subtitle}>
                    {content.text[0]}
                    <span className={classes.colored}>{content.text[1]}</span>
                    {content.text[2]}
                    <span className={classes.colored}>{content.text[3]}</span>
                    {content.text[4]}
                </Typography>
                <Typography variant="body1" className={classes.colored}>
                    {content.text[5]}
                </Typography>
            </div>
            <div className={classes.questionContainer}>
                {content.questions.map((e) => <QuestionCard question={e.q} answer={e.a}></QuestionCard>)}
            </div>
            <div className={classes.backgroundPositioner}>
                <div className={classes.background} style={{ width: '1500px', height: '1500px' }}>
                    <div className={classes.background} style={{ width: '75%', height: '75%' }}>
                        <div className={classes.background} style={{ width: '70%', height: '70%' }}></div>
                    </div>
                </div>
            </div> 
            <Footer history={history} />
        </div>
    )
}


const useStyles = makeStyles()(theme => ({
    root: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        overflow: 'hidden'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        margin: '100px 80px 50px'
    },
    title1: {
        color: theme.palette.primary.main,
        fontSize: 72,
        [theme.breakpoints.down('lg')]:{
            fontSize: 50,
        }
    },
    title2: {
        color: theme.palette.primary.main,
        fontSize: 72,
        [theme.breakpoints.down('lg')]:{
            fontSize: 35,
        }
    },
    subtitle: {
        fontSize: 25,
        [theme.breakpoints.down('lg')]:{
            fontSize: 18
        }
    },
    colored: {
        color: theme.palette.primary.main,
        fontSize: 25,
        [theme.breakpoints.down('lg')]:{
            fontSize: 18
        }
    },
    questionContainer: {
        marginBottom: 80
    },
    backgroundPositioner:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top: 500,
        left: 'calc(50vw - 750px)',
        zIndex:-1,
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    background:{
        border:'1px dashed rgba(132, 59, 168, 0.2)',
        borderRadius:'50%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:-1
    },
}));

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: state.language,
        modals: state.modals
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateModal: (modal) => dispatch({ type: actionTypes.SYS_UPDATE_MODAL, modal }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrequentlyAskedQuestions)