import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Footer from '../../components/Structure/Navigation/Footer';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import system_translations from '../../texts/system_translations';
import SectionJ from '../HomeView/sections/SectionJ/SectionJ';
import ContactModal from '../../modals/ContactModal/ContactModal';

const BackroundCircles = () => {
    const { classes } = useStylesBackroundCircles()
    return (
        <div className={classes.backgroundPositioner}>
            <div className={classes.background} style={{ width: '1250px', height: '1250px' }}>
                <div className={classes.background} style={{ width: '75%', height: '75%' }}>
                    <div className={classes.background} style={{ width: '70%', height: '70%' }}></div>
                </div>
            </div>
        </div>
    )
}

const useStylesBackroundCircles = makeStyles()(theme => ({
    backgroundPositioner: {
        position: 'absolute',
        height: '120%',
        zIndex: 0,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    background: {
        border: '1px dashed rgba(132, 59, 168, 0.2)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0
    }
}));

const AboutUs = () => {

    const content = system_translations['es'].layouts.about_us
    const { classes } = useStyles({ content })
    const contentHomeview = system_translations['es'].layouts.home
    const higlithInpager = (text) => {
        const array_text = text.split('InPager')
        if (array_text.length === 1) return text
        return (
            <>
                {array_text[0]}
                <span className={classes.color}>Inpager</span>
                {array_text[1]}
            </>
        )
    }
    return (
        <div >
            <ContactModal />
            <PublicTopbar />
            <div className={classes.root}>
                <img className={classes.logo} src={content.logo}></img>
                <Typography variant='h4' className={classes.title}>{content.title}</Typography>
                <Typography variant='body1' className={classes.body}>{higlithInpager(content.subtitle)}</Typography>
                <div className={classes.selfie_picture} ></div>
                <div className={classes.bodyConatiner}>
                    <BackroundCircles />
                    <div className={classes.body}>
                        <Typography variant='body1' style={{ marginBottom: 30 }}>{higlithInpager(content.body1)}</Typography>
                        <Typography variant='body1'>{higlithInpager(content.body2)}</Typography>
                    </div>
                    <img className={classes.mobile} src={content.mobile}></img>
                    <Typography variant='body1' className={classes.body}>{higlithInpager(content.body3)}</Typography>
                    <div className={classes.purple}>{content.purple_text}</div>
                    <div className={classes.black}>{content.black_text}</div>
                </div>
                <div className={classes.holding_phone} ></div>
                <SectionJ content={contentHomeview.sectionJ} />
            </div>
            <Footer />

        </div>
    );
}

const useStyles = makeStyles()((theme, { content }) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 120,
        fontFamily: 'Raleway',
    },
    logo: {
        width: 228, height: 191,
    },
    title: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontFamily: 'Raleway',
        fontSize: 72,
        fontWeight: 700,
        [theme.breakpoints.down('lg')]: {
            fontSize: 50
        },
    },
    body: {
        width: '80%',
        fontSize: 32,
        [theme.breakpoints.down('lg')]: {
            width: '90%',
            fontSize: 17
        },
        textAlign: 'center',
        marginTop: 60,
        marginBottom: 60,
        zIndex:1,
    },
    color: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    selfie_picture: {
        position: 'relative',
        width: '100vw',
        height: 588,
        backgroundImage: `linear-gradient(rgba(132, 59, 168, 0.45), rgba(132, 59, 168, 0.45)), url(${content.selfie_picture})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center 30%',
        [theme.breakpoints.down('lg')]: {
            height: 300
        }
    },
    holding_phone: {
        position: 'relative',
        width: '100vw',
        height: 588,
        backgroundImage: `linear-gradient(rgba(132, 59, 168, 0.45), rgba(132, 59, 168, 0.45)), url(${content.holding_phone})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center 55%',
        [theme.breakpoints.down('lg')]: {
            height: 300
        }
    },
    mobile: {
        zIndex: 1,
        [theme.breakpoints.down('lg')]: {
            height: 300
        }
    },
    purple: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFF',
        background: theme.palette.primary.main,
        height: 300,
        width: '100vw',
        textAlign: 'center',
        fontFamily: 'Raleway',
        fontSize: 50,
        fontStyle: 'italic',
        fontWeight: 600,
        [theme.breakpoints.down('lg')]: {
            fontSize: 25
        }
    },
    black: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFF',
        background: '#000',
        height: 300,
        width: '100vw',
        textAlign: 'center',
        fontFamily: 'Raleway',
        fontSize: 50,
        fontWeight: 800,
        [theme.breakpoints.down('lg')]: {
            fontSize: 25
        }
    },
    bodyConatiner:{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex:1,
    }
}));

export default AboutUs;