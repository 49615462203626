import { useState } from "react"
import catalogs from "../../../texts/esp/catalogs"
import { onInitForm, isFormValid, onSetErrorsToForm, onGetSelectedFormData, updateAuthorizationHeader, onGetErrorMessage } from "../../../shared/utility"
import translations from "../../../texts/system_translations"
import { generic_errors } from "../../../texts/esp/genericErrors"
import { private_server, public_server } from "../../../config"
import { USER_TYPES } from "../../../config"
import queryString from 'query-string'


const useSettingsView = (language, user, onUpdateUserData, profile, setProfile) => {
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [error, setError] = useState(null)
    const content =  translations[language].modals.edit_profile
    const [modals, setModals] = useState({
        add_category:false,
        edit_profile:false,
        delete_profile:false,
        edit_profile_picture:false,
        edit_profile_banner:false,
        add_media_kit:false,
        edit_media_kit:false,
        invitation_request:false,
        change_password:false
    });
    const [sending, setSending] = useState(false)
    const [send, setSend] = useState(false)
    const [owner, setOwner] = useState(true)
    const [manager, setManager] = useState(false)
    const [influencers, setInfluencers] = useState([])
    const [manager_data, setManagerData] = useState()

    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form, user, content.form)
            _form.gender_id.options = [...catalogs.genders]
            _form.country_id.options = [...catalogs.countries]
            
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetSelectedFormData(form, origin)
            if(data2send.quote === '') data2send.quote = '@null!!'

            setSending(true)
            let request_url = owner ? `/user/me` : `/user/${user.id_user}`
            // Create first the event
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(request_url, data2send)
                setError(null)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }        

            const _profile =  await actions.onGetUserData()
            onUpdateUserData(_profile)
            setSending(false)
            setSend(true)
        },
        onGetUserData: async() => {
            const {id} = user.id_user
            let url = '/user/me'
            if(Boolean(id)) url = `/user/url/public/ns?url=${id}`
            let user_request
            if(url === '/user/me'){
                user_request = await private_server.get(url)
            }else{
                user_request = await public_server.get(url)
            }
            const _profile = user_request.data.data.user

            // Is manager
            const ismanager = _profile.user_type_id === USER_TYPES.MANAGER
            setManager(ismanager)

            // Search for influencers
            if(ismanager){
                
                const parameters = {offset:0, limit:100, order:0, order_by:'first_name'}
                let queryParams = `?${queryString.stringify(parameters)}`
                let specialFilter = [
                    {field:'user_type_id', operator:'=',value:`${USER_TYPES.INFLUENCER}`},
                    {field:'influencer_client_id', operator:'=',value:`${_profile.id_user}`},
                ]
                queryParams = `${queryParams}&filter=${encodeURIComponent(JSON.stringify({"AND":specialFilter}))}`
                const url = `/user/all/public/ns${queryParams}`
                const request_users = await public_server.get(url)
                setInfluencers(request_users.data.data.users)

            }else{
                    
                if(_profile.influencer_client_id){
                    const manager_data = await public_server.get(`/user/${_profile.influencer_client_id}/public/ns`)
                    setManagerData(manager_data.data.data.user)
                }else{
                    setManagerData(false)
                }
                
            }

            setProfile(_profile)
            //onUpdateUserData(_profile)
            return _profile
        },
    }

    const system = { sending, error, send }
    return { form, actions, modals, system }
}

export default useSettingsView

const form_data = {
    user_type:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'type_user',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'text',
        }
    },
    first_name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'first_name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:0, max:256
        }
    },
    birth_date:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'birth_date',
            type:'date',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    },
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'noSpaces',
            minS:1, maxS:256
        }
    },
    country_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'country_id',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'select',
        }
    },
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'email',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email',
        }
    },
    zone:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'zone',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:0, max:256
        }
    },
    gender_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'gender_id',
            type:'select',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    }
}