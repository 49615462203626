import { useState } from "react"
import { private_server, public_server } from "../../config"
import { USER_TYPES } from "../../config"
import queryString from 'query-string'
import { updateAuthorizationHeader } from "../../shared/utility"
import { onGetErrorMessage } from "../../shared/utility"


const useDashboard = (user, onUpdateUserData) => {

    const [profile, setProfile] = useState(null)
    const [manager, setManager] = useState(false)
    const [influencers, setInfluencers] = useState([])
    const [influencer_categories, setInfluencerCategories] = useState([])
    const [influencer_types, setInfluencerTypes] = useState([])
    const [manager_types, setManagerTypes] = useState([])
    const [average_ratingUser, setAverageRatingUser] = useState(0.0)
    const [average_ratingManager, setAverageRatingManager] = useState(0.0)
    const [manager_data, setManagerData] = useState()
    const [reviews, setReviews] = useState([])
    const [modals, setModals] = useState({
        add_manager:false,
        delete_manager:false,
        send_invitation_review: false,
        delete_review: false,
    })
    const [hidden, setHidden] = useState(true)
    const [visits, setVisits] = useState(0)
    const [error, setError] = useState(null)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [filter, setFilter] = useState({
        limit:10, offset:0, order:1, order_by:'date'
    })
    const [deleteId, setDeleteId] = useState(null)
    const [loading, setLoading] = useState(false)

    const onGetParams = () => {
        let response = ''
        response = `?${queryString.stringify(filter)}`
        return response
    }

    const actions = {
        onGetUserData: async() => {
            setLoading(true)
            const {id} = user.id_user
            let url = '/user/me'
            if(Boolean(id)) url = `/user/url/public/ns?url=${id}`
            let user_request
            if(url === '/user/me'){
                user_request = await private_server.get(url)
            }else{
                user_request = await public_server.get(url)
            }
            const _profile = user_request.data.data.user
            // Is manager
            const ismanager = _profile.user_type_id === USER_TYPES.MANAGER
            setManager(ismanager)

            // Search for influencers
            if(ismanager){
                
                const parameters = {offset:0, limit:100, order:0, order_by:'first_name'}
                let queryParams = `?${queryString.stringify(parameters)}`
                let specialFilter = [
                    {field:'user_type_id', operator:'=',value:`${USER_TYPES.INFLUENCER}`},
                    {field:'influencer_client_id', operator:'=',value:`${_profile.id_user}`},
                ]
                queryParams = `${queryParams}&filter=${encodeURIComponent(JSON.stringify({"AND":specialFilter}))}`
                const url = `/user/all/public/ns${queryParams}`
                const request_users = await public_server.get(url)
                setInfluencers(request_users.data.data.users)

            }else{
                    
                if(_profile.influencer_client_id){
                    const manager_data = await public_server.get(`/user/${_profile.influencer_client_id}/public/ns`)
                    setManagerData(manager_data.data.data.user)
                }else{
                    setManagerData(false)
                }
                
            }

            setProfile(_profile)
            setLoading(false)
            return _profile
        },
        onUpdateModalStatus: (_key, _value, id) => {
            setModals({...modals, [_key]:_value})
            if(id){
                setDeleteId(id)
            }
        },
        onGetProfileDetails: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                // Get profile categories 
                const filter = {"AND":[{field:'user_id', operator:'=',value:user.id_user}]}
                const user_categories_req = await public_server.get(`/influencercategory/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                const user_categoires = user_categories_req.data.data.influencer_categories || []
                setInfluencerCategories(user_categoires)

                const user_types_figure_req = await public_server.get(`/influencertypes/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                const user_types_figure = user_types_figure_req.data.data.influencer_types || []
                setInfluencerTypes(user_types_figure)

                // Get average review
                //const review_url = owner ? '/user/me/reviewaverages' : `/user/${profile.id_user}/reviewaverages/public/ns`
                try{
                    const review_url = `/user/${user.id_user}/reviewaverages/public/ns`
                    const avg_review_rq = await public_server.get(review_url);
                    const _avgReview = avg_review_rq.data.data.review_averages;
                    setAverageRatingUser(_avgReview)
                }catch(e){
                }
                setLoading(false)

            } catch (error) {
            }
        },
        onGetProfileDetailsManager: async() => {
            try {
                updateAuthorizationHeader(private_server)
                // Get profile categories 
                const filter = {"AND":[{field:'user_id', operator:'=',value:user.influencer_client_id
            }]}
                const manager_types_req = await public_server.get(`/influencertypes/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                const manager_types = manager_types_req.data.data.influencer_types || []
                setManagerTypes(manager_types)

                const parameters = {offset:0, limit:100, order:0, order_by:'first_name'}
                let queryParams = `?${queryString.stringify(parameters)}`
                let specialFilter = [
                    {field:'user_type_id', operator:'=',value:`${USER_TYPES.INFLUENCER}`},
                    {field:'influencer_client_id', operator:'=',value:`${user.influencer_client_id}`},
                ]
                queryParams = `${queryParams}&filter=${encodeURIComponent(JSON.stringify({"AND":specialFilter}))}`
                const url = `/user/all/public/ns${queryParams}`
                const request_users = await public_server.get(url)
                setInfluencers(request_users.data.data.users)

                // Get average review
                //const review_url = owner ? '/user/me/reviewaverages' : `/user/${profile.id_user}/reviewaverages/public/ns`
                try{
                    const review_url = `/user/${user.influencer_client_id}/reviewaverages/public/ns`
                    const avg_review_rq = await public_server.get(review_url);
                    const _avgReview = avg_review_rq.data.data.review_averages;
                    setAverageRatingManager(_avgReview)
                }catch(e){
                }
                
            } catch (error) {
            }
        },
        onUpdateData: async() => {
            const args = onGetParams()
            const url = `/review/all/me${args}`
            updateAuthorizationHeader(private_server)
            const request_users = await private_server.get(url)
            setReviews(request_users.data.data.reviews)
        },
        getVisits: async (id) => {
            try {
                const response = await public_server.get(`/user/getvisits/${id}`)
                setVisits(response.data.data.visits)
            } catch (error) {
            }
        },
        onDeleteManager: async () => {
            try {
                updateAuthorizationHeader(private_server)
                setSending(true)

                const data2send = {
                    influencer_client_id: '@null!!'
                }

                const url = '/user/me'
                await private_server.patch(url, data2send)

                setSending(false)
                setSuccess('Manager eliminado correctamente')
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }

            const _profile =  await actions.onGetUserData()
            onUpdateUserData(_profile)
            actions.onUpdateModalStatus('delete_manager', false)
        },
    }

    const view_data = { profile, manager_data, average_ratingUser, average_ratingManager, hidden, setHidden, visits, influencers, influencer_categories, influencer_types, manager_types }
    const system = { modals, sending, error, success, setSuccess, reviews, deleteId, loading }

    return { actions, view_data, system }
}

export default useDashboard