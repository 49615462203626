import React, { useState } from 'react';
import axios from 'axios'
import system_translations from '../../../texts/system_translations';
import PictureModal from '../../../components/Modals/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob, updateAuthorizationHeader } from '../../../shared/utility'
import { private_server } from '../../../config';

const ChangeImageModal = (props) => {

    const { open, onClose, onCompleted, number, language } = props
    const content = system_translations[language].modals.change_image_modal

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const onSubmit = async (file) => {
        if (!file) {
            setError(content.error_msg)
            return
        }
        setError(null)
        try {
            setLoading(true)
            const _file = await onGetImageURL2Blob(file)
            const data = { ext: onGetFileExtension(_file.name) }
            updateAuthorizationHeader(private_server)
            const image_req = await private_server.post(`/podcast/image/${number}`, data)
            const options = { headers: { 'Content-Type': file.type } }
            delete axios.defaults.headers.common["Authorization"]
            await axios.put(image_req.data.data.url, _file, options);
            onCompleted()
            onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
        setLoading(false)
    }

    const onDelete = async () => {
        try {
            setError(null)
            setLoading(true)
            await private_server.delete(`/podcast/image/${number}`)
            onCompleted()
            onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
        setLoading(false)

    }


    return (
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} language={language} aspect_ratio={5 / 4}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} onDeleteFile={onDelete} />
    );
}

export default ChangeImageModal;