import React from 'react'
import facebook_logo from '../../assets/facebook_logo.png'

const FacebookButton = ({actions,openModal}) => {

    const {FB} = window

    const loginFacebook = (e) => {
        FB.login(function(response){
            if(response.status === 'connected'){
                FB.api('/me',{fields: 'name, email,birthday,first_name,last_name,gender'}, async function(response) {
                    let response_submit = await actions.onSubmit(true,false,response)
                    if(response_submit && response_submit.openModal){
                        openModal(response_submit.details)
                    }
                }); 
            }
        },{scope: 'public_profile,email'});
    }


    return (
        <>
            <button onClick={(e) => {loginFacebook(e)}} style={{display:'flex',justifyContent:'center',alignItems:'center',fontWeight:'bold',border:'1px solid white',cursor:'pointer',backgroundColor:'#1877f2',padding:'14px',borderRadius:'24px',width:'100%',color:'white',borderColor:'white'}}>
                <div style = {{marginRight:'8px'}}>
                    <img width='16px' src = {facebook_logo}/>
                </div>
                Continuar con Facebook
            </button>
        </>
    )
}

export default FacebookButton