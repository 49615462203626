import selfie_picture from '../../assets/homeView/signin.png'
import mobile from '../../assets/homeView/mobile.png'
import holding_phone from '../../assets/homeView/signup.png'
import logo from '../../assets/logo_mobile.png'

const general_translations = {
    logo: logo,
    title: 'Acerca de',
    subtitle: 'InPager es la herramienta digital específicamente diseñada  para todas aquellas  personas que trabajan en  la industria del Influencer Marketing y su principal objetivo es facilitar y eficientizar el trabajo detrás de las campañas.',
    selfie_picture: selfie_picture,
    body1: 'InPager ahorra tiempo y dinero para todos en la industria de las redes sociales, permitiendole al usuario encontrar y compartir la información importante cuando se necesita. ',
    body2: 'No más screenshots de estadísticas, media kits obsoletos, o información poco clara. Comparte tu información de manera más rápida, eficiente y completa a todas las marcas que buscan colaborar contigo en campañas digitales. ',
    mobile: mobile,
    body3: 'Con InPager muestra tu valor no solo con las famosas estadísticas o métricas de tus redes sociales, sino el 100% de tu trabajo y potencial como creador de contenido. Asegúrate de que las marcas vean lo mejor de tu carrera y posiciónate como un profesional dentro de la industria.',
    purple_text: 'El futuro del Influencer Marketing ya está aquí.',
    black_text: 'El último media kit de tu carrera',
    holding_phone: holding_phone
}

export default general_translations