import { alpha, Fade, Grid, Icon, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const translations = {
    es:{
        message:'Añadir imagen'
    }
}

const ImageDropZone = ({language, onChange, preview, onErase}) => {

    const { classes } = useStyles()
    const content = translations[language]

    const [hover, setHover] = useState(false)

    const onDropFile = (file) => {
        onChange(file)
    }

    return (
        <div>
            
            {preview ? (
                <div className={classes.image_container} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    {hover ? <Fade in>
                        <div className={classes.overlay}>
                        <IconButton className={classes.delete_button} onClick={onErase} size="large">
                            <Icon>delete</Icon>
                        </IconButton>
                    </div>
                        </Fade> : null}
                    <img src={preview} alt='' className={classes.image}/>
                </div>
            ) : <GenericDropzone text={content.message} onDropFile={onDropFile}/>   }
        </div>
    );
}

const useStyles = makeStyles()(theme => ({
    root:{

    },
    image_container:{
        width:'100%',
        paddingTop:'100%',
        position:'relative'
    },
    image:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        width:'100%',
        height:'100%',
        borderRadius:8,
        objectFit:'contain',
        objectPosition:'center'
    },
    overlay:{
        background:alpha("#000", 0.4),
        position:'absolute',
        top:'0%', left:'0%',
        width:'100%',
        height:'100%',
        zIndex:2,
        borderRadius:8,
        cursor:'pointer'
    },
    delete_button:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:3,
        color:'white'
    }
}));

export default ImageDropZone;






const GenericDropzone = props => {

    const { classes, cx } = useGenericDropzoneStyles()
    const {onDropFile, text} = props

    const [enter, setEnter] = useState(false)

    const onDrop = useCallback((files) => {
        onDropFile(files[0])
        setEnter(false)
    })

    const onDragEnter = useCallback((files) => {
        setEnter(true)
    })

    const onDragLeave = useCallback((files) => {
        setEnter(false)
    })

    const {getRootProps, getInputProps} = useDropzone({onDrop,onDragEnter, onDragLeave})

    return (
        <div {...getRootProps()}
        className={cx({
            [classes.container]: true,
            [classes.containerIn]:enter
        })}>
            <input {...getInputProps()} />
            <div className={classes.content}>
                <Grid container direction='column' alignItems='center' spacing={2}>
                    <Grid item>
                        <IconButton className={classes.icon_container} size="large">
                            <Icon className={classes.icon} color='inherit'>add</Icon>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography noWrap align='center' variant='body2'>{text}</Typography>
                    </Grid>
                </Grid>
            </div>
            
        </div>
    );
}

const useGenericDropzoneStyles = makeStyles()(theme => ({
    root:{},
    container:{
        background:grey[100],
        borderRadius:12,
        width:'100%',
        position:'relative',
        paddingTop:'100%',
        cursor:'pointer',
        border:'2px dotted grey',
        //padding:32,
        transition: theme.transitions.create(["background"], {
            easing: theme.transitions.easing.sharp, 
            duration: 500,
        })
    },
    containerIn:{
        background:alpha(theme.palette.primary.light,0.7),
        cursor:'pointer'
    },
    content:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    icon_container:{
        background:theme.palette.primary.main,
        color:'white',
        padding:8,
        '&:hover':{
            background:theme.palette.primary.main,
        }
    }
}));
