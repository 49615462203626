import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Añadir imagen',
    message:'Por favor ingrese los datos solicitados para agregar el contenido',
    add_button:'Agregar',
    edit_button:'Guardar',
    form:{
        title:{placeholder:'Nombre', label:'Nombre', helperText:'Campo requerido'},
        order:{placeholder:'Orden', label:'Orden', helperText:'Campo requerido'},
        description:{placeholder:'Descripción', label:'Descripción', helperText:'Campo requerido'},
    },
    nopicture:nopicture
}

export default translations