import { createTheme } from "@mui/material/styles";
import { blueGrey, lightBlue, red } from "@mui/material/colors";

export const theme = createTheme ({
    palette: {
      primary: { light:'#EBEBFF', main: '#843BA8', dark:'#1B0934' }, //#1A237E //0847a7
      secondary: { main:'#D95D71', light:'#FCECED' /*lightBlue[700]*/ },  //#2196f3 //4466f2
      tertiary:{main:'#96AE76'},
      error:{main:red[700]},
      layout:{background:'#F1F1F2'},
      text:{
        primary:"rgb(36,36,38)",
        secondary:"rgb(174,174,178)"
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily:[
        'Montserrat',
        'Raleway',
        'Work Sans',
      ].join(','),
    },
    components:{
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },
        },
      },
      MuiTypography:{
        styleOverrides:{
        h1:{
          fontSize:'4.5rem',
          fontWeight:500
        },
        h2:{
          fontSize:'3.75rem',
        },
        h3:{
          fontSize:'3rem',
          fontWeight:700,
        },
        h4:{
          fontWeight:700,
          fontSize:'2.5rem',
        },
        h5:{
            fontSize:'1.875rem',
            fontWeight:600
        },
        h6:{
          fontSize:'1.5rem',
          fontWeight:700,
        },
        subtitle1:{
          fontSize:'1.125rem',
          fontWeight:700,
        },
        subtitle2:{
          fontSize:'1rem',
          fontWeight:600,
        },
        body1:{
            fontSize:'1em',
            //fontWeight:500,
        },
        body2:{
            fontSize:'0.875rem',
            fontWeight:500,
        },
        caption:{
          fontSize:'0.875rem',
          fontWeight:700,
        },
        button:{
          fontSize:'1.125rem'
        },
      }
    }
    }
});