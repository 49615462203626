import { alpha, Avatar, Grid, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { useState } from "react"
import nopicture from '../../../../../../assets/image_placeholder_cut.png'
import GenericEditButton from "../../../../../../components/Actions/GenericEditButton"
import SmallLink from "../../../../../../components/Actions/SmallLink"
import { CONFIG_SETTINGS } from "../../../../../../config"
import { isValidDocument } from "../../../../../../shared/utility"


const ProductItem = ({name, description, image, onClick, link, last, modify}) => {

    const { classes } = useStyles()


    let selectedImage = nopicture
    const isValid = isValidDocument(image)
    selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : nopicture

    return(
        <div className={classes.root} style={{borderRight: last ? 'none' : '0.5px solid #F3F3F3'}} >
            <Grid container spacing={3} alignItems='center' >
                <Grid item md='auto' sm={12}  xs={12}>
                    <div className={classes.avatar_container} >
                        <Avatar src={selectedImage} className={classes.avatar} />
                    </div>
                </Grid>
                <Grid item md sm={12} xs={12} >
                    <Grid container alignItems='center' style={{marginBottom:8}}>
                        <Grid item xs><Typography variant='caption'>{name}</Typography></Grid>
                        {modify ? <Grid item ><GenericEditButton onClick={onClick} /></Grid> : null}
                    </Grid>  
                    <Typography variant='body2' style={{color:'#686868', textOverflow:'ellipsis', overflow:'hidden', height:82}}>{description}</Typography>
                </Grid>
                <Grid xs={12}>
                    {/* <div style={{float:'right'}} >
                        <SmallLink onClick={onClick}>Ver más</SmallLink>
                    </div> */}
                    <Grid container  justifyContent='flex-end' alignItems="center" >
                        {/* <Grid item>
                            <GenericEditButton onClick={onClick} />
                        </Grid> */}
                        <Grid item>
                            {/* <SmallLink onClick={onClick}>Ver más</SmallLink> */}
                            <a className={classes.link} href={link} target="_blank">Ver más</a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )

}

export default ProductItem

const useStyles = makeStyles()(theme => ({
    root:{
        width:350,
        padding:8,
        //height:86,
        overflow:'hidden',
        textOverflow:'ellipsis',
        cursor:'pointer',
        marginRight:16,
        paddingRight:16,
        borderRight:'0.5px solid #F3F3F3',
        //background:'green',
        /* [theme.breakpoints.down('sm')]:{
            width:'initial',
            height:'initial',
            padding:'0px 16px',
            //margin:'auto'
        }, */
        [theme.breakpoints.only('xs')]:{
            width:240,
            //height:'initial',
            padding:'0px 16px',
            margin:'30px auto 0px',
            height:'100%'
            //background:'red'
        }
        //background:'red'
    },
    avatar:{
        width:'100%',
        height:'100%'
    },
    avatar_container:{
        width:78,
        height:78,
        [theme.breakpoints.down('md')]:{
            width:100,
            height:100,
            margin:'auto'
        }
    },
    link:{
        textDecoration:'none',
        color:theme.palette.primary.main,
        padding:8,
        borderRadius:4,
        fontWeight:500,
        '&:hover':{
            background:alpha(theme.palette.primary.main,0.15)
        }
    },
    
    
    
}));