import React from 'react'
import SimpleModal from '../../../components/Modals/SimpleModal'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InputForm from '../../../components/Forms/InputForm'
import InfluencersSearch from '../../../layouts/WebsiteLayout/components/InfluencersSearch'
import useSendInvitationRosterModal from './useSendInvitationRosterModal'
import translations from '../../../texts/esp/modals/send-invitation-roster'
import RoundedButton from '../../../components/Actions/RoundedButton'

const SendInvitationRosterModal = ({open, onClose, language, user, history, master_actions}) => {

  const { classes } = useStyles()
  const content = translations[language]
  const { actions, system, form } = useSendInvitationRosterModal(open, content, language, master_actions)
  const theme = useTheme()
  const ismobile = useMediaQuery(theme.breakpoints.down('md'))

  const completePreValue = (data,pre_value) => {
    let temp = {...data};
    temp.value = pre_value;
    temp.isValid = true
    return temp
  }

  const customActionSearch = (user_item) => {
    if(user_item){

      let _form = {...form}
      const username = user_item.username
      const email = user_item.email

      _form.username_influencer = completePreValue(form.username_influencer,username)
      _form.email = completePreValue(form.email,email)

     actions.onChangeForm(_form,true)
    }
  }

  return(
    <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
      <Grid xs={12}>
        <Typography variant='h6'>{content.title}</Typography>
      </Grid>

      <Grid xs={12} style={{marginTop:25}}>
        <Typography variant='subtitle2'>{content.subtitle1}</Typography>
        <InputForm hidden = {true} data={form.username_influencer} onChange={actions.onChangeForm}/>
        <InfluencersSearch onlyManagers = {false} customActionSearch = {customActionSearch} mono={false}  ismobile={ismobile} user={user} history={history} />
      </Grid>

      <Grid hidden>
        <InputForm data={form.email} onChange={actions.onChangeForm}/>
      </Grid>

      <Grid xs={12} className={classes.button}>
        <RoundedButton loading={system.sending} onClick={actions.onSubmit}>{content.send_button}</RoundedButton>
      </Grid>
    </SimpleModal>
  )
}

export default SendInvitationRosterModal

const useStyles = makeStyles()(theme => ({
  button:{
    marginTop:20,
    display:'flex',
    justifyContent:'flex-end'
  }
}));