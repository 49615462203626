import principal from '../../assets/homeView/signin.png'
import logo from '../../assets/logo.png'
import logoWhite from '../../assets/logo-white.png'
import moment from 'moment'

import facebook from '../../assets/icons/contact/facebook.png'
import instagram from '../../assets/icons/contact/instagram.png'
import tiktok from '../../assets/icons/contact/tiktok.png'
import twitter from '../../assets/icons/contact/twitter.png'
import youtube from '../../assets/icons/contact/youtube.png'

const translations = {
    //title:'El futuro del Influencer Marketing',
    title:'Inicia sesión en InPager',
    forgotten_password:'¿Olvidaste tu contraseña?',
    login_button:'Iniciar sesión',
    noaccount:'¿Aún no tienes InPager?',
    joinus:'Únete ahora',
    banner:principal,
    form:{
        username:{placeholder:'Usuario', label:'Email o usuario', helperText:'Campo requerido'},
        password:{placeholder:'Contraseña', label:'Contraseña', helperText:'Campo requerido'},
    },
    topbar:{
        logo:logo,
        register:'Únete ahora',
        login:'Iniciar sesión'
    },
    footer:{
        /* logo:logoWhite,
        media:[
            {id:1, color:'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)', url:'https://www.instagram.com/inpager_/', name:'instagram', icon:instagram},
            {id:2, color:'#3b5998', url:'https://www.facebook.com/InPager', name:'facebook', icon:facebook},
            {id:3, color:'#00acee', url:'https://twitter.com/InPager', name:'twitter', icon:twitter},
            {id:4, color:'#212124', url:'https://www.tiktok.com/@inpager ', name:'tiktok', icon:tiktok},
            {id:5, color:'#F00', url:'https://www.youtube.com/channel/UCdFLIRzk1RyymX9iZfQ1LVQ', name:'youtube', icon:youtube},
        ],
        links:{
            first_column:[
                {id:2, label:'Acerca de', link:'/aboutus'},
                {id:1, label:'Contacto', link:'/', modal:true, ref:'contact'},
                {id:3, label:'Prensa', link:'/'},
            ],
            second_column:[
                {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
                {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
                {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
                {id:4, label:'Ayuda', link:'/'},
            ],
            third_column:[
                {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
                {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
                {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
                {id:4, label:'Ayuda', link:'/'},
            ],
        },
        copyright: `Copyright @ ${moment().year()} InPager` */
        logo:logoWhite,
        media:[
            {id:1, color:'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)', url:'https://www.instagram.com/inpager_/', name:'instagram', icon:instagram},
            {id:2, color:'#3b5998', url:'https://www.facebook.com/InPager', name:'facebook', icon:facebook},
            {id:3, color:'#00acee', url:'https://twitter.com/InPager', name:'twitter', icon:twitter},
            {id:4, color:'#212124', url:'https://www.tiktok.com/@inpager ', name:'tiktok', icon:tiktok},
            {id:5, color:'#F00', url:'https://www.youtube.com/channel/UCdFLIRzk1RyymX9iZfQ1LVQ', name:'youtube', icon:youtube},
        ],
        links:{
            /* first_column:[
                {id:2, label:'Acerca de', link:'/'},
                {id:1, label:'Contacto', link:'/', modal:true, ref:'contact'},
                {id:3, label:'Prensa', link:'/'},
            ], */
            first_column:[
                {id:2, label:'Acerca de', link:'/aboutus', target:'_self'},
                {id:1, label:'Contacto', link:'/', modal:true, ref:'contact'},
                {id:3, label:'Dealhunter Marcas', link:'/dealhuntermarcas', target:'_self'},
                {id:4, label:'Dealhunter Influencers', link:'/dealhunterinfluencers', target:'_self'},
                //{id:4, label:'Blog', link:'/blog'},
            ],
            second_column:[
                {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
                {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
                {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
                {id:4, label:'Ayuda', link:'/'},
            ],
            third_column:[
                {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
                {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
                {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
                {id:4, label:'Ayuda', link:'/'},
            ],
        },
        copyright: `Copyright @ ${moment().year()} InPager`
    },
    
    
}

export default translations