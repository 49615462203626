import React, { useState, useEffect } from 'react';
import { Grid, Icon, Typography } from '@mui/material';


import { makeStyles } from 'tss-react/mui';


const AnalyticPointData = ({title, value, type, icon}) => {

    const { classes } = useStyles()


    let _value = value
    let _unit = ''

    if(type = 'number' && value > 0){
        if(value > 999){
            _value = parseFloat(value/1000).toFixed(1)
            _unit = 'k'
        }
        if(value > 999999){
            _value = parseFloat(value/1000000).toFixed(1)
            _unit = 'M'
        }
    }

    if(title === 'Engagement Rate') {
        _unit = '%'
    }

    return ( 
        <div>
            <Typography className={classes.title}>
                {title}
            </Typography>
            <div>
                <Grid container spacing={1} style={{alignItems: 'center',display:'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <Grid item>
                        <div className={classes.icon_container}>
                            <Icon className={classes.icon}>{icon}</Icon>
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6'>
                            {_value} {_unit}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    icon_container:{
        width:64, height:64,
        position:'relative',
        background:'rgba(132, 59, 168, 0.05)',
        borderRadius:'50%'
    },
    icon:{
        position:'absolute',
        left:'50%', top:'50%',
        transform:'translate(-50%, -50%)',
        fontSize:32
    },
    title:{
        fontWeight:600,
        color:'#696969',
        marginBottom:8
    }
}));

export default AnalyticPointData;