import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import Card from "./components/Card";

const SectionG = ({ content, history }) => {
    const { classes } = useStyles();

    return (
        <Grid spacing={{ xs: 0.5, md: 2 }} container direction="column" justifyContent="space-around" alignItems="center" className={classes.container} xs={12} md={12}>
            <Grid item xs={12} md={12}>
                <Typography variant='h3' className={classes.title}>
                    {content.title}
                </Typography>
                <Typography variant='subtitle2' className={classes.subtitle}>
                    {content.text}
                </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="space-around" xs={12} md={12}>
                <Card content={content.card_1} color={'#843BA8'} />
                <Card content={content.card_2} color={'#151515'}/>
                <Card content={content.card_3} color={'#843BA8'}/>
            </Grid>
            <Button className={classes.button} onClick={() => history.push('/signup')}>{content.button}</Button>
        </Grid>
    )
}

export default SectionG;

const useStyles = makeStyles()(theme => ({
    container: {
        padding: '140px 100px',
        [theme.breakpoints.down('lg')]: {
            padding: '30px 18px'
        }
    },
    title: {
        textAlign: 'center',
        marginBottom: 38
    },
    subtitle: {
        color: theme.palette.primary.main,
        marginBottom: 60,
        fontSize: 26,
        [theme.breakpoints.down('lg')]: {
            fontSize: 16
        }
    },
    button: {
        background: theme.palette.primary.main,
        borderRadius: 40,
        color: 'white',
        textTransform: 'none',
        padding: '17px 42px',
        marginTop: 45,
        fontWeight: 600,
        fontSize: 25,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 20
        }
    }
}));