import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleCard from '../Structure/Cards/SimpleCard';
import ProfileAvatar from '../Structure/DisplayData/ProfileAvatar';
import moment from 'moment-timezone'
import catalogs from '../../texts/esp/catalogs';
import SmallLink from '../Actions/SmallLink';
import StatusData from '../Structure/DisplayData/StatusData';
import { grey } from '@mui/material/colors';
const WorkInvitationCard = (props) => {

    const { classes } = useStyles()
    const {data, onApprove, onGoToProfile, onAccept, onReject, isinfluencer, onCancel} = props


    const onInnerChange = (data) => {
        //const data2send = {approved: data.value === 1 ? 1 : 0}
        //onApprove(id_review, data2send)
    }
    const pending = !moment(data?.date).isValid()
    const _date = moment(moment.utc(data?.date).format()).format('LLL')

    const email = isinfluencer ? data?.client_email ?? '' : data?.influencer_email ?? ''

    const status_data = catalogs.invitation_status.find(el => el.value === data?.invitation_status_id)
    
    return ( 
        <div>
            <SimpleCard>
                <div className={classes.header}>
                   
                    <Grid container spacing={2} wrap='nowrap'>
                        <Grid item>
                            <ProfileAvatar />
                        </Grid>
                        <Grid item xs >
                            
                            <Grid container>
                                <Grid item xs>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item xs>
                                                    <Typography variant='subtitle2' style={{lineHeight:1}}>
                                                        {isinfluencer ? data?.client : data?.influencer}
                                                    </Typography>
                                                    <Typography variant='caption' color='textSecondary'>
                                                        {email} - {_date}
                                                    </Typography> 
                                                    <div>
                                                        <StatusData variant='body2'
                                                        data={status_data?.label ?? 'No disponible' }
                                                        color={status_data?.color ?? grey[200]}/>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <SmallLink onClick={() => onGoToProfile(data)}>Ver perfil</SmallLink>
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid>
                                        <Grid item xs={12}>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Grid>

                </div>
                
                <div className={classes.comment_container}>
                    
                    <Typography variant='body1'>
                        <i>{data?.message}</i>
                    </Typography>
                </div>
                {data?.invitation_status_id === 1 
                ?   <div>
                        <Grid container spacing={2} justifyContent='flex-end'>
                            {isinfluencer && data.direction === 1 ? <Grid item>
                                <SmallLink onClick={() => onCancel(data)}>Cancelar</SmallLink>
                            </Grid> : null}
                            {isinfluencer && data.direction === 0 ? <Grid item>
                                <SmallLink onClick={() => onAccept(data)}>Aceptar</SmallLink>
                            </Grid> : null}
                            {isinfluencer && data.direction === 0 ? <Grid item>
                                <SmallLink color={grey[800]} onClick={() => onReject(data)}>Rechazar</SmallLink>
                            </Grid> : null}
                            {!isinfluencer && data.direction === 1 ? <Grid item>
                                <SmallLink onClick={() => onAccept(data)}>Aceptar</SmallLink>
                            </Grid> : null}
                            {!isinfluencer && data.direction === 1 ? <Grid item>
                                <SmallLink color={grey[800]} onClick={() => onReject(data)}>Rechazar</SmallLink>
                            </Grid> : null}
                            {!isinfluencer && data.direction === 0 ? <Grid item>
                                <SmallLink onClick={() => onCancel(data)}>Cancelar</SmallLink>
                            </Grid> : null}
                        </Grid>
                    </div>
                : null}
            </SimpleCard>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    header:{

    },
    comment_container:{
        marginTop:8
    }
}));

export default WorkInvitationCard;

