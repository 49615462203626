import React, { useState, useEffect } from 'react';
import { Fade, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CONFIG_SETTINGS } from '../../config';
import AddButton from '../Actions/AddButton'
import EditButton from '../Actions/EditButton';
import DownloadButton from '../Actions/DownloadButton';
import { isValidDocument } from '../../shared/utility';
import nopicture from '../../assets/image_placeholder.png'
import { grey } from '@mui/material/colors';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const image_ratio = {
    '1/1':'100%',
    '16/9':'56.25%',
    '4/3':'75%',
    '2/3':'166%',
    '5/4':'80%',
    '3/2':'66%',
    '10/2':'20%',
    '10/4':'40%',
}

const api_url = process.env.REACT_APP_API_URL

const ImageContainer = ({src, size, noborder, ratio, onUpload, onEdit, src_temp,title,download_path, objectFit, borderRadius, modify,withFullscreen}) => {

    const { classes } = useStyles()
    const theme = useTheme()
    const [error, setError] = useState(false)

    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let selectedImage = nopicture

    if(src){
        const isValid = isValidDocument(src)
        selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : nopicture
    }

    if(src_temp){
        selectedImage = src_temp
    }

    const onDownload = async () => {

        if(download_path){
            var tag = document.createElement('a');
            var format = selectedImage.split('.').at(-1)
            const image = await fetch(api_url + download_path)
            const imageBlog = await image.blob()
            const imageURL = URL.createObjectURL(imageBlog)

            tag.href = imageURL
    
            if(title){
                tag.download = title + '.' + format
            }else{
                var name = selectedImage.split('/')[5]
                tag.download = name;
            }
    
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
    }
    
    let fullscreen = false

    if(withFullscreen){
        fullscreen = true
    }

    const _ratio = ratio ? ratio : '1/1'

    let image_height = image_ratio[_ratio] 

    return ( 
        <div>

            <div className={classes.image_container} style={{paddingTop:image_height}}>
                {onUpload ? <div className={classes.upload_container}><AddButton onClick={onUpload} /></div> : null}
                {onEdit && modify ? <div className={withFullscreen ? classes.edit_container_full : classes.edit_container}><EditButton size='small' onClick={onEdit} /></div> : null}    
                {onEdit && !onUpload ? <div style={(onEdit && modify ? {} : {top:12})} className={withFullscreen ? classes.download_container_full : classes.download_container}><DownloadButton size='small' onClick={onDownload} /></div>  : null}  
                {src && isValidDocument(src) && !error || src_temp ? 
                <Fade in={!error}>
                    <div className={withFullscreen ? classes.image_wrapper_full : classes.image_wrapper} >

                    {withFullscreen ?                     
                    <Zoom>
                        <img alt='' src={selectedImage} className={classes.image} 
                        style={{borderRadius:borderRadius ? borderRadius : 0, objectFit:objectFit ? objectFit : 'cover'}}
                        onLoad={onImageLoad} 
                        onError={onError} />
                     </Zoom>
                     : 
                     <img alt='' src={selectedImage} className={classes.image} 
                     style={{borderRadius:borderRadius ? borderRadius : 0, objectFit:objectFit ? objectFit : 'cover'}}
                     onLoad={onImageLoad} 
                     onError={onError} />}

                    </div>     
                </Fade> 
                :<div className={classes.image_wrapper}><img src={nopicture}  alt='' className={classes.image} /></div>}  
            </div>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    image_container:{
        position:'relative',
        margin:'auto',
        width:'100%',
        paddingTop:'100%',
        boxSizing:'border-box',
        borderRadius:8,
        background:'white',
    },
    image_wrapper:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        borderRadius:8,
        //border:'1.2508px solid #EAEAEA',
        borderRadius:2.5,
    },
    image_wrapper_full:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        borderRadius:8,
        //border:'1.2508px solid #EAEAEA',
        borderRadius:2.5,
        display:"flex",
        alignItems:"center"
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover',
        borderRadius:8,
        objectPosition:'center'
        //borderRadius:'14px 0px 0px 14px'
    },
    svg_container:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        padding:8,
        boxSizing:'border-box'
    },
    upload_container:{
        width:'100%',
        height:'100%',
        //background:'#C9C9C9',
        position:'absolute',
        top:0,
        left:0,
        zIndex:5,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        //new
        borderRadius:8,
        //border:'2px dotted grey',
        background:grey[100],
    },
    edit_container:{
        position:'absolute',
        top:12,
        right:12,
        zIndex:5,
        //width:'100%',
        //height:'100%',
        //background:'red'
    },
    download_container: {
        position:'absolute',
        top:48,
        right:12,
        zIndex:5
    },
    edit_container_full: {
        position:'absolute',
        top:0,
        right:0,
        zIndex:5,
    },
    download_container_full: {
        position:'absolute',
        top:36,
        right:0,
        zIndex:5
    },
    src_crop:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        //background:'red',
        //zIndex:7,
        cursor:'pointer'
    },

}));

export default ImageContainer;

{/* <Fade in={!error}>
                    <div className={classes.image_wrapper}>
                        <img alt='' src={imgSrc} className={classes.image} style={{objectFit: variant ? variant : 'contain'}} onLoad={onImageLoad} onError={onError} />
                    </div>     
                </Fade> */}