import React, { useState, useEffect } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Icon,
    IconButton,
    Modal,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import useContactModal from './useContactModal';
import InputForm from '../../components/Forms/InputForm';
import { actionTypes } from '../../store/actions';
import { connect } from 'react-redux';
import system_translations from '../../texts/system_translations';
import banner from '../../assets/contact/banner.png'
const ContactModal = ({language, modals, onUpdateModal}) => {

    const { classes } = useStyles()
    const content = system_translations[language].modals.contact
    const {system, form, actions} = useContactModal({content, onUpdateModal})

    return (
        <Dialog open={modals.contact} maxWidth='md' fullWidth classes={{paper:classes.dialog}} >
            <div className={classes.root}>
                <IconButton
                    className={classes.close_button}
                    onClick={() => onUpdateModal({contact:false})}
                    size="large"><Icon fontSize='large'>close</Icon></IconButton>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className={classes.left_container}>
                            <Typography variant='h4' style={{fontSize:36}}>
                                {content.title}
                            </Typography>
                            <Typography variant='body1' color='textSecondary' style={{fontSize:18,marginTop:8, marginBottom:16}}>
                                {content.message}
                            </Typography>
                            <div className=''>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <InputForm data={form.name} onChange={actions.onChangeForm}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputForm data={form.email} onChange={actions.onChangeForm}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputForm data={form.phone_number} onChange={actions.onChangeForm}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputForm data={form.message} onChange={actions.onChangeForm}/>
                                    </Grid>
                                    
                                </Grid>
                            </div>
                            <div style={{marginTop:40}}>
                                <Button fullWidth className={classes.button} onClick={actions.onSubmit}>
                                    {content.submit_button}
                                    {system.sending ? <CircularProgress size={32} style={{marginLeft:12, color:'white'}}/> : null}
                                </Button>
                                {system.error ? <Typography style={{marginTop:16}} align='center' color='error' variant='subtitle2'>{system.error}</Typography> : null}
                            </div>
                        </div>
                        
                    </Grid>
                    <Grid item xs={12} md={6}>
                       
                        <div className={classes.image_container}>
                            <img src={banner} alt='' className={classes.image}/>
                        </div>
                        
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles()(theme => ({
    left_container:{
        padding:32,
    },
    root:{
        position:'relative',
    },
    button:{
        fontSize:20,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
    },
    form:{
        margin:'40px 0px'
    },
    dialog:{
        borderRadius:16
    },
    image_container:{
        overflowY:'hidden',
        width:'100%',
        height:'100%',
        background:'grey',
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover',
        objectPosition:'center',
        filter: 'brightness(65%)'
    },
    close_button:{
        position:'absolute',
        top:8, right:8,
        color:'white',
        zIndex:2,
        [theme.breakpoints.down('md')]:{
            color:'black'
        }
    }
}));

const mapStateToProps = (state) => {
    return {
        language:state.language,
        modals:state.modals
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateModal: (modal) => dispatch({type:actionTypes.SYS_UPDATE_MODAL, modal  }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);