import { useState, useEffect } from "react"

const MAX_CATEGORIES = 100

const useGenericFilterModal = ({ open, master_actions, current_filters, options, filter_type}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [current_options, setCurrentOptions] = useState([])
    const [selected_filters, setSelectedFilters] = useState([])

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
        }
        
    }, [open])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                let modified_options = []
                setSelectedFilters([...current_filters])
                for(let i=0; i<options.length; i++){
                    const temp = options[i]
                    let data2add = {...temp, selected:false}
                    const elCat = current_filters.find(el => el.id === temp.value && el.type===filter_type)
                    //data2add.category_id = temp.id_category // add to match in ids
                    if(elCat) data2add.selected = true
                    modified_options.push(data2add)
                }
                modified_options.sort((a,b)=>{
                    if(a.name > b.name) return 1
                    if(a.name < b.name) return -1
                    return 0
                })
                setCurrentOptions(modified_options)

            } catch (error) {
            }
            setLoading(false)
        },
        onCategorySelected: (item) => {
            let _categories = [...current_options]
            let _selectedCategories = [...selected_filters]
            const posItem = current_options.findIndex(el => el.value === item.value || el.value === item.id)
            if(item.selected){
                //Remove itemx
                _categories[posItem].selected = false
                const _sCat = selected_filters.findIndex(el => el.id === item.value || el.id === item.id)
                _selectedCategories.splice(_sCat, 1)
            }else{
                //Add item
                if(selected_filters.length < MAX_CATEGORIES){
                    _selectedCategories.push({
                        id:item.id || item.value,
                        name:item.label || item.name, 
                        type:filter_type
                    })
                    _categories[posItem].selected = true
                }
                
            }
            setCurrentOptions(_categories)
            setSelectedFilters(_selectedCategories)
        },
        onRemoveCategory: (data) => {
            let _categories = [...current_options]
            let _selectedCategories = [...selected_filters]
            //Remove from category
            const posItem = current_options.findIndex(el => el.value === data.value || el.value === data.id)
            _categories[posItem].selected = false
            //remove from selected_Catagory
            const _sCat = selected_filters.findIndex(el => el.id === data.id || el.id === data.value)
            _selectedCategories.splice(_sCat, 1)
            setCurrentOptions(_categories)
            setSelectedFilters(_selectedCategories)
        },
        onResetError: () => {
            setError(null)
        },
        onSubmit: async() => {

            let data2send = []
            selected_filters.forEach(item=>{
                data2send.push(item)
            })
            master_actions.onUpdateFilters(data2send, filter_type)
            master_actions.onUpdateModalStatus(filter_type, false)


        },
        onGetCurrentCategories:() =>{

        },
       

    }

    const view_data = {current_options, selected_filters}
    const system = {loading, sending, error}

    return {system, actions, view_data}
}

export default useGenericFilterModal