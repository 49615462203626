import { Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import React from "react";

const useStyles = makeStyles()(theme => ({
    root:{
        //width:297,
        //height:368,
        borderRadius:8,
        boxShadow:'0px 6.24101px 45.074px rgba(0, 0, 0, 0.19)',
        position:'relative',
        overflow:'hidden',
        background:'white'
    },
    image:{
        height:'100%',
        width:'100%',
        objectFit:'cover',
        position:'absolute',
        top:0, left:0
    },
    imageContainer:{
        //borderRadius:'50%',
        width:'100%',
        paddingTop:'133%',
        position:'relative'
    },
    labelPositioner:{
        color:'white',
        position:'absolute',
        bottom:20,
        right:30,
        letterSpacing:'0.3em'
    }
}));

const CategoryCard = ({img, label, even}) => {

    const { classes } = useStyles()
    
    return(
        <div className={classes.root} style={{marginTop:even ? 32 : 0}}>
            <div className={classes.imageContainer} >
                <img src={img} className={classes.image} />
                <div className={classes.labelPositioner}>
                    <Typography variant='h6'>{label}</Typography>
                </div>
            </div>
            
        </div>
    )
}

export default CategoryCard