import axios from 'axios'

import amex_logo from './assets/cards/amex.png'
import cb_logo from './assets/cards/carte-bancaire.png'
import diners_logo from './assets/cards/diners-club.png'
import discover_logo from './assets/cards/discover.png'
import jcb_logo from './assets/cards/jcb.png'
import mastercard_logo from './assets/cards/mastercard.png'
import visa_logo from './assets/cards/visa.png' 
import unionpay_logo from './assets/cards/unionpay.png' 

export const nodata = '-'

export const CONFIG_SETTINGS = {
    MODE:'local',
    API_USERNAME:process.env.REACT_APP_API_USERNAME,
    API_PASSWORD:process.env.REACT_APP_API_PASSWORD,
    API_BEARER:`Basic ${btoa(process.env.REACT_APP_API_USERNAME+':'+process.env.REACT_APP_API_PASSWORD)}`,
    S3_SERVER_URL:process.env.REACT_APP_S3_SERVER_URL,
    DOMAIN:process.env.REACT_APP_DOMAIN
}

export const DASHBOARD_SETTINGS = {
    BOXSHADOW:`1px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 7%), 0px 3px 14px 2px rgb(0 0 0 / 6%)`,
    BOXSHADOW_PRIMARY:'0px 6px 14px rgba(202, 203, 251, 0.87)',
    DIVIDER_LINE:'1px solid #B3B3B3',
    COUNTRY_CODE:1,
    CURRENCY_ID:2,
    TOPBAR:{
        TRANSPARENT_MODE:'TRANSPARENT_MODE',
        WHITE_MODE:'WHITE_MODE',
        HEIGHT:80
    },
    PROFILE_DRAWER:{
        WIDTH:200,
        MWIDTH:200,
        SWIDTH:260
    }
}

export const TOKEN_STATUSES = {
    VALID:1,
    EXPIRED:2,
    REAUTH:3,
    NOT_VALID:-1
}


export const private_server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const public_server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization':CONFIG_SETTINGS.API_BEARER
    },
})

export const LAYOUT_SETTINGS = {
    BOXSHADOW: '0 10px 24px 0 rgba(82, 91, 115, .12)'
}

export const USER_TYPES = {
    ADMIN:1,
    EMPLOYEE:2,
    MANAGER:3,
    BRAND:4,
    AGENCY:5,
    INFLUENCER:6
}

export const EVENT_TYPES = {
    PRESS:2,
    CONFERENCE:3,
    COURSE:4,
    EVENT:5,
    MEDIAKIT:1
}

export const EVENT_MODES = {
    ONLINE:1,
    INPERSON:2
}
