import { useState, useEffect } from "react"
import { private_server, public_server, USER_TYPES } from "../../../config"
import { updateAuthorizationHeader } from "../../../shared/utility"
import queryString from 'query-string'

const useInvitationsView = ({ user,  match, history}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [invitation, setInvitation] = useState()
    const [invitations, setInvitations] = useState([])
    const [owner, setOwner] = useState(false)
    const [isInfluencer, setIsInfluencer] = useState(false)
    const [filter, setFilter] = useState({
        limit:20, offset:0, order:1, order_by:'invitation_date'
    })
    const [query, setQuery] = useState({
        search:''
    })

    const [modals, setModals] = useState({
        send_review_invitation:false,
        accept_invitation:false,
        reject_invitation:false,
        cancel_invitation:false
    });



    useEffect(() => {
        if(user) actions.onInitModule()
    }, [user, filter, query])

    useEffect(()=>{window.scrollTo(0,0)}, [])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                setIsInfluencer(user.user_type_id === USER_TYPES.INFLUENCER)
                await actions.onUpdateData()
                
            } catch (error) {
            }
            setLoading(false)
        },
        onApproveReview: async(idreview, data2send) => {
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                const url = `/review/${idreview}`
                await private_server.patch(url, data2send)
                await actions.onUpdateData()
            } catch (error) {
            }
            setSending(false)
        },
        onUpdateData: async() => {
            const args = onGetParams()
            const _isinfluencer = user.user_type_id === USER_TYPES.INFLUENCER
            
            const url = _isinfluencer ? `/invitation/influencer/me${args}` : `/invitation/client/me${args}`
            updateAuthorizationHeader(private_server)
            const request_invitations = await private_server.get(url)
            setInvitations(request_invitations.data.data.invitations)
        },
        onRequestSearch: async(word) => {
            let _query = {...query}
            _query.search = word
            setQuery(_query)
        },  
        onGetProfileDetails: async() => {
            try {
                updateAuthorizationHeader(private_server)
                // Get profile categories 
                //const filter = {"AND":[{field:'user_id', operator:'=',value:profile.id_user}]}
                //const user_categories_req = await public_server.get(`/influencercategory/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                //const user_categoires = onGetCatalogs(user_categories_req.data.data.influencer_categories, 'category_id', 'category_name')
                //setInfluencerCategories(user_categoires)
            } catch (error) {
            }
        },
        onGoToProfile: async(_profile) => {


            let _current_profile = null
            let _user_id = isInfluencer ? _profile.client_id : _profile.influencer_id
            
            try {
                const response = await public_server.get(`/user/${_user_id}/public/ns`)
                _current_profile = response.data.data.user
            } catch (error) {
                
            }

            if(!_current_profile){
                return
            }

            let url = ''

            switch(_current_profile.user_type_id){
                case USER_TYPES.MANAGER:
                    url = `/dash/managers/${_current_profile.url}`
                    break;
                case USER_TYPES.INFLUENCER:
                    url = `/dash/influencers/${_current_profile.url}`
                    break;
                default:
                    break;
            }
            history.push(url)
        },
        onAccept: (_data) => {
            setInvitation(_data)
            actions.onUpdateModalStatus('accept_invitation', true)
        },
        onReject: (_data) => {
            setInvitation(_data)
            actions.onUpdateModalStatus('reject_invitation', true)
        },
        onCancel: (_data) => {
            setInvitation(_data)
            actions.onUpdateModalStatus('cancel_invitation', true)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        

    }

    const onGetParams = () => {
        let response = ''
        response = `?${queryString.stringify(filter)}`
        //let specialFilter = []
        //const {search} = query
        //if(search) specialFilter.push({field:'influencer', operator:'LIKE',value:`%${search}%`})
        //if(specialFilter.length){
        //    specialFilter = {"AND":specialFilter}
        //    response = `${response}&filter=${encodeURIComponent(JSON.stringify(specialFilter))}`
        //}
        return response
    }

    const system = {loading, sending, error}
    const view_data = {invitations, invitation, isInfluencer}

    return {system, view_data, actions, modals}
}

export default useInvitationsView
