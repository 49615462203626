import { Grid, Typography, Button } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const SectionI = ({ content, history }) => {
    const { classes } = useStyles();

    return (
        <Grid container direction="row" justifyContent="space-around" alignItems="flex-end" className={classes.container}>
            <div className={classes.backgroundPositioner}>
                <div className={classes.background} style={{ width: '1250px', height: '1250px' }}>
                    <div className={classes.background} style={{ width: '75%', height: '75%' }}>
                        <div className={classes.background} style={{ width: '70%', height: '70%' }}></div>
                    </div>
                </div>
            </div>
            <Grid item xs={12} className={classes.title}>
                <Typography variant="h3" className={classes.text}>
                    {content.text1}
                </Typography>
            </Grid>
            <Grid item xs={0} className={classes.imageContainer}>
                <img src={content.image} className={classes.image}></img>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography variant="h4" className={classes.subtitle} style={{marginTop: 120}}>
                    {content.text2._1}
                    <span className={classes.subtitle_2}>{content.text2._2}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography variant="h4" className={classes.subtitle}>
                    {content.text3}
                </Typography>
            </Grid>
            <Grid item xs={0} md={0}>
                <Button className={classes.button} onClick={() => history.push('/signup')}>{content.button}</Button>
            </Grid>
        </Grid>
    )
}

export default SectionI;

const useStyles = makeStyles()(theme => ({
    container: {
        fontFamily: 'Raleway',
        position: 'relative',
        padding: '70px 65px',
        [theme.breakpoints.down('lg')]: {
            padding: '30px 20px'
        },
        overflow: 'hidden'
    },
    title:{
        maxWidth: '40%',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '80%',
        },
    },
    text: {
        fontFamily: 'Raleway',
        color: theme.palette.primary.main,
        fontSize: 42,
        [theme.breakpoints.down('md')]: {
            fontSize: 22,
            textAlign: 'center',
            marginBottom: 30
        },
        marginBottom: 70
    },
    subtitle:{
        fontFamily: 'Raleway',
        fontSize: 40,
        textAlign: 'center',
        marginBottom: 60,
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            marginBottom: 30
        }
    },
    subtitle_2:{
        fontFamily: 'Raleway',
        color: theme.palette.primary.main,
        fontSize: 40,
        textAlign: 'center',
        marginTop: 50,
        marginBottom: 25,
        [theme.breakpoints.down('md')]: {
            marginBottom: 30,
            fontSize: 20
        }
    },
    button: {
        position: 'relative',
        fontFamily: 'Raleway',
        background: theme.palette.primary.main,
        borderRadius: 40,
        color: 'white',
        textTransform: 'none',
        padding: '14px 42px',
        marginTop: 10,
        marginBottom: 45,
        fontWeight: 600,
        fontSize: 25,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 20
        }
    },
    image:{
        [theme.breakpoints.down('md')]: {
            width: 300
        }
    },
    imageContainer:{
        zIndex:1,
        maxWidth: '60%',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '80%',
        },
    },
    backgroundPositioner:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top: -50,
        left: 0,
        zIndex:0,
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    background:{
        border:'1px dashed rgba(132, 59, 168, 0.2)',
        borderRadius:'50%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:0
    },
}));