import { useState } from "react"
import { public_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onSetValueInForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"

const useResetPassword = ({ user, onClose }) => {
    

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isPasswordRecover, setIsPasswordRecover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isValid, setIsValid] = useState(false)



    //const {token} = queryString.parse(history.location.search)


    const actions = {
        onChangeForm: (data) => {
            let temp = {...form}
            const id = data.config.id
            temp[id] = {...data}
            if(id === 'password'){
                temp.passwordConfirmation.value2 = temp[id].value
            }
            setForm(temp)
            setIsValid(isFormValid(temp))
        },
        onSubmit: async() => {
            //request
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
            
            setLoading(true)
            try {
                updateAuthorizationHeader(public_server)
                const req = await public_server.post(`/user/passwordchange/requestwhmail`, {"username": user.username})
                let token = req.data.data.password;

                let data2send = {
                    token: token,
                    password: form.password.value
                }

                updateAuthorizationHeader(public_server)
                await public_server.post(`/user/passwordchange/validate`, {token:token})
                await public_server.post(`/user/passwordchange/execute`, data2send)
                setIsPasswordRecover(true)
                
            } catch (error) {
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onClearError: ()=>setError(null),
        onClose: ()=>{
            onClose()
            setLoading(false)
            onSetValueInForm(form, 'password', '')
            onSetValueInForm(form, 'passwordConfirmation', '')
            setIsPasswordRecover(false)
        },
    }

    const system = {loading, error}
    const view_data = {isPasswordRecover, form, isValid}

    return {system, view_data, actions}
}

export default useResetPassword

const formData = {
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'password',
          type:'password',
          fullWidth: true,
          label:'Contraseña',
          helperText:'Debe contener 6 a 20 caracteres'
        },
        rules:{
          type:'distance',
          min:6, max:20
        }
    },
    passwordConfirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'passwordConfirmation',
          type:'password',
          fullWidth: true,
          label:'Confirmar contraseña',
          helperText:'La contraseña no coincide'
        },
        rules:{
          type:'equals',
        }
    },
}
