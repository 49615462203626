import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import React from 'react';
import influencer_icon from '../../assets/icons/sidebar/influencer.svg'
import SVGIcon from '../Structure/DisplayData/SVGIcon';


const InfluencerSizeInfo = ({size}) => {

    const { classes } = useStyles()

    return ( 
        <div>
            <Typography variant='subtitle1'>No. influencers</Typography>
            <Grid container wrap='nowrap' alignItems='center' spacing={1}>
                <Grid item>
                    <div className={classes.icon_container}>
                        <div className={classes.icon}><SVGIcon src={influencer_icon} size={20} color='#696969' /></div>
                    </div>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2' >{size}</Typography>
                </Grid>
            </Grid>
            
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    icon_container:{
        background:'#F1F1F1',
        width:36, height:36,
        borderRadius:'50%',
        position:'relative'
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    }
}));

export default InfluencerSizeInfo;