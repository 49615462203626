import { alpha, Grid, Icon, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const translations = {
    es:{
        message:'De clic o arrastre un archivo aquí'
    }
}

const FileDropZone = ({language, onChange, label, file, onErase}) => {

    const { classes } = useStyles()
    const content = translations[language]

    const [hover, setHover] = useState(false)

    const onDropFile = (_file) => {
        onChange(_file)
    }

    return ( 
        <div>
            <Typography variant='caption'>{label}</Typography>
            <GenericDropzone text={content.message} onDropFile={onDropFile}/>
            {file ? (
                <div>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant='caption' style={{fontWeight:400}}>{file.name}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton size='small' onClick={() => onChange(null)}
                            ><Icon fontSize='small'>delete</Icon></IconButton>
                        </Grid>
                    </Grid>
                </div>
            ) : null}
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{

    },
    image_container:{
        width:'100%',
        paddingTop:'100%',
        position:'relative'
    },
    image:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        width:'100%',
        height:'100%',
        borderRadius:8,
        objectFit:'contain',
        objectPosition:'center'
    },
    overlay:{
        background:alpha("#000", 0.4),
        position:'absolute',
        top:'0%', left:'0%',
        width:'100%',
        height:'100%',
        zIndex:2,
        borderRadius:8,
        cursor:'pointer'
    },
    delete_button:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:3,
        color:'white'
    }
}));

export default FileDropZone;






const GenericDropzone = props => {

    const { classes, cx } = useGenericDropzoneStyles()
    const {onDropFile, text} = props

    const [enter, setEnter] = useState(false)

    const onDrop = useCallback((files) => {
        onDropFile(files[0])
        setEnter(false)
    })

    const onDragEnter = useCallback((files) => {
        setEnter(true)
    })

    const onDragLeave = useCallback((files) => {
        setEnter(false)
    })

    const {getRootProps, getInputProps} = useDropzone({onDrop,onDragEnter, onDragLeave})

    return(
        <div>
            
            <div {...getRootProps()}
                className={cx({
                [classes.container]: true,
                [classes.containerIn]:enter
            })}>
                <input {...getInputProps()} />
                <div className={classes.content}>
                    <Grid container alignItems='center' spacing={1} justifyContent='center' wrap='noWrap'>
                        <Grid item>
                            <IconButton className={classes.icon_container} size='small'>
                                <Icon className={classes.icon} color='inherit' fontSize='small'>add</Icon>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography noWrap align='center' variant='body2'>{text}</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            </div>
        </div>
       
    )
}

const useGenericDropzoneStyles = makeStyles()(theme => ({
    root:{},
    container:{
        background:grey[100],
        borderRadius:12,
        //width:'100%',
        position:'relative',
        cursor:'pointer',
        border:'2px dotted grey',
        padding:'16px 32px',
        transition: theme.transitions.create(["background"], {
            easing: theme.transitions.easing.sharp, 
            duration: 500,
        })
    },
    containerIn:{
        background:alpha(theme.palette.primary.light,0.7),
        cursor:'pointer'
    },
    content:{
        //position:'absolute',
        //top:'50%', left:'50%',
        //transform:'translate(-50%, -50%)'
    },
    icon_container:{
        background:theme.palette.primary.main,
        color:'white',
        padding:4,
        '&:hover':{
            background:theme.palette.primary.main,
        }
    }
}));
