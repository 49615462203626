import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
const SmallLink = ({children, onClick, color}) => {

    const { classes } = useStyles()

    let _style = {}

    if(color) _style = {..._style, color:color}

    return ( 
        <div className={classes.root} onClick={onClick} style={_style}>
            {children}
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        color:theme.palette.primary.main,
        fontWeight:500,
        fontSize:14,
        cursor:'pointer',
        padding:4,
        borderRadius:4,
        '&:hover':{
            //borderBottom:`1px solid ${theme.palette.primary.main}`,
            transition:'all 0.4s linear',
            background:alpha(theme.palette.primary.main, 0.1)
        }
    }
}));

export default SmallLink;