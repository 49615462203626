import { useState, useEffect } from "react"
import {  private_server,CONFIG_SETTINGS } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"

const useEditSocialMediaDataModal = ({ open, language, content, user_id, master_actions, owner, origin, onUpdateMasterUser,integration}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [image, setImage] = useState(null)
    const [preview, setPreview] = useState(null)
    const [file, setFile] = useState(null)
    const [saveButton,setSaveButton] = useState(true)

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
            setImage(null)
            setPreview(null)
            setFile(null)
        }
        
    }, [open])


    
    const actions = {
        onInitModule: async() => {
            let form_data = init_form_data(integration)
            let _form = onInitForm(form_data, origin, content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)

            setSaveButton(false)
        },
        onResetError: () => {
            setError(null)
        },
        onResetImage: () => {
            setImage(null)
            setPreview(null)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)
            const {id_user} = origin

            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetSelectedFormData(form, origin)

            setSending(true)
            let request_url = owner ? `/user/me` : `/user/${origin.id_user}`
            // Create first the event
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(request_url, data2send)
                const updatedUser = await private_server.get(request_url)
                onUpdateMasterUser(updatedUser.data.data.user)
                setError(null)
                setSending(false)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
                return
            }        

            await master_actions.onUpdateModalStatus('edit_social_media', false)

            setSending(false)

        },
        attachTikTok: async () => {

            try{
                let request_url = '/tiktok/auth'
                let response = await private_server.get(request_url)
                if(response.data.url){
                    console.log("a",response.data.url)
                    window.location.replace(response.data.url)
                }
            }catch(error){
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
        },
        deleteTiktok: async () => {
            try{
                let request_url = '/tiktok/integration'
                let response = await private_server.delete(request_url)
                window.location.reload()
            }catch(error){
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
        },
        attachFacebook: async () => {
            const url = `https://www.facebook.com/v12.0/dialog/oauth?client_id=364113061856959&redirect_uri=${CONFIG_SETTINGS.DOMAIN}&state=123456&scope=email,pages_read_engagement,pages_show_list,public_profile,read_insights,instagram_basic,instagram_manage_insights`
            window.open(url, "_self")
        },
        deleteFacebook : async () => {

        },
        saveUrlSocials : async () => {
            
            let links  =  onGetSelectedFormData(form, origin)
            let request_url = '/integration/modifylinks'
            let response = await private_server.post(request_url,{links, user_id: user_id})
            window.location.reload()
        }
    }

    const view_data = {preview, file}
    const system = {loading, sending, error,saveButton}

    return {system, form, actions, view_data}
}

export default useEditSocialMediaDataModal

const init_form_data = (integration) => {

    if(integration?.json_links){
        let json_links = JSON.parse(integration.json_links)
        let _form_data = {...form_data}
        if(json_links){
            Object.keys(json_links).forEach((i) => {
                if(_form_data[i]){
                    _form_data[i].value = json_links[i]
                }
            })

            return _form_data
        }
    }

    return form_data
}


const form_data = {

    instagram_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'instagram_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    facebook_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'facebook_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    tiktok_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'tiktok_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    linkedin_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'linkedin_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    twitter_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'twitter_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    youtube_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'youtube_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    twitch_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'twitch_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    podcasts_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'podcasts_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    pinterest_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'pinterest_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    snapchat_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'snapchat_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    spotify_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'spotify_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    reddit_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'reddit_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    only_fans_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'only_fans_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    tumblr_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'tumblr_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    sher_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'sher_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
    be_real_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'be_real_link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
            allowEmpty: true
        }
    },
}
