const general_translations = {
    title1: 'FAQ’s',
    title2: 'Preguntas frecuentes',
    text:[
        'En ',
        'InPager ',
        'estamos para resolver cualquier duda que tengas, así que si las ',
        'preguntas frecuentes ',
        'a continuación no terminan de aclararlas del todo, no dudes en contactarnos a:',
        'hola@inpager.app'
    ],
    questions: [
        {q:'¿Cuándo tendré acceso InPager?', a:'En cuanto realices tu registro tendrás acceso inmediato para comenzar a usar la plataforma.'},
        //{q:'¿Qué incluye mi suscripción?', a:'Depende la suscripción que elijas, esa información la puedes encontrar detallada en ww……'},
        {q:'¿Cómo sé si esto es para mí?', a:'Si estás cansado de la famosa “talacha” y de enviar screenshots de estadísticas, crear media kits y actualizarlos a mano, no lograr que conozcan el valor de tu perfil en su totalidad, InPager es para ti. Somos ese CV de influencers, creadores de contenido y figuras públicas que se actualiza rápido y de forma simple.'},
        //{q:'No vivo en México, ¿aún así puedo suscribirme?', a:'¡Por supuesto! Los precios se adaptan a la moneda de tu país automáticamente. Si en algún caso, algo llegase a fallar, siempre puedes  cambiar tu método de pago a otra de nuestras opciones.'},
        //{q:'¿Cuánto tiempo tendré acceso a mi suscripción?', a:'La suscripción es mensual o anual, así que no te preocupes, puedes cancelarla o renovarla en el momento que desees.'},
        //{q:'¿Cómo puedo pagar?', a:'Elige la opción que tu prefieras ya sea de forma gratuita, mensual o anual. Puedes encontrar nuestro menú de precios en la siguiente liga: www….'},
        //{q:'¿Cómo puedo pagar mi suscripción de InPager?', a:'Se aceptan cualquier tarjeta de crédito o débito (VISA, MASTERCARD, AMERICAN EXPRESS) y pagos PayPal, MercadoPago y Stripe.'},
        //{q:'¿El cobro mensual se hace automático?', a:'La respuesta es sí, no tienes qué preocuparte por renovarlo cada mes.'},
        {q:'¿Quién puede ver mi información?', a:'Cualquier persona a quien tu le envíes tu perfil, puede ver todo lo que tu incluyas a excepción de las métricas, para eso la persona deberá estar registrada. Y por otro lado, cualquier usuario de InPager podrá buscar, encontrar y ver tu perfil en su totalidad.'},
        {q:'Si tengo alguna duda o comentarios, ¿cómo me puedo comunicar con ustedes?', a:'Puedes contactarnos a través del correo hola@inpager.mx'}
        // {q:'Si tengo alguna duda o comentarios, ¿cómo me puedo comunicar con ustedes?', a:'Puedes contactarnos a través del correo hola@inpager.mx o bien llenar el formulario de contacto en: www….. o acceder a través de nuestro whats app business o chatbot en vivo.'}
    ]
}

export default general_translations