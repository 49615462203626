import React from 'react';
import PageMenu from '../../../../../../components/Structure/Navigation/PageMenu';
import { Grid } from '@mui/material';
import { useState } from 'react';
import FacebookCityChart from './FacebookCityChart';
import FacebookCountryChart from './FacebookCountryChart';


const FacebookLocationChart = ({data}) => {
  const menu = [{id:1, label:'Países'},{id:2, label:'Ciudades'}]
  const [view, setView] = useState(1)

  return(
    <Grid xs={12}>
      <PageMenu options={menu} selected={view} onChange={(id) => setView(id)}/>

      {view === 1 &&
        <FacebookCountryChart data={data.page_fans_country}/>
      }

      {view === 2 &&
        <FacebookCityChart data={data.page_fans_city}/>
      }
    </Grid>
  )
}

export default FacebookLocationChart;