import { Grid, Icon, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { connect } from 'react-redux';
import AddButton from '../../../components/Actions/AddButton';
import GenericSearchBar from '../../../components/Platform/GenericSearchBar';
import InfluencerCard from '../../../components/Platform/InfluencerCard';
import NoContent from '../../../components/Platform/NoContent';
import ReviewCard from '../../../components/Platform/ReviewCard';
import WorkInvitationCard from '../../../components/Platform/WorkInvitationCard';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import Page from '../../../components/Structure/Layouts/Page';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import AcceptInvitationModal from './modals/AcceptInvitationModal/AcceptInvitationModal';
import CancelInvitationModal from './modals/CancelInvitationModal/CancelInvitationModal';
import RejectInvitationModal from './modals/RejectInvitationModal/RejectInvitationModal';
import SendReviewInvitationModal from './modals/SendReviewInvitationModal/SendReviewInvitationModal';
import useInvitationsView from './useInvitationsView';
import parse from 'html-react-parser'



const InvitationsView = (props) => {

    const {user, history, language, match} = props
    //const content = system_translations[language].views.account
    const { classes } = useStyles()
    
    const {system, view_data, actions, modals} = useInvitationsView({...props})
    const parentprops = {history, language, match, user}

    let contentView = <NoContent />

    if(view_data.invitations && view_data.invitations.length){
        contentView = (
            <div style={{marginTop:32}}>
                <Grid container spacing={3}>
                    {view_data.invitations.map(item => {
                        return (
                            <Grid item xs={12} key={`invitations-${item.id_invitation}`}>
                                <WorkInvitationCard data={item} isinfluencer={view_data.isInfluencer}
                                onGoToProfile={actions.onGoToProfile} 
                                onAccept={actions.onAccept}
                                onReject={actions.onReject}
                                onCancel={actions.onCancel}/>
                            </Grid>
                        )
                         
                    })}
                </Grid>
            </div>
        )
    }


    return ( 
        <Page >
            <div>
                <AcceptInvitationModal open={modals.accept_invitation} language={language} owner={user} 
                invitation={view_data.invitation} isInfluencer={view_data.isInfluencer} 
                onClose={()=>actions.onUpdateModalStatus('accept_invitation', false)} master_actions={actions}/>
                <RejectInvitationModal open={modals.reject_invitation} language={language} owner={user} 
                invitation={view_data.invitation} isInfluencer={view_data.isInfluencer} 
                onClose={()=>actions.onUpdateModalStatus('reject_invitation', false)} master_actions={actions}/>
                <CancelInvitationModal open={modals.cancel_invitation} language={language} owner={user} 
                invitation={view_data.invitation} isInfluencer={view_data.isInfluencer} 
                onClose={()=>actions.onUpdateModalStatus('cancel_invitation', false)} master_actions={actions}/>
                <SendReviewInvitationModal open={modals.send_review_invitation} language={language} owner={user}
                onClose={()=>actions.onUpdateModalStatus('send_review_invitation', false)} master_actions={actions} />
                <Grid container alignItems='center' spacing={2}>
                    <Grid item>
                        <Typography variant='h6'>Mis invitaciones</Typography>
                    </Grid>
                    {/*<Grid item >
                        <AddButton onClick={()=>actions.onUpdateModalStatus('send_review_invitation', true)}/>
    </Grid>*/}
                </Grid>
                <Typography variant='body1' style={{fontSize:20,}}>
                    {parse(view_data.isInfluencer 
                    ? ' En esta sección podrás encontrar las invitaciones que has recibido para <strong style="color:#843BA8">ser parte de un Roster de Manager</strong>'
                    : ' En esta sección podrás encontrar las invitaciones que has enviado a influencers para <strong style="color:#843BA8">ser parte de tu Roster</strong>')}
                   
                </Typography>
                <div style={{marginTop:16}}>
                    <GenericSearchBar onRequestSearch={actions.onRequestSearch}/>
                </div>
                <LoadingContainer loading={system.loading}>
                    <div className={classes.content}>
                        {contentView}
                    </div>
                </LoadingContainer>
                
            </div>  
        </Page>
        
     );
}

const useStyles = makeStyles()(theme => ({
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    content:{
        marginTop:16
    },
    strongword:{
        color:theme.palette.primary.main
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsView);