import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import useEditSocialMediaDataModal from './useEditSocialMediaDataModal';
import translations from '../../../../../texts/system_translations';
import InputForm from '../../../../../components/Forms/InputForm';
import InputDateForm from '../../../../../components/Forms/InputDateForm';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import InputSelectForm from '../../../../../components/Forms/InputSelectForm';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ContainerSocialMediaOption from './ContainerSocialMediaOption';
import facebook_icon from '../../../../../assets/socialmedia/icons/facebook_icon.png';
import snapchat_icon from '../../../../../assets/socialmedia/icons/snapchat_icon.png';
import linkedin_icon from '../../../../../assets/socialmedia/icons/linkedin_icon.png';
import bereal_icon from '../../../../../assets/socialmedia/icons/bereal_icon.png';
import reddit_icon from '../../../../../assets/socialmedia/icons/reddit_icon.png';
import instagram_icon from '../../../../../assets/socialmedia/icons/instagram_icon.png';
import onlyfans_icon from '../../../../../assets/socialmedia/icons/onlyfans_icon.png';
import apple_podcast_icon from '../../../../../assets/socialmedia/icons/apple_podcast_icon.png';
import pinterest_icon from '../../../../../assets/socialmedia/icons/pinterest_icon.png';
import sher_icon from '../../../../../assets/socialmedia/icons/sher_icon.png';
import spotify_icon from '../../../../../assets/socialmedia/icons/spotify_icon.png';
import tik_tok_icon from '../../../../../assets/socialmedia/icons/tik_tok_icon.png';
import tumblr_icon from '../../../../../assets/socialmedia/icons/tumblr_icon.png';
import twitch_icon from '../../../../../assets/socialmedia/icons/twitch_icon.png';
import twitter_icon from '../../../../../assets/socialmedia/icons/twitter_icon.png';
import youtube_icon from '../../../../../assets/socialmedia/icons/youtube_icon.png';
import RoundedButton from '../../../../../components/Actions/RoundedButton';


const EditSocialMediaDataModal = (props) => {

    const { classes } = useStyles()
    const {language, open, onClose, user_id, master_actions, owner, origin, onUpdateMasterUser,integration} = props
    const content =  translations[language].modals.edit_profile_social_media

    const {system, form, actions, view_data} = useEditSocialMediaDataModal({...props, content, onUpdateMasterUser})
    
    const attachTikTok = () => {

        if(integration.tiktok_token !== null){
            actions.deleteTiktok()
        }else{
            actions.attachTikTok()
        }        
    }

    const attachTikTokBusiness = () => {
        // put integration
    }

    const attachFacebook = () => {
        if(integration.facebook_valid_token == 0){
            actions.attachFacebook()
        }else{
            master_actions.onUpdateModalStatus('delete_facebook_pages', true)
        }
    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <Typography variant='h6' style={{marginBottom:16}}>{content.title}</Typography>
                
                <Grid container xs={12}>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2'>{content.message}</Typography>
                    </Grid>
                    <Grid item xs = {2}>
                        <RoundedButton onClick={actions.saveUrlSocials} disabled={system.saveButton}>Guardar</RoundedButton>
                    </Grid>
                </Grid>


                <div className={classes.container}>
                    <Scrollbars autoHeight autoHeightMax={600}>
                        <div className={classes.content}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'Apple Podcast'} icon={apple_podcast_icon} width={28} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.podcasts_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'BeReal'} icon={bereal_icon} width={60} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.be_real_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption actionAttach={attachFacebook} enable = {integration?.facebook_valid_token == 1} integration={integration} title={'Facebook'} icon={facebook_icon} width={17} message={'Vinculando tu Facebook con InPager, estarás autorizando el obtener y proyectar las estadísticas de tu perfil en InPager, actualizándose cada 7 días. Puedes desvincular tu usuario en el momento que decidas.'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            {/*<Grid xs={4} style={{display:'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                <Typography variant='subtitle2' style={{textDecoration: 'underline'}}>https://www.facebook.com/</Typography>
    </Grid>*/}

                                            <Grid xs={11}>
                                                <InputForm data={form.facebook_link} onChange={actions.onChangeForm}/>
                                            </Grid>

                                            {integration?.facebook_valid_token == 1 ?                                            
                                             <Grid xs={7} style = {{paddingTop:'16px'}}>
                                                <RoundedButton onClick={() => {master_actions.onUpdateModalStatus('setup_facebook_pages', true)}}>Elegír página</RoundedButton>
                                            </Grid> : null}

                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption actionAttach={attachFacebook} enable = {integration?.facebook_valid_token == 1} integration={integration} title={'Instagram'} icon={instagram_icon} width={27} message={'Para vincular tu instagram, es necesario que este se encuentre vinculado con la Fanpage de Facebook que conectaste con InPager. Con esta acción, estarás autorizando el obtener y proyectar las estadísticas de tu perfil en InPager, actualizándose cada 7 días. Puedes desvincular tu usuario en el momento que decidas.'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                 

                                            <Grid xs={11}>
                                                <InputForm data={form.instagram_link} onChange={actions.onChangeForm}/>
                                            </Grid>

                                            {integration?.facebook_valid_token == 1 ?                                            
                                             <Grid xs={7} style = {{paddingTop:'16px'}}>
                                                <RoundedButton onClick={() => {master_actions.onUpdateModalStatus('setup_instagram_pages', true)}}>Elegír página vinculada</RoundedButton>
                                            </Grid> : null}
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'LinkedIn'} icon={linkedin_icon} width={25} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                      

                                            <Grid xs={11}>
                                                <InputForm data={form.linkedin_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'OnlyFans'} icon={onlyfans_icon} width={30} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.only_fans_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'Pinterest'} icon={pinterest_icon} width={28} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                 

                                            <Grid xs={11}>
                                                <InputForm data={form.pinterest_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'Reddit'} icon={reddit_icon} width={29} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.reddit_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>
                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable={false} integration={integration} title={'Sher'} icon={sher_icon} width={22} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{ marginTop: 10, marginLeft: 25 }}>
                                            <Grid xs={11}>
                                                <InputForm data={form.sher_link} onChange={actions.onChangeForm} />
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>
                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'Snapchat'} icon={snapchat_icon} width={27} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.snapchat_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'Spotify'} icon={spotify_icon} width={28} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.spotify_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {integration?.tiktok_token !== null} integration={integration} actionAttach = {attachTikTok} 
                                    
                                    title={'TikTok'} icon={tik_tok_icon} width={23}
                                    message='Vinculando tu TikTok con InPager, estarás autorizando el obtener y proyectar las estadísticas de tu perfil en tu InPager. Puedes desvincular tu usuario en el momento que lo decidas.'
                                    
                                    >
                                        
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>


                                            <Grid xs={11}>
                                                <InputForm data={form.tiktok_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration}  title={'Tumblr'} icon={tumblr_icon} width={27} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.tumblr_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'Twitch'} icon={twitch_icon} width={26} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.twitch_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'Twitter'} icon={twitter_icon} width={28} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.twitter_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>

                                <Grid item xs={12}>
                                    <ContainerSocialMediaOption enable = {false} integration={integration} title={'YouTube'} icon={youtube_icon} width={30} message={'PRÓXIMAMENTE'}>
                                        <Grid xs={12} container style={{marginTop: 10, marginLeft: 25}}>
                                            <Grid xs={11}>
                                                <InputForm data={form.youtube_link} onChange={actions.onChangeForm}/>
                                            </Grid>
                                        </Grid>
                                    </ContainerSocialMediaOption>
                                </Grid>
                               
                            </Grid>
                        </div>
                    </Scrollbars>

                </div>
                
                
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16,
        maxHeight:400,
        paddingRight:16
    },
    containerSocialMedia:{
        border: '1px solid black',
        width: '100%',
        borderRadius: 15
    }
}));



export default EditSocialMediaDataModal;