import React, { useState, useEffect } from 'react';
import { CONFIG_SETTINGS } from '../../config';
import SimpleCard from '../Structure/Cards/SimpleCard';
import nopicture from '../../assets/image_placeholder.png'
import { alpha, Fade, Grid, Icon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isValidDocument, onGetFullname, onGetYears } from '../../shared/utility';

const InfluencerCard = (props) => {

    const { classes } = useStyles()
    const {id_user, image, first_name, last_name, quote, birth_date, influencer_type, onSelectedUser, username} = props
    const [error, setError] = useState(false)
    const [hover, setHover] = useState(false)
    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let selectedImage = nopicture
    const isValid = isValidDocument(image)
    selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : nopicture

    return ( 
        <div className={classes.root} onClick={() => onSelectedUser(props)}>
            <SimpleCard padding='none' style={{borderRadius:8}}>
                <div className={classes.image_container} onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                    <div className={classes.overlay}>
                        <Typography variant='subtitle1' style={{color:'white', paddingTop:8, paddingLeft:8}}>
                            {onGetFullname(first_name, last_name)}
                        </Typography>
                        
                        <div style={{color:'white', position:'absolute', bottom:8, right:8}}>
                            <Grid container direction='column' alignItems='flex-end'>
                                <Grid item>
                                    <Typography variant='subtitle2' justifyContent='flex-end'>
                                        <i>{quote ? `"${quote}"` : `"-"`}</i>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1'justifyContent='flex-end' style={{color:'white',}}>
                                        {`@${username}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                            
                        </div>
                        
                            <div className={classes.details}>
                                <Fade in={hover} timeout={500}>
                                    <Grid container direction='column' alignItems='center'>
                                        <Grid item>
                                            <div style={{border:'1px solid white', padding:8, borderRadius:8}}>
                                                <Typography>Ver <strong>InPager</strong></Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Fade>
                            </div> 
                    </div>       
                    {isValid && !error ? 
                        <Fade in>
                                <img alt='' src={selectedImage} className={classes.image} key={`img-press-${id_user}`}
                                onLoad={onImageLoad} 
                                onError={onError} />  
                        </Fade> 
                    : <div className={classes.image_wrapper}>
                        <img src={nopicture}  alt='' className={classes.noimage} key={`noimg-press-${id_user}`} />
                    </div>} 
                </div>
                
            </SimpleCard>   
            {/*<div className={classes.info_container}>
                    <Typography variant='body2' className={classes.birthdate}><strong className={classes.strongword}>Edad </strong>{birth_date ? `${onGetYears(birth_date)} años` : "-"}</Typography>
                    <Typography variant='body2' className={classes.birthdate}><strong className={classes.strongword}>Tipo </strong>{influencer_type ? `${influencer_type}` : "-"}</Typography>
                    
                    </div>*/}
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        cursor:'pointer',
        position:'relative'
    },
    goto:{
        //position:'absolute',
        //background:alpha("#000",0.25),
        //top:0, left:0,
        //width:'100%',
        //height:'100%',
        //zIndex:10,
        //borderRadius:8
    },
    strongword:{
        color:theme.palette.primary.main,
        
    },
    image_container:{
        width:'100%',
        paddingTop:'100%',
        background:alpha(theme.palette.primary.main, 0.25),
        position:'relative'
    },
    image:{
        position:'absolute',
        top:0, left:0,
        objectFit:'cover',
        width:'100%',
        height:'100%',
        //borderTopLeftRadius:8,
        //borderBottomLeftRadius:8,
        objectPosition:'center',
        zIndex:2
    },
    noimage:{
        position:'absolute',
        top:'50%', left:'50%',
        objectFit:'cover',
        width:160,
        height:160,
        transform:'translate(-50%, -50%)',
        borderRadius:8,
        objectPosition:'center',
        zIndex:2
    },
    overlay:{
        background:alpha("#000",0.25),
        position:'absolute',
        top:0, left:0,
        width:'100%',
        height:'100%',
        zIndex:4
    },
    info_container:{
        padding:8,
        height:110
    },
    quote:{
    },
    link:{
        textDecoration:'none',
        color:theme.palette.primary.main,
        padding:8,
        borderRadius:4,
        fontWeight:500,
        '&:hover':{
            background:alpha(theme.palette.primary.main,0.15)
        }
    },
    details:{
        color:'white',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        position:'absolute'
    }
        
}));

export default InfluencerCard;