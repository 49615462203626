import { Icon, IconButton } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

const DownloadButton = ({ variant, onClick, size}) =>{

    const { classes, cx } = useStyles()

    return(
        <IconButton onClick={onClick} size={size  ? size : 'medium'} 
            className={cx({
                [classes.root]:true, 
                [classes.card]: variant === 'card'})}>
                    <Icon>download</Icon>
        </IconButton>
    )

}

export default DownloadButton

const useStyles = makeStyles()(theme => ({
    root:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.light
        }
    },
    card:{
        background:'white',
        color:'#5E5E5E',
    },
}));