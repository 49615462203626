import axios from "axios"
import { useState, useEffect } from "react"
import {  private_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetFileExtension, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import catalogs from "../../../../../texts/esp/catalogs"
import { generic_errors } from "../../../../../texts/esp/genericErrors"

const useEditEventModal = ({ open, language, content, profile, master_actions, owner, origin}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [image, setImage] = useState(null)
    const [preview, setPreview] = useState(null)
    const [file, setFile] = useState(null)

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
            setImage(null)
            setPreview(null)
            setFile(null)
        }
        
    }, [open])
    
    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form, origin,  content.form)
            _form.event_mode_id.options = [...catalogs.event_modes]
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImage: async(_file) =>{
            try {
                setImage(_file)
                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    // convert image file to base64 string
                    setPreview(reader.result)
                }, false);
                if (_file) reader.readAsDataURL(_file);
                
            } catch (error) {
                
            }
        },
        onChangeFile: async(_file) =>{
            setFile(_file)
        },
        onResetError: () => {
            setError(null)
        },
        onResetImage: () => {
            setImage(null)
            setPreview(null)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)
            const {id_user} = profile

            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetSelectedFormData(form, origin)

            setSending(true)

            let _event
            let event_url = owner ? `/event/${origin.id_event}/me` : `/event/${origin.id_event}`
            // Create first the event
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(event_url, data2send)
                await master_actions.onGetEvents()
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
                return
            }

            // Check if image is available
            if(image){
                try {
                    updateAuthorizationHeader(private_server)
                    const imageExt = onGetFileExtension(image.name)
                    const image_request = await private_server.post(`/event/${origin.id_event}/image${owner?'/me':''}`, {name:'image', ext:imageExt})
                    const images3path = image_request.data.data.url
                    const options = {headers: {'Content-Type': image.type}}
                    delete axios.defaults.headers.common["Authorization"]
                    await axios.put(images3path, image, options);
                } catch (error) {
                    setSending(false)
                    const _error = onGetErrorMessage(error)
                    setError(_error ? _error.message : null)
                    return
                }
            }

            await master_actions.onUpdateModalStatus('edit_event', false)

            setSending(false)

        }
    }

    const view_data = {preview, file}
    const system = {loading, sending, error}

    return {system, form, actions, view_data}
}

export default useEditEventModal

const form_data = {
    title:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'title',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },
    date:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'date',
            type:'date',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    },
    link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'link',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'link',
        }
    },
    description:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'description',
            type:'text',
            fullWidth: true,
            multiline:true,
            rows:8
        },
        rules:{
            type:'distance',
            min:1, max:512
        }
    },
    event_mode_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'event_mode_id',
            type:'select',
            fullWidth: true,
        },
        rules:{
            type:'select',
        }
    },
}
