import logo from '../../assets/logo.png'
import logo_mobile from '../../assets/logo_mobile.png'

/// Celudoc searchbar icons
import calendar_icon from '../../assets/icons/calendar.svg'
import timer_icon from '../../assets/icons/clock.svg'
import moment from 'moment'
import logoWhite from '../../assets/logo-white.png'

import logoTextIcon from '../../assets/logo-text.svg'

// Social media icons
import dash_icon from '../../assets/icons/sidebar/home.svg'
import influencer_icon from '../../assets/icons/sidebar/influencer.svg'
import agency_icon from '../../assets/icons/sidebar/agency.svg'
import blog_icon from '../../assets/icons/sidebar/blog.svg'
import configuracion_icon from '../../assets/icons/sidebar/configuracion.svg'
import logOut_icon from '../../assets/icons/sidebar/logOut.svg'
import personSearch_icon from '../../assets/icons/sidebar/personSearch.svg'
import profile_icon from '../../assets/icons/sidebar/profile.svg'

import facebook from '../../assets/icons/contact/facebook.png'
import instagram from '../../assets/icons/contact/instagram.png'
import tiktok from '../../assets/icons/contact/tiktok.png'
import twitter from '../../assets/icons/contact/twitter.png'
import youtube from '../../assets/icons/contact/youtube.png'
import linkedin from '../../assets/icons/contact/linkedin.png'
import { USER_TYPES } from '../../config'


const baseURL = '/dash'

const general_translations = {
    sidebar:{
        logo:logo,
        title:'Monitoring System',
        list:[
            {
                name:'Dashboard',
                path:`${baseURL}/board`,
                icon:'dashboard',
                src:dash_icon,
                auth:true,
                permissions:[],
            },
            {
                name:'Mi perfil',
                path:`${baseURL}/account`,
                icon:'profile',
                src:profile_icon,
                auth:true,
                permissions:[USER_TYPES.INFLUENCER, USER_TYPES.MANAGER, USER_TYPES.ADMIN],
            },
            {
                name:'Buscador',
                path:`${baseURL}/influencers`,
                icon:'personSearch',
                src:personSearch_icon,
                auth:false,
                permissions:[],             
            },
            {
                name:'Crear perfil infuencer',
                path:`${baseURL}/newinfuencer`,
                icon:'profile',
                src:influencer_icon,
                auth:true,
                permissions:[ USER_TYPES.MANAGER, USER_TYPES.ADMIN],
            },
            {
                name:'Blog',
                path:`${baseURL}/blog`,
                icon:'blog',
                src:blog_icon,
                permissions:[],
            },
            {
                name:'Configuración',
                path:`${baseURL}/configuracion`,
                icon:'configuracion',
                src:configuracion_icon,
                auth:true,
                permissions:[],
            },
            {
                name:'Cerrar sesión',
                path:`/logout`,
                icon:'logOut',
                src:logOut_icon,
                auth:true,
                permissions:[],
            },
        ]
    },
    topbar:{
        logo:logo,
        logo_mobile:logo_mobile,
        logo_white: logoWhite,
        register:'Únete ahora',
        login:'Iniciar sesión',
        cart_button:{
            remove:'Eliminar',
            subtotal:'Subtotal',
            view_button:'Ver carrito',
            pay_button:'Pagar'
        },
        user_button:{
            nouser:[
                {id:1, label:'Iniciar sesión', url:'/signin'},
                {id:2, label:'Regístrate', url:'/signup'},
                {id:3, label:'Hazte un celudoc', url:'/', top_line:true},
                {id:4, label:'Nuestras membresías', url:'/'},
                {id:5, label:'Ayuda', url:'/', top_line:true},
                {id:6, label:'Contáctanos', url:'/'},
            ],
            user:[
                {id:7, label:'Mi perfil', url:`${baseURL}/account`, permissions:[USER_TYPES.INFLUENCER, USER_TYPES.ADMIN, USER_TYPES.MANAGER]},
                {id:14, label:'Influencers', url:`${baseURL}/influencers`, permissions:[USER_TYPES.AGENCY, USER_TYPES.BRAND]},
                //{id:1, label:'Managers', url:`${baseURL}/managers`},
                //{id:2, label:'Influencers', url:`${baseURL}/influencers`},
                //{id:8, label:'Mis evaluaciones', url:`${baseURL}/reviews`, permissions:[USER_TYPES.INFLUENCER, USER_TYPES.ADMIN, USER_TYPES.MANAGER]},
                //{id:9, label:'Mis invitaciones', url:`${baseURL}/invitations`, permissions:[USER_TYPES.INFLUENCER, USER_TYPES.ADMIN, USER_TYPES.MANAGER]},
                //{id:7, label:'Ayuda', url:`${baseURL}/help`, top_line:true},
                {id:10, label:'Contáctanos', url:`${baseURL}/contactus`, modal:true, _ref:'contact', permissions:[]},
                //{id:8, label:'Términos de uso', url:`${baseURL}/terms_conditions`, top_line:true},
                //{id:8, label:'Aviso de privacidad', url:`${baseURL}/privacy_policy`},
                {id:11, label:'Términos de uso', url:`/terms-and-conditions`, target:'_blank', top_line:true, permissions:[]},
                {id:12, label:'Aviso de privacidad', url:`/noticeprivacy`, target:'_blank', permissions:[]},
                {id:13, label:'Cerrar sesión', url:'/logout', permissions:[]},
            ]
        },
        searchbar_menu:[
            {id:1, label:'Celufarma'},
            {id:2, label:'Celudoc'},
            {id:3, label:'Celuhistoria'},
        ],
        searchbar:{
            placeholder:'¿Cómo te podemos ayudar?',
            placeholder_mobile:'Buscar...',
            primary_menu:[
                {id:1, label:'Celufarma'},
                {id:2, label:'Celudoc'},
                {id:3, label:'Celuhistoria'},
            ],
            celudoc:{
                speciality_placeholder:'Especialidad',
                date_placeholder:'¿Cuándo?',
                date_options:[
                    {id:1, label:'Lo antes posible', icon:timer_icon},
                    {id:2, label:'Escoge una fecha', icon:calendar_icon},
                ],
                specialities_options:[
                    {id:1, label:'Dermatología'},
                    {id:2, label:'Medicina General'},
                    {id:3, label:'Nutrición'},
                    {id:4, label:'Psicología'},
                ],
                appointment:{
                    labels:{
                        date:'Fecha',
                        time:'Hora'
                    }
                }
            },
            celupharmacy:{
                placeholder:'Especialidad',
                speciality_options:[
                    {id:1, label:'Dermatología'},
                    {id:2, label:'Medicina General'},
                    {id:3, label:'Nutrición'},
                    {id:4, label:'Psicología'}
                ]
            }
        },
    },
    footer:{
        logo:logoWhite,
        media:[
            {id:1, color:'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)', url:'https://www.instagram.com/inpager_/', name:'instagram', icon:instagram},
            {id:2, color:'#3b5998', url:'https://www.facebook.com/InPager', name:'facebook', icon:facebook},
            {id:3, color:'#00acee', url:'https://twitter.com/InPager', name:'twitter', icon:twitter},
            {id:4, color:'#212124', url:'https://www.tiktok.com/@inpager ', name:'tiktok', icon:tiktok},
            {id:5, color:'#F00', url:'https://www.youtube.com/channel/UCdFLIRzk1RyymX9iZfQ1LVQ', name:'youtube', icon:youtube},
            {id:6, color:'#0E76A8', url:'https://www.linkedin.com/company/inpager/', name:'linkedin', icon:linkedin},

        ],
        links:{
            first_column:[
                {id:2, label:'Acerca de', link:'/aboutus', target:'_self'},
                {id:1, label:'Contacto', link:'/', modal:true, ref:'contact'},
                {id:4, label:'FAQs', link:'/faqs'},
                //{id:3, label:'Dealhunter Marcas', link:'/dealhuntermarcas', target:'_self'},
                //{id:4, label:'Dealhunter Influencers', link:'/dealhunterinfluencers', target:'_self'},
                //{id:4, label:'Blog', link:'/blog'},
            ],
            second_column:[
                {id:1, label:'Glosario', link:'/glosario'},
                {id:2, label:'Podcast Social Trenders', link:'/podcasts'},
                {id:3, label:'Manual de usuario', link:'/manual'}
            ],
            third_column:[
                {id:1, label:'Términos y condiciones', target:'_blank', link:'/terms-and-conditions'},
                {id:2, label:'Aviso de privacidad', target:'_blank', link:'/noticeprivacy'},
                {id:3, label:'Políticas de cookies', target:'_blank', link:'/cookies-policies'},
                // {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
                // {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
                // {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
                // {id:4, label:'Ayuda', link:'/'},
            ],
        },
        copyright: `Copyright @ ${moment().year()} InPager`,
        version: `v${process.env.REACT_APP_VERSION}`,
        version_page: 'https://inpager.gitbook.io/versiones-inpager/',
        manual_page: 'https://inpager.gitbook.io/guia-de-usuario/'
    },
    
}

export default general_translations

