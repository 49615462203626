import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ImageDropZone from '../../../../../components/Forms/ImageDropZone';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import useAddPressModal from './useAddPressModal';
import translations from '../../../../../texts/system_translations';
import InputForm from '../../../../../components/Forms/InputForm';
import InputDateForm from '../../../../../components/Forms/InputDateForm';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import FileDropZone from '../../../../../components/Forms/FileDropZone';

const AddPressModal = ({language, open, onClose, profile, master_actions, owner}) => {

    const { classes } = useStyles()
    const content =  translations[language].modals.add_press_modal

    const {system, form, actions, view_data} = useAddPressModal({open, language, content, profile, master_actions, owner})
 
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Typography variant='h6'>{content.title}</Typography>
                <Typography>{content.message}</Typography>
                <div className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <ImageDropZone language={language} onChange={actions.onChangeImage} preview={view_data.preview} onErase={actions.onResetImage}/>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputForm data={form.title} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputDateForm data={form.date} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.link} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.description} onChange={actions.onChangeForm} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <ActionModalBar loading={system.sending} btnLabel={content.add_button} error={system.error} onSubmit={actions.onSubmit} onCloseError={actions.onResetError}  />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16
    }
}));



export default AddPressModal;