import React, { useState, useEffect } from 'react';
import { alpha, Button, Grid, Icon, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';
import { grey } from '@mui/material/colors';

const UserTypeSection = ({content, history, actions, view_data,form}) => {

    const { classes, cx } = useStyles();

    const {REGISTER_STEPS} = content
    const {user_type} = view_data
    const _content = content.user_type_section


    const completePreValue = (data,pre_value) => {
        let temp = {...data};
        temp.value = pre_value;
        temp.isValid = true
        return temp
    }

    const completePreData = () => {

        let _form = {...form}
        let preData = view_data.preData

        _form.email = completePreValue(form.email,preData.email)                  
        _form.gender_id = completePreValue(form.gender_id,preData.gender)
        _form.first_name =  completePreValue(form.first_name,preData.first_name + ' ' + preData.last_name)
        _form.password =  completePreValue(form.password,"google_facebook")
        _form.password_confirmation = completePreValue(form.password_confirmation,"google_facebook")
        _form.birth_date = completePreValue(form.birth_date,'2000-01-01')

        return _form
    }

    return ( 
        <div>
            <div className={classes.content_data}>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                        <IconButton size='small' className={classes.return_button} onClick={() => actions.onUpdateModalStatus(false)}
                        ><Icon fontSize='large'>arrow_back_ios_new</Icon></IconButton>
                    </Grid> 
                    <Grid item xs>
                        <Typography variant='h4' style={{fontWeight:800}}>
                            {_content.title}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.form}>
                    <Grid container spacing={4}>
                        {_content.user_types.map(item=>{

                            const selected = item.id === user_type

                            return(
                                <Grid item xs={6} md={6} key={item.id.toString()}>
                                    <div className={cx({
                                        [classes.card]:true,
                                        [classes.card_selected]:selected,
                                        [classes.card_disabled]:!item.available
                                    })} onClick={item.available ? () => {

                                            let next_step = REGISTER_STEPS.PERSONAL_INFORMATION
                                            if(view_data.preData != false){
                                                next_step = REGISTER_STEPS.CREATE_YOUR_USERNAME
                                                let _form = completePreData()
                                                actions.onChangeForm(_form,true)
                                            }
                                            actions.onUpdateUserType(item.id,next_step)
                                            actions.onGoToView(next_step)
                                            } 
                                            : undefined}>
                                        <div className={classes.card_content}>
                                            <Grid container alignItems='center' direction='column' spacing={1}>
                                                <Grid item>
                                                    <SVGIcon src={item.icon} size={36} color={selected ? '#843BA8' : '#363636'}/>
                                                </Grid>
                                                <Grid item>
                                                    {item.label}
                                                </Grid>
                                                {!item.available ? (
                                                    <Grid item>
                                                        <Typography variant='caption'>Próximamente</Typography>
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                        </div>   
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                
            </div>
        </div>
    );
}

const useStyles = makeStyles()(theme => ({
    content_data:{
        marginTop:25,
    },
    form:{
        
        margin:'60px auto',
        width:400,
        paddingRight:24,
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            width:'auto'
        },
    },
    card:{
        width:'100%',
        paddingTop:'118%',
        border:`2px solid ${grey[300]}`,
        borderRadius:9,
        cursor:'pointer',
        transition:'all 0.1s linear',
        fontFamily:theme.typography.fontFamily,
        fontWeight:600,
        '&:hover':{
            transition:'all 0.4s linear',
            background:alpha("#000",0.01),
            border:`2px solid ${theme.palette.primary.main}`,
        },
        position:'relative'
    },
    card_selected:{
        border:`2px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main
        //background:'red'
    },
    card_content:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    return_button:{
        color:theme.palette.primary.main,
        '&:hover':{
            background:'none'
        }
    },
    card_disabled:{
        opacity:0.7,
        cursor:'not-allowed',
        '&:hover':{
            transition:'all 0.4s linear',
            border:`2px solid ${grey[300]}`,
            cursor:'not-allowed',
        },
    }
}));

export default UserTypeSection;