import { useState } from "react"
import {  public_server } from "../../config"
import { updateAuthorizationHeader, isFormValid } from "../../shared/utility"
import queryString from 'query-string'
import { onGetErrorMessage } from "../../shared/utility"

const useRegisterInfluencer = ({ history}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isPasswordRecover, setIsPasswordRecover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isValid, setIsValid] = useState(false)

    const {token} = queryString.parse(history.location.search)

    const actions = {
        onChangeForm: (data) => {
            let temp = {...form}
            const id = data.config.id
            temp[id] = {...data}
            if(id === 'password'){
                temp.passwordConfirmation.value2 = temp[id].value
            }
            setForm(temp)
            setIsValid(isFormValid(temp).length === 0)
        },
        onSubmit: async() => {
            let data2send = {
                token:token,
                password: form.password.value
            }

            setLoading(true)
            try {
                updateAuthorizationHeader(public_server)
                await public_server.post(`/user/register_infuencer`, data2send)
                setIsPasswordRecover(true)
            } catch (error) {
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        },
        onClearError: ()=>setError(null)
    }

    const system = {loading, error}
    const view_data = {isPasswordRecover, form, isValid}

    return {system, view_data, actions}
}

export default useRegisterInfluencer;

const formData = {
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'password',
          type:'password',
          fullWidth: true,
          label:'Contraseña',
          helperText:'Debe contener 6 a 20 caracteres'
        },
        rules:{
          type:'distance',
          min:6, max:20
        }
    },
    passwordConfirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'passwordConfirmation',
          type:'password',
          fullWidth: true,
          label:'Confirmar contraseña',
          helperText:'La contraseña no coincide'
        },
        rules:{
          type:'equals',
        }
    },
}
