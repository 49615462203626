import { Grid, Icon, IconButton } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { useEffect } from "react"
import logo from '../../assets/logo.png'

const NoticePrivacy = ({history}) => {

    const { classes } = useStyles()

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className={classes.root}>
            <div className={classes.logo_container}>
                <Grid container spacing={1} alignItems='center' >
                    <Grid item>
                        <IconButton onClick={()=>history.goBack()} size="large"><Icon>arrow_back_ios</Icon></IconButton>
                    </Grid>
                    <Grid item>
                        <img src={logo} width={300} />
                    </Grid>
                </Grid> 
            </div>
            <div className={classes.subroot}>
                <p style={{textAlign:'center', marginBottom:40}} ><strong>AVISO DE PRIVACIDAD</strong></p>
                <p>
                    <strong>
                        ¡Bienvenido a InPager! Para nosotros tu privacidad es muy importante por ello nos
                        comprometemos a realizar nuestro mayor esfuerzo para garantizar tu seguridad. Te
                        invitamos a leer este Aviso de Privacidad, el cual te permitirá conocer claramente la
                        forma en que recolectamos, almacenamos, utilizamos, y en general, tratamos tus Datos
                        Personales.
                    </strong>
                </p>
                <p>
                    GRUPO CASTAYA, S.A. DE C.V., (en el presente documento referido como “InPager”), con
                    domicilio en ubicado en Av. Pedro Ramirez Vazquez 200-11 INT A, Col. Valle Oriente,
                    Monterrey, Nuevo León. CP 66260 y sitio web INPAGER.APP, es el responsable del uso y
                    protección de sus Datos Personales y garantizar el adecuado tratamiento a tus datos se
                    realice conforme a este Aviso de Privacidad y la legislación vigente aplicable.
                </p>
                <p>
                    Los Datos Personales que nos proporcionas a través de nuestro sitio web
                    <strong><a href="http://www.inpager.app">HTTP://WWW.INPAGER.APP</a></strong>, y por lo tanto, se encuentran sujetos a este Aviso de
                    Privacidad, son los siguientes: nombre completo, género, fecha de nacimiento, teléfono
                    celular y fijo, correo electrónico, datos de contacto, nombre de usuario y contraseña para
                    ingresar a tu cuenta en nuestro sitio web
                </p>
                <p>
                    La solicitud y obtención de tus Datos Personales es necesaria para poder llevar cabo las
                    relaciones comerciales generadas a través de nuestro sitio web así como para brindarte un
                    mejor servicio, y las cuales son las siguientes:
                </p>
                <p className={classes.list_container} >
                    <ol type="I">
                    <li value='I' className={classes.list} >Creación de cuenta de nuestro sitio web</li>
                    <li value='II' className={classes.list}>Realizar análisis para la toma de decisiones de InPager</li>
                    <li value='III' className={classes.list}>Cumplir con la legislación aplicable en materia de Datos
                    Personales dentro de la República Mexicana, así como con
                    requerimientos de autoridad.
                    </li>
                    </ol>
                </p>
                <p>
                    En cualquier momento puedes limitar el uso o divulgación que les damos
                    a tus Datos Personales para aquellas finalidades no necesarias para el
                    cumplimiento de nuestras relaciones comerciales. Para ello puedes
                    enviar tu petición a nuestro correo electrónico a través de la
                    cuenta hola@inpager.app, en el cual indiques tu petición
                </p>
                <p>
                    En caso de que te registres en nuestro sitio web, accedas al mismo,
                    InPager podrá, además, recabar la siguiente información: dirección de
                    IP, estático o dinámico, horario de navegación, cookies, logs, tipos de
                    diapositivo.
                </p>
                <p>
                    Los Datos Personales recabados, serán utilizados con las siguientes
                    finalidades:
                </p>
                <p className={classes.list_container}>
                    <ol type="I">
                    <li value='I' className={classes.list}>Para crear una cuenta en nuestro sitio web.</li>
                    <li value='II' className={classes.list}>
                        Para editar tu Perfil en nuestro sitio web, tienes la opcion de
                        incluir, datos como tu educación, experiencia laboral, aptitutdes,
                        fotografías, tu ciudad.
                    </li>
                    <li value='III' className={classes.list}>
                        Contar con estadísticas que nos permitan conocer los intereses de
                        nuestros usuarios:  
                    </li>
                    <li value='IV' className={classes.list}>
                        Mejorar nuestro sitio web.
                    </li> 
                    </ol>
                </p>
                <p>
                    En InPager utilizamos diversas tecnologías que nos permiten recopilar
                    direcciones IP, y utilizamos cookies, web beacons o tracer tags, lo cual
                    nos permite mejorar nuestra página, brindarte un mejor servicio,
                    personalizar la visualización de nuestro sitio web, y mejorar la
                    experiencia que tienes al navegar en nuestro sitio web.
                </p>
                <p>
                    Las cookies son archivos de texto que son descargados automáticamente y
                    almacenados en el disco duro del equipo de cómputo del usuario al navegar en un
                    sitio web específico, que permiten recordar al servidor de Internet algunos datos
                    sobre este usuario, entre ellos, tus preferencias para la visualización de las páginas
                    en ese servidor, nombre y contraseña. Los web beacons son imágenes visibles u
                    ocultas insertadas en un sitio web correo electrónico que se utilizan para
                    monitorear tu información, para obtener tu dirección IP (dato que en algunas
                    circunstancias pudiera identificarte), información sobre el tiempo de interacción
                    con la página, el tipo de navegador, entre otras cuestiones.
                </p>
                <p>
                    En caso que te registres en nuestro sitio web, los Datos Personales que obtenemos
                    de estas tecnologías de rastreo son los siguientes: nombre, nombre de usuario,
                    contraseña, dirección IP, preferencias, horario y tiempo de navegación en el sitio
                    web, la dirección IP, el sistema operativo, las secciones consultadas y las páginas
                    de Internet accedidas antes y después de la nuestra.
                </p>
                <p>
                    En cualquier momento puedes deshabilitar las cookies y los web beacon
                    o tracer tags, a través de las herramientas que para tal efecto dispone
                    el navegador que utilices. Para conocer más acerca de nuestra política
                    de seguridad y cookies así como para poder darlas de baja, visita
                    nuestra <a href="https://inpager.app/cookies-policies">política de cookies.</a>
                </p>
                <p>
                    InPager no obtendrá, almacenará, divulgará, transferirá, rentará o
                    venderá tus Datos Personales, salvo en lo dispuesto en las leyes y
                    reglamentos aplicables. 
                </p>
                <p>
                    Puedes solicitar, en cualquier momento, acceder, rectificar, cancelar u oponerte al
                    tratamiento de tus Datos Personales (Derechos ARCO), o revocar tu consentimiento al
                    tratamiento de los mismos de conformidad con la legislación aplicable; para lo anterior, te
                    pedimos enviar tu Solicitud de Derechos ARCO (misma que podrás descargar <a href="https://inpager-public.s3.amazonaws.com/2306+Solicitud+derechos+ARCO+InPager+.xlsx">aquí</a>),
                    anexando la información aplicable y que aparece en la Solicitud, al correo
                    electrónico hola@inpager.app
                </p>
                <p>
                    En caso de que tengas dudas sobre el tratamiento de tus datos personales, puedes
                    comunicarte con nosotros al teléfono gratuito +52 1 55 8282 8955 o a través de nuestro
                    correo electrónico hola@inpager.app.
                </p>
                <p>
                    En cualquier momento InPager podrá modificar y/o actualizar este Aviso de Privacidad,
                    garantizandote que la versión actualizada siempre se encontrará disponible a través de la
                    página <strong><a href="http://www.inpager.app">HTTP://WWW.INPAGER.APP</a></strong>
                </p>
                <p>
                    <strong>¡Agradecemos la confianza que nos brindas!</strong>
                </p>
                <p>
                    Última actualización: 15 de junio de 2023. 
                </p>
            </div>
            <div className={classes.bottom_deco}>

            </div>
        </div>
    );
}

export default NoticePrivacy

const useStyles = makeStyles()(theme => ({
    root:{
        width:1000, 
        margin:'auto',
        [theme.breakpoints.down('lg')]:{
            width:'100%', 
        }
    },
    subroot:{
        padding:'0px 100px',
        fontFamily:theme.typography.fontFamily,
        textAlign:'justify',
    },
    logo_container:{
        padding:'40px 0px 0px',
    },
    bottom_deco:{
        width:'100%',
        height:40,
        background:theme.palette.primary.main,
        marginTop:60
    },
    list:{
        paddingLeft:20
    }, 
    list_container:{
        marginLeft:60
    },
    link:{
        //marginTop:40,
        color:theme.palette.primary.main
    }
}));