import { useState, useEffect } from "react"
import { public_server } from "../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../shared/utility"

const useContactModal = ({ content, onUpdateModal}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    useEffect(() => {
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            const _form = onInitForm(form, null,  content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onKeyPress: (event) => {
            if(event.key === 'Enter'){
              actions.onSubmit()
            }
        },
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                //setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            data2send.device = 'web'

            setSending(true)

            try {
                updateAuthorizationHeader(public_server)
                await public_server.post('/contactrequest/ns', data2send)
                onUpdateModal({contact:false})
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }

            setSending(false)
        }
    }

    const system = {loading, sending, error}

    return {system, form, actions}
}

export default useContactModal

const form_data = {
    name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance', min:1, max:256
        }
    },
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'email',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email', min:1, max:256
        }
    },
    phone_number:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'phone_number',
            type:'numeric',
            fullWidth: true,
        },
        rules:{
            type:'distance', min:10, max:256
        }
    },
    message:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'message',
            type:'text',
            rows:6,
            multiline:true,
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:512
        }
    },
}
