import SimpleModal from '../../components/Modals/SimpleModal';
import UserTypeSection from '../../layouts/SignUpLayout/views/UserTypeSection';
import PersonalInformationSection from '../../layouts/SignUpLayout/views/PersonalInformationSection';
import useSignUpLayout from '../../layouts/SignUpLayout/useSignUpLayout';
import ProfileSectionAgency from '../../layouts/SignUpLayout/views/ProfileSectionAgency';
import ProfileSection from '../../layouts/SignUpLayout/views/ProfileSection';
import { USER_TYPES } from '../../config';
import OverviewSection from '../../layouts/SignUpLayout/views/OverviewSection';
import FormDots from '../../layouts/SignUpLayout/components/FormDots'
import CreateUsername from '../../layouts/SignUpLayout/views/CreateUsername';
import AdditionalInformation from '../../layouts/SignUpLayout/views/AdditionalInformation';

const SignUpModal = (props) => {

    const { language, open, onClose, content, history, view_data, master_actions, system, form } = props
    const {REGISTER_STEPS} = content
    let content_view = null;

    if(view_data.step === REGISTER_STEPS.USER_TYPE_SELECTION) content_view = (
        <UserTypeSection form = {form} content={content} history={history} actions={master_actions} view_data={view_data}/>
    )

    if(view_data.step === REGISTER_STEPS.PERSONAL_INFORMATION) content_view = (
        <PersonalInformationSection form={form} content={content} history={history} actions={master_actions} view_data={view_data}/>
    )

    if(view_data.step === REGISTER_STEPS.CREATE_YOUR_PROFILE) content_view = (
        <>
            {view_data.user_type === USER_TYPES.AGENCY || view_data.user_type === USER_TYPES.BRAND ?
            <ProfileSectionAgency system={system} form={form} content={content} history={history} actions={master_actions} view_data={view_data}/> :
            <ProfileSection system={system} form={form} content={content} history={history} actions={master_actions} view_data={view_data}/>}
        </>
    )

    if(view_data.step === REGISTER_STEPS.CREATE_YOUR_USERNAME) content_view = (
        <CreateUsername system={system} form={form} content={content} history={history} actions={master_actions} view_data={view_data}/>
    )

    if(view_data.step === REGISTER_STEPS.OVERVIEW) content_view = (
        <OverviewSection form={form} content={content} history={history} actions={master_actions} view_data={view_data}/>
    )

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            {content_view}
            {view_data.step > 1 && view_data.step < 10 ? (
                <div style={{marginTop:40}}>
                    <FormDots step={view_data.step} actions={master_actions}/>
                </div>
                ) : null}
        </SimpleModal>
    )
}


export default (SignUpModal)