import React from 'react';
import { alpha, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleModal from '../SimpleModal';
import useGenericFilterModal from './useGenericFilterModal';
import ActionModalBar from '../../Actions/ActionModalBar';
import { grey } from '@mui/material/colors';
import Scrollbars from 'react-custom-scrollbars-2';
import GenericChip from '../../Platform/GenericChip';

const GenericFilterModal = ({title, message, open, onClose, master_actions, current_filters, options, filter_type}) => {

    const { classes } = useStyles()

    const {system, actions, view_data} = useGenericFilterModal({open, master_actions, current_filters, options, filter_type})
 
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Typography variant='h6'>{title}</Typography>
                <Typography>{message}</Typography>
                <div>
                    <Grid container spacing={1}>
                        {view_data.selected_filters.map(item =>{
                            if(item.type !== filter_type) return
                            return(
                                <Grid item>
                                    <GenericChip {...item}  editable
                                    onRemove={() => actions.onRemoveCategory(item)}/>
                                </Grid>

                            )
                        })}
                    </Grid>
                </div>
                <Scrollbars autoHeight autoHeightMax={300}>
                    <div className={classes.content}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                {!system.loading ? (
                                    <Grid container>
                                        {view_data.current_options.map(item =>{
                                            
                                            return(
                                                <Grid item xs={12}>
                                                    <CategoryItem {...item} onSelected={actions.onCategorySelected}/>
                                                </Grid>

                                            )
                                        })}
                                    </Grid>
                                ) : (
                                    <Grid container justifyContent='center'>
                                        <Grid item>
                                            <div><CircularProgress color='primary' size={40}/></div>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                    </div>
                </Scrollbars>
                <div style={{marginTop:32}}>
                    <ActionModalBar btnLabel='Actualizar' loading={system.sending} error={system.error} onSubmit={actions.onSubmit} onCloseError={actions.onResetError}  />
                </div>

            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16,
        paddingRight:16,
        paddingBottom:16
    }
}));



export default GenericFilterModal;



const CategoryItem = (props) => {
    const { classes, cx } = useCategoryStyles()
    const {name, label, description, onSelected, selected} = props

    return(
        <div className={cx({
            [classes.root]:true,
            [classes.root_selected]:selected,
        })} onClick={() => onSelected(props) }>
            {name || label} {description ?? ''}
        </div>
    )
}

const useCategoryStyles = makeStyles()(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        padding:'16px 16px',
        border:`2px dotted ${grey[300]}`,
        marginBottom:8,
        cursor:'pointer',
        borderRadius:6,
        '&:hover':{
            //border:`2px solid ${theme.palette.primary.main}`,
            background:alpha(theme.palette.primary.light, 0.4)
        }
    },
    root_selected:{
        border:`2px solid ${theme.palette.primary.main}`,
        background:alpha(theme.palette.primary.light, 0.4),
        fontWeight:600
    }
}));