import { Typography } from "@mui/material"
import { red } from "@mui/material/colors"


const DisplayError = (props) =>{

    return(
        <Typography variant='subtitle1' align="center" style={{marginBottom:20, color:red[500]}}>{props.children}</Typography>
    )
}

export default DisplayError