const translations = {
    title:'Solicitud de reseña',
    message:'Agrega los datos de contacto y envía una solicitud de reseña a tus clientes:',
    submit_button:'Enviar',
    subtitle1:'Nombre y apellido*',
    subtitle2:'Marca / empresa',
    subtitle3:'Correo electrónico*',
    form:{
        reviewer:{helperText:'Campo requerido'},
        email:{placeholder:'colaboraciones@agencia.com', helperText:'Correo no válido'},
        business:{placeholder:'(opcional)', helperText:''},

    },
}

export default translations