import logo_influencers from '../../../assets/dealhunter/logo_influencers_modal.png'
import logo_brands from '../../../assets/dealhunter/logo_brands_modal.png'
import logo from '../../../assets/dealhunter/logotipo.png'

const translations = {
    logo:logo,
    text:'Encuentra el match perfecto entre influencers, marcas y managers para llevar tus colaboraciones al siguiente nivel con ayuda de:',
    influencers:{
        logo:logo_influencers,
        text1:'Te presentamos',
        strong1:'dealhunter for influencers',
        text2:' de ',
        strong2:'InPager',
        text3:', una prospección automática de tu',
        strong3:' perfil ',
        text4:'ante toda clase de',
        strong4:' marcas',
        text5:'. Desde locales y nacionales hasta internacionales.',
        button:'¡Únete ahora!'
    },
    brands:{
        logo:logo_brands,
        text1:'Te presentamos',
        strong1:'dealhunter for brands ',
        text2:'de',
        strong2:'InPager',
        text3:', la búsqueda automática de',
        strong3:' influencers ',
        text4:'y creadores de contenido con quienes',
        strong4:' colaborar ',
        text5:'en tus ',
        strong5:'campañas.',
        button:'¡Únete ahora!'
    }
}

export default translations