import React from 'react';
import { Tooltip } from '@mui/material';

import { withStyles } from 'tss-react/mui';

const CustomTooltip = withStyles(Tooltip, (theme) => ({
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
}));

export default CustomTooltip