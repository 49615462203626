import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {ruleValidation} from './customFunctions.jsx';
import moment from 'moment-timezone';

const InputDateForm = props => {    

    const {onChange, data, disabled} = props
    const {value, isVisited, isValid} = props.data
    const {label,placeholder, helper} = props.data.config


    const handleDateChange = (date) => {
        const response =date.format('YYYY-MM-DD');
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    }

    const error = isVisited && !isValid;

    return(
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    disabled={disabled}
                    value={moment.utc(value)}
                    placeholder={placeholder}
                    error={error}
                    fullWidth
                    variant='inline'
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label={label}
                    helperText={error ? helper : null}
                    onChange={handleDateChange}
                    autoOk
                />
            </LocalizationProvider>
            
        </div>
    )
}

export default InputDateForm