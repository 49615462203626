import { useState, useEffect } from "react"
import { private_server, public_server, USER_TYPES } from "../../../config"
import { updateAuthorizationHeader } from "../../../shared/utility"
import queryString from 'query-string'

const useInfluencersView = ({ user, match, history }) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [influencers, setInfluencers] = useState([])
    const [owner, setOwner] = useState(false)

    const [tabValue, setTabValue] = useState(0);
    const [hidden, setHiddenValue] = useState(true);

    const [filter, setFilter] = useState({
        limit: 9, offset: 0, order: 0, order_by: 'first_name'
    })
    const [query, setQuery] = useState({
        search: ''
    })

    const [modals, setModals] = useState({
        edit_profile: false,
        edit_profile_picture: false,
        edit_profile_banner: false,
        add_media_kit: false,
        edit_media_kit: false,
        category_filter: false,
        influencer_type_filter: false,
        influencer_size_filter: false
    });
    // default USER_TYPES.INFLUENCER for tabValue === 0
    const [filters, setFilters] = useState([{id:USER_TYPES.INFLUENCER, type:'user_type_filter'}])

    useEffect(() => {
        //if(user) 
        actions.onInitModule()
    }, [user, filter, query, filters, tabValue])

    useEffect(() => { window.scrollTo(0, 0) }, [])

    const actions = {
        onInitModule: async () => {
            setLoading(true)
            try {
                const args = onGetParams()
                const url = `/user/all/public/ns${args}`
                const request_users = await public_server.get(url)
                setInfluencers(request_users.data.data.users)
            } catch (error) {
            }
            setLoading(false)
        },
        onSelectedUser: (_user) => {
            history.push(`/dash/influencers/${_user.url}`)
        },
        onRequestSearch: async (word) => {
            let _query = { ...query }
            _query.search = word
            setQuery(_query)
        },
        onGetProfileDetails: async () => {
            try {
                updateAuthorizationHeader(private_server)
                // Get profile categories 
                //const filter = {"AND":[{field:'user_id', operator:'=',value:profile.id_user}]}
                //const user_categories_req = await public_server.get(`/influencercategory/all/ns?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                //const user_categoires = onGetCatalogs(user_categories_req.data.data.influencer_categories, 'category_id', 'category_name')
                //setInfluencerCategories(user_categoires)
            } catch (error) {
            }
        },
        onOpenFilterModal: (_data) => {
            actions.onUpdateModalStatus(_data.id, true)
        },
        onUpdateFilters: (data, type) => {
            let _filters = []
            filters.forEach(item => {
                if (item.type !== type) _filters.push(item)
            })
            _filters = [..._filters, ...data]
            setFilters(_filters)
        },
        onRemoveSingleFilter: (_data) => {
            let _filters = []
            const temp = JSON.stringify(_data)
            filters.forEach(item => {
                if (JSON.stringify(item) !== temp) _filters.push(item)
            })
            setFilters(_filters)
        },
        onUpdateModalStatus: (_key, _value) => setModals({ ...modals, [_key]: _value }),
        onResetFilters: () => {
            setFilters([]);
        },
        handleTabChange: (event, newValue) => {
            if(newValue === 0){
                setFilters([{id:USER_TYPES.INFLUENCER, type:'user_type_filter'}]);
            }
            if(newValue === 1){
                setFilters([{id:USER_TYPES.MANAGER, type:'user_type_filter'}]);
            }
            setTabValue(newValue);
        },
        handleHidenChange: (event) => {
            setHiddenValue(!hidden);
            if(tabValue === 0){
                setFilters([{id:USER_TYPES.INFLUENCER, type:'user_type_filter'}]);
            }
            if(tabValue === 1){
                setFilters([{id:USER_TYPES.MANAGER, type:'user_type_filter'}]);
            }
        },

    }

    const onGetParams = () => {
        let response = ''
        response = `?${queryString.stringify(filter)}`
        let specialFilter = {} //{field:'user_type_id', operator:'=',value:`${USER_TYPES.INFLUENCER}`}
        const { search } = query
        if (search){
            specialFilter['first_name'] = specialFilter['first_name'] || [];
            specialFilter['first_name'].push({ field: 'first_name', operator: 'LIKE', value: `%${search}%` });
        }
        if (filters.length) {
            filters.forEach(item => {
                switch (item.type) {
                    case 'user_type_filter':
                        specialFilter['user_type_filter'] = specialFilter['user_type_filter'] || [];
                        specialFilter['user_type_filter'].push({ field: 'user_type_id', operator: '=', value: `${item.id}` });
                        break;
                    case 'category_filter':
                        specialFilter['category_filter'] = specialFilter['category_filter'] || [];
                        specialFilter['category_filter'].push({ field: 'category_id', operator: '=', value: `${item.id}` });
                        break;
                    case 'influencer_type_filter':
                        specialFilter['influencer_type_filter'] = specialFilter['influencer_type_filter'] || [];
                        specialFilter['influencer_type_filter'].push({ field: 'influencer_type_id', operator: '=', value: `${item.id}` });
                        break;
                    case 'influencer_size_filter':
                        specialFilter['influencer_size_filter'] = specialFilter['influencer_size_filter'] || [];
                        specialFilter['influencer_size_filter'].push({ field: 'influencer_size_id', operator: '=', value: `${item.id}` });
                        break;
                    case 'country_filter':
                        specialFilter['country_filter'] = specialFilter['country_filter'] || [];
                        specialFilter['country_filter'].push({ field: 'country_id', operator: '=', value: `${item.id}` });
                        break;
                    default:
                        break;
                }
            })
        }
        let queryFilter = []
        for(let key in specialFilter){
            if (specialFilter[key].length > 1) queryFilter.push({"OR": specialFilter[key]})
            else  queryFilter.push(...specialFilter[key])
        }

        specialFilter = { "AND": queryFilter }
        response = `${response}&filter=${encodeURIComponent(JSON.stringify(specialFilter))}`
        return response
    }

    const system = { loading, sending, error, hidden, tabValue }
    const view_data = { influencers, filters, query }

    return { system, view_data, actions, modals }
}

export default useInfluencersView
