import { Grid, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import system_translations from '../../../../../texts/system_translations'
import DisplayError from '../../../../../components/Structure/DisplayData/DisplayError'
import useSendReviewInvitationModal from './useSendReviewInvitationModal'
import parse from 'html-react-parser'


const SendReviewInvitationModal = ({open, onClose, language, onCompleted}) => {

    const { classes } = useStyles()
    const content = system_translations[language].modals.send_review_invitation
    const catalogs = system_translations[language].catalogs
    const {loading, sending, error, form, actions} = useSendReviewInvitationModal({open, language, content, onCompleted, onClose, catalogs})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.message}>{parse(content.message)}</div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.form}>
                        <Grid container spacing={3} >
                            <Grid item xs={12}>
                                <InputForm data={form.reviewer} onChange={actions.onChangeForm} />
                            </Grid>    
                            <Grid item xs={12}>
                                <InputForm data={form.email} onChange={actions.onChangeForm} />
                            </Grid> 
                            <Grid item xs={12}>
                                <InputForm data={form.business} onChange={actions.onChangeForm} />
                            </Grid>
                        </Grid>
                    </div>
                    
                </Grid>  
            </Grid>
            <div className={classes.button_container}>
                {error ? <DisplayError>{error}</DisplayError> : null}
                <RoundedButton onClick={actions.onSubmit} loading={sending} >{content.submit_button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default SendReviewInvitationModal

const useStyles = makeStyles()(theme => ({
    message:{
        marginTop:8,
        fontFamily:theme.typography.fontFamily,
        fontSize:'1.125rem',
        '& strong':{
            color:theme.palette.primary.main
        }
    },
    form:{
        margin:'32px 0px'
    },
    button_container:{
        textAlign:'center',
        margin:'00px 0px 0px'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    }
    
}));