import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grow, Card, ClickAwayListener, alpha, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { IconButton, InputBase, Button } from '@mui/material';
import { Icon } from '@mui/material';
import { DASHBOARD_SETTINGS, public_server } from '../../../config';
//import ProductListItem from '../../../components/Ecommerce/ProductListItem';
import qs from 'query-string'
//import { isProductAlreadyInCart } from '../../../shared/utility';
import { grey } from '@mui/material/colors';
import useInfluencersSearch from '../hooks/useInfluencersSearch';
import { onGetFullname } from '../../../shared/utility';
import ImageContainer from '../../../components/Ecommerce/ImageContainer'
import Scrollbars from 'react-custom-scrollbars-2';

const search_width = {
    md:450
}

const InfluencersSearch = ({mono, user, cart, onUpdateCart, history, customActionSearch, onlyManagers, both}) => {

    const { classes } = useStyles()
    const {inputRef, value, influencers, selected_product, loading, actions} = useInfluencersSearch({user, cart, onUpdateCart, onlyManagers, both})

    const line_items = cart ? cart.line_items : []
    return ( 
        <div >
            <Grid container justifyContent='center'>
                <Grid item xs={12}>
                    <div >
                        <Grid container wrap='nowrap' alignItems='center' style={{background:'#F1F1F1', borderRadius:40, padding:'8px 16px'}} >
                            <Grid item>
                                <Icon style={{marginRight:8, marginTop:4, color:grey[500]}}>search</Icon>    
                            </Grid>
                            <Grid item xs>
                                <InputBase ref={inputRef} fullWidth value={value} style={{fontSize:14}}
                                onKeyUp={actions.onStartCountDown} onKeyDown={actions.onStopCountDown}
                                onChange={actions.onUpdateSearchValue} placeholder='Buscar ...' />      
                            </Grid>     
                        </Grid>
                    </div>
                </Grid>
                {Boolean(influencers.length) ? <Grid item >
                    <Grow in={Boolean(influencers.length)}>
                        <div>
                            <ClickAwayListener onClickAway={actions.onClickAway}>
                                <Card className={classes.card}>
                                    <div className={classes.card_container}>
                                        <div className={classes.product_card_container}>
                                            <div className={classes.product_container}>
                                                <Scrollbars >
                                                    <div style={{paddingRight:16}}>
                                                    {loading ? (
                                                        <div style={{padding:40}}>
                                                            <Grid container justifyContent='center' direction='column' alignItems='center' spacing={1}>
                                                                <Grid item>
                                                                    <CircularProgress size={40}/>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    ) : (
                                                        <Grid container spacing={0}>
                                                            {influencers.map((item, key) => {
                                                                if(key > 8) return null

                                                                //const line_item = isProductAlreadyInCart(item,  line_items, 'id_product')
                                                                //const isbusy = Boolean(selected_product && (selected_product.id_product === item.id_product))
                                                                const islast = influencers.length -1 === key
                                                                return(
                                                                    <Grid item xs={12} md={12} key={key.toString()}>
                                                                        <div className={classes.product_item_container} style={islast ? {border:0} : {}}>
                                                                            <InfluencerItem {...item} onClick={ () => {

                                                                                if(customActionSearch){
                                                                                    customActionSearch(item)
                                                                                    actions.onClickAway(item.username)
                                                                                }else{
                                                                                    history.push(`/dash/influencers/${item.url}`)
                                                                                    actions.onClickAway()
                                                                                }
                                                                            }
                                                                            } />
                                                                        </div >
                                                                        
                                                                    </Grid>
                                                                )
                                                                
                                                            })}
                                                        </Grid>
                                                    )}
                                                    </div>
                                                </Scrollbars>
                                            </div>
                                        </div>
                                        
                                        {/* <Grid container justifyContent='center'>
                                            <Grid item xs={12}>
                                                <Button fullWidth color='primary' className={classes.button} >{`Ver todos (${influencers.length})`}</Button>
                                            </Grid>
                                        </Grid> */}
                                        
                                    </div>
                                </Card>
                            </ClickAwayListener>
                            </div>
                    </Grow>

                </Grid> : null}
            </Grid>
            
            
            
            
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{

    },
    searchbox:{
        marginTop:16,
        background:'white',
        boxSizing:'border-box',
        width:search_width.md,
        padding:4,
        borderRadius:40,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    search_btn:{
        color:'white',
        background:theme.palette.primary.main,
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    input:{
        marginLeft:24,
        marginRight:12,
        marginTop:8
    },
    tab:{
        position:'relative',
        paddingBottom:8,
        '&:hover':{
            cursor:'pointer'
        }
    },
    bar:{
        width:24,
        border:`2px solid ${theme.palette.primary.light}`,
        position:'absolute',
        bottom:0 ,
        left:'50%',
        transform:'translateX(-50%)',
        transition:theme.transitions.create(['width'], {
            easing:theme.transitions.easing.easeIn,
            duration:theme.transitions.duration.enteringScreen
        })
    },
    nobar:{
        width:0,
        border:0,
        transition:theme.transitions.create(['width'], {
            easing:theme.transitions.easing.easeOut,
            duration:theme.transitions.duration.leavingScreen
        })
    },
    product_item_container:{
        padding:'16px 0px',
        borderBottom:`1px solid ${grey[200]}`,
        //background:'green',
        overflowX:'hidden'
    },
    /////////////////   CARD CONTAINER    //////////////////
    card:{
        borderRadius:12,
        background:'white',
        marginTop:16,
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        width:search_width.md,
        boxSizing:'border-box'
    },
    card_container:{
        //padding:16,
        boxSizing:'border-box'
    },
    product_card_container:{
        padding:'0px 0px'
    },
    product_container:{
        //maxHeight:350,
        height:350,
        //overflowY:'auto',
        //overflowX:'hidden',
        padding:8,
        boxSizing:'border-box',
        //background:'red'
    },
    button:{
        marginTop:16,
        textTransform:'none',
        fontFamily:'Visby Round CF',
        fontSize:16,
        padding:'12px 8px',
        background:'white',
        '&:hover':{
            //background:'white',
            background:alpha('#FFF', 0.80),
        }
    }
}));

export default InfluencersSearch;


const InfluencerItem = ({username, first_name, last_name, image, onClick}) => {

    return(
        <div onClick={onClick} style={{cursor:'pointer'}}  >
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <ImageContainer src={image} borderRadius={10} />
                </Grid>
                <Grid item xs>
                    <Typography variant='subtitle1'>{onGetFullname(first_name, last_name)}</Typography>
                    <Typography>{username}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}