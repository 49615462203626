import { CacheProvider } from "@emotion/react";
import { TssCacheProvider } from "tss-react";
import createCache from "@emotion/cache";
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { theme } from './theme';
import MainRouter from './routes/router';
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux';
import { store } from './store/reducer';


function App() {
  const muiCache = createCache({
    "key": "mui",
    "prepend": true
  });
  const tssCache = createCache({
    "key": "tss"
});
  return (
    <Provider store={store}>
          <CacheProvider value={muiCache}>
          <TssCacheProvider value={tssCache}> 
        <ThemeProvider theme={theme}>
          <MainRouter history={createBrowserHistory()}/>
        </ThemeProvider>
        </TssCacheProvider>
      </CacheProvider>
    </Provider>
  );
}

export default App;