import React,{useState,useEffect} from 'react'
import { TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles(TextField, {
    root: {
        //paddingTop:6,
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  });

const InputForm = props => {

    const {data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown, onKeyPress,preValue,hidden} = props
    const [readonly,setReadonly] = useState(false)
    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited} = data


    useEffect(() => {
      if(preValue != undefined && preValue != false){
        setReadonly(true)
        let temp = {...data}
        temp.value = preValue
        temp.isValid = true
        onChange(temp)
      }
    },[])



    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''

    return(
        <div style={(hidden) ? {display:'none'} :{}}>
            <CssTextField disabled = {readonly} value={value} variant='outlined'  onKeyPress={onKeyPress}
            error={isError} {...interConfig} onChange={onInnerChange} 
            InputProps={
                {
                    startAdornment:startAdornment ? startAdornment : undefined,
                    endAdornment:endAdornment ? endAdornment : undefined,
                    onKeyUp:onKeyUp ? onKeyUp : undefined,
                    onKeyDown:onKeyDown ? onKeyDown : undefined,
                }}
            />
        </div>
    )
}

export default InputForm