import principal from '../../assets/homeView/signin.png'

const translations = {
    title:'¡Tu Email a sido cambiado con exito!',
    message:'¡Tu cuenta ha sido verificada exitosamente!',
    submessage:'Ya estás list@',
    error: {
        title: '¡Hubo un error!',
        susbtitle: 'Al parecer el token ya fue utilizado o no es válido.'
    },
    banner:principal
}

export default translations;