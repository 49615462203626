import { Grid, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export default function SideLabelInput({ label, children}) {

  const { classes } = useStyles();

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.container}>
      <Grid item className={classes.label}>{label}</Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item>
        {children}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles()(theme => ({
  container: {
    width: '100%',
    height: 64,
    background: '#ffffff',
    borderRadius: 15,
    padding: 10,
    margin: '10px 0',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  },
  select: {
    width: 230,
    height: '100%',
    paddingLeft: 5,
    '&:focus': {
      backgroundColor: '#ffffff'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 100
    }
  },
  items: {
    width: 250
  },
  label: {
    width: 120,
    paddingLeft: 3,
    maxHeight: '100%'
  }
}));