import { Grid, Icon, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react'
import TypeChip from './CategoryChip'
import { grey } from '@mui/material/colors';


const TypePublicFigure = ({onAddTypePublicFigure, types, onRemoveType, modify}) => {

    const { classes } = useStyles()

    const addTypeButton = (
        modify
        ?
        <div className={classes.add_button} onClick={onAddTypePublicFigure}>
            <Icon fontSize='small' style={{marginTop:2}} >add</Icon> Agregar
        </div>
        :null
    )

  return (
      <div className={classes.root}>
              <Grid container justifyContent='center' spacing={2}>
                  {types.map(item => {
                      return(
                          <Grid item>
                              <TypeChip editable={modify} {...item} onRemove={() => onRemoveType(item)}/>
                          </Grid>
                      )
                  })}
                  {types.length === 0
                  ? <>
                      <Grid item>
                          {addTypeButton}
                      </Grid>
                      <Grid item>
                          {addTypeButton}
                      </Grid>
                      <Grid item>
                          {addTypeButton}
                      </Grid>
                    </>
                  : null}
                  {types.length === 1
                  ? <>
                      <Grid item>
                          {addTypeButton}
                      </Grid>
                      <Grid item>
                          {addTypeButton}
                      </Grid>
                    </>
                  : null}
                  {types.length === 2
                  ? <Grid item>
                      <Grid item>
                          {addTypeButton}
                      </Grid>
                    </Grid>
                  : null}
                  {types.length === 3
                  ? null
                  : null}
              </Grid>
          </div>
  );
}

export default TypePublicFigure

const useStyles = makeStyles()(theme => ({
    root:{
        marginTop:16
    },
    tag:{
        background:grey[300],
        padding:'6px 16px',
        color:grey[700],
        borderRadius:20
    },
    add_button:{
        position:'relative',
        display:'flex',
        alignItems:'center',
        border:`1px dashed ${theme.palette.primary.main}`,
        padding:'4px 8px',
        borderRadius:32,
        color:theme.palette.primary.main,
        fontWeight:600,
        cursor:'pointer',
        transition:'all 0.4s linear',
        '&:hover':{
            background:alpha(theme.palette.primary.main,0.2),
            transition:'all 0.4s linear'
        }
    },
    addCategoryButton:{
        display:'flex',
        flexDirection:'row'
    }
}));