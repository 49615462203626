import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
/* import { Bar } from 'react-chartjs-2'; */
import NoAnalyticsMessage from '../../../../../../components/Platform/Placeholder/NoAnalyticsMesage';


const FacebookGenderChart = ({data}) => {
  if(data === undefined || data === null) {
    data = {}
  }

  const [percentageMale, setPercentageMale] = useState(0)
  const [percentageFemale, setPercentageFemale] = useState(0)

  let male = 0
  let female = 0

  useEffect( () => {
    Object.keys(data).forEach( key => {
      if(key[0] === 'F') {
        female += data[key]
      }
  
      if(key[0] === 'M') {
        male += data[key]
      }
    })
    
    setPercentageMale(((male/(male+female))*100).toFixed(0))
    setPercentageFemale(((female/(male+female))*100).toFixed(0))
  }, [])
  
  return(
    <>
      {data && Object.keys(data).length > 0
      ? <Grid xs={12} style = {{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <div style={{width:'400px',height:'400px',position:'relative',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{width:'300px',height:'300px',position:'absolute',zIndex:'1', backgroundImage: "conic-gradient(#5E5E5E "+percentageMale+"%, #CC43CB "+(percentageMale)+"% 100%)",borderRadius: '50%'}}>

            </div>
            <div style={{width:'250px',height:'250px',position:'absolute',zIndex:'2', backgroundImage: "conic-gradient(white 0% 100%)",borderRadius: '50%'}}>

            </div>
          </div>
          <div style={{alignSelf:'start',marginTop:'50px',width:'150px',position:'absolute',right:'55px'}}>
          <div style={{zIndex:'3',fontSize:'18px',fontWeight:'bold',textAlign:'start',display:'flex',alignItems:'center',justifyContent:'start'}}>
              <div style={{width:'20px',height:'20px',borderRadius:'4px',backgroundColor:'#CC43CB',marginRight:'8px'}}></div>{percentageFemale}% Mujeres
            </div>
            <div style={{zIndex:'3',fontSize:'18px',fontWeight:'bold',textAlign:'start',display:'flex',alignItems:'center',justifyContent:'start'}}>
            <div style={{width:'20px',height:'20px',borderRadius:'4px',backgroundColor:'#5E5E5E',marginRight:'8px'}}></div> {percentageMale}% Hombres
            </div>
          </div>
        </Grid>
      : <NoAnalyticsMessage />
      }
    </>
  )
}

export default FacebookGenderChart;