import React from 'react';
import Rating from '@mui/material/Rating';
import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';

const ProfileRating = ({rating, average_rating}) => {

    const { classes } = useStyles()

    const _customer_support = parseFloat(average_rating?.customer_support??"0.00")
    const _general_satisfaction = parseFloat(average_rating?.general_satisfaction??"0.00")
    const _price_quality = parseFloat(average_rating?.price_quality??"0.00")
    const _professionalism = parseFloat(average_rating?.professionalism??"0.00")
    const _response_time = parseFloat(average_rating?.response_time??"0.00")

    let _rating = 0;
    let _count = 0;

    if(_customer_support > 0.00){
        _rating += _customer_support
        _count++
    }
    if(_general_satisfaction > 0.00){
        _rating += _general_satisfaction
        _count++
    }
    if(_price_quality > 0.00){
        _rating += _price_quality
        _count++
    }
    if(_professionalism > 0.00){
        _rating += _professionalism
        _count++
    }

    if(_response_time > 0.00){
        _rating += _response_time
        _count++
    }
    if(_count === 0 ) _count++
    let total = _rating / (_count);

    return ( 
        <div className={classes.root}>
            <Typography variant='subtitle1' style={{marginRight:8}}>{total === 0 ? null : total.toString().substring(0, 3)}</Typography>
            <Rating readOnly value={total} precision={0.1} name="unique-rating"/>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        display:'flex',
        alignItems:'center'
    }
}));

export default ProfileRating;