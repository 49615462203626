import { Grid, Typography, Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import NoContent from '../../../../../components/Platform/NoContent';
import LoadingContainer from '../../../../../components/Structure/Layouts/LoadingContainer';
import AddEventModal from '../../modals/AddEventModal/AddEventModal';
import DeleteEventModal from '../../modals/DeleteEventModal';
import EditEventModal from '../../modals/EditEventModal/EditEventModal';
import EventCard from './components/EventCard';
import useEvent from './useEvent';
import { grey } from '@mui/material/colors';


const Event = (props) => {

    //const content = translations_esp
    const { classes } = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const {system, view_data, actions, modals} = useEvent({...props})
    const {language, content, master_view_data} = props
    const sortMode = ['Más reciente', 'Más lejano']

    const onInnerClose = () => {
        setAnchorEl(null)
    }

    const onInnerItemSelected = (name) => {
        setAnchorEl(null)
        actions.onChangeSort(name)
    }

    let events

    if(view_data.sortEvents === 'Más reciente'){
        events = view_data.events.sort(function(a, b) {
            if(a.date > b.date)
                return 1
            if(a.date < b.date)
                return -1
            return 0
        })
    } else {
        events = view_data.events.sort(function(a, b) {
            if(a.date > b.date)
                return -1
            if(a.date < b.date)
                return 1
            return 0
        })
    }

    return ( 
        <div>
            <AddEventModal open={modals.add_event} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} 
            onClose={()=>actions.onUpdateModalStatus('add_event', false)} master_actions={actions} />
            <EditEventModal open={modals.edit_event} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.event}
            onClose={()=>actions.onUpdateModalStatus('edit_event', false)} master_actions={actions} />
            <DeleteEventModal open={modals.delete_event} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.event}
            onClose={()=>actions.onUpdateModalStatus('delete_event', false)} master_actions={actions} />

            <div style={{marginBottom:16}}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='h6'>Eventos</Typography>
                    </Grid>
                    {master_view_data.modify ? <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('add_event', true)}>Agregar</RoundedButton>
                            </Grid>
                            <Grid item>
                                <RoundedButton size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>Ordenar por</RoundedButton>
                                <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onInnerClose} anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                                transformOrigin={{vertical: 'top',horizontal: 'left'}} classes={{paper: classes.paper}}>
                                    <Grid style={{padding:'10px 0px'}}>
                                        {sortMode.map(sort => {
                                            return(
                                                <Grid key={sort}>
                                                    <OptionSortComponent label={sort} onItemSelected={onInnerItemSelected}/>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Popover>
                            </Grid>
                        </Grid>
                    </Grid> : null}
                </Grid>
            </div>
            <LoadingContainer loading={system.loading}>
                
                
                <Grid container spacing={3}>
                    {events.map((item) => {
                        return(
                            <Grid item xs={12} md={6} key={item.id_event}>
                                <EventCard {...item} key={item.id_event} modify={master_view_data.modify}
                                onEdit={() => actions.onEditEvent(item)} 
                                onDelete={() => actions.onDeleteEvent(item)}
                                onDownload={() => actions.onDownloadEvent(item)}/>
                            </Grid>
                        )
                    })}
                    
                </Grid>
                {view_data.events.length === 0 ? (
                        <div style={{paddingBottom:32}}>
                            <Grid container justifyContent='center'>
                                <Grid item xs={12}>
                                    <NoContent />
                                </Grid>
                                {master_view_data.modify ? <Grid item>
                                    <RoundedButton onClick={()=>actions.onUpdateModalStatus('add_event', true)}>
                                        Agregar
                                    </RoundedButton>
                                </Grid> : null}
                            </Grid>
                            
                        </div>
                ) : null}
            </LoadingContainer>

           
        </div>
        
     );
}

const useStyles = makeStyles()(theme => ({
    topbar:{
        marginBottom:40
    },
    paper:{
        borderRadius:12,
        marginTop:5,
        fontFamily:theme.typography.fontFamily,
        width:140
    }
}));


export default Event;

const useStyles4Option = makeStyles()(theme=>({
    item:{
        height:'20px',
        padding:'10px 15px',
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            fontWeight:700,
            background:theme.palette.primary.light,
            transition:theme.transitions.create(['background','font-weight'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
        }),
    }}
}));

const OptionSortComponent = props => {

    const {label, onItemSelected} = props
    const { classes } = useStyles4Option()

    return(
        <div onClick={() => onItemSelected(label)} className={classes.item}>
            {label}
        </div>
    )
}