import { makeStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors'

export const useStyles = makeStyles()(theme=>({
    root:{
        position:'relative'
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(3)
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        padding:theme.spacing(4),
    },
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    checkIcon:{
        color:green[700],
        borderRadius:'50%',
        border:`2px solid ${green[700]}`,
        fontSize:48,
        margin:'auto',
    },
    linkContainer:{marginTop:16}
}));