import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/Page';
import { actionTypes } from '../../store/actions';
import { private_server } from '../../config';

const TiktokRedirect = ({user,history}) => {

    useEffect(() => {
        if(user){
            getToken()
        }
    },[user])

    const getToken = async () => {
        let urlParams = new URLSearchParams(window.location.search)

        if(urlParams.has('code') && urlParams.has('state')){
            
            let code = urlParams.get('code')
            let state = urlParams.get('state')

            let url = '/tiktok/redirect?code='+code+'&state='+state

            let response = await private_server.get(url)
            if(response.data.status == 'ok'){
                history.push('/dash/account')
            }
        }
    }

    return ( 
        <Page >
            <div>
            
            </div>  
        </Page>
        
     );
}


const mapStateToProps = (state) => {
    return {
        user:state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(TiktokRedirect);