import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ImageDropZone from '../../../../../components/Forms/ImageDropZone';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import translations from '../../../../../texts/system_translations';
import InputForm from '../../../../../components/Forms/InputForm';
import InputDateForm from '../../../../../components/Forms/InputDateForm';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import useEditProductModal from './useEditProductModal';

const EditProductModal = (props) => {

    const { classes } = useStyles()
    const {language, open, onClose, profile, master_actions, owner, origin, onUpdateProducts, id_business, onPreDelete, modify} = props
    const content =  translations[language].modals.product

    const {system, form, actions, view_data} = useEditProductModal({...props, content})
 
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Typography variant='h6'>{content.title}</Typography>
                <Typography>{content.message}</Typography>
                <div className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            {modify ? <ImageDropZone language={language} onChange={actions.onChangeImage} preview={view_data.preview} onErase={actions.onResetImage}/> : null}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputForm data={form.name} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.price} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.link} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.description} onChange={actions.onChangeForm} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel={content.edit_button} error={system.error} loading={system.sending}
                            onSubmit={actions.onSubmit} onCloseError={actions.onResetError}  btnDisabled={!modify}
                            onDelete={modify ? onPreDelete : null} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16
    }
}));



export default EditProductModal;