import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import system_translations from '../../texts/system_translations';

const NoContent = ({language,message_2}) => {

    const { classes } = useStyles()

    const content = system_translations[language ? language : 'es'].nocontent

    return ( 
        <div className={classes.root}>
            <Grid container direction='column' alignItems='center'>
                <Grid item>
                    <div>
                        <img src={content.image} alt='' width={60}/>
                    </div>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1'>
                        {content.title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        {message_2 === true ? content.message_2 : content.message}
                    </Typography>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        padding:16,
        paddingTop:60
    }
}));

export default NoContent;