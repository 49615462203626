import general_es from './esp/general-translations'
import front_end_errors_es from './esp/frontend-erros'

import account_es from './esp/account-translations'
import change_email_es from './esp/change_email_es'
import catalogs_es from './esp/catalogs'

// layouts
import home_layout_es from './esp/home-translations'
import sigin_layout_es from './esp/signin-translations'
import sigup_layout_es from './esp/signup-translations'
import faqs_es from './esp/faqs'
import glosario_es from './esp/glosario'
import podcasts_es from './esp/podcasts'
import about_us_es from './esp/about_us'
import account_verification_layout_es from './esp/account-verification-translations'

// Views
import deal_hunter_es from './esp/deal-hunter-translations'

// Modals
import event_modal_es from './esp/modals/event-modal'
import general_modals_es from './esp/modals/general-modals'
import contact_modal_es from './esp/modals/contact-moda-translations'
import account_description_modal_es from './esp/modals/account-description'
import add_manager_modal from './esp/modals/add-manager-modal'
import send_review_invitation from './esp/modals/send-review-invitation'
import delete_manager_modal from './esp/modals/delete-manager-modal'

import add_mediakit_modal_es from './esp/modals/add-mediakit-translation'
import add_press_modal_es from './esp/modals/add-press-translation'
import add_event_modal_es from './esp/modals/add-event-translation'
import add_conference_modal_es from './esp/modals/add-conference-translation'
import business_modal_es from './esp/modals/business-translation'
import add_user_image_es from './esp/modals/add-user-image-translations'
import product_modal_es from './esp/modals/product-translations'

import add_category_modal_es from './esp/modals/add-category-translation'

import add_facebook_instragram_pages_modal_es from './esp/modals/add-facebook-instragram-pages-translation'

import edit_profile_modal_es from './esp/modals/edit-profile-translation'
import delete_profile_modal_es from './esp/modals/delete-profile-translation'
import edit_profile_personal_data_modal_es from './esp/modals/edit-profile-personal-data-translation'
import edit_profile_social_media_modal_es from './esp/modals/edit-profile-social-media-translation'
import edit_profile_description_modal_es from './esp/modals/edit-profile-description-translations'
import edit_profile_picture_modal_es from './esp/modals/edit-profile-picture-translations'
import edit_profile_banner_modal_es from './esp/modals/edit-profile-banner-translations'
import edit_user_image_es from './esp/modals/edit-user-image-translations'

import send_review_invitation_es from './esp/modals/send-review-invitation-translations'

import category_filter_modal_es from './esp/modals/add-category-filter-translation'

import dealhunter_modal_es from './esp/modals/dealhunter-modal-translations'


// Generic
import no_content_es from './esp/no-content-translations'




const system_translations = {
    es:{
        errors: front_end_errors_es,
        general:general_es,
        catalogs:catalogs_es,
        nocontent:no_content_es,
        layouts:{
            home: home_layout_es,
            signin: sigin_layout_es,
            signup: sigup_layout_es,
            faqs: faqs_es,
            account_verification:account_verification_layout_es,
            glosario: glosario_es,
            podcasts: podcasts_es,
            about_us: about_us_es,
        },
        views:{
            account:account_es,
            deal_hunter:deal_hunter_es,
            change_email: change_email_es
            //reviews:
        },
        modals:{
            contact: contact_modal_es,
            account_description: account_description_modal_es,
            event: event_modal_es,
            change_image_modal: general_modals_es.change_image_modal,
            delete_manager: delete_manager_modal,
            picture_modal:general_modals_es.picture_modal,
            add_mediakit_modal:add_mediakit_modal_es,
            add_manager_modal: add_manager_modal,
            add_press_modal:add_press_modal_es,
            add_event_modal:add_event_modal_es,
            add_conference_modal:add_conference_modal_es,
            edit_profile:edit_profile_modal_es,
            delete_profile:delete_profile_modal_es,
            edit_profile_personal_data:edit_profile_personal_data_modal_es,
            edit_profile_social_media:edit_profile_social_media_modal_es,
            edit_profile_description:edit_profile_description_modal_es,
            edit_profile_picture:edit_profile_picture_modal_es,
            edit_profile_banner:edit_profile_banner_modal_es,
            send_review_invitation:send_review_invitation_es,
            send_review_invitation_modal:send_review_invitation,
            business:business_modal_es,
            edit_user_image:edit_user_image_es,
            add_user_image:add_user_image_es,
            add_category:add_category_modal_es,
            product:product_modal_es,
            add_facebook_instragram_pages:add_facebook_instragram_pages_modal_es,
            category_filter: category_filter_modal_es,
            dealhunter: dealhunter_modal_es
        },

    },
    eng:{
        
    }
}

export default system_translations