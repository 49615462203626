import React, { useEffect, useRef } from 'react';
import { Grid, Typography, Avatar, CircularProgress, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_SETTINGS } from '../../../config';
import { isValidDocument, onGetFullResourcePath } from '../../../shared/utility';
import image_profile from '../../../assets/nouser.png'
import useInvitationsCard from '../useInvitationsCard';
import ProfileRating from '../../../components/Platform/ProfileRating';
import RoundedButton from '../../DealHunter/components/RoundedButton';
import { useHistory } from 'react-router'
import AcceptInvitationModal from '../../Invitations/Invitations/modals/AcceptInvitationModal/AcceptInvitationModal';
import RejectInvitationModal from '../../Invitations/Invitations/modals/RejectInvitationModal/RejectInvitationModal';
import CancelInvitationModal from '../../Invitations/Invitations/modals/CancelInvitationModal/CancelInvitationModal';

const InvitationCard = ({ invitation, user, master_actions, history }) => {

  const { classes } = useStyles()

  useEffect( () => {
    const id = user.user_type_id === 6 ? invitation.client_id : invitation.influencer_id
    actions.getUser(id)
  }, [])

  const { actions, system, view_data } = useInvitationsCard()

  let userTypes = view_data.invitationUserTypes.map(type => type.influencer_type).join(', ')

  let userCategories = view_data.invitationUserCategories.map(category => category.category_name).join(', ')

  let isValidProfileImg = isValidDocument(view_data.invitationUser ? view_data.invitationUser.image : null)
  let profileSrc = isValidProfileImg ? onGetFullResourcePath(view_data.invitationUser.image) : image_profile

  return(
    <Grid>
      <AcceptInvitationModal open={system.modals.accept_invitation} onClose={() => actions.onUpdateModalStatus('accept_invitation', false)} invitation={invitation} master_actions={master_actions}/>
      <RejectInvitationModal open={system.modals.reject_invitation} onClose={() => actions.onUpdateModalStatus('reject_invitation', false)} invitation={invitation} master_actions={master_actions}/>
      <CancelInvitationModal open={system.modals.cancel_invitation} onClose={() => actions.onUpdateModalStatus('cancel_invitation', false)} invitation={invitation} master_actions={master_actions}/>
      {system.loading
      ? <Grid container style={{justifyContent:'center', marginTop:'20px', height:130, alignItems:'center'}}>
          <CircularProgress size={30}/>
        </Grid>
      : <Grid container xs={12} className={classes.option}>
          <Grid xs={7} md={2} >
            <Avatar src={profileSrc} className={classes.avatar}/>
          </Grid>
          <Grid container md={6} xs={12} className={classes.content}>
            <Grid xs={12}>
              <Typography variant='h5'>{view_data.invitationUser.first_name}</Typography>
            </Grid>
            <Grid xs={12}>
                <ProfileRating average_rating={view_data.invitationUserReview}/>
            </Grid>
            <Grid container xs={12} className={classes.item}>
              <Typography variant='subtitle2'>Tipo de figura pública:&nbsp;<Typography variant='caption' color='textSecondary'>{userTypes}</Typography></Typography>
              <Typography variant='subtitle2'>Categorías de contenido:&nbsp;<Typography variant='caption' color='textSecondary'>{userCategories}</Typography></Typography>
            </Grid>
          </Grid>
          <Grid md={4}>
            <Grid xs={12} container className={classes.button}>
              <RoundedButton size='small' color='white' onClick={() => history.push(`/dash/managers/${view_data.invitationUser.url}`)}><Typography variant='caption'>Ver perfil</Typography></RoundedButton>
            </Grid>
              {user.user_type_id === 6 && invitation.direction === 0
              ? <Grid container xs={12} style={{marginTop:50, justifyContent:'flex-end'}}>
                  <RoundedButton size='small' color='white' onClick={() => actions.onUpdateModalStatus('reject_invitation', true)}><Typography variant='caption'>Rechazar</Typography></RoundedButton>
                  <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('accept_invitation', true)}>Aceptar</RoundedButton>
                </Grid>
              : null}
              {user.user_type_id === 3 && invitation.direction === 1
              ? <Grid container xs={12} style={{marginTop:50, justifyContent:'flex-end'}}>
                  <RoundedButton size='small' color='white' onClick={() => actions.onUpdateModalStatus('reject_invitation', true)}><Typography variant='caption'>Rechazar</Typography></RoundedButton>
                  <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('accept_invitation', true)}>Aceptar</RoundedButton>
                </Grid>
              : null}
              {user.user_type_id === 3 && invitation.direction === 0
              ? <Grid container xs={12} style={{marginTop:50, justifyContent:'flex-end'}}>
                  <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('cancel_invitation', true)}>Cancelar</RoundedButton>
                </Grid>
              : null}
              {user.user_type_id === 6 && invitation.direction === 1
              ? <Grid container xs={12} style={{marginTop:50, justifyContent:'flex-end'}}>
                  <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('cancel_invitation', true)}>Cancelar</RoundedButton>
                </Grid>
              : null}
          </Grid>
        </Grid>
    }
    </Grid>
  )
}

export default InvitationCard

const useStyles = makeStyles()(theme => ({
  avatar:{
    width:'90%', height:'90%',
    boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
    border:'4px solid white',
    [theme.breakpoints.down('md')]:{
      marginBottom:'8px'
    }
  },
  option:{
    background:'white',
    boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
    padding: '20px 15px',
    borderRadius: '15px',
    marginBottom:'15px',
  },
  content:{
    padding:'0px 5px',
    display:'flex',
    flexDirection:'row',
  },
  button:{
    justifyContent:'flex-end'
  }
}));