import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/Page';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import system_translations from '../../../texts/system_translations';
import blog_img from '../../../assets/dealhunter/blog.png'
import logo from '../../../assets/logo.png'





const BlogsView = (props) => {

    const {user, history, language, match} = props
    useEffect(() => {window.scrollTo(0,0)}, [])    

    const { classes } = useStyles()

    return ( 
        <Page  >
            <div>
                
                <div>
                    <Grid container direction='column' alignItems='center' spacing={2}>
                        <Grid item>
                            <Typography variant='h4' color='primary' style={{marginBottom:40}}>Próximamente</Typography>
                        </Grid>
                        <Grid item>
                            <img src={blog_img} width={250}/>
                            
                        </Grid>
                        <Grid item>
                            <div>
                                <Typography align='center' variant='body1' style={{fontSize:'1.375rem', fontWeight:500, whiteSpace:'pre-wrap'}}>
                                    {`Estamos trabajando \n para darte los mejores servicios`}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item >
                            <div className={classes.logo_container}>
                                <img alt='' src={logo} className={classes.logo} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>  
        </Page>
        
     );
}

const useStyles = makeStyles()(theme => ({
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    paragraphs:{
        margin:'10px 0px 40px',
        fontFamily:theme.typography.fontFamily,
        textAlign:'justify'
    },
    divider:{
        margin:'70px auto',
        width:'90%',
        height:1,
        background:'#DADADA'
    },
    button_container:{
        width:'100%',
        textAlign:'center',
        margin:'32px 0px'
    },
    logo:{
        width:'100%'
    },
    logo_container:{
        marginTop:80,
        width:200,
        position:'relative',
        [theme.breakpoints.down('md')]:{
            width:120,
        }

    },
    img_container:{
        /* height:400,
        background:'blue',
        width:'100%' */
        background:'black'
    },
    how_works_container:{
        background:'black',
        padding:'50px 100px',
        textAlign:'center',
        color:'white',
        fontFamily:theme.typography.fontFamily,
        [theme.breakpoints.down('md')]:{
            padding:'30px'
        }
    },
    what_get_container:{
        background:theme.palette.primary.main,
        padding:'40px 0px',
        color:'white'
    },
    BlogsView_section:{
        padding:'20px 40px'
    },
    blog_section:{
        padding:'20px 40px ',
        background:theme.palette.primary.light,
        textAlign:'right'
    },
    section_button:{
        marginTop:20
    },
    section_img:{
        width:'100%',
        [theme.breakpoints.down('md')]:{
            width:100
        }
    },
    section_img_container:{
        width:200
    }
    
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogsView);