import { useState, useEffect } from "react"
import { private_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import catalogs from "../../../../../texts/esp/catalogs"
import { generic_errors } from "../../../../../texts/esp/genericErrors"

const useEditProfileDataModal = ({ open, language, content, profile, master_actions, owner, origin, onUpdateUserData}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [image, setImage] = useState(null)
    const [preview, setPreview] = useState(null)
    const [file, setFile] = useState(null)

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
            setImage(null)
            setPreview(null)
            setFile(null)
        }
        
    }, [open])


    
    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form, origin, content.form)
            
            _form.gender_id.options = [...catalogs.genders]
            _form.country_id.options = [...catalogs.countries]
            
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImage: async(_file) =>{
            try {
                setImage(_file)
                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    // convert image file to base64 string
                    setPreview(reader.result)
                }, false);
                if (_file) reader.readAsDataURL(_file);
                
            } catch (error) {
                
            }
        },
        onChangeFile: async(_file) =>{
            setFile(_file)
        },
        onResetError: () => {
            setError(null)
        },
        onResetImage: () => {
            setImage(null)
            setPreview(null)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetSelectedFormData(form, origin)
            if(data2send.quote === '') data2send.quote = '@null!!'
            if(form.email_public.value === '') data2send.email_public = '@null!!';

            setSending(true)
            let request_url = owner ? `/user/me` : `/user/${profile.id_user}`
            // Create first the event
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(request_url, data2send)
                setError(null)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            const _profile = await master_actions.onGetUserData()
            await master_actions.onUpdateModalStatus('edit_profile', false)
            if (owner) {
                onUpdateUserData(_profile)
            }
            setSending(false)
        },
        onDeleteProfile: async () => {
            await master_actions.onUpdateModalStatus('delete_profile', true)
        }
    }

    const view_data = {preview, file}
    const system = {loading, sending, error}

    return {system, form, actions, view_data}
}

export default useEditProfileDataModal

const form_data = {
    birth_date:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'birth_date',
            type:'date',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    },
    gender_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'gender_id',
            type:'select',
            fullWidth: true,
        },
        rules:{
            type:'select',
        }
    },
    first_name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'first_name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    last_name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'last_name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    quote:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'quote',
            type:'text',
            fullWidth: true,
            multiline:true,
            rows:3
        },
        rules:{
            type:'distance',
            min:0, max:256
        }
    },
    whatsapp_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'whatsapp_link',
            type:'link',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:0, max:256
        }
    },
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'email',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email',
        }
    },
    email_public:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'email_public',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email'
        }
    },
    website_link:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'website_link',
            type:'link',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:0, max:256
        }
    },
    country_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'country_id',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'select',
        }
    },
    mobile_country_code:{
        value: '52',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        config:{
            id:'mobile_country_code',
            type:'select',
            fullWidth: true,
        },
        rules:{
            type:'select',
        }
    },
    mobile:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'mobile',
            type:'number',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:0, max:45
        }
    },
    zone:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'zone',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:0, max:256
        }
    },
}
