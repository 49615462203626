import { alpha, Grid, Typography, Icon, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard';
import SmallLink from '../../../../../components/Actions/SmallLink';
import useSocialMedia from './useSocialMedia';
import SocialMediaCard from './components/SocialMediaCard';
import Scrollbars from 'react-custom-scrollbars-2';
import FacebookPagesModal from './modals/FacebookPagesModal/FacebookPagesModal';
import DeleteIntegrationModal from './modals/DeleteIntegrationModal';
import EditSocialMediaDataModal from '../../modals/EditSocialMediaDataModal/EditSocialMediaDataModal';
import InstagramPagesModal from './modals/InstagramPagesModal/InstagramPagesModal';
import HideMetricsModal from '../../modals/HideMetricsModal/HideMetricsModal';
import CardSocialMedia from './CardSocialMedia';
import PopoverIcon from './components/PopoverIcon';
import { ReactSortable } from "react-sortablejs";

const SocialMedia = (props) => {

    const { classes } = useStyles()
    const [loading, setLoading] = useState(false);
    const {system, view_data, actions, modals} = useSocialMedia({...props})
    const {language, content, master_view_data, onUpdateMasterUser, user, onHideMetrics, profile} = props
    const [json_links,setJson_links] = useState({})
    const [sortableList,setSortableList] = useState([])
    const [follower_count, setFollowerCount] = useState({})


    useEffect(()  => {
        const integration = view_data.integration
        if(integration){
            let json_links_strings = integration.json_links

            if(json_links_strings){
                setJson_links(JSON.parse(json_links_strings))
            }
            if(integration.integrations_follower_count) {
                setFollowerCount(JSON.parse(integration.integrations_follower_count))
            }
        }
    },[view_data.integration])

    useEffect(() => {
        let new_list = []
        let list_of_keys = []

        view_data.listSocialMedia.forEach((item,i) => {
            new_list.push({id:i,type:item.type,onlylink:false})
            list_of_keys.push(item.type + '_link')
        })

        if(json_links){
            Object.entries(json_links).map((item,i) => {
                let key = item[0]
                let value = item[1] 
                if(value){
                    if(!list_of_keys.includes(key)){
                        let new_id = new_list.length + 1
                        new_list.push({id:new_id,type:key.replace('_link',''),onlylink:true,link:value})
                    }
                }               
            })
        }

        //order
        let ordered_list = new_list
        if(view_data.integration){
            if(view_data.integration.integrations_order){
                let order = JSON.parse(view_data.integration.integrations_order)
                let aux = []
                new_list.forEach((item,i) => {
                    if(order[item.type] !== undefined && !isNaN(order[item.type])){
                        let index_to_save = findIndex(order[item.type],aux)
                        aux[index_to_save] = item
                    }
                })
                ordered_list = aux.filter(Boolean)
            }
        }


        setSortableList(ordered_list)
    },[view_data.listSocialMedia,json_links])

    useEffect( () => {
        getDataSocialMedia();
    }, [])

    const findIndex = (i,array) => {
        let index = i
        if(array[i]){
            index = findIndex(i+1,array)
        }
        return index
    }

    const getDataSocialMedia = async () => {
        setLoading(true)
        const socialMedia = await actions.getSocialMedia(profile.id_user)
        setLoading(false)
    }

    const setOrder = (data) => {
        if(view_data.integration && sortableList && (master_view_data.owner || master_view_data.modify)){
            setSortableList(data)

            let order_list = {}
    
            data.forEach((item,i) => {
                order_list[item.type] = i
            })
    
            if(view_data.integration.integrations_order){
                let new_order_string = JSON.stringify(order_list)
                if(new_order_string != view_data.integration.integrations_order){
                    actions.saveIntegrationsOrder(order_list, profile.id_user)
                }
            }else{
                actions.saveIntegrationsOrder(order_list, profile.id_user)
                view_data.integration.integrations_order = order_list
            }
        }
    }
    const fake_json = {"instagram_link":"https://www.inpager.app","facebook_link":"https://www.inpager.app","tiktok_link":"https://www.inpager.app","linkedin_link":"https://www.inpager.app","twitter_link":"https://www.inpager.app","youtube_link":"https://www.inpager.app","twitch_link":"https://www.inpager.app","podcasts_link":"https://www.inpager.app","pinterest_link":"https://www.inpager.app","snapchat_link":"https://www.inpager.app","spotify_link":"https://www.inpager.app","reddit_link":"https://www.inpager.app","tumblr_link":"https://www.inpager.app"}
    return ( 
        <div>
            <FacebookPagesModal open={modals.setup_facebook_pages} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} 
            onClose={()=>actions.onUpdateModalStatus('setup_facebook_pages', false)} master_actions={actions}/>

            <DeleteIntegrationModal error = {system.error} open={modals.delete_facebook_pages} language={language} 
            onClose={()=>actions.onUpdateModalStatus('delete_facebook_pages', false)} master_actions={actions}/>

            <InstagramPagesModal open={modals.setup_instagram_pages} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} 
            onClose={()=>actions.onUpdateModalStatus('setup_instagram_pages', false)} master_actions={actions}/>

            <EditSocialMediaDataModal  open={modals.edit_social_media} onClose={()=>actions.onUpdateModalStatus('edit_social_media', false)} language={language} 
                origin={master_view_data.profile} onCompleted={actions.onInitModule} master_actions={actions} owner={master_view_data.owner}
                onUpdateMasterUser={onUpdateMasterUser} integration = {view_data.integration} profile = {profile} user_id = {profile.id_user}/>

            <HideMetricsModal open = {modals.hide_metrics} onClose = {() =>actions.onUpdateModalStatus('hide_metrics',false)} language = {language}
            master_actions = {actions} owner  = {master_view_data.owner}  profile = {master_view_data.profile} onHideMetrics = {onHideMetrics}
            />
            <div>
                <div>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item xs>                    
                            <Typography variant='h6'>Métricas de Redes Sociales</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <PopoverIcon />
                        </Grid>
                        {master_view_data.modify ? <Grid style={{display:'flex'}} item>
                            <div className={classes.button} onClick={()=>actions.onUpdateModalStatus('edit_social_media', true)} style={{marginRight: 10}}>
                                <Icon fontSize='small' style={{marginTop:2}} >add</Icon> Agregar
                            </div>
                            <div className={classes.button} onClick={()=>actions.onUpdateModalStatus('hide_metrics', true)}>
                                {!master_view_data.profile.hide_metrics ? 'Ocultar estadisticas' : 'Mostrar estadisticas'} 
                            </div>

                        </Grid> : null}
                    </Grid>
                    <div style={{marginBottom:24}}>
                        <Scrollbars autoHeight autoHeightMax={800}>
                            {loading
                            ? <div style={{textAlign: 'center', height: 100, alignItems: 'center', padding: '60px 0px'}}>
                                <CircularProgress></CircularProgress>
                              </div>
                            : <>
                                {
                                /*
                                json_links &&
                                    
                                         Object.entries(json_links)
                                        .map(([key, value]) => !(view_data.listSocialMedia.map(e => e.type).includes(key.replace('_link',''))) && value !== '' && 
                                        <CardSocialMedia just_redirect json_links={json_links} key={key} type={key.replace('_link','')} user={user} profile={profile}>{value} </CardSocialMedia>)
                                    
                                   */
                                }
                                {
                                /*
                                view_data.listSocialMedia.length > 0 
                                ? 
                                view_data.listSocialMedia.map(item => {
                                    
                                    return( 
                                        <CardSocialMedia json_links = {json_links} key={item.type} type={item.type} followers={item.num_followers} user={user} profile={profile}/>
                                    )
                                })
                                
                                : <>
                                  {master_view_data.modify && 
                                    <div className={classes.noSocialMedia}>
                                        <div>
                                            <img src={lapiz_editable} alt=''/>
                                            <Typography style={{marginTop: 10, marginBottom: 10}}>Agrega tus redes sociales y comparte tu información <br></br> de forma rápida y eficiente a todos tus clientes</Typography>
                                            <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('edit_social_media', true)}><Typography variant='subtitle2'>Vincular redes sociales</Typography></RoundedButton>
                                        </div>
                                    </div>
                                  }
                                  </>
                                  */
                                }

                                {user && sortableList ? 
                                    master_view_data.owner || master_view_data.modify
                                        ? 
                                            <ReactSortable animation={200} list = {sortableList} setList = {(data) => {setOrder(data)}}>
                                                {sortableList.map((item) => {
                                                    return (
                                                    <div style = {{display:'flex',alignItems:'center'}} key = {item.id}>
                                                        <div><Icon fontSize='small' style={{color:'#868686'}} >sort</Icon></div>
                                                        {item.onlylink 
                                                        ? <CardSocialMedia just_redirect owner={''} json_links={json_links} type={item.type} user={user} followers={follower_count[item.type] || 0} profile={profile}>{item.link} </CardSocialMedia> 
                                                        : <CardSocialMedia json_links = {json_links} type={view_data.listSocialMedia[item.id].type} followers={view_data.listSocialMedia[item.id].num_followers} vinculation_name={view_data.listSocialMedia[item.id].name} user={user} profile={profile}/>}
                                                    </div> )
                                                })}
                                            </ReactSortable>
                                        
                                        : <div>
                                            {sortableList.map((item) => {
                                                    return (
                                                    <div style = {{display:'flex',alignItems:'center'}} key = {item.id}>
                                                        {item.onlylink 
                                                        ? <CardSocialMedia just_redirect json_links={json_links} type={item.type} user={user} followers={follower_count[item.type] || 0} profile={profile}>{item.link} </CardSocialMedia> 
                                                        : <CardSocialMedia json_links = {json_links} type={view_data.listSocialMedia[item.id].type} followers={view_data.listSocialMedia[item.id].num_followers} vinculation_name={view_data.listSocialMedia[item.id].name} user={user} profile={profile}/>}
                                                    </div> )
                                                })}
                                        </div>

                                
                                    : Object.entries(fake_json).map(([key, value]) =>  <CardSocialMedia json_links={fake_json} type={key.split('_link')[0]} user={user} followers={1000} profile={profile}>{'facebook'} </CardSocialMedia>)
                                    }
                              </>
                            }
                        </Scrollbars>
                        
                        
                    </div>
                </div>
            </div>

            {(!master_view_data.modify && master_view_data.profile.hide_metrics === 1)? 
                
                null:              
                
                <div>
                    {/* <Grid container>
                        <Grid item xs={12}>
                            <FacebookIntegration  {...view_data} actions={actions} modify={master_view_data.modify} owner={master_view_data.owner} master_user={user}
                            onSetPages={() => actions.onUpdateModalStatus('setup_facebook_pages', true)} 
                            onDeletePages = {() => actions.onUpdateModalStatus('delete_facebook_pages', true)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InstagramIntegration  {...view_data} actions={actions} modify={master_view_data.modify} owner={master_view_data.owner} master_user={user}
                            onSetPages={() => actions.onUpdateModalStatus('setup_instagram_pages', true)}
                            onDeletePages = {() => actions.onUpdateModalStatus('delete_facebook_pages', true)}/>
                        </Grid>
                    </Grid> */}
                </div>         
            }  

            
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    button:{
        position:'relative',
        display:'flex',
        alignItems:'center',
        border:`1px dashed ${theme.palette.primary.main}`,
        borderRadius:32,
        padding:'4px 8px',
        color:theme.palette.primary.main,
        fontWeight:600,
        cursor:'pointer',
        transition:'all 0.4s linear',
        '&:hover':{
            background:alpha(theme.palette.primary.main,0.2),
            transition:'all 0.4s linear'
        }
    },
    noSocialMedia: {
        width: '99.5%',
        border: `1px dashed ${theme.palette.primary.main}`,
        borderRadius: '15px',
        marginTop: 10,
        textAlign: 'center',
        padding: '45px 0px'
    }
}));

export default SocialMedia;