const helper_text = 'Este campo debe contener min un caracter'
const helper_select = 'Debe seleccionar al menos una opción'
const helper_password = 'La contraseña debe contener como mínimo 8 caracteres'


const translations = {
    change_password_modal:{
        title:'Cambiar contraseña',
        button:'Guardar',
        form:{
            password:{tag:'Nueva contraseña', helperText:helper_password},
        },
        form_me:{
            previous_password:{tag:'Contraseña anterior', helperText:helper_password},
            new_password:{tag:'Nueva contraseña', helperText:helper_password},
        } 
    },
    picture_modal:{
        selected_image:'Imagen seleccionada',
        cropped_area:'Seleccione el área que desea recortar',
        dropzone:{
            text:'Arrastre o de clic para subir una imagen'
        }
    },
    download_doc:{
        title:'¡Listo!',
        message:'Archivo generado exitosamente.',
        button:'Descargar',
        //image:file
    },
    change_image_modal:{
        title:'Cambiar imagen',
        button:'Guardar',
        error_msg:'Debe adjuntar/seleccionar una imagen para continuar'
    },
    warning_modal: {
        title:'¿Estás seguro?',
        button:'Eliminar',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido'
    },
    error_modal:{
        default_message:'Hubo un error',
        button:'Continuar'
    },
    document_modal:{
        title:'Subir archivo',
        button:'Subir',
        input_file:{
            title:'Adjuntar archivo',
            max_size:'Tamaño máximo del archivo: 25MB',
            change_file:'Si desea modificar el archivo de click o arrastre uno nuevo en el recuadro'
        }
    },
    error_document:'Debe adjuntar un documento'
}

export default translations