import React, { useState } from 'react';
import { alpha, CircularProgress, Grid, Typography, IconButton, Icon } from '@mui/material';
import InputForm from '../../../../../components/Forms/InputForm'
import { makeStyles } from 'tss-react/mui';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import useChangePasswordModal from './useChangePasswordModal';
import { green } from '@mui/material/colors'
import RoundedButton from '../../../../../components/Actions/RoundedButton'

const useStyles = makeStyles()(theme => ({
    icon:{
        color:green[600],
        fontSize:100,
        border:`3px solid ${green[600]}`,
        borderRadius:'50%'
    },
    showPasswordButton:{
        textAlign:'center',
        justifyItems:'center',
        padding:'5px 0px'
    }
}));

const ChangePasswordModal = ({user, open, onClose}) => {

    const { classes } = useStyles()

    const [passState, setPassState] = useState(false)
    const [passConfirmState, setPassConfirmState] = useState(false)

    const {system, actions, view_data} = useChangePasswordModal({ user, onClose })    

    if(!view_data.isPasswordRecover){
        return (
            <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
                <Grid container spacing={6}>
                <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item>
                                        <Typography color='primary' variant='h6'>Nueva contraseña</Typography>
                                    </Grid>
                                </Grid>         
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color='bluegrey' size='normal'>Por favor ingresa tu nueva contraseña con la que podrás acceder a tu cuenta</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Grid container spacing={4}>
                                <Grid item xs={12} container>
                                    <Grid xs={10}>
                                        <InputForm data={view_data.form.password} onChange={actions.onChangeForm}/>
                                    </Grid>

                                    <Grid className={classes.showPasswordButton} xs={2}>
                                        <IconButton item size='small' color='primary' onClick={passState ? () => {
                                            view_data.form.password.config.type='password'
                                            setPassState(false)
                                        } 
                                        : () => {
                                            view_data.form.password.config.type='text'
                                            setPassState(true)
                                        }}
                                        >{passState 
                                        ? <Icon fontSize='large'>visibility_icon</Icon> 
                                        : <Icon fontSize='large'>visibility_off_icon</Icon>}</IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container>
                                    <Grid xs={10}>
                                        <InputForm data={view_data.form.passwordConfirmation} onChange={actions.onChangeForm}/>
                                    </Grid>

                                    <Grid className={classes.showPasswordButton} xs={2}>
                                        <IconButton item size='small' color='primary' onClick={passConfirmState ? () => {
                                            view_data.form.passwordConfirmation.config.type='password'
                                            setPassConfirmState(false)
                                        } 
                                        : () => {
                                            view_data.form.passwordConfirmation.config.type='text'
                                            setPassConfirmState(true)
                                        }}
                                        >{passConfirmState 
                                        ? <Icon fontSize='large'>visibility_icon</Icon> 
                                        : <Icon fontSize='large'>visibility_off_icon</Icon>}</IconButton>
                                    </Grid>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <RoundedButton fullWidth color='primary' onClick={actions.onSubmit}
                                        disabled={!view_data.isValid}>Continuar</RoundedButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item xs={12}>
                                            <div style={{minHeight:44}}>
                                                <Grid container justifyContent='center'>
                                                    {system.loading ? (
                                                        <Grid item> <CircularProgress color='secondary'/> </Grid>
                                                    ) : null}
                                                    {system.error ? (
                                                        <Grid item>
                                                            <Typography color='red'>{system.error}</Typography>
                                                        </Grid>
                                                    ):null}
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </SimpleModal>
        );
    }
    else{
        return (
            <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
                    <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography align='center' color='primary' variant='h6'>¡Contraseña cambiada!</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align='center' color='bluegrey' size='large'>Ya puede iniciar sesión con su nueva contraseña</Typography>
                        </Grid>
                        <Grid  item xs={12}>
                            <Grid container justifyContent='center'>
                                <Grid item>
                                    <Icon className={classes.icon}>done</Icon>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item xs={12}>
                    <div>
                        <Grid container spacing={4}>
                            
                            <Grid item xs={12}>
                                <RoundedButton fullWidth color='primary' onClick={actions.onClose}>OK</RoundedButton>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                
            </Grid>
                </SimpleModal>
        );
    }
}

export default ChangePasswordModal;