import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import useDeleteProfileDataModal from './useDeleteProfileDataModal';
import translations from '../../../../../texts/system_translations';
import InputForm from '../../../../../components/Forms/InputForm';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { actionTypes } from '../../../../../store/actions';


const DeleteProfileDataModal = (props) => {

    const { classes } = useStyles()
    const {language, open, onClose} = props
    const content = translations[language].modals.delete_profile
    const {system, form, actions} = useDeleteProfileDataModal({...props, content})

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Typography variant='h6' style={{marginBottom:16}}>{content.title}</Typography>
                <Typography variant='subtitle2' >{content.message}</Typography>
                <div className={classes.container}>
                    <Scrollbars autoHeight autoHeightMax={600}>
                        <div className={classes.content}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputForm data={form.confirm_delete} onChange={actions.onChangeForm}/>
                                </Grid>
                            </Grid>
                        </div>
                    </Scrollbars>
                    <div style={{marginTop:16}}>
                        <ActionModalBar btnLabel={content.delete_button} error={system.error} loading={system.sending}
                        onSubmit={actions.onSubmit}/>
                    </div>

                </div>
                
                
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16,
        maxHeight:400,
        paddingRight:16
    },
    container:{
        //height:400
    }
}));


const mapStateToProps = state => {
    return {
        user:state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProfileDataModal);