import { useState, useEffect } from 'react';
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS } from '../../config';
import nouser from '../../assets/nouser.png'
import { alpha, Divider, Fade, Grid, Icon, Typography, Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isValidDocument, onGetFullname } from '../../shared/utility';
import ProfileRating from './ProfileRating';
import RoundedButton from '../Actions/RoundedButton';
import { public_server } from '../../config';

const SearchProfileCard = (props) => {
    const { classes } = useStyles()
    const { id_user, image, first_name, last_name, onSelectedUser } = props
    const [rating, setRating] = useState(false)
    const [error, setError] = useState(false)
    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let selectedImage = nouser
    const isValid = isValidDocument(image)
    selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : nouser
 
    const getUserRating = async(id_user) => {
        try{
            const review_url = `/user/${id_user}/reviewaverages/public/ns`
            const avg_review_rq = await public_server.get(review_url);
            const _avgReview = avg_review_rq.data.data.review_averages;
            setRating(_avgReview);
        }catch(e){
            console.error(e);
        }
    }

    useEffect(() => {
        getUserRating(id_user)
    },[])

    return (
        <Grid item container md={12} className={classes.root} onClick={() => onSelectedUser(props)}
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
        >
            <Grid item xs={5} md={3}>
                <Fade in>
                    {isValid && !error ?
                        <Avatar src={selectedImage} onLoad={onImageLoad}
                            onError={onError} key={`img-press-${id_user}`} className={classes.avatar} />
                        :
                        <Avatar src={nouser} className={classes.avatar} key={`noimg-press-${id_user}`} />}
                </Fade>
            </Grid>
            <Grid item xs={3} md={5}>
                <Typography variant="subtitle2">
                    {onGetFullname(first_name, last_name)}
                </Typography>
                <ProfileRating rating={4} average_rating={rating} />
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={1} md={1}>

            </Grid>
            <Grid item xs={2} md={2}>
                <RoundedButton size="small">Ver Perfil</RoundedButton>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles()(theme => ({
    root: {
        cursor: 'pointer',
        position: 'relative',
        borderRadius: 15,
        boxShadow: DASHBOARD_SETTINGS.BOXSHADOW,
        marginBottom: 15,
        padding: 16
    },
    avatar: {
        width: 140,
        height: 140,
        objectFit: 'cover',
        boxShadow: DASHBOARD_SETTINGS.BOXSHADOW,
        border: '4px solid white',
        [theme.breakpoints.down('md')]: {
            width: 100,
            height: 100
        }
    }
}));

export default SearchProfileCard;