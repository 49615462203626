import { Grid, Icon, IconButton, Typography } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import RoundedButton from './RoundedButton';
//import RoundedButton from './RoundedButton';

const ActionModalBar = ({btnLabel, loading, btnColor, error, onSubmit, onDelete, onCloseError, btnDisabled}) => {

    const { classes } = useStyles()

    return (
        <Grid container>
            <Grid item xs={12}>
                {error ? <div className={classes.error_container}>
                        <Icon color='error'>error</Icon>
                        <Typography variant='subtitle2' color='inherit'  className={classes.error_text}>{error}</Typography>
                        <IconButton size='small' onClick={onCloseError}><Icon>close</Icon></IconButton>
                    </div> : null}
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent='flex-end'>
                    {onDelete ? (
                        <Grid item><IconButton onClick={onDelete} size="large"><Icon >delete</Icon></IconButton>  </Grid>
                    ) : null}
                    <Grid item xs></Grid>
                    <Grid item>
                        <RoundedButton loading={loading} color={btnColor} onClick={onSubmit} disabled={btnDisabled} >{btnLabel}</RoundedButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles()(theme => ({
    error_container:{
        background:red[100],
        border:`1px dashed ${red[300]}`,
        padding:8,
        borderRadius:8,
        marginBottom:16,
        color:grey[900],
        display:'flex',
        wrap:'no-wrap',
        alignItems:'center'
        
    },
    error_text:{
        fontWeight:400,
        marginLeft:4,
        flex:1
    }
}));

export default ActionModalBar;