import { useState, useEffect } from "react"
import { public_server } from "../../config"
import { isFormValid, onGetFormData, onGetFullname, onSetErrorsToForm } from "../../shared/utility"
import queryString from 'query-string'
import { generic_errors } from "../../texts/esp/genericErrors"

const useReviewInvitationLayout = ({ language,history}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [user, setUser] = useState('')
    const [token, setToken] = useState()
    const [view, setView] = useState(1)

    useEffect(() => {
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            try {
               const urlArgs = queryString.parse(history.location.search)
               const _token = urlArgs.token
               setToken(_token)
               const data2send = {
                   token: _token
               }
            

               const confirmation_request = await public_server.post('/review/complete/validate', data2send)
               const user = confirmation_request.data.data.influencer
               setUser(onGetFullname(user.first_name, user.last_name))
               //const _user = confirmation_request.data.data
               //const user_req = await public_server.get(`/user/url/public/ns?url=${_user.username}`)
               //const user = user_req.data.data.user
               //setReferalCode(user.referal_code)
               //setUserType(user.user_type_id)
               setError(false)
                

            } catch (error) {
                
                setError(true)
                setView(2)
            }
            //const _form = onInitForm(form, null,  content.form)
            //setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onKeyPress: (event) => {
            if(event.key === 'Enter'){
              actions.onSubmit()
            }
        },
        onChangeView:(value) => {
            setView(value)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                let _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            data2send.token = token
            data2send.attracted_sales = "-"
            data2send.attracted_reputation = "-"
            //data2send.token = token
            //delete data2send.password_confirmation
            //data2send.device = "web"


            setSending(true)

            try {
                const response = await public_server.post('/review/complete/execute', data2send);
                //setUser(response.data.data)
                //// device, emial, exp, first_name, last_name, token, user_id, user_type_id, username
                actions.onChangeView(2)
                setError(null)
            } catch (error) {
                setError(true)
                actions.onChangeView(2)
            }

            setSending(false)

        }
    }
    const view_data = {view, user}
    
    const system = {loading, sending, error}

    return {system, form, actions, view_data}
}

export default useReviewInvitationLayout

const form_data = {
    response_time: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'response_time',
            type: 'rating',
            fullWidth: true,
            label: 'Tiempo de respuesta',
            helper: 'Debe seleccionar una opción'
        },
        rules: {type: 'numeric', min:1, max:5}
    },
    professionalism: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'professionalism',
            type: 'rating',
            fullWidth: true,
            label: 'Profesionalismo',
            helper: 'Debe seleccionar una opción'
        },
        rules: {type: 'numeric', min:1, max:5}
    },
    price_quality: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'price_quality',
            type: 'rating',
            fullWidth: true,
            label: 'Calidad/Precio',
            helper: 'Debe seleccionar una opción'
        },
        rules: {type: 'numeric', min:1, max:5}
    },
    customer_support: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'customer_support',
            type: 'rating',
            fullWidth: true,
            label: 'Soporte al cliente',
            helper: 'Debe seleccionar una opción'
        },
        rules: {type: 'numeric', min:1, max:5}
    },
    general_satisfaction: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'general_satisfaction',
            type: 'rating',
            fullWidth: true,
            label: 'Satisticación general',
            helper: 'Debe seleccionar una opción'
        },
        rules: {type: 'numeric', min:1, max:5}
    },
    comment:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'comment',
            type:'text',
            fullWidth: true,
            multiline:true, 
            rows:5,
            label:'Comentarios',
            placeholder:'Comentarios',
            helperText:'Este campo es obligatorio y debe de tener entre 1 y 512 caracteres'
        },
        rules:{
            type:'distance',
            min:1, max:512
        }
    },
    
}
