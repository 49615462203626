import React, { useState, useEffect } from 'react';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import Footer from '../../components/Structure/Navigation/Footer';
import system_translations from '../../texts/system_translations';
import {
    alpha,
    Button,
    CircularProgress,
    Grid,
    Typography,
    Divider,
    IconButton,
    Icon,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useSignInLayout from './useSignInLayout';
import InputForm from '../../components/Forms/InputForm';
import { Redirect } from 'react-router';
import useAuth from '../../shared/useAuth';
import ContactModal from '../../modals/ContactModal/ContactModal';
import ForgottenPasswordModal from '../../modals/ForgottenPasswordModal/ForgottenPasswordModal'
import GoogleButton from '../../components/Actions/GoogleButton';
import FacebookButton from '../../components/Actions/FacebookButton';

const SignInLayout = ({history, language, user,  onUpdateAuthStatus, onUpdateUserData, onUpdateModal, modals}) => {

    const { classes } = useStyles()
    const content = system_translations[language].layouts.signin
    const {system, form, actions} = useSignInLayout({language, content,  onUpdateAuthStatus, onUpdateUserData, history})
    const {authenticated} = useAuth({onUpdateUserData})
    const [passState, setPassState] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    const onChangePage = (url) => {
        history.push(url)
    }

    let contentRedirect = null
    if(authenticated) contentRedirect = <Redirect to='/dash'/>

    return ( 

        <div className={classes.root}>
            {contentRedirect}
            <ContactModal />
            <ForgottenPasswordModal open={modals.recover_password} onClose={()=>onUpdateModal({recover_password:false})} />
            <PublicTopbar content={content.topbar} onChangePage={onChangePage}/>
                <div className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div className={classes.left_container}>
                                <div className={classes.content_data}>
                                    <Typography variant='h4'>
                                        {content.title}
                                    </Typography>

                                    <div className={classes.form}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <InputForm data={form.username} onChange={actions.onChangeForm}
                                                onKeyPress={actions.onKeyPress}/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <InputForm data={form.password} onChange={actions.onChangeForm}
                                                onKeyPress={actions.onKeyPress}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <IconButton size='small' className={classes.show_password_button} onClick={passState ? () => {
                                                    form.password.config.type='password'
                                                    setPassState(false)
                                                }
                                                : () => {
                                                    form.password.config.type='text'
                                                    setPassState(true)
                                                }}
                                                >{passState 
                                                    ? <Icon fontSize='large'>visibility_icon</Icon>
                                                    : <Icon fontSize='large'>visibility_off_icon</Icon>}</IconButton>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item>
                                            <div className={classes.link} onClick={()=>onUpdateModal({['recover_password']:true})} >{content.forgotten_password}</div>
                                        </Grid>
                                    </Grid>
                                    <div style={{marginTop:40}}>
                                        <Button fullWidth className={classes.button} onClick={() => {actions.onSubmit(false)}}>
                                            {`${content.login_button} `}{/*<Typography variant='body1' style={{marginLeft:8, fontSize:16}}>(Próximamente)</Typography>*/}
                                            {system.sending ? <CircularProgress size={32} style={{marginLeft:12, color:'white'}}/> : null}
                                        </Button>
                                        {system.error ? <Typography style={{marginTop:16}} align='center' color='error' variant='subtitle2'>{system.error}</Typography> : null}
                                    </div>
                                    <div style={{marginTop:40}}>
                                        <Divider/>
                                    </div>
                                    <div style={{marginTop:40}}>
                                        <GoogleButton actions={actions} />
                                    </div>
                                    <div style={{marginTop:40}}>
                                        <FacebookButton actions={actions} />
                                    </div>
                                    <div style={{marginTop:40}}>
                                        <Typography align='center'>{content.noaccount}</Typography>
                                        <div className={classes.link}  onClick={() => history.push('/signup')}
                                        style={{marginTop:8, textAlign:'center', textDecoration:'none'}}>
                                            {content.joinus}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            <Footer content={content.footer} history={history} />
            <div className={classes.overlay}/>
            <div className={classes.imageContainer}>
                <img src={content.banner} className={classes.image}/>
            </div>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        position:'relative',
        fontFamily:theme.typography.fontFamily,
        position:'relative'
    },
    imageContainer:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        height:'100vh',
        background:'#EEE',
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    overlay:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        height:'100vh',
        zIndex:3,
        background: alpha('#000',0.25),
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    container:{
        width:1400,
        margin:'auto',
        minHeight:'100vh',
        //background:'red',
        boxSizing:'border-box',
        [theme.breakpoints.only('lg')]:{
            width:1200,
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            padding:'0px 18px'
            //padding:'12px 0px',
            //height:300
        },
    },
    left_container:{
        position:'relative',
        padding:'24px 60px',
        paddingLeft:24,
        paddingRight:100,
        //height:'100vh',
        //background:"#DDD"
        [theme.breakpoints.down('md')]:{
            paddingRight:24
        },
    },
    content_data:{
        marginTop:100,
    },
    link:{
        color:theme.palette.primary.main,
        fontWeight:600,
        textDecoration:`underline ${theme.palette.primary.main}`,
        cursor:'pointer'

    },
    button:{
        fontSize:20,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
        '&:disabled':{
            color:'white',
            opacity:0.5
        }
    },
    form:{
        margin:'40px 0px'
    },
    show_password_button:{
        color:theme.palette.primary.main,
        '&:hover':{
            background:'none'
        },
        paddingTop:9
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language,
        modals:state.modals
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateModal: (modal) => dispatch({type:actionTypes.SYS_UPDATE_MODAL, modal  }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInLayout);