import React from 'react'
import { Grid, Typography, Avatar, IconButton, Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_SETTINGS } from '../../../config'
import { isValidDocument, onGetFullResourcePath } from '../../../shared/utility'
import image_profile from '../../../assets/nouser.png'
import ProfileRating from '../../../components/Platform/ProfileRating'
import moment from 'moment-timezone'
import RoundedButton from '../../../components/Actions/RoundedButton'


const ReviewCard = ({review, master_actions, actions, system}) => {

    const { classes } = useStyles()

    let isValidProfileImg = isValidDocument(review ? review.user_image : null)
    let profileSrc = isValidProfileImg ? onGetFullResourcePath(review.user_image) : image_profile

    const average_rating = {
        general_satisfaction: review.general_satisfaction,
        customer_support: review.customer_support,
        price_quality: review.price_quality,
        professionalism: review.professionalism,
        response_time: review.response_time,
    }

    const _date = moment(moment.utc(review.date).format()).format('LLL')

  return (
      <Grid container xs={12} className={classes.option}>
          <Grid item md={1} xs={3}>
              <Avatar src={profileSrc} className={classes.avatar}/>
          </Grid>
          <Grid container xs={8} className={classes.infoUser}>
              <Grid container xs={12}>
                  <Typography variant='subtitle1'>{review.user_first_name}</Typography>
              </Grid>

              <Grid xs={12}>
                  <ProfileRating average_rating={average_rating}/>
              </Grid>

          </Grid>
          <Grid md={2} xs={12} className={classes.approveButton}>
              <RoundedButton size='small' onClick={() => {
                  actions.onApproveReview(review.id_review)
                  master_actions.onUpdateData()
              }}>Aprobar</RoundedButton>
          </Grid>

          <Grid item xs={12} className={classes.date}>
              <Typography variant='caption' color='textSecondary'>{_date}</Typography>
          </Grid>

          <Grid xs={11}>
              <Typography>{review.comment}</Typography>
          </Grid>

          <Grid xs={1} className={classes.deleteButton}>
              <IconButton
                  onClick={() => {
                      master_actions.onUpdateModalStatus('delete_review', true, review.id_review)
                  }}
                  size="large"><Icon>delete_icon</Icon></IconButton>
          </Grid>

      </Grid>
  );
}

export default ReviewCard

const useStyles = makeStyles()(theme => ({
    option:{
        background:'white',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        padding: '20px 20px',
        borderRadius: '15px',
        marginTop:'10px',
        alignItems:'center'
    },
    infoUser:{
        marginLeft:'10px'
    },
    avatar:{
        width:'100%', height:'100%',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
    },
    date:{
        marginTop:'5px'
    },
    approveButton:{
        marginLeft:'48px',
        [theme.breakpoints.down('md')]:{
            marginTop:'10px',
            marginLeft:'85px',
        }
    },
    deleteButton:{
        [theme.breakpoints.down('md')]:{
            position:'relative',
            top:'-145px', right:'10px'
        }
    }
}));