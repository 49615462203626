import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Redes sociales',
    message:'Por favor ingrese los datos solicitados para agregar el contenido',
    add_button:'Agregar',
    edit_button:'Actualizar',
    form:{
        instagram_link:{placeholder:'URL de usuario', label:'URL de usuario', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.instagram.com/example'"},
        facebook_link:{placeholder:'URL de usuario', label:'URL de usuario', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.facebook.com/example'"},
        tiktok_link:{placeholder:'URL de usuario', label:'URL de usuario', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.tiktok.com/@example'"},
        linkedin_link:{placeholder:'URL de usuario', label:'URL de usuario', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.linkedin.com/in/example'"},
        twitter_link:{placeholder:'URL de usuario', label:'URL de usuario', helperText:"El campo debe ser un link con una forma similar a esta: 'https://twitter.com/example/'"},
        youtube_link:{placeholder:'URL de canal', label:'URL de canal', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.youtube.com/@example'"},
        twitch_link:{placeholder:'URL de usuario', label:'URL de usuario', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.twitch.tv/example'"},
        podcasts_link:{placeholder:'URL de canal', label:'URL de canal', helperText:"El campo debe ser un link con una forma similar a esta: 'https://podcasts.apple.com/us/podcast/example/id0123456789'"},
        pinterest_link:{placeholder:'URL de usuario', label:'URL de usuario', helperText:"El campo debe ser un link con una forma similar a esta: 'https://pinterest.com/example/'"},
        snapchat_link:{placeholder:'URL de perfil', label:'URL de perfil', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.snapchat.com/add/example'"},
        spotify_link:{placeholder:'URL de canal', label:'URL de canal', helperText:"El campo debe ser un link con una forma similar a esta: 'https://open.spotify.com/artist/example'"},
        reddit_link:{placeholder:'URL de perfil', label:'URL de perfil', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.reddit.com/user/example/'"},
        only_fans_link:{placeholder:'URL de perfil', label:'URL de perfil', helperText:"El campo debe ser un link con una forma similar a esta: 'https://onlyfans.com/example'"},
        tumblr_link:{placeholder:'URL de perfil', label:'URL de perfil', helperText:"El campo debe ser un link con una forma similar a esta: 'https://www.tumblr.com/example'"},
        sher_link:{placeholder:'URL de perfil', label:'URL de perfil', helperText:"El campo debe ser un link con una forma similar a esta: 'https://sher.com/example/'"},
        be_real_link:{placeholder:'URL de perfil', label:'URL de perfil', helperText:"El campo debe ser un link con una forma similar a esta: 'https://bere.al/Example'"},
    },
    nopicture:nopicture
}

export default translations