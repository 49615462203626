import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Descripción',
    message:'Por favor ingrese los datos solicitados para agregar el contenido',
    add_button:'Agregar',
    edit_button:'Actualizar',
    form:{
        description:{placeholder:'Descripción', label:'Descripción', helperText:'Se aceptan máximo 250 caracteres'},
    },
    nopicture:nopicture
}

export default translations