//import nopicture from '../../assets/image_placeholder.png'
import logo from '../../assets/logo.png'
import logo_influencers from '../../assets/dealhunter/logo_influencers.png'
import banner_influencers from '../../assets/dealhunter/banner_influencers.png'
import logo_brands from '../../assets/dealhunter/logo_brands.png'
import banner_brands from '../../assets/dealhunter/banner_brands.png'
import dealhunter_img from '../../assets/dealhunter/dealhunter.png'
import blog_img from '../../assets/dealhunter/blog.png'



const translations = {
    logo:logo,
    manager:{
        title:'PARA PERFIL MANAGERS E INFLUENCERS',
        paragraphs:[
            'Te presentamos el Dealhunter de InPager, una prospección automática de tu perfil ante toda clase de marcas. Desde locales y nacionales hasta internacionales. ',
            'Sabemos bien que hay un billón de campañas sucediendo todos los días y sobre todo hay marcas detrás de ellas que buscan influencers y creadores nuevos con quienes colaborar. Y por otro lado, sabemos que la prospección activa, es decir, el estar presentando tu perfil a marcas, puede ser algo complicado, y es por eso que ahora InPager lo hace por ti. ',
            'Al obtener tu membresía Premium o Professional, el Dealhunter enviará tu perfil a todas las marcas que estén buscando influencers similares a ti. ',
            'Semanalmente recibirás un correo electrónico donde te diremos con cuantas marcas hiciste match.',
            'Estamos seguros de que muy pronto estarás conectando con las mejores marcas y agencias.',
        ],
        button:'Ir a Dealhunter',

        //nueva vista
        subtitle1:'Te presentamos el',
        subtitle2:'de InPager, una prospección automática de tu perfil ante toda clase de marcas. Desde locales y nacionales hasta internacionales. ',
        how_it_works:{
            title:'¿Cómo funciona?',
            paragraphs1:[
                'Sabemos bien que hay un billón de campañas sucediendo todos los días y sobre todo hay marcas detrás de ellas que buscan influencers y creadores nuevos con quienes colaborar. Y por otro lado, sabemos que la prospección activa, es decir, el estar presentando tu perfil a marcas, puede ser algo complicado, y es por eso que ahora InPager lo hace por ti. ',
                'Al obtener tu membresía Premium o Professional, el Dealhunter enviará tu perfil a todas las marcas que estén buscando influencers similares a ti. ',
            ],
            paragraphs2:[
                'Semanalmente recibirás un correo electrónico donde te diremos con cuantas marcas hiciste match.',
                'Estamos seguros de que muy pronto estarás conectando con las mejores marcas y agencias.',
            ],
        },
        what_get:{
            title:'¿Qué vas a obtener?',
            dealhunter:{
                title:'Dealhunter',
                text:'Encuentra el match perfecto entre influencers, marcas y managers para llevar tus colaboraciones al siguiente nivel'
            },
            blog:{
                title:'InPager Blog',
                text:'El newsletter semanal que contiene la información más relevante de la industria del influencer marketing.'
            },
        },

    },
    agency:{
        title:'PARA MARCAS Y AGENCIAS',
        paragraphs1:[
            'Te presentamos el Dealhunter de InPager, la búsqueda automática de influencers y creadores de contenido con quienes colaborar en tus campañas.',
            'Sabemos bien que hay un millón de influencers nuevos todos los días y sobre todo lo tardado que puede resultar el conocerlos a todos. Y por otro lado, sabemos que hay muchos influencers que buscan activamente colaborar con marcas como la tuya, sin embargo, puede ser complicado lograr ese match perfecto entre lo que tu buscas y lo que el influencer quisiera. Y es por eso que ahora InPager lo hace por ti. ',
            'Al obtener tu membresía de Marca o Agencia, el Dealhunter de InPager buscará influencers exactamente como tú lo necesitas, solo tienes que llenar el siguiente formulario donde nos indiques las características a considerar para buscar al influencer y ¡listo!',
        ],
        paragraphs2:[
            'Semanalmente recibirás un correo electrónico donde te mostraremos 4 perfiles que hagan match perfecto con lo que buscas. ',
            'Estamos seguros de que muy pronto estarás conectando con los mejores influencers y creadores de contenido. ',
        ],
        button:'Enviar datos'
    },

    //new
    influencers:{
        logo:logo_influencers,
        banner:banner_influencers,
        button:'Ir a Dealhunter',
        subtitle1:'Te presentamos el',
        subtitle2:' de ',
        subtitle3:', una prospección automática de tu perfil ante toda clase de marcas. Desde locales y nacionales hasta internacionales. ',
        how_it_works:{
            title:'¿Cómo funciona?',
            paragraphs1:[
                'Sabemos bien que hay un billón de campañas sucediendo todos los días y sobre todo hay marcas detrás de ellas que buscan influencers y creadores nuevos con quienes colaborar. ',
                'Y por otro lado, sabemos que la prospección activa, es decir, el estar presentando tu perfil a marcas, puede ser algo complicado, y es por eso que ahora InPager lo hace por ti. '
                //'Al obtener tu membresía Premium o Professional, el Dealhunter enviará tu perfil a todas las marcas que estén buscando influencers similares a ti. ',
            ],
            paragraphs2:[
                'Semanalmente recibirás un correo electrónico donde te diremos con cuantas marcas hiciste match.',
                'Estamos seguros de que muy pronto estarás conectando con las mejores marcas y agencias.',
            ],
        },
        what_get:{
            title:'¿Qué vas a obtener?',
            dealhunter:{
                title:'Dealhunter',
                text:'Encuentra el match perfecto entre influencers, marcas y managers para llevar tus colaboraciones al siguiente nivel',
                img:dealhunter_img,
            },
            blog:{
                title:'InPager Blog',
                text:'El newsletter semanal que contiene la información más relevante de la industria del influencer marketing.',
                img:blog_img
            },
        },
        //new design
        section2:{
            title:'Hacemos que las marcas te encuentren',
            text1:'Al suscribirte a ',
            text2:', el',
            text3:'enviará tu perfil a todas las marcas que estén buscando influencers similares a ti.',
            button:'¡Unéte ahora!'
        },
        section3:{
            text1:'¡Estamos seguros que muy pronto estarás',
            strong1:'conectado',
            text2:'con las mejores',
            strong2:'marcas y agencias!',
        },
        section4:{
            title:'¡A cerrar esas colaboraciones!',
            text1:'Da click en el botón a continuación para crear tu cuenta en ',
            text2:' y logra que tu perfil empiece a ser compartido ante cientos de marcas y empresas con ayuda de',
            button:'¡Unéte ahora!'
        },
    },
    brands:{
        logo:logo_brands,
        banner:banner_brands,
        button:'Ir a Dealhunter',
        subtitle1:'Te presentamos ',
        subtitle2:' de ',
        subtitle3:', la búsqueda automática de influencers y creadores de contenido con quienes colaborar en tus campañas.',
        how_it_works:{
            title:'¿Cómo funciona?',
            paragraphs1:[
                'Sabemos bien que hay un millón de influencers nuevos todos los días y sobre todo lo tardado que puede resultar el conocerlos a todos. ',
                'Y por otro lado, sabemos que hay muchos influencers que buscan activamente colaborar con marcas como la tuya, sin embargo, puede ser complicado lograr ese match perfecto entre lo que tu buscas y lo que el influencer quisiera. ',
                'Y es por eso que ahora InPager lo hace por ti. ',
                //'Al obtener tu membresía de Marca o Agencia, el Dealhunter de InPager buscará influencers exactamente como tú lo necesitas, solo tienes que llenar el siguiente formulario donde nos indiques las características a considerar para buscar al influencer y ¡listo!',
            ],
            paragraphs2:[
                'Semanalmente recibirás un correo electrónico donde te mostraremos 4 perfiles que hagan match perfecto con lo que buscas. ',
                'Estamos seguros de que muy pronto estarás conectando con los mejores influencers y creadores de contenido. ',
            ],
        },
        what_get:{
            title:'¿Qué vas a obtener?',
            dealhunter:{
                title:'Dealhunter',
                text:'Encuentra el match perfecto entre influencers, marcas y managers para llevar tus colaboraciones al siguiente nivel',
                img:dealhunter_img,
            },
            blog:{
                title:'InPager Blog',
                text:'El newsletter semanal que contiene la información más relevante de la industria del influencer marketing.',
                img:blog_img
            },
        },
        //new design
        section2:{
            title:'Buscamos influencers para tus campañas',
            text1:'Al obtener tu membresía de marca o agencia, el ',
            text2:' de ',
            text3:'buscará influencers exactamente como tu los solicites y te haremos llegar sus perfiles todas las semanas directo en tu bandeja de correo electrónico.',
            button:'¡Unéte ahora!'
        },
        section3:{
            text1:'¡Estamos seguros de que muy pronto estarás',
            strong1:'conectando',
            text2:'con los ',
            strong2:'influencers',
            text3:' ideales para tu próxima',
            strong3:'campaña!'
        },
        section4:{
            title:'¡A cerrar esas colaboraciones!',
            text1:'Da click en el botón a continuación para crearle una cuenta en ',
            text2:' a tu marca y deja que ',
            text3:' se haga cargo de buscar y encontrar por ti a el o los influencers adecuados para tu siguiente campaña exitosa.',
            button:'¡Unéte ahora!'
        },
    }
    
    
}

export default translations