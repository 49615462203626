import { Grid } from '@mui/material';
import { blue, green, pink } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import AnalyticsProgressBar from '../../../../../../components/Platform/AnalyticsProgressBar';
import NoAnalyticsMessage from '../../../../../../components/Platform/Placeholder/NoAnalyticsMesage';

const FacebookCountryChart = ({data}) => {

    const [chartdata, setChartData] = useState([])  
    
    const [total, setTotal] = useState(0)
    const [data5, setData5] = useState([])

    useEffect(() => {
        if(data){
            let temp = (typeof data === 'object') ? data : JSON.parse(data)
            let _chartdata = []
            Object.keys(temp).map(item => {
                _chartdata.push({
                    name:item,
                    qty:parseInt(temp[item])
                })
            })
            
            _chartdata.sort((a,b) => {
                if(a.qty > b.qty) return -1
                if(a.qty < b.qty) return 1
                return 0
            })
            setChartData(_chartdata)

            const _total =  _chartdata.map(item => item.qty).reduce((prev, curr) => prev + curr, 0);
            setTotal(_total)
            let _data5 = [..._chartdata]
            _data5.splice(5)
            setData5(_data5)
        }
    }, [data])

    return ( 
        <div style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
            {data && Object.entries(data).length > 0 ? 
                <Grid container spacing={2}>
                    {data5.map( (item, key) => {
                        return(
                            <Grid key={`progress-bar-${key}`} item xs={12}>
                                <AnalyticsProgressBar label={item.name} units={item.qty} total={total} />
                            </Grid>
                        )
                    })}
                </Grid>: <NoAnalyticsMessage />}
        </div>
     );
}
 
export default FacebookCountryChart;

const basedata = [
    {
        name:'13-17',
    UF:0,
        M:0,
        U:0
    },
    {
        name:'18-24',
        F:0,
        M:0,
        U:0
    },
    {
        name:'25-34',
        F:0,
        M:0,
        U:0
    },
    {
        name:'35-44',
        F:0,
        M:0,
        U:0
    },
    {
        name:'45-54',
        F:0,
        M:0,
        U:0
    },
    {
        name:'55-64',
        F:0,
        M:0,
        U:0
    },
    {
        name:'65+',
        F:0,
        M:0,
        U:0
    },
]