import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import { DASHBOARD_SETTINGS } from '../../../../config';


const useStyles = makeStyles()(theme => ({
    root:{
        paddingTop:0,
        background:'#FCFCFC',  
        [theme.breakpoints.down('md')]:{
            padding:'50px 0px 0px'
        }
    },
    positionerInfo:{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        //background:'red',
        //boxSizing:'border-box'
    },
    infoContainer:{
        textAlign:'center',
        padding:'100px 40px',
        paddingBottom:32,
        [theme.breakpoints.down('md')]:{
            padding:'100px 32px',
            boxSizing:'border-box'
        }
        
        //background:'green'
    },
    infoSubcontainer:{
        width:'100%', 
        display:'flex', 
        justifyContent:'center',
        [theme.breakpoints.down('md')]:{
            display:'inline'
        }
    },
    video_master:{
        background:'black'
    },
    video_container:{
        width:1200,
        height:600,
        margin:'auto',
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            height:450,
        }
    }
}));

export default useStyles;