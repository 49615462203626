
const translations = {
    title:'Editar descripción',
    submit_button:'Guardar',
    description:'En esta sección usted puede actualizar su información',
    form:{
        description:{placeholder:'Descripción', label:'', helperText:'Campo requerido'},
    },
}

export default translations