import banner from '../../assets/podcast/banner.png'
import social_trenders_logo from '../../assets/podcast/social_trenders_logo.png'
import instagram_icon from '../../assets/socialmedia/icons/instagram_icon.png'
import twitter_icon from '../../assets/socialmedia/icons/twitter_icon.png'
import spotify_icon from '../../assets/socialmedia/icons/spotify_icon.png'
import youtube_icon from '../../assets/socialmedia/icons/youtube_icon.png'
import facebook_icon from '../../assets/socialmedia/icons/facebook_icon.png'

const general_translations = {
    subtitle: 'Un podcast especializado en la Economía del Creador',
    title: 'Aprende sobre el behind the scenes de la industria a través de pláticas con creadores de contenido, managers de influencers, marcas y agencias de influencer marketing, productores de conenido y plataformas de redes sociales.',
    banner: banner,
    social_trenders_logo: social_trenders_logo,
    creator_links: 'Conoce más sobre el invitado:',
    footer: ['Este es un podcast creado por InPager, la herramienta que está profesionalizando la industria de los influencers. ', '@inpager_', 'Host: Marce Ayala, especialista en Influencer Marketing ', '@marceayalac'],
    follow_us: 'Síguenos en nuestras cuentas:',
    links: ['Instagram:', '@socialtrenders_', 'Tiktok: ', ' @socialtrenders'],
    form: {
        title: { placeholder: 'Titulo', helperText: 'Campo requerido' },
        number: { helperText: 'El campo debe ser un numero unico mayor que 1' },
        creator: { placeholder: 'Nombre de los creadores en el episodio', helperText: 'El campo debe tener al menos un caracter' },
        description: { placeholder: 'Descripción', helperText: 'El campo debe tener al menos un caracter' },
        youtube_url: { placeholder: 'https://youtu.be/Q00Lrk6VheA', helperText: 'Puede ser el url completo como tan solo el id' },
        spotify_url: { placeholder: 'https://youtu.be/Q00Lrk6VheA', helperText: 'Debe ser el url completo' },
        apple_url: { placeholder: 'https://youtu.be/Q00Lrk6VheA', helperText: 'Debe ser el url completo' },
        cratorLinks: { placeholder: '', helperText: 'Debe ser el url completo'}
    },
    icons: {
        instagram: instagram_icon,
        twitter: twitter_icon,
        spotify: spotify_icon,
        youtube: youtube_icon,
        facebook: facebook_icon
    }
}

export default general_translations