import { useState, useEffect } from 'react';
import { public_server } from "../config"

const useUserPublicFilters = () => {
    const [filters, setFilters] = useState({});

    const getFilterOption = async () => {
        const category_request = await public_server.get("/category/all/ns");
        const _categories = category_request.data.data.categories;

        const type_request = await public_server.get("/typefigure/all/ns");
        const _types = type_request.data.data.types;


        setFilters({
            'categories': _categories.map((e) => ({ id: e.id_category, name: e.name })),
            'types': _types.map((e) => ({ id: e.id_influencer_type, name: e.name })),
        });


    }

    useEffect(() => {
        try {
            getFilterOption();
        }
        catch (error) {
            console.error(error);
        }
    }, [])

    return { filters }
}

export default useUserPublicFilters;