const translations = {
    title:'Categorías',
    message:'Seleccione hasta 3 categorías para agregar a su cuenta',
    add_button:'Actualizar',
    edit_button:'Guardar',
    form:{
        title:{placeholder:'Título de la publicación', label:'Título de la publicación', helperText:'Campo requerido'},
        date:{placeholder:'Fecha de publicación', label:'Fecha de publicación', helperText:'Campo requerido'},
        link:{placeholder:'URL del sitio', label:'URL del sitio', helperText:''},
        description:{placeholder:'Resumen de la nota', label:'Resumen de la nota', helperText:'Campo requerido'},
    },
}

export default translations