import React from 'react'
import {
    Breadcrumbs,
    Link,
    Icon,
    Grid,
    CircularProgress,
    IconButton,
    Snackbar,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { blueGrey, green } from '@mui/material/colors'
import { withRouter } from 'react-router-dom'
import ErrorModal from '../../Modals/ErrorModal'
import SectionContainer from './SectionContainer'

const useStyles = makeStyles()(theme => ({
    root:{
        width:800,
        //border:'1px solid black',
        margin:'auto',
        marginTop:'40px',
        boxSizing:'border-box', 
        minHeight:'calc(100vh - 76px)'    ,
        //background:'red',
        padding:'20px 0px',
        [theme.breakpoints.only('md')]:{
            width:800
        },
        [theme.breakpoints.down('md')]:{
            width:'100%'
        }

    },
    container:{
        //paddingTop:'40px',
        //width:1100,
        //margin:'auto',
        boxSizing:'border-box',
        [theme.breakpoints.down('lg')]:{
            //paddingTop:16
        }
    },
    link:{
        color:blueGrey[500],
        fontWeight:400,
        '&:hover':{
            cursor:'pointer'
        }
    },
    selectedLink:{
        color:blueGrey[700],
        fontWeight:500
    },
    icon:{
        color:blueGrey[500],
        paddingTop:4,
        
    },
    loading:{
        height:`calc(100vh - 400px)`
    },
    success:{
        background:green[700]
    },
    sending:{
        background:theme.palette.primary.main
    },
}));

const Page = props => {

    const { classes, cx } = useStyles()
    const {children, breadcrumbs, loading, sending, nopadding, error, onCloseErrorModal, success, onCloseSuccess} = props

    const onSelectedLink = (url) => {
        if(url) props.history.push(url)
    }

    let breadcrumbsContent = null
    if(breadcrumbs){
        breadcrumbsContent = (
            <Grid item xs={12}>
                <Breadcrumbs>
                    <Link className={classes.link} onClick={() => onSelectedLink('/')}
                    ><Icon className={classes.icon}>home</Icon></Link>
                    {breadcrumbs.map((item,key)=>{
                        return (
                            <Link key={key.toString()} 
                            onClick={() => onSelectedLink(item.url)}
                            className={cx({
                                [classes.link]:true,
                                [classes.selectedLink]:item.selected
                            })}>
                                {item.label}
                            </Link>
                        );
                    })}
                </Breadcrumbs>
            </Grid>
        )
    }

    let contentView = children

    if(loading){
        contentView = (
            <Grid container justifyContent='center'>
                <Grid item>
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                    
                </Grid>
            </Grid>
        )
    }

    const sendingContent = <Snackbar  ContentProps={{className:classes.sending}} open={Boolean(sending)} 
    message={<Grid container alignItems='center' spacing={1}>
        
        <Grid item><Typography style={{color:'white'}}>Actualizando...</Typography></Grid>
        <Grid item><CircularProgress size={18} style={{color:'white'}}/></Grid>
    </Grid>}  anchorOrigin={{vertical:'bottom', horizontal:'left'}}/>

    const successContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.success}} open={Boolean(success)} onClose={onCloseSuccess}  autoHideDuration={1500} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><Typography style={{color:'white'}}>{success}</Typography></Grid>
    </Grid>}/>

    return(
        <div className={classes.root} >
            <ErrorModal open={Boolean(error)} message={error} onClose={onCloseErrorModal}/>
            <div className={classes.container}>
                {successContent}
                {sendingContent}
                {breadcrumbsContent}
                {contentView}
            </div>         
        </div>
    )
}

export default withRouter(Page)