import { Grid, Icon, IconButton } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { useEffect } from "react"
import logo from '../../assets/logo.png'

const TermsAndConditions = ({history}) => {

    const { classes } = useStyles()

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className={classes.root}>
            <div className={classes.logo_container}>
                <Grid container spacing={1} alignItems='center' >
                    <Grid item>
                        <IconButton onClick={()=>history.goBack()} size="large"><Icon>arrow_back_ios</Icon></IconButton>
                    </Grid>
                    <Grid item>
                        <img src={logo} width={300} />
                    </Grid>
                </Grid> 
            </div>
            <div className={classes.subroot}>
                <p style={{textAlign:'center', marginBottom:40}} ><strong>TÉRMINOS Y CONDICIONES</strong></p>
                <p>
                    <strong>
                    ¡Te damos la bienvenida a INPAGER.APP! Para poder brindarte una mejor experiencia, 
                    te invitamos a leer estos Términos y Condiciones que rigen el uso de nuestro sitio web, 
                    así como los servicios brindados a través de este.
                    </strong>
                </p>
                <p>
                    <strong>
                    Al hacer clic en crear cuenta u otro enlace similar, al registrarte, acceder o utilizar nuestros 
                    servicios, los cuales se describirán más adelante, aceptas que has leído y comprendido estos 
                    Términos y Condiciones, por lo cual consientes que el acuerdo que se celebra con 
                    GRUPO CASTAYA, S.A. DE C.V., (en lo sucesivo y para efectos del presente instrumento referido como 
                    “InPager”)
                    </strong>
                </p>
                <p className={classes.subtitle}><strong>I. Información de “InPager”:</strong></p>
                <p>
                    Este sitio web es propiedad de GRUPO CASTAYA, S.A. DE C.V., (en el presente documento
                    referido como “InPager”), y es puesto a tu disposición de manera gratuita en
                    HTTP://WWW.INPAGER.APP (en adelante referido como “WWW.INPAGER.APP”). El domicilio de
                    físico de “InPager”, se encuentra ubicado en Av. Pedro Ramirez Vazquez 200-11 INT A, Col. Valle
                    Oriente, Monterrey, Nuevo León. CP 66260. Cualquier notificación legal deberá ser realizada en
                    el mismo.
                </p>
                <p>
                    “InPager” podrá únicamente ser utilizado como la herramienta clave para profesionalizar la
                    industria del influencer marketing a nivel internacional.
                </p>
                <p className={classes.subtitle}><strong>II. Información y uso de INPAGER.APP:</strong></p>
                <p>
                    En el momento que ingresas a INPAGER.APP, o a través de cualquier plataforma, aplicación o
                    herramienta tecnológica que sea proveída, aceptas los Términos y Condiciones aquí
                    establecidos, así como los demás anexos relacionados y referenciados en los presente Términos
                    y Condiciones; por lo que, en caso de que no estés de acuerdo con alguno de ellos, deberás de
                    abstenerte de utilizar los mismos.
                </p>
                <p>
                    Con la finalidad de poder acceder a nuestros servicios en línea a través de INPAGER.APP, es
                    necesario darte de alta de manera electrónica y crear una cuenta (en adelante como la
                    “Cuenta”); llenando un formulario de registro, en el cual se te solicitará:
                </p>
                <p className={classes.list_container} >
                    <ol type="I">
                        <li value='I' className={classes.list} >Nombre completo,</li>
                        <li value='II' className={classes.list}>Fecha de nacimiento,</li>
                        <li value='III' className={classes.list}>Correo electrónico,</li>
                        <li value='IV' className={classes.list}>Número de teléfono,</li>
                        <li value='V' className={classes.list} >Domicilio,</li>
                        <li value='VI' className={classes.list}>Genero,</li>
                        <li value='VII' className={classes.list}>Tipo de usuario (influencer, manager, marca, agencia),</li>
                        <li value='VIII' className={classes.list}>Contraseña,</li>
                    </ol>
                </p>
                <p>
                    Cuando te registras y te unes al Servicio de “InPager”, te conviertes en un Miembro y al
                    momento de registrarte, certificas bajo protesta de decir verdad que los datos que proporcionas
                    e ingresas son correctos, válidos, legítimos y que te pertenecen.
                </p>
                <p>
                    El uso de tu cuenta y contraseña es responsabilidad exclusiva tuya, por lo que te obligas a
                    mantener la confidencialidad al respecto, y no revelar dicha información. “InPager” no es
                    responsable de la utilización de tu cuenta y/o contraseña, por lo que se deslinda de cualquier
                    actividad realizada a través de INPAGER.APP de manera irregular. En caso de que consideres que
                    tu cuenta y/o contraseña han sido utilizados de manera incorrecta, o detectes alguna actividad
                    sospechosa, te pedimos te comuniques de inmediato con nosotros, a través del número de
                    teléfono: +52 1 55 8282 8955 o al correo electrónico hola@inpager.app..
                </p>
                <p>
                    En “InPager” cuidamos en todo momento la seguridad de los Datos Personales que nos
                    proporcionas; por ello, los Datos Personales obtenidos a través de INPAGER.APP son utilizados
                    para crear tu usuario, brindar un mejor servicio, mejorar tu experiencia en la plataforma. Para
                    obtener más información de cómo obtenemos, utilizamos, almacenamos, y en general,
                    tratamos tus datos personales, te pedimos consultar nuestro Aviso de Privacidad que se
                    encuentra disponible en el siguiente <a href='https://inpager.app/terms-and-conditions'>enlace</a>. En caso de que desees limitar el uso de tus datos
                    personales, te pedimos que revises el Aviso de Privacidad antes mencionado.
                </p>
                <p>
                    “InPager” en cualquier momento podrá modificar estos Términos y Condiciones, por lo que te
                    invitamos a consultarlos de manera periódica a través de este medio, para conocer las
                    actualizaciones del mismo.
                </p>
                <p>
                    El contenido de INPAGER.APP y los servicios que se ofrecen en el mismo están dirigidos a un
                    público mayor de 18 años, por lo que es responsabilidad de los padres y tutores de menores de
                    edad revisar y supervisar la conducta de dichos menores de edad.
                </p>
                <p>
                    El uso de INPAGER.APP es única y exclusivamente para que, puedas consultar y, en su caso,
                    contactar con los diferentes perfiles y usuarios disponibles; de manera personal, por lo tanto,
                    cualquier otro uso no relacionado de manera directa con el anterior, se considerará como una
                    violación a estos Términos y Condiciones. En caso de que “InPager” detecte que el Usuario está
                    haciendo mal uso de su cuenta en INPAGER.APP “InPager” se reserva el derecho de dar de baja,
                    suspender, cancelar y/o inhabilitar dicha cuenta, e incluso tomar las acciones legales que considere
                    adecuadas para garantizar el correcto funcionamiento de INPAGER.APP, sin necesidad de dar aviso
                    previo o notificar con anticipación al Usuario y/o Miembro.
                </p>
                <p className={classes.subtitle}><strong>III. Como Usuario y/o Miembro de INPAGER.APP te obligas a:</strong></p>
                <p className={classes.list_container}>
                    <ol type="I">
                        <li  className={classes.list}>
                            Cumplir todas las leyes pertinentes, incluidas, entre otras, las leyes de privacidad, las
                            leyes de propiedad intelectual, las leyes de control de las exportaciones, las leyes en
                            materia fiscal y tributaria, y otros requisitos regulatorios;
                        </li>
                        <li  className={classes.list}>
                            Proporcionar a “InPager” información exacta y mantenerla actualizada;
                        </li>
                        <li  className={classes.list}>
                            Usar tu nombre verdadero en el perfil (incluyendo nombres artísticos, marcas);
                        </li>
                        <li  className={classes.list}>
                            Usar los Servicios de una manera profesional y honesta;
                        </li> 
                        <li  className={classes.list}>
                            Hacer uso de INPAGER.APP de conformidad con estos Términos y Condiciones, y demás
                            anexos que se relacionen y se referencien el dicho Términos y Condiciones;
                        </li>
                        <li  className={classes.list}>
                            Los Miembros son los titulares de las cuentas: Aceptas: (1) Utilizar una contraseña
                            segura y mantener la confidencialidad de esta, (2) No transferir ninguna parte de tu
                            cuenta y (3) respetar la legislación aplicable. Eres responsable de todo lo que suceda en
                            tu cuenta salvo que la cierres o que nos notifiques que se está haciendo un uso indebido
                            de la misma;
                        </li>
                        <li  className={classes.list}>
                            Tu eres propietario de tu cuenta en todo momento. Entre los demás y tú (incluida tu
                            empresa, agencia, representante artístico, manager), tu cuenta te pertenece.
                        </li>
                        <li  className={classes.list}>
                            No obstante, si los Servicios han sido adquiridos por terceros para que tú los utilices, es
                            decir, “Suscripción Manager” ese tercero que paga por dichos Servicios, es decir, el
                            “Administrador de Cuenta” tendrá derecho al acceso de manera limitada y a recibir
                            informes sobre el uso que realices del Servicio de pago; sin embargo el “Administrador
                            de Cuenta” no estará facultado para extraer tu información de otras cuentas y/o redes
                            sociales que utilices, así como, el “Administrador de Cuenta” no tendrá derechos sobre
                            tu cuenta personal;
                        </li> 
                        <li  className={classes.list}>
                            Es tu obligación, informar a “InPager” que tu relación con el “Administrador de Cuenta”
                            se ha terminado, para que el “Administrador de Cuenta”, deje de tener cualquier tipo de
                            acceso a tu cuenta e información.
                        </li>
                        <li  className={classes.list}>
                            Proporcionar información veraz, precisa y legítima al momento que te sea solicitada;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de proporcionar información de terceros sin que estés autorizado para ello;
                        </li>
                        <li  className={classes.list}>
                            Realizar operaciones legales y legítimas a través de INPAGER.APP;
                        </li>
                        <li  className={classes.list}>
                            Ser responsable de las transacciones llevadas a cabo a través de INPAGER.APP;
                        </li>
                        <li  className={classes.list}>
                            Una vez que nos proporciones tu cuenta de correo electrónico, conscientes que éste
                            será nuestro medio de comunicación, por lo cual cualquier notificación realizada por
                            este medio se entenderá como legalmente hecha. Te invitamos a revisar tu bandeja de
                            entrada y bandeja de correo no deseado para asegurar que recibas nuestra
                            comunicación;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de suplantar a personas físicas o morales, así como proporcionar
                            información de correos electrónicos, direcciones, formas de pago, y en general cualquier
                            dato que no corresponda a ti;
                        </li> 
                        <li  className={classes.list}>
                            Abstenerte de copiar, reenviar, reproducir, reutilizar, vender, transmitir, distribuir,
                            descargar, otorgar licencia, modificar, publicar o usar de alguna otra manera el contenido
                            de INPAGER.APP para fines públicos o comerciales;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de obtener o extraer, de manera directa o indirecta, cualquier información
                            contenida en INPAGER.APP;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de crear y utilizar una identidad falsa en “InPager”, falsificar tu identidad,
                            crear un perfil de Miembro para alguien que no seas tú (una persona física), ni usar o
                            intentar usar la cuenta de otra persona;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de desarrollar, apoyar o utilizar programas, dispositivos, scripts, robots o
                            cualquier otro medio o proceso (incluidos crawlers, plugins de navegación y
                            complementos, o cualquier otra tecnología) para plagiar los Servicios o copiar de otro
                            modo perfiles u otros datos de los Servicios;
                        </li> 
                        <li  className={classes.list}>
                            Abstenerte de eludir cualquier funcionalidad de seguridad o sortear cualquier control de
                            acceso o los límites de uso del Servicio (como límites en las búsquedas de palabras clave
                            o visualizaciones del perfil);
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de copiar, utilizar, divulgar o distribuir cualquier información obtenida de los
                            Servicios, ya sea de forma directa o a través de terceros (como los motores de
                            búsqueda), sin el consentimiento por escrito por parte de “InPager”;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de revelar información que no tienes derecho a compartir (como
                            Información Confidencial);
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de infringir los derechos de propiedad intelectual de terceros, como
                            derechos de autor, patentes, marcas, secretos comerciales u otros derechos de
                            propiedad;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de infringir la propiedad intelectual u otros derechos de “InPager”, incluido,
                            sin limitación, (i) copiar o distribuir nuestros vídeos de aprendizaje u otros materiales; o
                            (ii) copiar o distribuir nuestra tecnología, (iii) utilizar el término “InPager” o nuestros
                            logotipos en cualquier nombre comercial, email o URL;
                        </li> 
                        <li  className={classes.list}>
                            Abstenerte de publicar cualquier cosa que contenga virus informáticos, gusanos o
                            cualquier otro código dañino;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de utilizar técnicas de ingeniería inversa, descompilar, desarmar, descifrar o
                            de otro modo tratar de obtener el código fuente de los Servicios o de cualquier
                            tecnología relacionada;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de dar a entender o declarar que estás asociado o avalado por “InPager” sin
                            nuestro consentimiento expreso;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de alquilar, prestar, comercializar, vender, revender u obtener cualquier otro
                            tipo de beneficio de los Servicios o datos relacionados, ni acceder a los mismos sin el
                            consentimiento de “InPager”;
                        </li> 
                        <li  className={classes.list}>
                            Abstenerte de establecer enlaces a nuestros Servicios para cualquier otra finalidad que
                            la de promover tu perfil o un grupo en nuestros Servicios, sin el consentimiento de
                            “InPager”;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de utilizar bots u otros métodos automatizados para acceder a los Servicios,
                            para añadir o descargar contactos o para enviar o redirigir mensajes;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de controlar la disponibilidad, el rendimiento o el funcionamiento de los
                            Servicios con fines competitivos;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de llevar a cabo prácticas de «framing» o «mirroring», o similar de otro
                            modo la apariencia o función de los Servicios;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de superponer o modificar de cualquier otro modo los Servicios o su
                            apariencia;
                        </li>
                        <li  className={classes.list}>
                            Abstenerte de interferir en el funcionamiento o cargar los Servicios de manera poco
                            razonable;
                        </li>
                    </ol>
                </p>
                <p className={classes.subtitle}><strong>IV. Pago</strong></p>
                <p>
                    Si adquieres algunos de nuestros Servicios de Pago (“Suscripciones”), aceptas abonarnos las
                    tasas y los impuestos aplicables y cumplir con los términos específicos vinculados a dichos
                    Servicios de pago. El incumplimiento de pago en las fechas correspondientes puede dar lugar a
                    la suspensión temporal de los Servicios de pago que recibes, de igual manera, aceptas que:
                </p>
                <p>
                    Tu suscripción puede estar sujeta a comisiones de cambio o diferencias de precios en función de
                    tu ubicación (por ejemplo, tipos de cambio).
                </p>
                <p>
                    Nos otorgas autorización para almacenar y continuar utilizando tu forma de pago para fines de
                    facturación, incluso después de haber caducado, para evitar la interrupción de los Servicios y
                    utilizarla para pagar otros Servicios que puedas adquirir.
                </p>
                <p>
                    Podemos calcular los impuestos aplicables en función de la información de facturación que nos
                    proporciones en el momento de la compra.
                </p>
                <p className={classes.subtitle}><strong>V. Formas y Métodos de Pago</strong></p>
                <p>
                    Las formas de pago que puedes utilizar para tus compras realizadas a través de “InPager”, son
                    las siguientes:
                </p>
                <p className={classes.list_container} >
                    <ol type="I">
                        <li className={classes.list} >
                            Tarjeta de Crédito y/o Débito: son aceptadas todas las que se
                            encuentren respaldadas por VISA, Master Card o American Express.
                        </li>
                        <li className={classes.list}>
                            PAYPAL: podrás realizar tu pago a través de la plataforma electrónica
                            PAYPAL al momento de realizar tu Suscripción.
                        </li>
                        <li className={classes.list}>
                            MERCADO PAGO: podrá realizar tu pago através de la plataforma
                            electrónica MERCADO PAGO al momento de realziar tu Suscripción.
                        </li>
                    </ol>
                </p>
                <p>
                    Todos los pagos realizados a través de PAYPAL, MERCADO PAGO, así como los pagos
                    realizados a través de Tarjetas de Crédito, Débito son procesados a través de páginas de
                    terceros certificados contratados para tales efectos, así como a través de tecnologías de
                    terceros, por lo cual los datos financieros enviados a través de INPAGER.APP o en pago
                    con los métodos de señalados anteriormente, son tratados por una institución bancaria
                    o un tercero quien procesa dichas transacciones electrónicas por su cuenta. Por lo que,
                    al ser terceros certificados quienes procesan la información y los pagos realizados de
                    esta manera, y que quienes tratan dicha información son las instituciones bancarias o
                    algún tercero, “InPager” no será responsable del procesamiento y tratamiento que se le
                    dé a esta información.
                </p>
                <p>
                    Los mencionados terceros PAYPAL, Mercado Pago, así como los pagos realizados a través
                    de Tarjetas de Crédito, Débito tienen sus propias políticas, términos y condiciones, por
                    lo que recomendamos revisar sus TÉRMINOS LEGALES para entender la manera en
                    que tu información personal será manejada por estos 
                </p>
                <p>
                    También, es importante considerar que ciertos proveedores pueden estar ubicados o
                    tienen instalaciones en otro país, bajo una jurisdicción diferente a la de México. Por lo
                    tanto, si decides continuar con una transacción que involucra los servicios de un
                    tercero, tu información pudiera quedar sujeta a las leyes de la jurisdicción en la que se
                    encuentra ese proveedor.
                </p>
                <p className={classes.subtitle}><strong>VI. Links</strong></p>
                <p>
                    Dependiendo de la configuración de tu navegador de internet y dispositivo, al visitar nuestro
                    sitio web puedes estar expuesto a publicidad o enlaces a otros sitios. También, algunas partes
                    de nuestro sitio web pueden contener hipervínculos activos, que al dar clic en ellos podrían
                    redirigirte a otro sitio web. Cuando eres redirigido a otro sitio web o aplicación de tercero, es
                    importante que consideres que estos se rigen por políticas, términos y condiciones diferentes a
                    los nuestros, por lo que recomendamos revisar sus TÉRMINOS LEGALES, ya que nosotros no nos
                    hacemos responsables por el contenido o servicio que dichos sitios pudieran llegar a ofrecer.
                </p>
                <p className={classes.subtitle}><strong>VII. Compartir</strong></p>
                <p>
                    Nuestros Servicios permiten compartir información incluyendo información de contacto en tu
                    perfil. La información y el contenido que compartes o publiques podrán ser visibles, si se cuenta
                    con el URL del perfil de la Cuenta.
                </p>
                <p>
                    No estamos obligados a publicar información o contenido en nuestro Servicio y podemos
                    retirarlo sin previo aviso. 
                </p>
                <p className={classes.subtitle}><strong>VIII. Derechos y limitaciones</strong></p>
                <p>
                    Tú eres el propietario del contenido y de la información que proporciones o publiques
                    en los Servicios, y solo otorgas a “InPager”, la siguiente licencia no exclusiva:
                </p>
                <p>
                    Un derecho mundial, transferible y sujeto a sub-licencia para usar, copiar modificar,
                    distribuir, publicar, tratar la información y el contenido que nos proporciones a través
                    de nuestros Servicios y los servicios de terceros, sin ningún consentimiento adicional,
                    notificación o compensación
                </p>
                <p>
                    Acuerdas con “InPager” que podremos acceder, almacenar, procesar y usar cualquier
                    información y dato personal que facilites de acuerdo con lo establecido en los términos
                    del Aviso de Privacidad y tus elecciones (incluida la configuración).
                </p>
                <p>
                    Aceptas solo proporcionar contenido e información que no infrinja la ley ni los derechos
                    de ninguna persona. También acuerdas facilitar información verdadera en tu perfil. 
                </p>
                <p>
                    A “InPager” se le puede exigir por ley retirar determinada información o contenido en
                    ciertos países. 
                </p>
                <p className={classes.subtitle}><strong>IX. Disponibilidad de los Servicios de “InPager”</strong></p>
                <p>
                    “InPager” podrá cambiar, suspender o retirar cualquiera de nuestros Servicios. De la
                    misma forma, podrá modificar los precios con carácter prospectivo, previa notificación
                    dentro de un plazo razonable y en la medida en la que la legislación lo permita.
                </p>
                <p>
                    “InPager” no es un servicio de almacenamiento. Reconoces y aceptas que “InPager” no
                    tiene la obligación de almacenar, conservar o proporcionar una copia de cualquier
                    contenido o información que facilites tú u otras personas, salvo en la medida en que se
                    exija por ley y tal y como se señala en el Aviso de Privacidad. 
                </p>
                <p className={classes.subtitle}><strong>X. Otros tipos de contenido, sitios web y aplicaciones</strong></p>
                <p>
                    Al utilizar los Servicios, puede que encuentres información o contenido inexactos,
                    incompletos, atrasados, engañosos, ilegales, ofensivos o dañinos. Por lo general,
                    “InPager” no revisa el contenido proporcionado por sus Miembros u otras personas. 
                </p>
                <p>
                    Reconoces y aceptas que “InPager” no es responsable del contenido o de la información
                    de otras personas. “InPager” en ocasiones no podrá evitar el uso indebido de los
                    Servicios, y aceptas que “InPager” no es responsable de dicho uso.  
                </p>
                <p>
                    “InPager” podrá ayudar a que los Miembros que ofrecen sus servicios, conecten con
                    Miembros que buscan servicios. “InPager” no actúa ni emplea a particulares para que
                    lleven a cabos estos servicios. Debes tener al menos 18 años para ofrecer, realizar o
                    contratar estos servicios. Reconoces y aceptas que “InPager” no supervisa, dirige,
                    controla ni lleva a cabo seguimiento alguno de los Miembros en el desempeño de estos
                    servicios y aceptas que (1) “InPager” no es responsable de la oferta, el rendimiento o la
                    adquisición de los servicios; (2) “InPager” no suscribe en forma alguna los servicios
                    ofrecidos por ningún Miembro en particular, y (3) nada creará una relación laboral,
                    agencia o empresa conjunta entre “InPager” y cualquier Miembro que ofrezca servicios.
                    Si eres Miembro y ofreces tus servicios, declaras y garantizas que posees todas las
                    certificaciones necesarias, así como que proporcionarás tus servicios conforme la
                    legislación aplicable.   
                </p>
                <p className={classes.subtitle}><strong>XI. Limitaciones</strong></p>
                <p>
                    “InPager” se reserva el derecho de limitar tu uso de los Servicios, incluidos tu número
                    de contactos y tu capacidad para contactar con otros Miembros. 
                </p>
                <p>
                    “InPager” se reserva el derecho de restringir, suspender o cerrar tu cuenta si considera
                    que puedes haber incumplido este Contrato, la ley o que estás utilizando
                    incorrectamente los Servicios.  
                </p>
                <p className={classes.subtitle}><strong>XII. Propiedad Intelectual</strong></p>
                <p>
                    “InPager” se reserva todos sus derechos de propiedad intelectual en los Servicios. Todas
                    las marcas, logotipos, nombres comerciales, rótulos de establecimiento,
                    denominaciones sociales, expresiones publicitarias y cualquier derecho de autor
                    (dibujos, etc.) utilizados en relación con los Servicios son las marcas de sus respectivos
                    propietarios. “InPager”, los logotipos, otras marcas y derechos de autor de “InPager”,
                    imágenes, etc. Utilizados para nuestros Servicios son marcas registradas de “InPager” 
                </p>
                <p>
                    Queda prohibida la publicación de contenidos, mensajes, datos y/o cualquier otro tipo
                    de información que viole derechos de propiedad intelectual, industrial y/o cualquier otro
                    derecho por lo cual como declaras ser el autor y propietario de los derechos de propiedad
                    intelectual de cualquier tipo de contenido enviado a “InPager”. Lo anterior incluye
                    marcas, nombres comerciales, rótulos de establecimiento, denominaciones sociales,
                    expresiones publicitarias, nombres y seudónimos de personajes de relevancia pública o
                    famosos para cuya utilización no esté autorizada para este sitio. Asimismo, se prohíbe
                    reproducir, fijar distribuir, transformar, publicar, comercializar o editar los contenidos,
                    mensajes, datos y/o cualquier otra información publicada a menos que cuente con la
                    autorización del titular de los correspondientes derechos o ello resulte legalmente
                    permitido.  
                </p>
                <p className={classes.subtitle}><strong>XIII. Procesamiento Automatizado</strong></p>
                <p>
                    “InPager” podrá utilizar información y los datos que nos facilitas, así como los datos de
                    los que disponemos sobre los Miembros, para realizar recomendaciones con respecto a
                    contactos, contenido y funcionalidades que pueden resultar útiles. El mantenimiento
                    de un perfil actualizado con datos precisos nos ayuda a realizar recomendaciones más
                    relevantes y rigurosas. 
                </p>
                <p className={classes.subtitle}><strong>XIV. Exención y limitación de responsabilidad</strong></p>
                <p>
                    “INPAGER” NO PROPORCIONARÁ DECLARACIONES O GARANTÍA SOBRE LOS
                    SERVICIOS, INCLUIDA CUALQUIER DECLARACIÓN QUE ESTABLEZCA QUE LOS
                    SERVICIOS SERÁN ININTERRUMPIDOS O QUE ESTÉN LIBRES DE ERRORES, Y
                    PROPORCIONARÁN DICHOS SERVICIOS. EN LA MEDIDA EN QUE LO PERMITA LA
                    LEGISLACIÓN APLICABLE, INPAGER DECLARA LA EXENCIÓN DE CUALQUIER TIPO
                    DE GARANTÍA IMPLÍCITA O LEGAL, INCLUIDAS LAS GARANTÍAS IMPLÍCITAS DE
                    TÍTULO, PRECISIÓN DE LOS DATOS, NO INFRACCIÓN, COMERCIABILIDAD O
                    IDONEIDAD PARA UN FIN DETERMINADO. 
                </p>
                <p>
                    EN NINGÚN CASO “INPAGER” SERÁ RESPONSABLE DE ALGÚN DAÑO,
                    INCLUYENDO ENUNCIATIVA MÁS NO LIMITATIVAMENTE: DAÑOS,
                    PÉRDIDAS O GASTOS DIRECTOS, INDIRECTOS, INHERENTES O
                    CONSECUENTES, QUE SURJAN EN RELACIÓN CON ESTA APLICACIÓN O
                    SU USO O IMPOSIBILIDAD DE USO POR ALGUNA DE LAS PARTES, O EN
                    RELACIÓN CON CUALQUIER FALLA EN EL RENDIMIENTO, ERROR,
                    OMISIÓN, INTERRUPCIÓN, DEFECTO, DEMORA EN LA OPERACIÓN O
                    TRANSMISIÓN, VIRUS DE COMPUTADORA O FALLA DE SISTEMA O LÍNEA,
                    AÚN EN EL CASO DE QUE INPAGER FUERA INFORMADO SOBRE LA
                    POSIBILIDAD DE DICHOS DAÑOS, PÉRDIDAS O GASTOS.
                </p>
                <p>
                    “INPAGER” NO SERÁ RESPONSABLE, EN RELACIÓN CON ESTE CONTRATO, DE LA
                    PÉRDIDA DE BENEFICIOS O DE OPORTUNIDADES DE NEGOCIO, DE LA
                    REPUTACIÓN (POR EJEMPLO, DECLARACIONES OFENSIVAS O DIFAMATORIAS), DE
                    LA PÉRDIDA DE DATOS (POR EJEMPLO, TIEMPO DE INACTIVIDAD O PÉRDIDA, USO
                    O CAMBIOS EN LA INFORMACIÓN O EL CONTENIDO) O DE CUALQUIER DAÑO
                    INDIRECTO, ACCIDENTAL, RESULTANTE COMO CONSECUENCIA, ESPECIAL O DE
                    CARÁCTER PUNITIVO. 
                </p>
                <p>
                    “INPAGER” NO SERÁ RESPONSABLE ANTE TI EN RELACIÓN CON ESTE CONTRATO
                    POR NINGUNA CANTIDAD QUE EXCEDA LOS GASTOS TOTALES ABONADOS O
                    PENDIENTES DE ABONAR POR TU PARTE A INPAGER POR LOS SERVICIOS
                    PRESTADOS DURANTE EL PLAZO ESTABLECIDO POR ESTE CONTRATO, SI LOS
                    HUBIERA.
                </p>
                <p>
                    LAS LIMITACIONES DE RESPONSABILIDAD DE ESTA SECCIÓN FORMAN PARTE DEL
                    ACUERDO ESTABLECIDO ENTRE “INPAGER” Y TÚ, Y SE APLICARÁN A TODAS LAS
                    RECLAMACIONES DE RESPONSABILIDAD, INCLUSO AUNQUE SE HAYA
                    COMUNICADO A “INPAGER” LA POSIBILIDAD DE ESTE DAÑO, E INCLUSO AUNQUE
                    ESTAS SOLUCIONES NO CUMPLAN SU OBJETIVO PRINCIPAL.
                </p>
                <p className={classes.subtitle}><strong>XV. Terminación del Contrato</strong></p>
                <p>
                    Cualquiera de las partes podrá dar por concluido este Contrato en cualquier momento
                    tras comunicarlo a la otra parte. Una vez rescindido, perderás el derecho a acceder y
                    utilizar los Servicios. Lo siguiente continuará aplicándose tras la rescisión:
                    <ul>
                        <li className={classes.list} >Nuestro derecho a utilizar y divulgar tus comentarios.</li>
                        <li className={classes.list}>
                            Cualquier importe debido por cualquiera de las Partes antes de la finalización
                            continuará debiéndose después de la misma.
                        </li> 
                    </ul>
                </p>
                <p className={classes.subtitle}><strong>XVI. Disposiciones Miscelaneas</strong></p>
                <p>
                    <ol type="a" >
                        <li className={classes.list} >
                            “InPager” se obliga a cumplir con sus compromisos y obligaciones
                            asumidas según estos Términos y Condiciones, siempre y cuando el
                            Usuario/ Miembro cumpla con todos los compromisos y obligaciones
                            adquiridos y señalados en el presente documento, así como en la
                            confirmación de pedido.
                        </li>
                        <li className={classes.list}>
                            b. El acceso a las ligas o links con otras páginas electrónicas de Internet
                            son a su propio riesgo; “InPager” no investiga, verifica, controla ni
                            respalda el contenido, la exactitud, las opiniones expresadas y otras
                            conexiones suministradas por estos medios. En cualquiera de los 
                            casos la Responsabilidad máxima por parte de InPager será hasta por
                            el monto de la cantidad pagado por el Usuario/ Miembro por los
                            servicios otorgados
                        </li> 
                    </ol>
                </p>
                <p className={classes.subtitle}><strong>XVII. Legislación aplicable y jurisdicción</strong></p>
                <p>
                    Cualquier controversia que deba resolverse en virtud del uso de INPAGER.APP y/o los servicios
                    que deriven de la misma, las partes acuerdan someterse a la jurisdicción de los Tribunales de la
                    Ciudad de Monterrey, Nuevo León, renunciando a cualquier otra jurisdicción que pudiese
                    corresponderle por su domicilio presente o futuro, o por cualquier otra causa.
                </p>
                <p>
                    Para una atención personalizada y orientación respecto a estos Términos y Condiciones, te
                    invitamos a que te comuniques con nosotros, a través del teléfono número de teléfono: +52 1
                    55 8282 8955 o al correo electrónico hola@inpager.app.
                </p>
                <p>
                    <i>Última actualización:</i> 15 de junio de 2023. 
                </p>
            </div>
            <div className={classes.bottom_deco}>

            </div>
        </div>
    );
}

export default TermsAndConditions

const useStyles = makeStyles()(theme => ({
    root:{
        width:1000, 
        margin:'auto',
        [theme.breakpoints.down('lg')]:{
            width:'100%', 
        }
    },
    subroot:{
        padding:'0px 100px',
        fontFamily:theme.typography.fontFamily,
        textAlign:'justify',
    },
    logo_container:{
        padding:'40px 0px 0px',
    },
    bottom_deco:{
        width:'100%',
        height:40,
        background:theme.palette.primary.main,
        marginTop:60
    },
    list:{
        paddingLeft:20
    }, 
    list_container:{
        marginLeft:60
    },
    link:{
        //marginTop:40,
        color:theme.palette.primary.main
    },
    subtitle:{
        margin:'30px 0px',
        marginLeft:20
    }
}));