import React from 'react'
import { Typography, Avatar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';
import RoundedButton from '../../DealHunter/components/RoundedButton'
import { DASHBOARD_SETTINGS } from '../../../config'
import { catalogs } from '../../../texts/esp/catalogs'

const ProfileData = ({profileSrc, user, history, visits, influencer_categories, influencer_types}) => {

    const { classes } = useStyles()
    let user_label = '';
    if(user?.user_type_id === 5 || user?.user_type_id === 4){
        user_label = catalogs.user_types.find(el => el.value === user.user_type_id).label
    }

    let categories = influencer_categories.map(category => category.category_name).join(', ')

    let types = influencer_types.map(type => type.influencer_type).join(', ')

    return(
        <Grid direction='row' justifyContent='center' alignItems='center' spacing={2} container xs={12} className={classes.option}>
            <Grid item container justifyContent='center' alignItems='center' xs={12} md={2}>
                <Avatar src={profileSrc} className={classes.avatar}/>
            </Grid>

            <Grid item container xs={12} md={6} spacing={5} className={classes.content}>
                <Typography item variant='h5'>{user?.first_name}</Typography>
                <Typography item variant='subtitle2'>Tipo de figura pública: 
                    <Typography variant='caption' color='textSecondary'>
                        &nbsp;{user_label}. {types}
                    </Typography>
                </Typography>
                {user?.user_type_id !== 5 && user?.user_type_id !== 4 &&
                <>
                <Typography item variant='subtitle2'>Categorías de contenido: <Typography variant='caption' color='textSecondary'>&nbsp;{categories}</Typography></Typography>
                </>}
            </Grid>
            {user?.user_type_id !== 5 && user?.user_type_id !== 4 &&
            <Grid item container xs={12} md={4} className={classes.text}>
                <Typography item variant='h6'>Vistas al perfil</Typography>
                <Typography item>Últimos 7 días</Typography>
                <Typography item variant='h4'>{visits}</Typography>
                <Grid>
                    <RoundedButton item onClick={() => history.push('/dash/account')} size='small'>
                        <Typography variant='subtitle2'>Ir al perfil</Typography>
                    </RoundedButton>
                </Grid>
            </Grid>
            }
        </Grid>
    )
}

export default ProfileData

const useStyles = makeStyles()(theme => ({
    avatar:{
        width:'100%', height:'100%',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        border:'4px solid white',
        [theme.breakpoints.down('sm')]:{
            marginBottom:'8px',
            width:'60%', height:'60%',
            display:'flex',
            justifyContent: 'center'
        }
    },
    option:{
        background:'white',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        marginBottom: '30px',
        padding: '15px 25px',
        borderRadius: '15px',
        display: 'flex',
        [theme.breakpoints.down('md')]:{
            padding:'15px 10px'
        }
    },
    content:{
        textAlign:'start',
        padding:'10px 0px 10px 40px',
    },
    text:{
        display:'flex',
        flexDirection:'column',
        alignContent:'center',
        justifyContent:'center',
        textAlign:'center'
    }
}));