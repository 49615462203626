import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import system_translations from '../../../texts/system_translations';

const Footer = ({language, onUpdateModal, history}) => {

    const { classes } = useStyles()
    const general_content = system_translations[language].general
    const content = general_content.footer

    return ( 
        <div className={classes.root}>
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <div className={classes.logoContainer}>
                            <img src={content.logo} width={200} />
                            <div className={classes.mediaContainer}>
                                {content.media.map(item => {
                                    return(
                                        <a href={item.url} target='_blank' key={item.id.toString()}>
                                            <div className={classes.media_button} style={{background:item.color}} >
                                                <img src={item.icon} className={classes.media_icon}/>
                                            </div>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <div className={classes.linksContainer}>
                            <Grid container justifyContent='space-around'>
                                <Grid item xs={12} md={4}>
                                    <Grid container spacing={4}>
                                        {content.links.first_column.map(item => {
                                            if(item.modal){
                                                return <Grid item xs={12} key={`link-key-${item.id}`}>
                                                    <div onClick={() => {
                                                        onUpdateModal({[item.ref]:true})
                                                        }} className={classes.link}>
                                                        {item.label}
                                                    </div>
                                                </Grid>
                                            }
                                            return(
                                                <Grid item xs={12} key={`link-key-${item.id}`}>
                                                    <a href={item.link} target={item.target ? item.target : '_blank'} className={classes.link} >
                                                        {item.label}
                                                    </a>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.second_column} >
                                    <Grid container spacing={4}>
                                        {content.links.second_column.map(item => {
                                            return(
                                                <Grid item xs={12} key={`link-key-${item.id}`}>
                                                    {<a href={item.link} target='_blank' className={classes.link}>
                                                        {item.label}
                                                    </a> }
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.second_column}>
                                    <Grid container spacing={4}>
                                        {content.links.third_column.map(item => {
                                            return(
                                                <Grid item xs={12} key={`link-key-${item.id}`}>
                                                    {<a href={item.link} target='_blank' className={classes.link}>
                                                        {item.label}
                                                    </a> }
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.copyright}>
                    {content.copyright} - <a href={'/versions'}>{content.version}</a>
                </div>
            </div>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        background:'black',
        position:'relative',
        fontFamily:theme.typography.fontFamily,
        zIndex:theme.zIndex.appBar+1,
    },
    second_column:{
        [theme.breakpoints.down('md')]:{
            marginTop:30
        }
    },
    container:{
        width:1400,
        margin:'auto',
        boxSizing:'border-box',
        color:'white',
        padding:'60px 24px',
        paddingBottom:20,
        [theme.breakpoints.only('lg')]:{
            width:1200,
            //height:300
        },
        [theme.breakpoints.down('xl')]:{
            width:'100%',
            //height:300
        },
        [theme.breakpoints.down('md')]:{
            width:'100%',
        }
    },
    logoContainer:{
        borderRight:'1px solid #686868',
        [theme.breakpoints.down('md')]:{
            borderRight:'none',
            textAlign:'center',
            marginBottom:32,
            //background:'green'
        }
    },
    mediaContainer:{
        //display:'flex',
        alignItems:'center',
        marginTop:16,
        marginLeft:24,
        height:'100%',
        //background:'red',
        width:200,
        [theme.breakpoints.down('md')]:{
            justifyContent:'center',
            width:'100%',
            //background:'red',
            marginLeft:0
        }
    },
    media_button:{
        display:'inline-block',
        width:42,
        paddingTop:42,
        background:'#868686',
        borderRadius:8,
        marginRight:12,
        marginBottom:8,
        position:'relative'
    },
    media_icon:{
        position:'absolute',
        top:'50%',
        left:'50%',
        width:'60%',
        height:'60%',
        transform:'translate(-50%, -50%)'
    },
    link:{
        textDecoration:'none',
        cursor:'pointer',
        color:'white',
        fontSize:'1rem',
    },
    linksContainer:{
        margin:'0px 100px',
        [theme.breakpoints.down('md')]:{
            textAlign:'center',
            margin:'0px'
        }
    },
    copyright:{
        width:'100%',
        //border:'1px solid white',
        textAlign:'center',
        paddingTop:60,
        fontSize:'0.875rem'
    }
}));

const mapStateToProps = (state) => {
    return {
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateModal: (modal) => dispatch({type:actionTypes.SYS_UPDATE_MODAL, modal  }),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Footer);
