import { Grid, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import SimpleModal from "../../../../../../components/Modals/SimpleModal"
import DisplayError from "../../../../../../components/Structure/DisplayData/DisplayError"
import RoundedButton from "../../../../../../components/Actions/RoundedButton"


const DeleteIntegrationModal = ({open, onClose,error, master_actions}) => {

    const { classes } = useStyles()
    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
           
           <div className = {classes.modal_container} >
            <Typography variant='h6'>
                    ¿Seguro que desea desvincular la cuenta?
                </Typography>
                <div className={classes.button_container}>
                    <DisplayError>{error}</DisplayError>
                    <RoundedButton color = 'red' error = {error} onClick={master_actions.onDeleteIntegration}>Desvincular</RoundedButton>
                </div>
           </div>
        </SimpleModal>
    )

}

export default DeleteIntegrationModal

const useStyles = makeStyles()(theme => ({
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    pic_container:{
        position:'relative'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    },
    modal_container: {
        display:'flex',
        flexDirection:'column',
        alignItems: 'center'
    },
    highlight:{
        color:theme.palette.primary.main
    }
    
}));