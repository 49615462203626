import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Información de perfil',
    message:'Por favor ingrese los datos solicitados para agregar el contenido',
    add_button:'Agregar',
    edit_button:'Actualizar',
    delete_button:'Eliminar perfil',
    quote_instructions:'Ingrese una frase con la que la gente lo pueda identificar en InPager',
    contact_instructions:'Añada datos de contacto para que la comunidad pueda estar en contacto contigo',
    form:{
        user_type_id:{placeholder:'Tipo de cuenta', helperText:'Campo requerido'},
        first_name:{helperText:'El campo debe tener al menos un caracter'},
        last_name:{placeholder:'Apellido(s)', helperText:'El campo debe tener al menos un caracter'},
        username:{placeholder:'Usuario', helperText:'El campo debe tener al menos un caracter'},
        email:{placeholder:'test@inpager.co', helperText:'Correo no válido'},
        email_public:{placeholder:'colaboraciones@agencia.com', helperText:'Correo no válido'},
        birth_date:{placeholder:'Fecha de nacimiento', helperText:''},
        gender_id:{placeholder:'Género', helperText:'Campo requerido'},
        quote:{placeholder:'Ejemplo: Todo es posible', helperText:'La frase debe contener máximo 256 caracteres'},
        whatsapp_link:{placeholder:'https://api.whatsapp.com/message/', helperText:''},
        website_link:{placeholder:'https://agencia.com', helperText:''},
        country_id:{placeholder:'País', helperText:''},
        zone:{helperText:''},
        mobile_country_code:{helperText:''},
        mobile:{placeholder:'Número de teléfono', helperText:''},
    },
    nopicture:nopicture
}

export default translations