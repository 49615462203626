import React, { useState, useEffect } from 'react';
import {
    alpha,
    Avatar,
    Card,
    Fade,
    Grid,
    Chip,
    Icon,
    IconButton,
    Typography,
    Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import banner_profile from '../../assets/banners/banner-profile.jpg'
import image_profile from '../../assets/nouser.png'
import { isValidDocument, onGetFullname, onGetFullResourcePath, onGetValidValue } from '../../shared/utility';
import ProfileRating from './ProfileRating';
import { grey } from '@mui/material/colors';
import ProfileCategories from './ProfileCategories';
import ProfileContactInfo from './ProfileContactInfo';
import AgencyInfoCard from './AgencyInfoCard';
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS } from '../../config';
import GenericEditButton from '../Actions/GenericEditButton';
import GenericDeleteButton from '../Actions/GenericDeleteButton';
import InfluencerSizeInfo from './InfluencerSizeInfo';
import Scrollbars from 'react-custom-scrollbars-2';
import TypePublicFigure from './TypePublicFigure';
import RoundedButton from '../Actions/RoundedButton';
import moment from 'moment';
import { onGetDateFormat } from '../../shared/utility';
import { catalogs } from '../../texts/esp/catalogs'

const ProfileCard = ({data, categories, links, menu, page, actions, modify, influencers, manager,manager_data, owner, average_rating, isauth, auth_user,
     onEditProfile, onEditProfilePicture, onEditProfileBanner, onChangePassword, onSendInvitationRequest, onAddTypePublicFigure, influencer_types, onRemoveType, onShowQrCode}) => {

    let user_label = ''
    if(data){
        user_label = catalogs.user_types.find(el => el.value === data.user_type_id).label
    }
    const { classes, cx } = useStyles()
    const banner = document.getElementById('edit-banner')
    const [hover, setHover] = useState(false)
    const [hover_banner, setHoverBanner] = useState(false)

    let isValidBannerImg = isValidDocument(data ? data.banner_image : null)
    let bannerSrc = isValidBannerImg ? onGetFullResourcePath(data.banner_image) : banner_profile

    let isValidProfileImg = isValidDocument(data ? data.image : null)
    let profileSrc = isValidProfileImg ? onGetFullResourcePath(data.image) : image_profile
    // Get user location

    const onNextPage = () =>{
        if(page >= menu.lenght) return
        actions.onChangePage(page+1)
    }
    
    useEffect(() => {
        if(banner){
            if(hover_banner)
                banner.classList.add(classes.color_edit_banner)
            else
                banner.classList.remove(classes.color_edit_banner)
        }
    }, [hover_banner])


    const dates = influencer_types.map(type => new Date(type.updated_at))
    const maxDate = new Date(Math.max.apply(null, dates))
    const dateUpdate = onGetDateFormat(moment.utc(maxDate).format(), 'LLL')

    return (
        <Card className={classes.card}>
            <div className={classes.banner_container}>
                <div onMouseEnter={() => setHoverBanner(true)} onMouseLeave={() => setHoverBanner(false)}>
                    <img src={bannerSrc} alt='' className={classes.banner} />
                    <div className={classes.edit_banner} id='edit-banner'>
                        { modify
                        ? <div className={classes.edit_banner_content}>
                            <RoundedButton onClick={onEditProfileBanner} size='small'><Icon>photo_camera</Icon>&nbsp;Editar foto</RoundedButton>
                          </div>
                        : null}
                    </div>
                </div>
                
                
                <div className={classes.avatar_container}>
                    <div className={classes.avatar_subcontainer} onMouseEnter={() => {
                            setHover(true)
                            }} onMouseLeave={() => setHover(false)} >
                        <div className={classes.single_avatar} 
                        >
                            {hover && !hover_banner && modify ? (
                                <Fade in>
                                    <div className={classes.edit_avatar} onClick={onEditProfilePicture}>
                                        <div className={classes.edit_avatar_content}>
                                            <Grid container direction='column'  alignItems='center'>
                                                <Grid item>
                                                    <Icon>photo_camera</Icon>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2'> Editar </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </Fade>
                            ):null}
                            <Avatar src={profileSrc} className={classes.avatar}/>
                        </div>
                        {data?.user_type_id != 6 &&
                            <Chip label={user_label} color='primary' className={classes.user_type_chip} />
                        }
                    </div>
                </div>
            </div>
            <div className={classes.content} >
                <Grid xs={12} className={classes.location_container}>
                    <Grid container xs={7}>
                        <IconButton onClick={onShowQrCode} size="large"><Icon style={{color: 'grey'}}>qr_code_scanner</Icon></IconButton>
                    </Grid>
                    <Grid className={classes.location}>
                        <Icon>home</Icon>
                        <Typography variant='body1' color='textSecondary' style={{marginLeft:16}}>Vive en</Typography>
                        <Typography variant='subtitle2' color='textPrimary' style={{marginLeft:4}}>
                        {`${onGetValidValue(data?data.zone:null)}, ${onGetValidValue(data?data.country:null)}`}
                        </Typography>
                    </Grid>
                </Grid>
                
                <div className={classes.profile_info_container}>
                    
                    <div className={classes.name_container}>
                        <Grid container spacing={0} alignItems='center'>
                            <Grid item>
                                <Typography variant='h6' align='center' style={{marginRight:8}}>
                                    {onGetFullname(data ? data.first_name : null, data ? data.last_name : null)}
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <IconButton size='small' 
                                    onClick={() => {navigator.clipboard.writeText(`${CONFIG_SETTINGS.DOMAIN}/dash/${manager ? 'managers' : 'influencers'}/${data?.url}`)}}>
                                    <Icon fontSize='small' >content_copy</Icon>
                                </IconButton>
                            </Grid>
                            <Grid item><div style={{marginLeft:8}}/></Grid>
                            {modify ? <Grid><GenericEditButton onClick={onEditProfile} /></Grid> : null}
                        </Grid>
                        
                    </div>
                    <ProfileRating rating={4} average_rating={average_rating}/>
                    <Typography variant='body1' align='center' className={classes.quote}>{`"${onGetValidValue(data ? data.quote : null)}"`}</Typography>
                    {data?.user_type_id === 6 || data?.user_type_id === 1
                    ? <Typography variant='subtitle1'>Tipo de figura pública</Typography>
                    : null}
                    {data?.user_type_id === 3
                    ? <Typography variant='subtitle1'>Especialidad</Typography>
                    : null}
                    {(data?.user_type_id === 6 || data?.user_type_id === 3 || data?.user_type_id === 1) && dateUpdate ?
                        <Typography variant='body2' color='textSecondary'>Actualizado el {dateUpdate}</Typography>
                    : null
                    }
                    {data?.user_type_id === 6 || data?.user_type_id === 3 || data?.user_type_id === 1 ?
                    <TypePublicFigure onAddTypePublicFigure={onAddTypePublicFigure} types={influencer_types} onRemoveType={onRemoveType} modify={modify}/>
                    : null}
                </div>
                <div className={classes.contact_container}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            {auth_user && 
                                <ProfileContactInfo data={links}/>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {manager 
                            ? <InfluencerSizeInfo size={influencers.length} /> 
                            : <AgencyInfoCard user={auth_user} isauth={isauth} manager={manager_data} onSendInvitationRequest={onSendInvitationRequest}/>}
                        </Grid>
                    </Grid>
                </div>
                
            </div>
        
                <div >
                    <Scrollbars autoHeight autoHeightMax={400}>
                        <div className={classes.menu}>
                            <Grid container wrap='nowrap' >
                                {menu.map(item => {

                                    if(!owner && !modify && item.total === 0) return null

                                    return(
                                        <Grid item key={`link-${item.id}`}>
                                            
                                                <div className={cx({
                                                    [classes.link]:true,
                                                    [classes.link_selected]:page === item.id
                                                })} onClick={() => actions.onChangePage(item.id)} >
                                                    {item.label}
                                                </div>
                                            
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                    </Scrollbars>
                </div>
         
        </Card>
    );
}

const useStyles = makeStyles()(theme => ({
    card:{
        borderRadius:16,
        padding:0,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        position:'relative'
    },
    banner_container:{
        width:'100%',
        paddingTop:'25%',
        position:'relative',
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            paddingTop:'30%'
        }
    },
    banner:{
        position:'absolute',
        top:0, left:0,
        width:'100%',
        height:'100%',
        objectFit:'cover',
        objectPosition:'top',
        zIndex:1
    },
    single_avatar:{
        position:'relative'
    },
    avatar_container:{
        bottom:-66,
        left:'50%',
        zIndex:2,
        position:'absolute',
        transform:'translate(-50%)',
        width:140,
        height:140
    },
    avatar_subcontainer:{
        position:'relative',
        width:'100%',
        height:'100%',
    },
    status:{
        background:'#21AB1E',
        width:24, height:24,
        position:'absolute',
        bottom:4, right:4,
        borderRadius:'50%',
        border:'2px solid white'
    },
    avatar:{
        width:'100%', height:'100%',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        border:'4px solid white',
        background: '#FFFFFF'
    },
    user_type_chip:{
        position: 'absolute',
        left: '52%',
        transform: 'translateX(-50%)',
        bottom: -10,
        width:120,
        borderRadius:5,
        zIndex:10
    },
    content:{
        minHeight:200,
        padding:'10px 25px'
    },
    location_container:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        fontFamily:theme.typography.fontFamily,
        [theme.breakpoints.down('md')]:{
            margin:'80px 0px 10px',
            justifyContent:'center',
        }
    },
    profile_info_container:{
        width:600,
        margin:'auto',
        marginTop:48,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        [theme.breakpoints.down('md')]:{
            width:'100%'
        }
    },
    quote:{
        marginTop:8,
        color:grey[600]
    },
    contact_container:{
        width:600,
        margin:'auto',
        marginTop:24,
    }, 
    menu:{
        padding:'0px 16px',
        [theme.breakpoints.down('md')]:{
            paddingBottom:12
        }
    },
    menu_container_mobile:{
        display:'flex',
        padding:'0px 16px',
        display:'flex',
        justifyContent:'center',
        minWidth:800,
    },
    menu_button_mobile:{
        position:'absolute',
        right:4,
        bottom:4,
        zIndex:10,
    },
    edit_avatar:{
        position:'absolute',
        top:4, left:4,
        width:140,
        height:140,
        borderRadius:'100vh',
        background:alpha("#000",0.2),
        cursor:'pointer',
        zIndex:2,
    },
    edit_avatar_content:{
        color:'white',
        marginTop:'40%'
    },
    link:{
        padding:'4px 8px',
        margin:'0px 16px',
        fontFamily:theme.typography.fontFamily,
        fontSize:16,
        fontWeight:500,
        color:'#868686',
        borderBottom:'3px solid white',
        cursor:'pointer',
        borderTopLeftRadius:4,
        borderTopRightRadius:4,
        '&:hover':{
            background:alpha(theme.palette.primary.main, 0.3),
            borderBottom:`3px solid ${alpha(theme.palette.primary.main, 0.05)}`,
            color:theme.palette.primary.main
        }
    },
    link_selected:{
        color:theme.palette.primary.main,
        borderBottom:`3px solid ${theme.palette.primary.main}`,
        '&:hover':{
            background:'white',
            borderBottom:`3px solid ${theme.palette.primary.main}`,
        }
    },
    name_container:{
        display:'flex',
        flexWrap:'no-wrap',
        alignItems:'center'

    },
    edit_banner:{
        position:'absolute',
        top:0, left:0,
        width:'100%',
        height:'100%',
        borderTopLeftRadius:16,
        borderTopRightRadius:16,
        zIndex:2
    },
    color_edit_banner:{
        background:alpha("#000",0.2),
    },
    edit_banner_content:{
        color:'white',
        position:'absolute',
        bottom:8, right:8,
    },
    first_info_container:{
        displat:'flex',
        justifyContent:'space-between'
    },
    location:{
        display:'flex',
    }
}));

export default ProfileCard;