import { useState } from "react";
import { private_server } from "../../../config";
import { onInitForm, isFormValid, onSetErrorsToForm, onGetSelectedFormData, updateAuthorizationHeader, onGetErrorMessage } from "../../../shared/utility";
import translations from "../../../texts/system_translations";
import { generic_errors } from "../../../texts/esp/genericErrors";

const usePodcastModalTest = (user, language, setModalOpen, editModalMode) => {
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)));
    const [error, setError] = useState(null);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [creatorLinks, setCreatorLinks] = useState([{link: '', tag:''}]);
    const content = translations[language].layouts.podcasts;

    const actionsModal = {
        onInitModule: async () => {
            const _form = onInitForm(form, user, content.form);
            setForm(_form);
            setSent(false);
        },
        onChangeForm: (data) => {
            let temp = { ...form };
            const id = data.config.id;
            temp[id] = { ...data };
            if (error) setError(null)
            setForm(temp);
        },
        addChangeCreatorLink: () => {
            setCreatorLinks(prev => [...prev, {link: '', tag: ''}])
        },
        removeChangeCreatorLink : (index) => {
            setCreatorLinks(prev => {
                const temp = [...prev];
                temp.splice(index, 1);
                return temp;
            })
        },
        onChangeCreatorLink: (index, type, event) => {
            event.persist()
            setCreatorLinks(prev => {
                const temp = [...prev]
                temp[index][type] = event.target.value;
                return temp;
            })
        },
        onSubmit: async () => {

            const errors = isFormValid(form);

            if (errors && errors.length) {
                const _form = onSetErrorsToForm(form, errors);
                setForm(_form);
                setError(generic_errors[language].INCOMPLETE_FORM);
                return
            }

            const data2send = onGetSelectedFormData(form, origin);
            data2send.creator_links = creatorLinks;
            setSending(true);
            let request_url = `/podcast`;
            try {
                updateAuthorizationHeader(private_server);
                if (editModalMode) {
                    await private_server.put(request_url, data2send);
                }
                else {
                    await private_server.post(request_url, data2send);
                }
                setError(null);
                setModalOpen(false);
                setForm(form_data);
                setSent(true)
            } catch (error) {
                setSending(false);
                const _error = onGetErrorMessage(error);
                setError(_error ? _error.message : null);
            }

            setSending(false);
        },
        setFormValues: (podcast) => {
            const temp = { ...form };
            Object.keys(temp).forEach((item) => {
                if (item) {
                    temp[item].value = podcast[item]
                    temp[item].isValid = true
                }

            })
            setForm(temp)
            setCreatorLinks(podcast.creator_links)
        }
    }
    return ({ actionsModal, form, error, sending, sent, creatorLinks });
}

export default usePodcastModalTest;

const form_data = {
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'number',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    creator: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'creator',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    youtube_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'youtube_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    spotify_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'spotify_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    apple_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'apple_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    }
}