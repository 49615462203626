const translations = {
    title:'Queremos saber más de ti',
    message:'Por favor déjanos tus datos y a la brevedad un asesor de InPager se pondrá en contacto contigo.',
    submit_button:'Enviar',
    form:{
        name:{placeholder:'Nombre', label:'Nombre', helperText:'Campo requerido'},
        email:{placeholder:'Correo electrónico', label:'Correo electrónico', helperText:'Correo no válido'},
        phone_number:{placeholder:'Teléfono', label:'Teléfono', helperText:'Teléfono no válido'},
        message:{placeholder:'Mensaje', label:'Mensaje', helperText:'Campo requerido'},
    },
}

export default translations