import React, { useState, useEffect } from 'react'
import { Typography, Icon, IconButton, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import influencer_icon from '../../../assets/icons/sidebar/influencer.svg'
import { DASHBOARD_SETTINGS } from '../../../config';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';
import card_icon from '../../../assets/icons/card.svg'
import Page from '../../../components/Structure/Layouts/Page';
import { connect } from 'react-redux';
import SettingsForm from './componentes/SettingsForm'
import useSettingsView from './useSettingsView';
import ChangePasswordModal from '../../Account/AccountView/modals/ChangePasswordModal/ChangePasswordModal';
import DeleteProfileDataModal from '../../Account/AccountView/modals/DeleteProfileDataModal/DeleteProfileDataModal';
import { actionTypes } from '../../../store/actions';


const Settings = (props) => {

    const { classes } = useStyles()
    const [profile, setProfile] = useState(null)
    const [hidden, setHidden] = useState(true)
    const { language, user, onUpdateUserData } = props
    const { form, actions, modals, system } = useSettingsView(language, user, onUpdateUserData, profile, setProfile)

    useEffect( () => {
        setProfile(user)
        window.scrollTo(0, 0)
    }, [])

    const handleClick = () => {
        const div = document.getElementById('formulario')
        const cuenta = document.getElementById('cuenta')
        const facturacion = document.getElementById('facturacion')

        if(hidden) {
            div.removeAttribute('hidden')
            actions.onInitModule()
            cuenta.classList.add(classes.option2)
            cuenta.classList.remove(classes.option)
            facturacion.classList.add(classes.option2)
            facturacion.classList.remove(classes.option)
            setHidden(false)
        }
        else{
            cuenta.classList.add(classes.option)
            cuenta.classList.remove(classes.option2)
            facturacion.classList.add(classes.option)
            facturacion.classList.remove(classes.option2)
            div.setAttribute("hidden", "hidden")
            setHidden(true)
        }
    }

    return (
        <Page>
            <ChangePasswordModal open={modals.change_password} user={user} onClose={()=>actions.onUpdateModalStatus('change_password', false)}/>
            <DeleteProfileDataModal open={modals.delete_profile} user={user} onClose={()=>actions.onUpdateModalStatus('delete_profile', false)} language={language} profile={profile} master_actions={actions}/>

            <div className={classes.container}>
                <Grid container alignItems='center' className={classes.option} id='cuenta'>
                    <Grid container justifyContent='space-between' alignItems='center' spacing={1} className={classes.title}>
                        <Grid spacing={1} display='flex' flexDirection='column'>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item>
                                    <SVGIcon src={influencer_icon} size={24}/>
                                </Grid>
                                                
                                <Grid item xs>
                                    <Typography variant='h6'>Cuenta</Typography>
                                </Grid> 
                            </Grid>
                        </Grid>

                        <Grid>
                            <IconButton color='primary' onClick={handleClick} size="large">
                                { hidden ? <Icon>expand_circle_down</Icon> : <Icon>expand_less_outlined</Icon>}
                            </IconButton>
                        </Grid> 
                    </Grid>
                    
                    <SettingsForm item form={form} actions={actions} language={language} onChangePassword={() => actions.onUpdateModalStatus('change_password', true)} onDeleteProfile={() => actions.onUpdateModalStatus('delete_profile', true)} sending={system.sending} error={system.error} send={system.send} />

                </Grid>

                <Grid container alignItems='center' wrap='nowrap' className={classes.option} id='facturacion'>
                    <Grid container justifyContent='center' alignItems='center' spacing={1}>
                        <Grid item>
                            <SVGIcon src={card_icon} size={24}></SVGIcon>
                        </Grid>
                                    
                        <Grid item xs>
                            <Typography variant='h6'>Facturacion</Typography>
                        </Grid> 
                    </Grid>

                    <Grid>
                        <IconButton color='primary' size="large">
                            <Icon>expand_circle_down</Icon>
                        </IconButton>
                    </Grid>      
                </Grid>
            </div>
        </Page>
    );
}

const useStyles = makeStyles()(theme => ({
    container:{
        width: '100%',
        padding:'32px 10px',
    },
    form:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    content:{
        marginTop:16
    },
    strongword:{
        color:theme.palette.primary.main
    },
    back_container:{
        position:'relative',
        width:'100%',
        textAlign:'center',
        color:'white',
        [theme.breakpoints.only('xs')]:{
            height:420,
            //background:'red'
        }

    },
    option:{
        background:'white',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        marginBottom: '30px',
        padding: '15px 25px',
        borderRadius: '15px',
        height:'70px'
    },
    option2:{
        background:'white',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        marginBottom: '30px',
        padding: '15px 25px',
        borderRadius: '15px'
    },
    back:{
        width:'100%',
        [theme.breakpoints.only('xs')]:{
            height:'100%',
            objectFit:'cover'
        }
    },
    logo:{
        width:280,
        [theme.breakpoints.down('md')]:{
            width:200
        },
        [theme.breakpoints.only('xs')]:{
            width:180
        }
    },
    info_container:{
        position:'absolute',
        top:'45%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        fontSize:'1.5rem',
        width:'85%',
        [theme.breakpoints.down('md')]:{
            fontSize:'1.2rem'
        }
    },
    button:{
        textAlign: 'justify',
    },
    title:{
        marginBottom:'10px'
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)