import React, { useState, useEffect } from 'react';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import Footer from '../../components/Structure/Navigation/Footer';
import system_translations from '../../texts/system_translations';
import { alpha, Button, CircularProgress, Grid, Typography, Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useAccountVerificationLayout from './useAccountVerificationLayout';
import InputForm from '../../components/Forms/InputForm';
import { Redirect } from 'react-router';
import useAuth from '../../shared/useAuth';
import { blueGrey } from '@mui/material/colors';
import { USER_TYPES } from '../../config';

const AccountVerificationLayout = ({history, language, user,  onUpdateAuthStatus, onUpdateUserData}) => {

    const { classes } = useStyles()
    const content = system_translations[language].layouts.account_verification
    const {system, view_data, actions} = useAccountVerificationLayout({language, content,  onUpdateAuthStatus, onUpdateUserData, history})
    const {error} = system

    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    const onChangePage = (url) => {
        history.push(url)
    }

    
    const succed_content = (
        <div>
            <Typography variant='h5' style={{fontWeight:700}}>
                {content.title} <Icon>celebration</Icon>
            </Typography>
            <Typography variant='body1' className={classes.message}>
                <strong style={{color:'#843BA8'}}>{content.message}</strong>
            </Typography>
            <Typography variant='body1' className={classes.message} >
                {content.submessage}
            </Typography>
            
            {view_data.user_type === USER_TYPES.MANAGER && false ? 
                <>
                    <Typography className={classes.message_coupon}>
                        Gracias a que has usado tu Código de Manager 
                        <strong style={{color:'#843BA8', fontSize:20}}> {view_data ? view_data.referal_code : '-'}</strong> has obtenido 
                        <strong> 6 meses gratis</strong> en la plataforma. 
                    </Typography>
                    <Typography className={classes.message_coupon}>
                        Envía tu <strong style={{color:'#843BA8'}}> Código de Referido </strong>a tus influencers para que ellos obtengan 
                        <strong> 2 meses gratis</strong> al crear su 
                        <strong style={{color:'#843BA8'}}> InPager</strong>. Además, 
                        si <strong>15 influencers</strong> utilizan tu código, extiende tu promoción a 
                        <strong> 1 año de InPager para Managers gratis</strong>.
                    </Typography>
                </>
            : null}
            {view_data.user_type === USER_TYPES.INFLUENCER && false ? 
                <>
                    <Typography className={classes.message_coupon}>
                        Gracias a que has usado tu <strong>Código de referido</strong> has obtenido 
                        <strong> 2 meses gratis</strong> en la plataforma. 
                    </Typography>
                </>
            : null}
            {/*
            <Typography className={classes.message_coupon}>
                Gracias por formar parte del futuro del <strong style={{color:'#843BA8'}}>Influencer Marketing</strong>. 
            </Typography>
                */
            }
        </div>
    )

    const error_content = (
        <div>
            <Typography variant='h5' style={{fontWeight:700}}>
                Opps!! 
            </Typography>
            <Typography variant='body1' className={classes.message} >
                <strong style={{color:'#843BA8'}}>¡Hubo un error!</strong> Al parecer el token ya fue utilizado o no es válido.
            </Typography>
        </div>
    )


    return ( 

        <div className={classes.root}>
            <PublicTopbar content={content.topbar} onChangePage={onChangePage}/>
                <div className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div className={classes.left_container}>
                                <div className={classes.content_data}>
                                    {!error ? succed_content : error_content}    
                                    <div style={{marginTop:40}}>
                                        <Button fullWidth className={classes.button} onClick={actions.onSubmit}>
                                            Finalizar
                                            {system.sending ? <CircularProgress size={32} style={{marginLeft:12, color:'white'}}/> : null}
                                        </Button>
                                        {system.error ? <Typography style={{marginTop:16}} align='center' color='error' variant='subtitle2'>{system.error}</Typography> : null}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            <Footer content={content.footer}/>
            <div className={classes.overlay}/>
            <div className={classes.imageContainer}>
                <img src={content.banner} className={classes.image}/>
            </div>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    message:{
        marginTop:40,
        fontSize:20,
    },
    message_coupon:{
        marginTop:16,
        fontWeight:400,
        color:blueGrey[900]
    },
    root:{
        position:'relative',
        fontFamily:theme.typography.fontFamily,
        position:'relative'
    },
    imageContainer:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        height:'100vh',
        background:'#EEE',
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    overlay:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        height:'100vh',
        zIndex:3,
        background: alpha('#000',0.25),
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    container:{
        width:1400,
        margin:'auto',
        height:'100vh',
        //background:'red',
        boxSizing:'border-box',
        [theme.breakpoints.only('lg')]:{
            width:1200,
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            padding:'0px 18px'
            //padding:'12px 0px',
            //height:300
        },
    },
    left_container:{
        position:'relative',
        padding:'24px 60px',
        paddingLeft:24,
        paddingRight:100,
        //height:'100vh',
        //background:"#DDD"
        [theme.breakpoints.down('md')]:{
            paddingRight:24
        },
    },
    content_data:{
        marginTop:140,
    },
    link:{
        color:theme.palette.primary.main,
        fontWeight:600,
        textDecoration:`underline ${theme.palette.primary.main}`,
        cursor:'pointer'

    },
    button:{
        fontSize:20,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
    },
    form:{
        margin:'40px 0px'
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountVerificationLayout);