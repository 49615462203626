import girl from '../../assets/homeView/girl.jpg'
import principal from '../../assets/homeView/main.jpg'
import logo from '../../assets/logo.png'
import logoWhite from '../../assets/logo-text-white.svg'
import card_logo from '../../assets/logo_membership.png'
import moment from 'moment'
import mobile from '../../assets/homeView/mobile.png'
import logo_mobile from '../../assets/logo_mobile.png'
import InPager_Isotipo_cut from "../../assets/InPager_Isotipo_cut.png";
import mac_web from '../../assets/homeView/perfil.png'
import check from '../../assets/homeView/check.png'
import macbook_web from '../../assets/homeView/metricas.png'
import infuencer_icon from '../../assets/icons/influencer.png'
import manager_icon from '../../assets/icons/manager.png'
import brand_icon from '../../assets/icons/marca.png'
//////////////////////// categories  ///////////////////////////
import art from '../../assets/categories/art.jpg'
import beauty from '../../assets/categories/beauty.jpg'
import business from '../../assets/categories/business.jpg'
import conferences from '../../assets/categories/conferences.jpg'
import enterteinment from '../../assets/categories/enterteinment.jpg'
import fitness from '../../assets/categories/fitness.jpg'
import foodie from '../../assets/categories/foodie.jpg'
import gamers from '../../assets/categories/gamers.jpg'
import lifestyle from '../../assets/categories/lifestyle.jpg'
import parenting from '../../assets/categories/parenting.jpg'
import social from '../../assets/categories/social.jpg'
import travel from '../../assets/categories/travel.jpg'



const translations = {
    topbar:{
        logo:logo,
        register:'Únete ahora',
        login:'Iniciar sesión'
    },
    bottombar:{
        logo:logoWhite,
        media:[
            {id:1, url:'', name:'instagram', icon:null},
            {id:2, url:'', name:'facebook', icon:null},
            {id:3, url:'', name:'twitter', icon:null},
            {id:4, url:'', name:'tiktok', icon:null},
        ],
        links:{
            first_column:[
                {id:1, label:'Agencias', link:'/'},
                {id:2, label:'Influencers', link:'/'},
                {id:3, label:'Prensa', link:'/'},
                {id:4, label:'Marcas', link:'/'},
            ],
            second_column:[
                {id:1, label:'Contacto', link:'/'},
                {id:2, label:'Acerca de', link:'/'},
                {id:3, label:'Servicios', link:'/'},
            ],
            third_column:[
                {id:1, label:'Términos de uso', link:'/'},
                {id:2, label:'Aviso de privacidad', link:'/'},
                {id:3, label:'Ayuda', link:'/'},
            ],
        },
        copyright: `Copyright @ ${moment().year()} InPager`
    },
    sectionA:{  
        title:'Tu marca personal, en un solo lugar',
        text:'Reúne tu trabajo y tus métricas sociales en un mismo sitio para cerrar acuerdos comerciales a la velocidad de un like.',
        button:'Únete ahora',
        login_button:'Inicia sesión',
        image:principal
    },
    sectionB:{
        title:'¡El portafolio digital que cabe en tu bio!',
        subtitle:'Víncula tus redes para calcular tu alcance real y el valor comercial de tus colaboraciones.',
        image: mac_web
    },
    sectionC:{
        title:'¡Únete a nosotros!',
        text:'Crea tu perfil de InPager y envíalo a todas las marcas con quien quieras conectar.',
        button:'Crear InPager',
        image:girl
    },
    sectionD:{
        title:'Te presentamos ',
        title_color:'InPager',
        text:'Conoce los alcances y todos los beneficios de usar la plataforma para cada una de tus colaboraciones'
    },
    sectionE:{
        title:'Te damos el mejor precio, de acuerdo a tus necesidades',
        menu:[{id:1, label:'Influencers'}, {id:2, label:'Managers'}],
        card:{
            logo:card_logo,
            monthly_suscription:'monthly suscription',
            anual_suscription:'annual suscription',
            includes_title:'Incluye:',
            /* includes:[
                'link de perfil público',
                'buscador de influencers y managers (ilimitado)',
                'descripción y tipos de contenido',
                'contacto y ubicación',
                'estadística de redes sociales',
                'fotos descargables',
                'portafolio de marcas',
                'conferencias/cursos',
                'prensa',
                'eventos',
                'empresas',
                'reseñas de clientes',
                'dealhunter',
                'campo para manager',
                'acceso al editor de manager (ilimitado)'
            ], */
            special_price:'*precio especial por introducción',
            monthly:'(mensuales)',
            anual:'(anuales)',
            one_day:'(por un día)',
            button:'suscribirme'
        },
        influencers:{
            menu:[{id:1, label:'Subscripción mensual'}, {id:2, label:'Subscripción anual'}],
            basic:[
                {included:true, feature:'link de perfil público'},
                {included:true, feature:'buscador de influencers y managers (ilimitado)'},
                {included:true, feature:'descripción y tipos de contenido'},
                {included:true, feature:'contacto y ubicación'},
                {included:true, feature:'estadística de redes sociales'},
                {included:false, feature:'fotos descargables'},
                {included:false, feature:'portafolio de marcas'},
                {included:false, feature:'conferencias/cursos'},
                {included:false, feature:'prensa'},
                {included:false, feature:'eventos'},
                {included:false, feature:'empresas'},
                {included:false, feature:'reseñas de clientes'},
                {included:false, feature:'dealhunter'},
                {included:false, feature:'campo para manager'},
                {included:false, feature:'acceso al editor de manager (ilimitado)'}
            ],
            standard:[
                {included:true, feature:'link de perfil público'},
                {included:true, feature:'buscador de influencers y managers (ilimitado)'},
                {included:true, feature:'descripción y tipos de contenido'},
                {included:true, feature:'contacto y ubicación'},
                {included:true, feature:'estadística de redes sociales'},
                {included:true, feature:'fotos descargables'},
                {included:true, feature:'portafolio de marcas'},
                {included:false, feature:'conferencias/cursos'},
                {included:false, feature:'prensa'},
                {included:false, feature:'eventos'},
                {included:false, feature:'empresas'},
                {included:true, feature:'reseñas de clientes'},
                {included:true, feature:'dealhunter'},
                {included:true, feature:'campo para manager'},
                {included:false, feature:'acceso al editor de manager (ilimitado)'}
            ],
            premium:[
                {included:true, feature:'link de perfil público'},
                {included:true, feature:'buscador de influencers y managers (ilimitado)'},
                {included:true, feature:'descripción y tipos de contenido'},
                {included:true, feature:'contacto y ubicación'},
                {included:true, feature:'estadística de redes sociales'},
                {included:true, feature:'fotos descargables'},
                {included:true, feature:'portafolio de marcas'},
                {included:true, feature:'conferencias/cursos'},
                {included:true, feature:'prensa'},
                {included:true, feature:'eventos'},
                {included:true, feature:'empresas'},
                {included:true, feature:'reseñas de clientes'},
                {included:true, feature:'dealhunter'},
                {included:true, feature:'campo para manager'},
                {included:true, feature:'acceso al editor de manager (ilimitado)'}
            ],
        },
        managers:{
            menu:[{id:1, label:'Acesso por un día'}, {id:2, label:'Subscripción mensual'}, {id:3, label:'Subscripción anual'}],
            premium:[
                {included:true, feature:'buscador de influencers (ilimitado)'},        
                {included:true, feature:'dealhunter'},
            ],
            day_access:[
                {included:true, feature:'buscador de influencers (acceso por un día)'},        
                {included:true, feature:'dealhunter (acceso por un día)'},
            ],
        }
    },
    sectionF:{
        title: 'De InFormal a InFluencer...',
        text_1:'El número de influencers crece día a día, y en el mundo del marketing, cada segundo cuenta.',
        text_2:{
            _1:'InPager ayuda a influencers, managers, y marcas a establecer ',
            _2:'colaboraciones rentables ',
            _3:'más fácil que nunca, ',
            _4:'en cuestión de minutos',
            _5:', con información clara, precisa, y transparente.'
        },
        text_3:'No más dolores de cabeza, confianza a ciegas, o disparos en la oscuridad.',
        alt: 'telefono con pagina de InPager',
        image: mobile,
        backround_image: InPager_Isotipo_cut
    },
    sectionG:{
        title: '¡Una herramienta con la que todos ganan!',
        text:'InPager ahorra tiempo, labor de convencimiento, y resulta en inversiones más inteligentes para las partes involucradas: desde creadores de contenido hasta empresas reconocidas.',
        card_1: {
            title: 'Influencer',
            text: 'Crea tu perfil, vincula tus redes, y muestra tus métricas de engagement actualizadas en tiempo real para ganar confianza y credibilidad.',
            bottom_text: '¡Más marcas y puertas abiertas!',
            icon: infuencer_icon
        },
        card_2: {
            title: 'Manager',
            text: 'Gestiona tu talento desde un mismo lugar, con orden y sin estrés. ',
            bottom_text: '¡Menos mareos, malabares y media kits!',
            icon: manager_icon
        },
        card_3: {
            title: 'Marca',
            text: 'Prospecta influencers afines a tu negocio, con acceso a sus métricas legítimas, y trata con su equipo directamente, sin perder tiempo con intermediarios.',
            bottom_text: '¡Mejores meetings, en minutos!',
            icon: brand_icon
        },
        button: 'Prueba Inpager'
    },
    sectionH:{
        text:'Dale mayor legitimidad y profesionalismo a tu negocio mostrando tus conferencias, cursos y eventos próximos.',
        image: check
    },
    sectionI:{
        image: macbook_web,
        text1:'Agrega tus proyectos destacados con empresas a tu perfil para que tus números y trayectoria hablen por ti.',
        text2: {
            _1:'InPager es un portafolio profesional para influencers que puedes comenzar a compartir hoy mismo, y ',
            _2:'el último media kit que necesitarás en tu carrera.'
        },
        text3: '¿La mejor parte? Es gratis, se actualiza solo, y cabe en tu bio.',
        button:'Crea tu InPager'
    },
    sectionJ:{
        title1:'Ya nos conoces...',
        title2:' ahora, ¡ÚNETE A NOSOTROS!',
        text1:'Crea tu perfil de ',
        text2:'InPager',
        text3:' y envíalo a todas las marcas con quienes quieras colaborar.',
        button:'¡ÚNETE AHORA!'
    },
}

export default translations