import React from 'react'
import SimpleModal from '../../../components/Modals/SimpleModal'
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import RoundedButton from '../../../components/Actions/RoundedButton'
import deleteSvg from '../../../assets/delete.svg'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import useReviews from './useReviews'
import { useEffect } from 'react'


const DeleteReviewModal = ({open, onClose, deleteId, master_actions}) => {

    const { classes } = useStyles()

    const { actions, system } = useReviews(master_actions)

    useEffect( () => {
        system.setSuccess(null)
        system.setError(null)
    }, [open])

  return (
    <SimpleModal open={open} onClose={onClose}>
        <Grid container xs={12} className={classes.container}>
            <SVGIcon src={deleteSvg} size={60}/>

            <Grid container xs={12} className={classes.title}>
                <Typography variant='h6'>¿Seguro que quieres eliminar la reseña?</Typography>
            </Grid>

            {system.success && 
                <Grid xs={12} className={classes.success}>
                    <Typography variant='subtitle2'>{system.success}</Typography>
                </Grid>
            }

            {system.error && 
                <Grid xs={12} className={classes.error}>
                    <Typography color='red' variant='subtitle2'>{system.error}</Typography>
                </Grid>
            }

            <Grid xs={12} className={classes.button}>
                <RoundedButton onClick={() => actions.onDeleteReview(deleteId)} loading={system.sending}>
                    Eliminar
                </RoundedButton>
            </Grid>
        </Grid>
    </SimpleModal>
  )
}

export default DeleteReviewModal

const useStyles = makeStyles()(theme => ({
    container:{
        justifyContent:'center',
    },
    title:{
        textAlign:'center',
        marginTop:'30px'
    },
    button:{
        marginTop:'30px',
        textAlign:'center',
    },
    success:{
        textAlign:'center',
        marginTop:'15px',
        color:'primary'
    },
    error:{
        textAlign:'center',
        marginTop:'15px',
        color:'red'
    }
  }));