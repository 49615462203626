import React, { useState } from 'react';
import { Avatar, Grid, Icon, Popover, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CONFIG_SETTINGS } from '../../config';
import {  grey } from '@mui/material/colors';
import nouser from '../../assets/nouser.png'
import { isValidDocument, onGetFullname } from '../../shared/utility';
import { catalogs } from '../../texts/esp/catalogs';

const UserButton = ({mono, user, onItemSelected, isauth, content, ismobile, onUpdateModal, mode}) => {

    const { classes, cx } = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerItemSelected = (url) => {
        setAnchorEl(null)
        onItemSelected(url)
    }
    
    const onInnerClose = () => {
        setAnchorEl(null)
    }
    

    let userContent = null
    let isimagevalid = isValidDocument(user ? user.image : null)
    let fullname = user ? onGetFullname(user.first_name, user.last_name) : ''
    let userType = ''
    if(user){
        const el = catalogs.user_types.find(el => el.value === user.user_type_id)
        if(el) userType = el.label
    }


    if(!user || !isimagevalid){
        userContent = (
            
                <Avatar src={nouser} alt='' className={classes.avatar}/>
            
                
            
        )
    }else{
        const imgSrc = user.image ? `${CONFIG_SETTINGS.S3_SERVER_URL}${user.image}` : null
        userContent =  <Avatar src={imgSrc} alt='' className={classes.avatar} />
    }

    const menu_options = JSON.parse(JSON.stringify(content.user))

    return ( 
        <div>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onInnerClose} classes={{paper:classes.paper}}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                <div className={classes.popper_container}>
                    <Grid container>
                        {menu_options.map(item => {
                            let enabled = true 
                            if(item.permissions.length && user){
                                const findpermission = item.permissions.find(el => el === user.user_type_id)
                                
                                if(!findpermission){
                                    enabled = false
                                }
                            }
                            if(!enabled) return null
                            return(
                                <Grid item xs={12} key={`top-menu-item-${item.id}`}>
                                    <OptionComponent {...item} onItemSelected={onInnerItemSelected} onUpdateModal={onUpdateModal}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </Popover>
            <div className={cx({
                [classes.card]:true,
                [classes.card_mono]:mono,
                [classes.card_dark]:mode === 'dark' && !mono
            })} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Grid container alignItems='center' spacing={0}>
                    <Grid item>
                        <div className={classes.avatar_container}>
                            {userContent}
                        </div>
                    </Grid>
                    {ismobile ? null : 
                    <Grid item>
                        <Typography className={classes.name}>{fullname}</Typography>
                        <Typography className={cx({
                            [classes.user_type]:true,
                            [classes.mono]:mono
                        })} >{userType}</Typography>
                    </Grid>}
                    
                    <Grid item>
                        <div className={cx({
                            [classes.icon_container]:true,
                            [classes.icon_container_mono]:mono
                        })}>
                            <Icon color='inherit' className={cx({
                            [classes.icon]:true,
                            [classes.mono]:mono
                        })}  >keyboard_arrow_down</Icon>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    card:{
        borderRadius:48,
        cursor:'pointer',
        boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.25)',
        color:'black',
        '&:hover':{
            background: grey[100]
        }
    },
    card_dark:{
        color:'black',
        boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.25)',
    },
    card_mono:{
        boxShadow:`0px 0px 2px #666`,
        color: theme.palette.primary.main,
        '&:hover':{
            background: 'transparent'
        }
    },
    avatar_container:{
        position:'relative',
        marginRight:8
    },
    avatar:{
        width:48,
        height:48,
        background:'#FFF'
    },
    container:{
        marginTop:8,
        borderRadius:16,
        
    },
    paper:{
        marginTop:4,
        borderRadius:12,
        width:250,
    },
    popper_container:{
        padding:'12px 0px'
    },
    name:{
        fontSize:14,
        fontWeight:600, 
    },
    user_type:{
        fontSize:12,
        fontWeight:500, 
        color:theme.palette.primary.main
    },
    mono:{
        color: '#666'
    },
    status:{
        width:12,
        height:12,
        background: '#21AB1E',
        position:'absolute',
        border:'2px solid white',
        bottom:0,
        right:0,
        borderRadius:'50%',
    },
    icon_container:{
        border:`1px solid ${theme.palette.primary.main}`,
        width:30,
        height:30,
        borderRadius:'50%',
        marginLeft:16,
        marginRight:8,
        color:theme.palette.primary.main,
        position:'relative',
        [theme.breakpoints.down('md')]:{
            marginLeft:4
        },
    },
    icon_container_mono:{
        border:`1px solid #666`,
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    }
}));

export default UserButton;

const useStyles4Option = makeStyles()(theme=>({
    root:{
        position:'relative',
        
        paddingLeft:theme.spacing(3),
        paddingRight:theme.spacing(3),
        fontFamily:theme.typography.fontFamily,
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            fontWeight:700,
            background:theme.palette.primary.light,
            transition:theme.transitions.create(['background','font-weight'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    label_container:{
        padding:'12px 0px',
        
    },
    border:{
        borderTop:`1px solid ${grey[200]}`
    },
    icon:{color:theme.palette.primary.main}
}));

const OptionComponent = props => {

    const {label, onItemSelected, url, top_line, modal, _ref, onUpdateModal, target} = props
    const { classes, cx } = useStyles4Option()

    return(
        <div>
            {target ? 
            <a href={url} target={target} style={{textDecoration:'none', color:'black'}} ><div className={cx({
                [classes.root]:true,

            })} >
                <div className={cx({
                [classes.label_container]:true,
                [classes.border]:top_line,
                })}>
                    {label}
                </div>
            </div></a> :
            <div className={cx({
                [classes.root]:true,

            })} onClick={modal && _ref ? () => onUpdateModal({[_ref]:true}) : () => onItemSelected(url)}>
                <div className={cx({
                [classes.label_container]:true,
                [classes.border]:top_line,
                })}>
                    {label}
                </div>
            </div> 
            }
        </div>
    )
}