import React, { useState } from 'react';
import { Grid, IconButton, Typography, Icon, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';

const ContainerSocialMediaOption = ({ children, icon, title, width, message, addedDisclaimer, actionAttach, actionAttachBusiness, enable }) => {
  const { classes } = useStyles();

  const [hidden, setHidden] = useState(true);

  return(
    <div className={classes.containerSocialMedia}>
      <Grid xs={12} container>
        <Grid xs={1} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <img src={icon} alt='' width={width}/> 
        </Grid>

        <Grid xs={10} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <Typography variant='subtitle2'>{title}</Typography>
        </Grid>

        <Grid xs={1} style={{display: 'flex', justifyContent: 'flex-end'}}>
          <IconButton color='primary' size='small' iconSize='10' onClick={() => setHidden(!hidden)}>
            {hidden
            ? <Icon style={{fontSize: '30px'}}>add_circle_icon</Icon>
            : <Icon style={{fontSize: '30px'}}>remove_icon</Icon>
            }
            
          </IconButton>
        </Grid>
      </Grid>   

      {!hidden &&
        <>
          {children}
          <Grid xs={12} container style={{marginLeft: 25, marginTop: 15}} alignItems='flex-end'>
            <Grid itme md={2} xs={4} container spacing={1} direction='column'>
              <Grid item>
              <Button className={classes.button} onClick={() => {
                if(actionAttach){
                  actionAttach()
                }
              }} disabled={message === 'PRÓXIMAMENTE'}>
                <Typography variant='body2'>
                  {enable ? 'Desvincular' : 'Vincular'}
                  
                  <br></br>{title === 'Apple Podcast' ? 'Podcast' : title}</Typography>
                </Button>
              </Grid>


              {actionAttachBusiness &&
                <Grid item>
                <Button className={classes.button} onClick={() => { actionAttachBusiness() }}>
                  <Typography variant='body2'>
                    {enable ? 'Desvincular' : 'Vincular'} <br/>{title === 'Apple Podcast' ? 'Podcast' : title} Businnes
                    </Typography>
                </Button>
                </Grid>
              }
                              
            </Grid>
            
            <Grid item md={9} xs={7} container direction='column'  justifyContent='center' spacing={2} style={{marginLeft: 15}}>
              <Grid item><Typography variant='subtitle3'>{message}</Typography></Grid>
              <Grid item ><Typography variant='subtitle3'>{addedDisclaimer}</Typography></Grid>
            </Grid>
          </Grid>
        </>
      }          
    </div>
  )
};

export default ContainerSocialMediaOption;

const useStyles = makeStyles()(theme => ({
  containerSocialMedia: {
    border: '1px solid black',
    width: '94%',
    borderRadius: 12,
    padding: '10px 15px',
    [theme.breakpoints.down('md')]: {
      width: '85%'
    }
  },
  button:{
    background:theme.palette.primary.main,
    fontFamily:theme.typography.fontFamily,
    color:'white',
    fontSize:'1rem',
    borderRadius: 5,
    textTransform:'none',
    width: '100%',
    '&:hover':{
        background:theme.palette.primary.dark,
    },
    '&:disabled':{
      background:grey[300],
      color:grey[500],
      cursor:'not-allowed'
  }
  }
}));