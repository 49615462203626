import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AccountView from '../views/Account/AccountView/AccountView'
import BlogsView from '../views/Blogs/BlogsView/BlogsView'
import NewSearch from '../views/Influencers/Influencers/NewSearch'
import InvitationsView from '../views/Invitations/Invitations/InvitationsView'
import ManagersView from '../views/Manangers/Managers/ManagersView'
import ReviewsView from '../views/Reviews/Reviews/ReviewsView'
import NewInfuencerView from '../views/NewInfuencer/NewInfuencerView/NewInfuencerView'
import { USER_TYPES } from '../config'
import Settings from '../views/Influencers/Influencers/Settings'
import Dashboard from '../views/Dashboard/Dashboard'
import TiktokRedirect from '../views/TiktokRedirect/TiktokRedirect';


const baseUrl = '/dash'

const DashboardRouter = (user_type) => {

    return(
        <Switch>
            <Route exact path={`${baseUrl}/account`} component={AccountView} />
            <Route exact path={`${baseUrl}/board`} component={Dashboard} />
            <Route exact path={`${baseUrl}/influencers/:id`} component={AccountView} />
            <Route exact path={`${baseUrl}/influencers`} component={NewSearch} />
            <Route exact path={`${baseUrl}/configuracion`} component={Settings} />
            <Route exact path={`${baseUrl}/managers/:id`} component={AccountView} />
            <Route exact path={`${baseUrl}/managers`} component={ManagersView} />
            <Route exact path={`${baseUrl}/blog`} component={BlogsView} />
            <Route exact path={`${baseUrl}/reviews`} component={ReviewsView} />
            <Route exact path={`${baseUrl}/invitations`} component={InvitationsView} />
            <Route exact path = {`${baseUrl}/tiktok`} component = {TiktokRedirect}/>
            <Route exact path={`${baseUrl}/newinfuencer`} component={NewInfuencerView} />
            <Redirect to={`${baseUrl}/influencers`} />
            {/* {user_type.user_type === 4 ? <Redirect to={`${baseUrl}/influencers`} /> : <Redirect to={`${baseUrl}/account`} />} */}
        </Switch>
    )
}

export default DashboardRouter

