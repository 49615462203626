import { useState, useEffect } from "react"
import { private_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"

const useEditDescriptionDataModal = ({ open, language, content, profile, master_actions, owner, origin}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [image, setImage] = useState(null)
    const [preview, setPreview] = useState(null)
    const [file, setFile] = useState(null)

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
            setImage(null)
            setPreview(null)
            setFile(null)
        }
        
    }, [open])


    
    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form, origin, content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImage: async(_file) =>{
            try {
                setImage(_file)
                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    // convert image file to base64 string
                    setPreview(reader.result)
                }, false);
                if (_file) reader.readAsDataURL(_file);
                
            } catch (error) {
                
            }
        },
        onChangeFile: async(_file) =>{
            setFile(_file)
        },
        onResetError: () => {
            setError(null)
        },
        onResetImage: () => {
            setImage(null)
            setPreview(null)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)
            const {id_user} = origin

            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetSelectedFormData(form, origin)
            if(data2send.description === '') data2send.description = '@null!!'


            setSending(true)
            let request_url = owner ? `/user/me` : `/user/${origin.id_user}`
            // Create first the event
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(request_url, data2send)
                setError(null)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }        

            await master_actions.onGetUserData()
            await master_actions.onUpdateModalStatus('edit_specific_description', false)

            setSending(false)

        }
    }

    const view_data = {preview, file}
    const system = {loading, sending, error}

    return {system, form, actions, view_data}
}

export default useEditDescriptionDataModal

const form_data = {
    description:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'description',
            type:'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules:{
            type:'distance',
            min:0, max:256
        }
    },

}
