import { useState, useEffect } from "react"
import { private_server, public_server } from "../../config"
import { isFormValid, onGetCurrentUserProfile, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility"
import queryString from 'query-string'

const useSignInLayout = ({ language, content, onUpdateAuthStatus, onUpdateUserData, history}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [referal_code, setReferalCode] = useState('asdfas')
    const [user_type, setUserType] = useState()

    useEffect(() => {
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            try {
               const urlArgs = queryString.parse(history.location.search)
               const _token = urlArgs.token
               const data2send = {
                   token: _token,
                   device:'web'
               }

               const confirmation_request = await public_server.post('/user/confirm', data2send)
               const _user = confirmation_request.data.data
               const user_req = await public_server.get(`/user/url/public/ns?url=${_user.username}`)
               const user = user_req.data.data.user
               setReferalCode(user.referal_code)
               setUserType(user.user_type_id)
               setError(false)
                

            } catch (error) {
                setError(true)
            }
            //const _form = onInitForm(form, null,  content.form)
            //setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onKeyPress: (event) => {
            if(event.key === 'Enter'){
              actions.onSubmit()
            }
        },
        onSubmit: async() => {

            history.push('/')

        }
    }
    const view_data = {referal_code, user_type}
    
    const system = {loading, sending, error}

    return {system, form, actions, view_data}
}

export default useSignInLayout

const form_data = {
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance', min:1, max:256
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password',
            type:'password',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
}
