import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import NoContent from '../../../../../components/Platform/NoContent';
import LoadingContainer from '../../../../../components/Structure/Layouts/LoadingContainer';
import AddConferenceModal from '../../modals/AddConferenceModal/AddConferenceModal';
import DeleteConferenceModal from '../../modals/DeleteConferenceModal';
import EditConferenceModal from '../../modals/EditConferenceModal/EditConferenceModal';
import ConferenceToolbar from './components/ConferenceToolbar';
import EventCard from './components/EventCard';
import useConference from './useConference';


const Conference = (props) => {

    //const content = translations_esp
    const { classes } = useStyles()

    const {system, view_data, actions, modals} = useConference({...props})
    const {language, content, master_view_data} = props


    return ( 
        <div>
            <AddConferenceModal open={modals.add_event} actions={actions} language={language} view={view_data.view}
            profile={master_view_data.profile} owner={master_view_data.owner} 
            onClose={()=>actions.onUpdateModalStatus('add_event', false)} master_actions={actions} />
            <EditConferenceModal open={modals.edit_event} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.event} view={view_data.view}
            onClose={()=>actions.onUpdateModalStatus('edit_event', false)} master_actions={actions} />
            <DeleteConferenceModal open={modals.delete_event} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.event} view={view_data.view}
            onClose={()=>actions.onUpdateModalStatus('delete_event', false)} master_actions={actions} />

            <div style={{marginBottom:16}}>
                <ConferenceToolbar selected={view_data.view} modify={master_view_data.modify} actions={actions}/>
            </div>
            <LoadingContainer loading={system.loading}>
                
                
                <Grid container spacing={3}>
                    {view_data.events.map((item) => {
                        return(
                            <Grid item xs={12} key={item.id_event}>
                                <EventCard {...item} key={item.id_event} modify={master_view_data.modify}
                                onEdit={() => actions.onEditEvent(item)} 
                                onDelete={() => actions.onDeleteEvent(item)}
                                onDownload={() => actions.onDownloadEvent(item)}/>
                            </Grid>
                        )
                    })}
                    
                </Grid>
                {view_data.events.length === 0 ? (
                        <div style={{paddingBottom:32}}>
                            <Grid container justifyContent='center'>
                                <Grid item xs={12}>
                                    <NoContent />
                                </Grid>
                                {master_view_data.modify ? <Grid item>
                                    <RoundedButton onClick={()=>actions.onUpdateModalStatus('add_event', true)}>
                                        Agregar
                                    </RoundedButton>
                                </Grid> : null}
                            </Grid>
                            
                        </div>
                ) : null}
            </LoadingContainer>

           
        </div>
        
     );
}

const useStyles = makeStyles()(theme => ({
    topbar:{
        marginBottom:40
    }
}));



export default Conference;