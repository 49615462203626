import { Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import icon from '../../../assets/icons/placeholder/nodata.png'

const NoAnalyticsMessage = () => {
    return ( 
        <div style={{margin:'70px'}}>
            <Grid container direction='column' spacing={1} alignItems='center'>
                <Grid item>
                    <Typography variant='h4' color='primary'>Oops!</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2'>
                        No se encontró información <br></br> suficiente. Intenta más tarde.
                    </Typography>
                </Grid>
            </Grid>
        </div>
     );
}
 
export default NoAnalyticsMessage;