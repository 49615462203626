import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import useEditDescriptionDataModal from './useEditDescriptionDataModal';
import translations from '../../../../../texts/system_translations';
import InputForm from '../../../../../components/Forms/InputForm';
import InputDateForm from '../../../../../components/Forms/InputDateForm';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import InputSelectForm from '../../../../../components/Forms/InputSelectForm';
import { Scrollbars } from 'react-custom-scrollbars-2';

const EditDescriptionDataModal = (props) => {

    const { classes } = useStyles()
    const {language, open, onClose, profile, master_actions, owner, origin} = props
    const content =  translations[language].modals.edit_profile_description


    const {system, form, actions, view_data} = useEditDescriptionDataModal({...props, content})
 
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Typography variant='h6' style={{marginBottom:16}}>{content.title}</Typography>
                <Typography variant='subtitle2'>{content.message}</Typography>
                <div className={classes.container}>
                        <div className={classes.content}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputForm data={form.description} onChange={actions.onChangeForm}/>
                                </Grid>
                            </Grid>
                        </div>
                    <div style={{marginTop:16}}>
                        <ActionModalBar btnLabel={content.edit_button} error={system.error} loading={system.sending}
                        onSubmit={actions.onSubmit} onCloseError={actions.onResetError}  />
                    </div>

                </div>
                
                
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16,
        //maxHeight:400,
        paddingRight:16
    },
    container:{
        //height:400
    }
}));



export default EditDescriptionDataModal;