import { Icon, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const CategoryChip = ({name, category_name, onRemove, editable, influencer_type}) => {

    const { classes } = useStyles()

    return ( 
        <div className={classes.root} style={{paddingRight:editable ? 8 : 10}}>
            {name}{category_name}{influencer_type}
            {editable ? 
            <IconButton size='small' style={{marginLeft:4}} onClick={onRemove}>
                <Icon fontSize='small' >close</Icon>
            </IconButton>
            : null}
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        background:'#F1F1F1',
        padding:'4px 10px',
        paddingRight:8,
        borderRadius:32,
        fontSize:14
    }
}));

export default CategoryChip;