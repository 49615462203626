import { useState, useEffect } from "react"
import { private_server, public_server, USER_TYPES } from "../../config"
import { isFormValid,  onGetErrorMessage, onGetFormData,  onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility"

const useSignInLayout = ({  content, onUpdateAuthStatus, onUpdateUserData, history}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    useEffect(() => {
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            const _form = onInitForm(form, null,  content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onKeyPress: (event) => {
            if(event.key === 'Enter'){
              actions.onSubmit()
            }
        },
        onSubmit: async(google_facebook = false,object_google = false,object_facebook = false) => {


            //if login with google or facebook, we dont verified the form fields
            let data2send = {device:'web',object_google:false,object_facebook:false}
            if(google_facebook){
                
                data2send.username = 'google_facebook'
                data2send.password = 'google_facebook'

                if(object_google) data2send.object_google = object_google
                
                if(object_facebook) data2send.object_facebook = object_facebook
            }else{
                const errors = isFormValid(form)
                if(errors && errors.length){
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    //setError(generic_errors[language].INCOMPLETE_FORM)
                    return
                }
                data2send = {...data2send,...onGetFormData(form)}
            }

            setSending(true)

            try {
                const response = await public_server.post(`/login`, data2send)

                const token_data = response.data.data
                await onSetTokenSession(token_data)
                updateAuthorizationHeader(private_server)
                const user_request = await private_server.get('/user/me')
                onUpdateUserData(user_request.data.data.user)
                onUpdateAuthStatus(true)
                if(user_request.data.data.user.user_type_id === USER_TYPES.AGENCY || user_request.data.data.user.user_type_id === USER_TYPES.BRAND ){
                    history.push('/dash/influencers')
                }else{
                    history.push('/dash/account')
                }
                setError(null)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)

                //If has error 401(user not found) and the login is with google or facebook, redirect to register
                if(_error.code == 401 && google_facebook){
                    history.push('/signup')
                }
                setError(_error ? _error.message : null)
            }

            setSending(false)
        }
    }

    const system = {loading, sending, error}

    return {system, form, actions}
}

export default useSignInLayout

const form_data = {
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance', min:1, max:256
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password',
            type:'password',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
}
