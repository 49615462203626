import { Grid, Typography } from "@mui/material"
import ImageContainer from '../../components/Ecommerce/ImageContainer'
import SimpleCard from '../../components/Structure/Cards/SimpleCard'
import SmallLink from "../Actions/SmallLink"



const ProfilePicturesCard = ({images, onEdit, onAdd, onSeeAll, modify}) => {


    let _images = images ? images : []

    return(
        <SimpleCard>
            <div>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='subtitle1'>Fotos</Typography>
                    </Grid>
                    <Grid item>
                        <SmallLink onClick={onSeeAll} >Ver todo</SmallLink>
                    </Grid>
                </Grid>
               
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {_images.length !== 0 ? <ImageContainer ratio='10/4' download_path = {_images[0].download_path} title = {_images[0].title} src={_images[0].image} borderRadius={8} onEdit={()=>onEdit(_images[0])} modify={modify}/> :
                        modify ? <ImageContainer onUpload={onAdd} modify={modify}/> : null}
                    </Grid>
                    {_images.length >= 1 ? <Grid item xs={4}>
                        {_images.length >=2 ? <ImageContainer download_path = {_images[1].download_path} title = {_images[1].title} src={_images[1].image} borderRadius={8} onEdit={()=>onEdit(_images[1])} modify={modify}/> : 
                        modify ? <ImageContainer onUpload={onAdd} modify={modify}/> : null}
                    </Grid> : null}
                    {_images.length >= 2 ? <Grid item xs={4}>
                        {_images.length >=3 ? <ImageContainer download_path = {_images[2].download_path} title = {_images[2].title} src={_images[2].image} borderRadius={8} onEdit={()=>onEdit(_images[2])} modify={modify}/> : 
                        modify ? <ImageContainer onUpload={onAdd} modify={modify}/> : null}
                    </Grid> : null}
                    {_images.length >= 3 ? <Grid item xs={4}>
                        {_images.length >=4 ? <ImageContainer download_path = {_images[3].download_path} title = {_images[3].title} src={_images[3].image} borderRadius={8} onEdit={()=>onEdit(_images[3])} modify={modify}/> : 
                        modify ? <ImageContainer onUpload={onAdd} modify={modify}/> : null}
                    </Grid> : null}
                </Grid>
            </div>
        </SimpleCard>
    )
}

export default ProfilePicturesCard