import { useState, useEffect } from "react";
import { onInitForm, isFormValid, onSetErrorsToForm, onGetSelectedFormData, updateAuthorizationHeader, onGetErrorMessage } from "../../../shared/utility";
import { generic_errors } from "../../../texts/esp/genericErrors";
import { private_server, public_server } from "../../../config";


const useModalsManager = (language, open, content, master_actions, onUpdateUserData) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [error, setError] = useState(null)
    const [sending, setSending] = useState(false)
    const [send, setSend] = useState(false)

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setForm(JSON.parse(JSON.stringify(form_data)))
        }
        
    }, [open])

    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form, origin, content.form)
            
            setForm(_form)
        },
        onChangeForm: (data,full = false) => {

            if(!full){
                let temp = { ...form }
                const id = data.config.id
                temp[id] = { ...data }
                if(error) setError(null)
                setForm(temp)
            }else{
                setForm(data)
            }
        },
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetSelectedFormData(form, origin)

            setSending(true)

            // Create first the event
            try {
                updateAuthorizationHeader(public_server)
                // consulta al back
                const response = await public_server.get(`/user/get-user/${data2send.username_manager}/${data2send.email}`)

                data2send.manager_id = response.data.data.user.id_user
                data2send.direction = 1

                try {
                    updateAuthorizationHeader(private_server)

                    const response = await private_server.post('/invitation/manager/me', data2send)

                    setSend(true)
                } catch (error) {
                    setSending(false)
                    const _error = onGetErrorMessage(error)
                    setError(_error ? _error.message : null)
                }

                setError(null)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }  

            const _profile =  await master_actions.onGetUserData()
            onUpdateUserData(_profile)
            setSending(false)
            master_actions.onUpdateModalStatus('add_manager', false);
        },
    }

    const system = { sending, error, send, setSend }
    
    return { form, actions, system }
}

const form_data = {
    username_manager:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username_manager',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'email',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email',
        }
    },
}

export default useModalsManager