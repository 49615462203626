import React, { useState } from "react";
import { Icon, Typography, Avatar, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_SETTINGS } from "../../../config";
import SVGIcon from "../../../components/Structure/DisplayData/SVGIcon";
import manager_icon from '../../../assets/icons/manager.svg'
import RoundedButton from "../../DealHunter/components/RoundedButton";
import managerSvg from '../../../assets/manager.svg';
import ProfileRating from "../../../components/Platform/ProfileRating";


const Manager = ({manager_data, actions, system, managerSrc, average_ratingManager, influencers, history, manager_types}) => {

    const { classes } = useStyles()

    let types = manager_types.map(type => type.influencer_type).join(', ')

    return (
        <Grid item container spacing={2} xs={12} className={classes.option}>
            { !manager_data 
            ?   <Grid container xs={12} className={classes.container}>
                    <Grid container xs={12} className={classes.icon}>
                        <SVGIcon item size={71} src={manager_icon}></SVGIcon>
                    </Grid>

                    <Grid container xs={12} className={classes.text}>
                        <Typography variant='subtitle2' className={classes.manager}>Aún no agregas un manager que te represente.</Typography>
                    </Grid>

                    <Grid container xs={12} className={classes.button}>
                        <RoundedButton onClick={() => actions.onUpdateModalStatus('add_manager', true)} size='small'>
                            <Icon>add_icon</Icon>
                            <Typography variant='subtitle2'>Agregar manager</Typography>
                        </RoundedButton>
                    </Grid>
                </Grid>
            : 
              <Grid container spacing={1} xs={12} className={classes.container}>
                    {managerSrc && 
                    <Grid item xs={7} md={2}>
                        <Avatar src={managerSrc} className={classes.avatar}/>
                    </Grid>
                    }
                    {!managerSrc &&
                        <Grid xs={7} md={2} item className={classes.managerImage}>
                            <Typography item>Manager</Typography>
                            <Grid container className={classes.managerImage}>
                                <SVGIcon item size={100} src={managerSvg}></SVGIcon>
                            </Grid>
                        </Grid>
                    }

                <Grid container xs={12} md={7} spacing={5} className={classes.content}>
                    <Grid container xs={12} className={classes.name}>
                        <Typography item variant='h5'>{manager_data.first_name}</Typography>
                    </Grid>

                    <Grid container xs={12}>
                        <ProfileRating average_rating={average_ratingManager}/>
                    </Grid>
                    
                    <Grid container xs={12} className={classes.item}>
                        <Typography item variant='subtitle2' className={classes.item}>Tipo de figura pública: <Typography variant='caption' color='textSecondary'>&nbsp;{types}</Typography></Typography> 
                    </Grid>
                    
                    <Grid container xs={12}>
                        <Typography item variant='subtitle2' className={classes.item}>No. de figuras públicas: <Typography variant='caption' color='textSecondary'>&nbsp; {influencers.length}</Typography></Typography>
                    </Grid>
                    
                </Grid>

                <Grid item container xs={12} md={3} className={classes.text}>
                    <Grid>
                        <IconButton
                            className={classes.deleteButton}
                            onClick={() => actions.onUpdateModalStatus('delete_manager', true)}
                            size="large"><Icon>delete_icon</Icon></IconButton>
                    </Grid>
                    <Grid xs={12} className={classes.managerProfileButton}>
                        <RoundedButton item onClick={() => history.push(`/dash/managers/${manager_data.url}`)} size='small'>
                            <Typography variant='subtitle2'>Ir al perfil</Typography>
                        </RoundedButton>
                    </Grid>
                </Grid>
              </Grid>}
        </Grid>
    );
}

export default Manager

const useStyles = makeStyles()(theme => ({
    container:{
        width: '100%',
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center',
        textAlign:'center'
    },
    avatar:{
        width:'100%', height:'100%',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        border:'4px solid white',
        
    },
    option:{
        background:'white',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        marginBottom: '30px',
        padding: '15px 25px',
        borderRadius: '15px',
        display:'flex',
        flexDirection:'row'
    },
    content:{
        marginLeft:'15px',
    },
    icon:{
        justifyContent:'center'
    },
    text:{
        alignContent:'center',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center'
    },
    manager:{
        color: '#616161'
    },
    button:{
        alignContent:'center',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center',
        marginTop:'15px'
    },
    managerImage:{
        justifyContent:'center',
        alignContent:'center',
        textAlign:'center',
        alignItems:'center'
    },
    rating:{
        color:'grey',
        marginTop:'3px'
    },
    deleteButton:{
        position:'relative',
        top:-13, right:-80,
        [theme.breakpoints.down('md')]:{
            top:-265, right:-145
        }
    },
    managerProfileButton:{
        marginTop:'25px',
        textAlign:'end'
    },
    name:{
        textAlign:'start'
    },
    item:{
        textAlign:'start'
    }
}));