const translations = {
    title:'Invitación para evaluación',
    message:'Por favor ingrese los datos solicitados para poder enviar la solicitud de evaluación a su cliente dentro de <strong> InPager</strong>',
    submit_button:'Enviar',
    edit_button:'Guardar',
    form:{
        reviewer:{placeholder:'Nombre de la persona', label:'Nombre de la persona', helperText:'Campo requerido'},
        email:{placeholder:'Correo electrónico', label:'Correo electrónico', helperText:'Correo no válido'},
        business:{placeholder:'Empresa (opcional)', label:'Empresa', helperText:''},

    },
}

export default translations