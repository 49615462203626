import nopicture from '../../../assets/image_placeholder.png'


const translations = {
    press:{
        add_title:'Añadir evento de prensa',
        edit_title:'Editar evento de prensa',
    },
    event:{
        add_title:'Añadir evento',
        edit_title:'Editar evento',
    },
    add_button:'Añadir',
    edit_button:'Guardar',
    form:{
        title:{placeholder:'Título', label:'Título', helperText:'Campo requerido'},
        url:{placeholder:'Url', label:'Url', helperText:'Campo requerido'},
        date:{placeholder:'Fecha', label:'Fecha', helperText:'Campo requerido'},
        description:{placeholder:'Descripción', label:'Descripción', helperText:'Campo requerido'},
        event_mode_id:{placeholder:'Modo', label:'Modo', helperText:'Campo requerido'},
        link:{placeholder:'Link', label:'Link', helperText:'Campo requerido'},
        duration:{placeholder:'Duración (min)', label:'Duración (min)', helperText:'Campo requerido'},
        event_type_id:{placeholder:'Tipo de evento', label:'Tipo de evento', helperText:'Campo requerido'},
    },
    nopicture:nopicture
}

export default translations