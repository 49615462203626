import React, { useState } from 'react';
import { Badge, Button, Card, ClickAwayListener, Grid, Grow, Icon, Popper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { blueGrey } from '@mui/material/colors';


const useStyles = makeStyles()(theme => ({
    root:{
        //backgroundColor:'green',
        //width:'70%'
        zIndex:3,
        marginBottom:12
    },
    selected:{
        color:'white',
        borderRadius:8,
        background:theme.palette.primary.dark,
        fontWeight:500,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover':{background:theme.palette.primary.main},
        padding:'8px 16px',
        textTransform:'none'
        //paddingTop:8
        //border:`1px solid ${theme.palette.primary.main}`
    }
    
}));


const PageMenu = (props) => {

    const {selected, onChange, options} = props


    const { classes } = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const onSelectItem = (id) =>{
        setAnchorEl(null)
        onChange(id)
    }

    const buttonContentMobile = (
    <Card >
        <Grid container direction='column' >
        {options.map(item => {
            return (<Grid item xs>
                <CustomButton 
                fullWidth={true}
                number={item.number} 
                selected={item.id === selected} 
                //onClick={() => onChange(item.id)}
                onClick={() => onSelectItem(item.id)}
                >
                    {item.label}
                </CustomButton>
                </Grid>)
        })}
        </Grid>
    </Card>)

    const onGetName = (op , sel) => {
        let name= null
        op.forEach(element => {
            if(sel === element.id)
            name = element.label
        });
        return name
    }

    return (
        <div>
            <Grid container spacing={2} sx={{ display: { xs: 'none', md: 'default' } }}>
                    {options.map(item => {
                        return <Grid item key={item.id.toString()}><CustomButton number={item.number} onClick={() => onChange(item.id)} selected={item.id === selected} >{item.label}</CustomButton></Grid>
                    })}
            </Grid>
                <div className={classes.root} sx={{ display: { md: 'none', xs: 'default' } }}>
                    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-start' disablePortal style={{zIndex:10}} >
                        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                            <Grow in={Boolean(anchorEl)} timeout={350}>
                            {buttonContentMobile}
                            </Grow>
                        </ClickAwayListener>
                    </Popper>
                    <div onClick={(e) => setAnchorEl(e.currentTarget)} >
                        <Button className={classes.selected}>    
                            {onGetName(options, selected)}
                            <Icon >expand_more</Icon>                    
                        </Button>
                    </div>
                </div>
        </div>
    );
}

export default PageMenu;

const useButtonStyles = makeStyles()(theme => ({
    root:{position:'relative'},
    button:{
        //background:blueGrey[50],
        background:'rgba(132, 59, 168, 0.07)',
        color:'black',
        borderRadius:8,
        padding:'8px 16px',
        '&:hover':{background:'rgba(132, 59, 168, 0.09)'},
        textTransform:'none'
    },
    selected:{
        color:'white',
        background:theme.palette.primary.dark,
        fontWeight:500,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover':{background:theme.palette.primary.main}
        //border:`1px solid ${theme.palette.primary.main}`
    }
}));

const CustomButton = props => {
    const { classes, cx } = useButtonStyles()
    const {children, selected, onClick, number, fullWidth} = props



    return(
        <div className={classes.root} > 
            {/* <Badge badgeContent={number} color='primary'> */} 
                <Button onClick={onClick} fullWidth={fullWidth} className={cx({
                    [classes.button]:true,
                    [classes.selected]:selected
                })}>{children}</Button> 
            {/* </Badge> */}
           
        </div>
    )
}