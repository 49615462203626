import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { connect } from 'react-redux';
import FilterButton from '../../../components/Actions/FilterButton';
import CategoryFilterModal from '../../../components/Modals/CategoryFilterModal/CategoryFilterModal';
import GenericFilterModal from '../../../components/Modals/GenericFilterModal/GenericFilterModal';
import GenericChip from '../../../components/Platform/GenericChip';
import GenericSearchBar from '../../../components/Platform/GenericSearchBar';
import ManagerCard from '../../../components/Platform/ManagerCard';
import NoContent from '../../../components/Platform/NoContent';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import Page from '../../../components/Structure/Layouts/Page';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import catalogs from '../../../texts/esp/catalogs';
import system_translations from '../../../texts/system_translations';
import useManagersView from './useManagersView';

const ManagersView = (props) => {

    const {user, history, language, match} = props
    const content = system_translations[language].views.account
    const { classes } = useStyles()
    
    const {system, view_data, actions, modals} = useManagersView({...props})
    const parentprops = {history, language, match, user}

    let contentView = <NoContent />

    if(view_data.influencers && view_data.influencers.length){
        contentView = (
            <div style={{marginTop:32}}>
                <Grid container spacing={3}>
                    {view_data.influencers.map(item => {
                        return (
                            <Grid item xs={12} md={4} key={`influencer-${item.id_user}`}>
                                <ManagerCard {...item} onSelectedUser={actions.onSelectedUser}/>
                            </Grid>
                        )
                         
                    })}
                </Grid>
            </div>
        )
    }


    return ( 
        <Page >
            <div>
                <CategoryFilterModal {...parentprops}
                open={modals.category_filter} onClose={()=>actions.onUpdateModalStatus('category_filter', false)}
                filtered_categories={[...view_data.filters]} master_actions={actions} onUpdateFilter={actions.onUpdateFilters}
                />
                <GenericFilterModal {...parentprops} options={catalogs.influencer_types} title='Por tamaño' 
                message='Seleccione las opciones por las cuales desea filtrar' filter_type='influencer_type_filter'
                open={modals.influencer_type_filter} onClose={()=>actions.onUpdateModalStatus('influencer_type_filter', false)}
                current_filters={[...view_data.filters]} master_actions={actions} onUpdateFilter={actions.onUpdateFilters}
                />
                <GenericFilterModal {...parentprops} options={catalogs.influencer_sizes} title='Por tamaño' 
                message='Seleccione las opciones por las cuales desea filtrar' filter_type='influencer_size_filter'
                open={modals.influencer_size_filter} onClose={()=>actions.onUpdateModalStatus('influencer_size_filter', false)}
                current_filters={[...view_data.filters]} master_actions={actions} onUpdateFilter={actions.onUpdateFilters}
                />
                <Typography variant='h6'>Nuestra comunidad de <strong className={classes.strongword}>managers</strong></Typography>
                <Typography variant='body1' style={{fontSize:20,}}>Estas a un clic de contactar a un especialista que pueda colaborar contigo</Typography>
                <div style={{marginTop:16}}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <GenericSearchBar onRequestSearch={actions.onRequestSearch}/>
                        </Grid>
                        <Grid item>
                            <FilterButton
                                options={[
                                    //{id:'category_filter', label:'Por categoría'},
                                    //{id:'influencer_type_filter', label:'Por tipo'},
                                    {id:'influencer_size_filter', label:'Rango de seguidores'},
                                ]}
                                
                                onSelected={actions.onOpenFilterModal}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div style={{paddingTop:16}}>
                    <Grid container spacing={2}>
                        {view_data.filters.map((item, _key)=> {
                            return(
                                <Grid item>
                                    <GenericChip {...item} editable
                                    onRemove={() => actions.onRemoveSingleFilter(item)}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                <LoadingContainer loading={system.loading}>
                    <div className={classes.content}>
                        {contentView}
                    </div>
                </LoadingContainer>
                
            </div>  
        </Page>
        
     );
}

const useStyles = makeStyles()(theme => ({
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    content:{
        marginTop:16
    },
    strongword:{
        color:theme.palette.primary.main
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagersView);