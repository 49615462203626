import React from 'react'
import { Grid, Typography, Button, Icon } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import InputForm from "../../../../components/Forms/InputForm"
import RoundedButton from '../../../DealHunter/components/RoundedButton'
import InputSelectForm from '../../../../components/Forms/InputSelectForm'


const SettingsForm = ({form, actions, onChangePassword, onDeleteProfile, sending, error, send}) => {
    const { classes } = useStyles()

    return(
        <div id='formulario' hidden='hidden' className={classes.container}>
            <Grid item container spacing={3} justifyContent='space-between'>
                <Grid item xs={6}>
                    <Typography variant='subtitle2'>Tipo de cuenta</Typography>
                    <Typography className={classes.user}>{form.user_type.value}</Typography>
                </Grid>

                <Grid item xs={12} md={7}>
                    <Typography variant='subtitle2'>Nombre completo</Typography>
                    <InputForm data={form.first_name} onChange={actions.onChangeForm}/>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant='subtitle2'>Fecha de nacimiento</Typography>
                    <InputForm data={form.birth_date} onChange={actions.onChangeForm}/>
                </Grid>

                <Grid item xs={12} md={7}>
                    <Typography variant='subtitle2'>Usuario</Typography>
                    <InputForm data={form.username} onChange={actions.onChangeForm}/>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant='subtitle2'>Pais</Typography>
                    <InputSelectForm data={form.country_id} onChange={actions.onChangeForm}/>
                </Grid>
            
                <Grid item xs={12} md={7}>
                    <Typography variant='subtitle2'>Correo electrónico</Typography>
                    <InputForm data={form.email} onChange={actions.onChangeForm}/>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant='subtitle2'>Ciudad</Typography>
                    <InputForm data={form.zone} onChange={actions.onChangeForm}/>
                </Grid>

                <Grid item container xs={12} md={7} alignItems='center' className={classes.change_password}>
                    <RoundedButton item className={classes.button} fullWidth color='grey' onClick={onChangePassword}>
                        <Typography variant='subtitle2'>Cambio de contraseña</Typography>
                        <Icon>change_circle</Icon>
                    </RoundedButton>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant='subtitle2'>Genero</Typography>
                    <InputSelectForm data={form.gender_id} onChange={actions.onChangeForm}/>
                </Grid>

                <Grid container item justifyContent='center'>
                    {error ? <Typography item className={classes.error} variant='subtitle2'>{error}</Typography>
                    : null}
                    {!error && send ? <div> <Typography item color='primary' variant='subtitle2'>Cambios guardados correctamente!</Typography>
                    <Typography item className={classes.change_mail} variant='subtitle1'>Si se cambio el mail, revisar el correo para confirmarlo</Typography></div>
                    : null}
                </Grid>

                <Grid item justifyContent='space-between' container className={classes.buttons}>
                    <Grid container xs={12} md={7} justifyContent='space-between' alignItems='center' alignContent='center'>
                        <Button sx={{color: '#000'}} item className={classes.button} onClick={onDeleteProfile}>
                            <Icon>delete_icon</Icon>
                            <Typography variant='subtitle2'>Eliminar cuenta de InPager</Typography>
                        </Button>
                    </Grid>

                    <Grid container xs={12} md={4} alignItems='center' justifyContent='center'>
                        <RoundedButton item className={classes.button} loading={sending} onClick={actions.onSubmit}>
                            <Typography variant='subtitle2'>Guardar</Typography>
                        </RoundedButton>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles()(theme => ({
    error:{
        color: 'red'
    },
    user:{
        marginTop:'5px',
        border: '1px',
        borderColor:'#bdbdbd',
        borderStyle:'solid',
        padding:'15px',
        borderRadius:'12px'
    },
    form:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    content:{
        marginTop:16
    },
    strongword:{
        color:theme.palette.primary.main
    },
    buttons:{
        marginBottom:'20px'
    },
    back_container:{
        position:'relative',
        width:'100%',
        textAlign:'center',
        color:'white',
        [theme.breakpoints.only('xs')]:{
            height:420,
        }

    },
    back:{
        width:'100%',
        [theme.breakpoints.only('xs')]:{
            height:'100%',
            objectFit:'cover'
        }
    },
    logo:{
        width:280,
        [theme.breakpoints.down('md')]:{
            width:200
        },
        [theme.breakpoints.only('xs')]:{
            width:180
        }
    },
    info_container:{
        position:'absolute',
        top:'45%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        fontSize:'1.5rem',
        width:'85%',
        [theme.breakpoints.down('md')]:{
            fontSize:'1.2rem'
        }
    },
    button:{
        [theme.breakpoints.down('md')]:{
            marginBottom:'12px'
        }
        
    },
    change_password:{
        marginTop:'22px',
        [theme.breakpoints.down('md')]:{
            marginTop:'15px',
            marginBottom:'8px'
        }
    },
    change_mail:{
        fontSize: 10
    }
}));

export default SettingsForm