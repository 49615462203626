import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';


const useStyles = makeStyles()(theme => ({
    moreRoot:{
        background:'#F1F1FA', padding:'100px 0px', position:'relative', overflow: 'hidden', height: 780,
        [theme.breakpoints.down('lg')]:{
            height: '68vh',
            padding:'50px 0px'
        }
    },
    root:{
        //margin:'100px',
        //background:'red',
        //marginTop:100,
        //marginBottom:100,
        width:1400,
        margin:'auto',
        //background:'red',
        [theme.breakpoints.only('lg')]:{
            width:1200,
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            padding:'0px 18px',
            //margin:0
            //padding:'12px 0px',
            //height:300
        },
        [theme.breakpoints.down('md')]:{
            width:'auto',
            margin:0,
            //background:'green'
        },
        //zIndex:20
    },
    title_container:{
        display:'flex',
        fontSize: 80,
        fontWeight: 700,
        maxWidth: '85%',
        fontFamily: 'Raleway',
        [theme.breakpoints.down('lg')]:{
            fontSize: 20
        },
        [theme.breakpoints.down('md')]:{
            display:'inline',
            //background:'red'
        },
    },
    title: {
        marginRight: 8,
        fontSize: 80,
        fontWeight: 700,
        [theme.breakpoints.down('lg')]: {
            fontSize: 30
        }
    },
    contentContainer:{
        marginTop: 50
    },
    subroot:{
        zIndex:10,
        //background:'blue',
        width:'100%',
        height:'100%',
    },
    backgroundPositioner:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top: -200,
        left: 200,
        zIndex:0,
        //background:'blue'
        [theme.breakpoints.down('md')]:{
            display:'none'
            //background:'green'
        },
    },
    background:{
        border:'1px dashed rgba(132, 59, 168, 0.2)',
        borderRadius:'50%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:0
    },
    imageContainer:{
        position: 'relative',
        // [theme.breakpoints.up('md')]:{
        //     maxWidth: '50%',
        // },
        zIndex: 2
    },
    image:{
        [theme.breakpoints.up('md')]: {
        position: 'absolute',
        position: 'absolute',
        width: '120%',
            position: 'absolute',
        width: '120%',
            bottom: 0,
            right: '-10%',
            width: '70%'
        },
        [theme.breakpoints.down('lg')]: {
            position: 'relative',
            maxWidth: '80vw'
        }
    },
    subtitleContainer:{
        [theme.breakpoints.up('md')]:{
            maxWidth: '50%',
            marginTop: 280
        }
    },
    subtitle: {
        fontSize: 42,
        lineHeight: 'normal',
        fontWeight: 700,
        marginBottom: 30,
        [theme.breakpoints.down('lg')]:{
            fontSize: 30,
        }
    },
    arrowButton:{
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        '&:disabled':{
            border:'1px solid #AEAEAE',
            color:'#AEAEAE'
        }
    },
    arrowButtonDisable:{
        border:'1px solid #AEAEAE',
        color:'#AEAEAE'
    },
    buttonsContainer:{
        //background:'green',
        height:'100%',
        alignItems:'center',
        display:'flex',
        textAlign:'center',
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    },
    buttonsContainerSmall:{
        //background:'green',
        height:'100%',
        alignItems:'center',
        display:'flex',
        textAlign:'center',
        [theme.breakpoints.down('md')]:{
            marginTop:50
        },
        [theme.breakpoints.up('md')]:{
            display:'none'
        },

    },
    large:{
        [theme.breakpoints.down('lg')]:{
            display:'none'
        }
    },
    med:{
        [theme.breakpoints.up('lg')]:{
            display:'none'
        },
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
        
    },
    small:{
        [theme.breakpoints.up('md')]:{
            display:'none'
        },
    }
}));

export default useStyles;