import React, {useState, useEffect} from 'react'
import { Grid, Icon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { red } from '@mui/material/colors'
import ActionModalBar from '../../../../components/Actions/ActionModalBar'
import SimpleModal from '../../../../components/Modals/SimpleModal'
import { updateAuthorizationHeader } from '../../../../shared/utility'
import { private_server } from '../../../../config'


const DeleteEventModal = props => {

    const { classes } = useStyles()
    const {open, onClose, origin, master_actions, owner} = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setError(false)
        setLoading(false)
    }, [open])

    const onSubmit = async() => {
        setLoading(true)
        let event_url = owner ? `/event/${origin.id_event}/me` : `/event/${origin.id_event}`
        try {
            updateAuthorizationHeader(private_server)
            await private_server.delete(event_url)
            await master_actions.onGetEvents()
            await master_actions.onUpdateModalStatus('delete_event', false)
        } catch (error) {
            setError('Hubo un error')
        }

        

        setLoading(false)
    }

    return(
        <SimpleModal open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <Icon fontSize='large' className={classes.icon}>clear</Icon>
                            </Grid>
                            <Grid item>
                                <Typography variant='h6'  align='center'>¿Estás seguro?</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1' align='center'> ¿Realmente quieres eliminar este elemento? Este proceso no puede ser revertido</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar loading={loading} error={error} btnLabel='Eliminar'
                         onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

const useStyles = makeStyles()(theme => ({
    icon:{
        color:red[700],
        fontSize:54,
        border:`2px solid ${red[700]}`,
        padding:8,
        borderRadius:'50%'
    },
}));


export default DeleteEventModal