import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Portafolio',
    message:'Por favor ingrese los datos solicitados para agregar el contenido',
    add_button:'Agregar',
    edit_button:'Guardar',
    form:{
        title:{placeholder:'Título', label:'Título', helperText:'Campo requerido'},
        date:{placeholder:'Fecha', label:'Fecha', helperText:'Campo requerido'},
        link:{placeholder:'Link externo', label:'Link externo', helperText:''},
        description:{placeholder:'Descripción', label:'Descripción', helperText:'Campo requerido'},
        
    },
    nopicture:nopicture
}

export default translations