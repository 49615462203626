import { Grid, Typography, Button } from "@mui/material";


import { makeStyles } from 'tss-react/mui';


const SectionJ = ({ content, history }) => {
    const { classes } = useStyles();

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.container}>
            <Grid item xs={12} md={12}>
                <Typography variant="h3">{content.title1}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography variant="h3">{content.title2}</Typography>
            </Grid>
            <Grid item xs={12} md={12} className={classes.text}>
                {content.text1}
                <span className={classes.bold}>{content.text2}</span>
                {content.text3}
            </Grid>
            <Grid item xs={12} md={12}>
                <Button className={classes.button} onClick={() => history.push('/signup')}>{content.button}</Button>
            </Grid>
        </Grid>
    )
}

export default SectionJ;

const useStyles = makeStyles()(theme => ({
    container: {
        position: 'relative',
        padding: '70px 200px',
        [theme.breakpoints.down('lg')]: {
            padding: '30px 20px'
        },
        color: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'hidden',
        fontFamily: 'Raleway'
    },
    button: {
        maxWidth: 361,
        fontFamily: 'Raleway',
        position: 'relative',
        borderRadius: 50,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        textTransform: 'none',
        background: 'rgba(132, 59, 168, 0.00)',
        padding: '12px 65px',
        marginTop: 60,
        marginBottom: 40,
        fontWeight: 700,
        fontSize: 24,
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.light
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 20
        }
    },
    image: {
        zIndex: 1
    },
    backgroundPositioner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: -50,
        left: 0,
        zIndex: 0,
        [theme.breakpoints.down('md')]: {
            display: 'none'

        },
    },
    text: {
        marginTop: 30,
        fontSize: 30,
        fontFamily: 'Raleway',
        [theme.breakpoints.down('lg')]: {
            fontSize: 20
        },
    },
    bold: {
        fontWeight: 700,
        fontFamily: 'Raleway'
    }
}));