import React from 'react'
import { Typography, Icon, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_SETTINGS } from '../../../config'
import ProfileRating from '../../../components/Platform/ProfileRating'
import RoundedButton from '../../DealHunter/components/RoundedButton'
import ReviewCard from './ReviewCard'
import useReviews from '../modals/useReviews'


const Review = ({average_rating, hidden, master_actions, reviews, setHidden}) => {

    const { classes } = useStyles()

    const { actions, system } = useReviews(master_actions)

    const _reviews = reviews.filter(review => review.approved === 0)

    return (
        <Grid container spacing={2} xs={12} className={classes.option}>
            <Grid container xs={12} spacing={1} className={classes.topbar}>
                <Grid className={classes.title}>
                    <Typography variant='h6'>Centro de reseñas &nbsp;</Typography>
                    <ProfileRating average_rating={average_rating}/>
                </Grid>
                <Grid className={classes.title}>
                    <RoundedButton onClick={() => master_actions.onUpdateModalStatus('send_invitation_review', true)} size='small'>
                        <Icon>add_icon</Icon>
                        <Typography variant='subtitle2'>Nueva reseña</Typography>
                    </RoundedButton>
                    <IconButton
                        color='primary'
                        className={classes.iconButtonDownReviews}
                        onClick={() => setHidden(!hidden)}
                        size="large">
                        { hidden 
                        ? <Icon style={{fontSize:'1.8em'}}>expand_circle_down</Icon> 
                        : <Icon style={{fontSize:'1.8em'}}>expand_less_outlined</Icon>}
                    </IconButton>
                </Grid>
            </Grid>

            <Typography>Invita a tus clientes a escribir una reseña sobre ti y aprueba las reseñas que ya se han realizado.</Typography>
            
            { !hidden && _reviews.length !== 0
            ? <Grid container xs={12}>
                {_reviews.map(review => {
                    return(
                        <Grid item xs={12} key={review.id_review}>
                            <ReviewCard review={review} master_actions={master_actions} actions={actions} system={system}/>
                        </Grid>
                    )
                })}
              </Grid>
            : null}

            { !hidden && _reviews.length === 0
            ? <Typography variant='caption'>No hay reseñas pendientes por aprobar!</Typography>
            : null}
        </Grid>
    );
}

export default Review

const useStyles = makeStyles()(theme => ({
    container:{
        width: '100%',
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center',
        textAlign:'center'
    },
    avatar:{
        width:'100%', height:'100%',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        border:'4px solid white',
        
    },
    option:{
        background:'white',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        marginBottom: '30px',
        padding: '15px 25px',
        borderRadius: '15px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        alignText:'center'
    },
    content:{
        marginLeft:'20px',
        display:'flex',
        flexDirection:'column',
        padding:'10px 0px'
    },
    text:{
        alignContent:'center',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center'
    },
    manager:{
        color: '#616161'
    },
    button:{
        alignContent:'center',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center',
        marginTop:'15px'
    },
    title:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
    },
    topbar:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        [theme.breakpoints.down('md')]:{
            justifyContent:'center',
            alignContent:'center'
        }
    },
    iconButtonDownReviews:{
        marginLeft:'5px'
    },
}));