import React, { useEffect } from 'react';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import Footer from '../../components/Structure/Navigation/Footer';
import system_translations from '../../texts/system_translations';
import { alpha, Fade, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useSignUpLayout from './useSignUpLayout';
import RegisterMethodSection from './views/RegisterMethodSection';
import { grey } from '@mui/material/colors';
import useAuth from '../../shared/useAuth';
import { Redirect } from 'react-router';
import ContactModal from '../../modals/ContactModal/ContactModal';
import SignUpModal from '../../modals/SignUpModal/SignUpModal';


const SignInLayout = ({history, language, onUpdateUserData,onUpdateAuthStatus}) => {

    const { classes } = useStyles()
    const content = system_translations[language].layouts.signup
    const { system, form, actions, view_data } = useSignUpLayout({language, content, history,onUpdateUserData,onUpdateAuthStatus})
    const {authenticated} = useAuth({onUpdateUserData})

    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    const onChangePage = (url) => {
        history.push(url)
    }

    let contentRedirect = null
    if(authenticated) contentRedirect = <Redirect to='/dash'/>

    return ( 
<div>


        <div className={classes.root}>
            {contentRedirect}
            <ContactModal />
            <PublicTopbar content={content.topbar} onChangePage={onChangePage}/>
                <div className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div className={classes.left_container}>
                                <RegisterMethodSection content={content} history={history} actions={actions}/>
                                {<SignUpModal open={view_data.modal} onClose={() => actions.onUpdateModalStatus(false)} language={language} content={content} history={history} master_actions={actions} view_data={view_data} system={system} form={form}/>} 
                            </div>
                            
                        </Grid>
                    </Grid>
                </div>
            
            <div className={classes.overlay}/>
            <div className={classes.imageContainer}>
                <img src={content.banner} className={classes.image}/>
            </div>
        </div>
        <Footer content={content.footer} history={history} />
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        position:'relative',
        fontFamily:theme.typography.fontFamily,
        position:'relative',
    },
    imageContainer:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        height:'100%',
        background:'#EEE',
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    overlay:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        height:'100%',
        zIndex:3,
        background: alpha('#000',0.25),
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    container:{
        width:1400,
        margin:'auto',
        minHeight:'100vh',
        boxSizing:'border-box',
        [theme.breakpoints.only('lg')]:{
            width:1200,
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            padding:'0px 18px'
        },
        [theme.breakpoints.down('md')]:{
            height:'100%'
        },
    },
    left_container:{
        position:'relative',
        padding:'24px 60px',
        paddingLeft:24,
        paddingRight:120,
        [theme.breakpoints.down('md')]:{
            paddingRight:24,
            boxSizing:'border-box',
        },
    },
    content_data:{
        marginTop:100,
    },
    link:{
        color:theme.palette.primary.main,
        fontWeight:600,
        textDecoration:`underline ${theme.palette.primary.main}`,
        cursor:'pointer'

    },
    button:{
        fontSize:18,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
        '&:disabled':{
            background:grey[300]
        }
    },
    social_button:{
        fontSize:18,
        textTransform:'none',
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        fontWeight:600,
        padding:12,
        borderRadius:40
    },
    form:{
        
        margin:'60px auto',
        width:400
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInLayout);