import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Información de perfil',
    message:'Por favor ingrese los datos solicitados para agregar el contenido',
    add_button:'Agregar',
    edit_button:'Actualizar',
    quote_instructions:'Ingrese una frase con la que la gente lo pueda identificar en InPager',
    contact_instructions:'Añada datos de contacto para que la comunidad pueda estar en contacto contigo',
    form:{
        birth_date:{placeholder:'Fecha de nacimiento', label:'Fecha de nacimiento', helperText:''},
        gender_id:{placeholder:'Género', label:'Género', helperText:'Campo requerido'},
        country_id:{placeholder:'País', label:'País', helperText:''},
        zone:{placeholder:'Ciudad/Ubicación', label:'Ciudad/Ubicación', helperText:''},
        influencer_type_id:{placeholder:'Tipo de influencer', label:'Tipo de influencer', helperText:'Campo requerido'},
        influencer_size_id:{placeholder:'Tamaño del influencer', label:'Tamaño del influencer', helperText:'Campo requerido'},
    },
    nopicture:nopicture
}

export default translations