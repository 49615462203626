import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import { DASHBOARD_SETTINGS } from '../../../../config';


const useStyles = makeStyles()(theme => ({
    root:{
        position:'relative',
        background:'black'
    },
    infoContainer:{
        color:'white',
        position:'relative',
        zIndex:5,
        padding:'100px',
        boxSizing:'border-box',
    },
    button:{
        borderRadius:32,
        border:'1px solid white',
        padding:'16px 32px',
        color:'white',
        textTransform:'none',
        fontFamily:theme.typography.fontFamily,
        fontSize:18,
        fontWeight:600
    },
    imagePositioner:{
        position:'absolute',
        top:0,
        right:0,
        width:'65%',
        height:'100%',
        zIndex:1,
        [theme.breakpoints.down('md')]:{
            position:'relative',
            width:'100%'
        }
    },
    imageContainer:{
        position:'relative',
        width:'100%',
        height:'100%'
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover',
        objectPosition:'top'
    },
    mask:{
        position:'absolute',
        width:'100%',
        height:'100%',
        background:'rgba(132, 59, 168, 0.78)',
        mixBlendMode:'multiply'
    }
}));

export default useStyles;