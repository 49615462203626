import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { grey } from '@mui/material/colors';

const dots = [2,3,4,5]

const FormDots = ({step, actions, onChangeStep}) => {

    const { classes, cx } = useStyles()

    return ( 
        <div className={classes.root}>
            <Grid container justifyContent='center' spacing={2}>
                {dots.map(item => {
                    return(
                        <Grid item key={item.toString()}>
                            <div className={cx({
                                [classes.dot]:true,
                                [classes.dot_selected]:step === item
                            })} onClick={item <= step ? () => actions.onChangeForwardStep(item) : null}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        //marginLeft:24,
        marginBottom:24
    },
    dot:{
        width:10,
        height:10,
        borderRadius:'50%',
        background:grey[400],
        cursor:'pointer'
    },
    dot_selected:{
        background:theme.palette.primary.main
    }
}));

export default FormDots;