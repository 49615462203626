import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import Footer from '../../components/Structure/Navigation/Footer';
import ErrorModal from '../../components/Modals/ErrorModal';
import system_translations from '../../texts/system_translations';
import Sidebar from './components/Sidebar';
import { DASHBOARD_SETTINGS } from '../../config';
import ContactModal from '../../modals/ContactModal/ContactModal';




const WebsiteLayout = ({ blur, user, error, language, onUpdateError, drawer_status, onUpdateModal}) => {

    const { classes, cx } = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('md'))


    
    const content = system_translations[language].general

    let redirectContent = null
    //if(done && !authenticated) redirectContent = <Redirect to='/signin'/>

    return ( 
        <div className={cx({
            [classes.root]:true,
            [classes.root_blur]:blur
        })}>
            {redirectContent}
            <ContactModal />
            <ErrorModal open={Boolean(error)} message={error ? error.message : null}
            onClose={() => onUpdateError(null)}/>   
            <main className={classes.main} >
                <Topbar ismobile={ismobile} content={content.topbar} onUpdateModal={onUpdateModal} />
                <Sidebar  ismobile={ismobile} content={content.sidebar} onUpdateModal={onUpdateModal} /> 
                <div className={cx({
                    [classes.subroot]:true,
                    [classes.subroot_shift]: !drawer_status,
                })}>
                    <WebsiteRouter user_type={user ? user.user_type_id : null} />
                    
                </div>
                
            </main>
            <Footer content={content.footer} /> 
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{
        //background:'#edf2f8',
        minHeight:'100vh',
        //overflowY:'auto',
        filter:'none',
        overflow:'hidden'

    },
    root_blur:{
        filter:'blur(3px)'
    },
    main:{
        overflowY:'auto',
        //marginLeft:DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        //marginTop:DASHBOARD_SETTINGS.TOPBAR.HEIGHT,
        //width:`calc(100% - ${DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH}px)`,
    },
    subroot:{
        marginTop:72,
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        [theme.breakpoints.down('md')]: {
            marginLeft:0
          },
    },
    subroot_shift:{
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        
    },
}));

const mapStateToProps = state => {
    return {
        ip_address:state.ip_address,
        blur: state.blur,
        user: state.user,
        error: state.error,
        language:state.language,
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateError: (error) => dispatch({type:actionTypes.SYS_UPDATE_ERROR, error}),
        onUpdateModal: (modal) => dispatch({type:actionTypes.SYS_UPDATE_MODAL, modal  }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteLayout);

