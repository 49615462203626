import Scrollbars from 'react-custom-scrollbars-2';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress, Grid } from '@mui/material';
import MultiSelectItem from './MultiSelectItem';

const MultiSelectOption = ({ loading, items, onSelected }) => {
    const { classes } = useStyles()
    return (
        <Scrollbars autoHeight autoHeightMax={300}>
            <div className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {!loading ? (
                            <Grid container>
                                {items.map(item => {
                                    return (
                                        <Grid item xs={12}>
                                            <MultiSelectItem {...item} onSelected={onSelected} />
                                        </Grid>

                                    )
                                })}
                            </Grid>
                        ) : (
                            <Grid container justifyContent='center'>
                                <Grid item>
                                    <div><CircularProgress color='primary' size={40} /></div>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            </div>
        </Scrollbars>
    )
}

const useStyles = makeStyles()(theme => ({
    content: {
        marginTop: 16,
        paddingRight: 16,
        paddingBottom: 16
    }
}));

export default MultiSelectOption;