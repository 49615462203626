import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import useStyles from "./useStyles";
import YouTube from 'react-youtube';

//import { translations as translations_esp } from "../../../../texts/esp/home-translations";

const SectionD = ({content}) => {

    const { classes } = useStyles()
    //const content = translations_esp.sectionD

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };

    return(
        <div className={classes.root}>       
            <div className={classes.video_master}>
                <div className={classes.video_container} >
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/rRHZXNxpv_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default SectionD