import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { onGetFullname } from '../../shared/utility';
import AvatarPicture from '../Structure/DisplayData/AvatarPicture';
import managerIcon from '../../assets/manager.png'
import SmallLink from '../Actions/SmallLink';
import { useHistory } from 'react-router';
import { grey } from '@mui/material/colors';
import { USER_TYPES } from '../../config';

const AgencyInfoCard = ({manager, isauth, onSendInvitationRequest, user}) => {

    const { classes } = useStyles()
    const history = useHistory()

    return ( 
        <div>
            <Typography variant='subtitle1'>Manager</Typography>
            {!manager
                ? 
                    <Grid container direction='column'>
                        <Grid item>
                            <Typography style={{marginTop:8}} color='textSecondary'>No tiene manager aún</Typography>
                        </Grid>
                        {isauth && user?.user_type_id !== USER_TYPES.INFLUENCER ? <Grid item>
                            <div style={{display:'inline-block'}}>
                                <SmallLink onClick={onSendInvitationRequest}>Enviar invitación</SmallLink>
                            </div>
                        </Grid> : null}
                    </Grid>
                    
                : <div>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item><AvatarPicture src={manager?.image} nopicture={managerIcon} size={48}/></Grid>
                        <Grid item>
                            <Grid container direction='column'>
                                <Grid item>
                                    <Typography style={{fontWeight:600}}>{onGetFullname(manager?.first_name, manager?.last_name)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <SmallLink onClick={() => history.push(`/dash/managers/${manager?.url}`)}>Ver perfil</SmallLink>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
     );
}

const useStyles = makeStyles()(theme => ({

}));

export default AgencyInfoCard;