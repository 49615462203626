import { useState, useEffect } from "react"
import { EVENT_TYPES, private_server, public_server } from "../../../../../config"
import { updateAuthorizationHeader, onGetErrorMessage } from "../../../../../shared/utility"
import qs from 'query-string'


const useOverview = ({ language, history, content, onUpdateAuthStatus, onUpdateMasterProfile, user, match, master_view_data}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [profile, setProfile] = useState(null)
    
    const [influencer_categories, setInfluencerCategories] = useState([])
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState({
        event_type_id:EVENT_TYPES.MEDIAKIT,
        query:''
    })

    //Media kits
    const [mediakits, setMediakits] = useState([])
    const [mediakit, setMediakit] = useState({})

    //Reviews
    const [review_size, setReviewSize] = useState(10)
    const [reviews, setReviews] = useState([])

    // Permisions
    const [modify, setModify] = useState(false)

    //images
    const [images, setImages] = useState([])
    const [image, setImage] = useState(null)
    
    const [page, setPage] = useState(1)

    const [ismoved, setIsmoved] = useState(false)

    const [modals, setModals] = useState({
        edit_description:false,
        edit_specific_description:false,
        add_media_kit:false,
        edit_media_kit:false,
        delete_media_kit:false,
        edit_image:false,
        add_image:false,
        see_all_images:false
    });


    useEffect(() => {
        
        if(master_view_data.profile){
            actions.onInitModule()
        } 
    }, [master_view_data.profile, user])

    useEffect(()=>{window.scrollTo(0,0)}, [])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                //await actions.onGetUserData()
                await actions.onGetMediakit()
                await actions.onGetRewviews()
                await actions.onGetUserImages()
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
            setLoading(false)
        },
        onGetUserData: async() => {
            const {owner} = master_view_data
            const {id} = match.params
            let url = owner ? '/user/me' : `/user/url/public/ns?url=${id}`
            let user_request
            if(owner){
                updateAuthorizationHeader(private_server)
                user_request = await private_server.get(url)
            }else{
                user_request = await public_server.get(url)
            }
            const _profile = user_request.data.data.user
            onUpdateMasterProfile(_profile)
            setProfile(_profile)
        },
        ////////////////// MEDIA KIT  //////////////////
        onGetMediakit: async() => {
            try {
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                const events_request = await public_server.get(`/event/all/ns${params}`)
                setMediakits(events_request.data.data.events)
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
        },
        onGetRewviews: async() => {
            try {
                updateAuthorizationHeader(private_server)
                const params = onGetReviewParams()
                const reviews_request = await public_server.get(`/review/all/approved/ns${params}`)
                setReviews(reviews_request.data.data.reviews)
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
        },
        onGetUserImages: async() => {
            try {
                updateAuthorizationHeader(private_server)
                const params = onGetImagesParams()
                const images_request = await public_server.get(`/userimage/all/ns${params}`)
                setImages(images_request.data.data.user_images)
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
        },
        onEditImage: async(item) => {
            setImage(item)
            actions.onUpdateModalStatus('edit_image', true)
        },
 
        onEditMediaKit: async(item) => {
            setMediakit(item)
            actions.onUpdateModalStatus('edit_media_kit', true)
        },
        onDeleteMediaKit: async(item) => {
            setMediakit(item)
            actions.onUpdateModalStatus('delete_media_kit', true)
        },
        onDownloadMediaKit: (item) => {
            if(item.link) window.open(item.link, '_blank')
           // window.open(item.url)
        },
        //////////////////////  INFLUENCERS //////////////////////
        onSelectedInfluencer: (_user) => {
            history.push(`/dash/influencers/${_user.url}`)
        },
        onMove: () => setIsmoved(!ismoved),
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onChangePage: (value) => setPage(value),
    }

    const onGetParams = () => {
        const {event_type_id, query} = filter
        let and_args = [
            {field:'event_type_id', operator:'=', value:event_type_id},
            {field:'user_id', operator:'=', value:master_view_data.profile.id_user},
        ]
        if(query !== '')and_args.push({field:'title', operator:'LIKE', value:`%${query}%`})

        let result = `?filter=${encodeURIComponent(JSON.stringify(
            {'AND':[...and_args]}
        ))}`
        return result
    }

    const onGetImagesParams = () => {
    
        let and_args = [
            {field:'user_id', operator:'=', value:master_view_data.profile.id_user},
        ]
        //if(query !== '')and_args.push({field:'title', operator:'LIKE', value:`%${query}%`})

        let result = `?filter=${encodeURIComponent(JSON.stringify(
            {'AND':[...and_args]}
        ))}`
        return result
    }

    const onGetReviewParams = () => {
        const params = {
            offset:0, 
            limit:review_size,
            order:1,
            order_by:'date'
        }
        let and_args = [
            {field:'user_id', operator:'=', value:master_view_data.profile.id_user},
        ]

        let result = `?${qs.stringify(params)}&filter=${encodeURIComponent(JSON.stringify(
            {'AND':[...and_args]}
        ))}`
        return result
    }

    const system = {loading, sending, error}
    const view_data = {profile, influencer_categories, page, mediakits, mediakit, reviews, images, image, ismoved}

    return {system, view_data, actions, modals}
}

export default useOverview
