import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { connect } from 'react-redux';
import ProfileCard from '../../../components/Platform/ProfileCard';
import Page from '../../../components/Structure/Layouts/Page';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import system_translations from '../../../texts/system_translations';
import AddCategoryModal from './modals/AddCategoryModal/AddCategoryModal';
import EditProfileBanner from './modals/EditProfileBanner/EditProfileBanner';
import EditProfileDataModal from './modals/EditProfileDataModal/EditProfileDataModal';
import DeleteProfileDataModal from './modals/DeleteProfileDataModal/DeleteProfileDataModal';
import EditProfilePicture from './modals/EditProfilePicture/EditProfilePicture';
import ChangePasswordModal from './modals/ChangePasswordModal/ChangePasswordModal';
import QrCodeModal from './modals/QrCodeModal';
import AddTypeFigureModal from './modals/AddTypeFigureModal/AddTypeFigureModal';

import SendInvitationRequestModal from './modals/SendInvitationRequestModal/SendInvitationRequestModal';
import useAccountView from './useAccountView';
import Business from './views/Business/Business';
import Conference from './views/Conference/Conference';
import Event from './views/Event/Event';
import Overview from './views/Overview/Overview';
import Press from './views/Press/Press';
import SocialMedia from './views/SocialMedia/SocialMedia';
import { useEffect } from 'react';


const AccountView = (props) => {

    const {user, history, language, match, onUpdateUserData} = props
    const content = system_translations[language].views.account
    const { classes } = useStyles()
    
    const {system, view_data, actions, modals} = useAccountView({...props})

    const parentprops = {history, language, match, user}

    //let redirectContent = !user ? <Redirect to='/dash/influencers' /> : null 
    let redirectContent = null

    let contentView = null
    let url = ''

    if(view_data.profile?.user_type_id === 6)
        url = process.env.REACT_APP_DOMAIN + `/dash/influencers/${view_data.profile.url}`
    if(view_data.profile?.user_type_id === 3)
        url = process.env.REACT_APP_DOMAIN + `/dash/managers/${view_data.profile.url}`

    useEffect( () => {
        let today = Date.now()

        if(user) {
            if (view_data.profile && view_data.profile.id_user !== user.id_user) {
                const data2send = {
                    idVisitado: view_data.profile.id_user,
                    idVisitante: user.id_user,
                    tiempo: today 
                }
                actions.addVisit(data2send)
            }
        }
    }, [view_data])

    switch (view_data.page) {
        case 1:
            contentView = <Overview {...parentprops} onUpdateMasterProfile={actions.onUpdateMasterProfile}
            content={content.views.overview} 
            master_view_data={view_data} manager={view_data.manager} categories={view_data.influencer_categories} modify={view_data.modify} master_actions={actions} onRemoveCategory={actions.onRemoveCategory}  onAddCategory={()=>actions.onUpdateModalStatus('add_category', true)}/>
            break;
        case 2:
            contentView = <SocialMedia content={content.views.press} {...parentprops}  master_view_data={view_data}
            onUpdateMasterUser={actions.onUpdateMasterUser} onHideMetrics = {actions.onHideMetrics} user={user} profile={view_data.profile}/>
            break;
        case 3:
            contentView = <Press content={content.views.press} {...parentprops}  master_view_data={view_data}/>
            break;
        case 4:
            contentView = <Conference content={content.views.press} {...parentprops}  master_view_data={view_data} />
            break;
        case 5:
            contentView = <Event content={content.views.press} {...parentprops}  master_view_data={view_data} />
            break;
        case 6:
            contentView = <Business  {...parentprops}  master_view_data={view_data} />
            break;
        default:
            break;
    }

    const isauth = user !== null && user !== undefined

    return ( 
        <Page loading={system.loading} sending={system.sending} success={system.success} onCloseSuccess={actions.onCleanSuccess}>
            <div>
                <QrCodeModal open={modals.qr_code} onClose={()=>actions.onUpdateModalStatus('qr_code', false)} url={url}/>
                <SendInvitationRequestModal open={modals.invitation_request} language={language} owner={user} profile={view_data.profile}
                onClose={()=>actions.onUpdateModalStatus('invitation_request', false)} master_actions={actions}
                onCompleted={actions.onCompleteInvitationRequest} />
                <EditProfileDataModal open={modals.edit_profile} master_actions={actions} language={language} 
                profile={view_data.profile} origin={view_data.profile} owner={view_data.owner} onUpdateUserData={onUpdateUserData}
                onClose={()=>actions.onUpdateModalStatus('edit_profile', false)} />
                <DeleteProfileDataModal open={modals.delete_profile} master_actions={actions} language={language} auth_user={user}
                profile={view_data.profile} origin={view_data.profile} owner={view_data.owner} onUpdateUserData={onUpdateUserData}
                onClose={()=>actions.onUpdateModalStatus('delete_profile', false)} /> 
                <EditProfilePicture open={modals.edit_profile_profile} language={language} actions={actions}
                profile={view_data.profile} owner={view_data.owner} onUpdateUserData={onUpdateUserData}
                onClose={()=>actions.onUpdateModalStatus('edit_profile_profile', false)}/>
                <EditProfileBanner open={modals.edit_profile_banner} language={language} actions={actions}
                profile={view_data.profile} owner={view_data.owner}
                onClose={()=>actions.onUpdateModalStatus('edit_profile_banner', false)}/>
                <AddCategoryModal open={modals.add_category} language={language} master_actions={actions}
                user_id={view_data?.profile?.id_user} owner={view_data.owner} user_categories={view_data.influencer_categories}
                onClose={()=>actions.onUpdateModalStatus('add_category', false)}/>
                <AddTypeFigureModal open={modals.add_type_public_figure} onClose={()=>actions.onUpdateModalStatus('add_type_public_figure', false)} user_types={view_data.influencer_types} user_id={view_data?.profile?.id_user} owner={view_data.owner} master_actions={actions}/>
                <ChangePasswordModal open={modals.change_password} user={user} history={history} onClose={()=>actions.onUpdateModalStatus('change_password', false)}/>
                <ProfileCard data={view_data.profile} categories={view_data.influencer_categories} links={view_data.links} isauth={isauth}
                menu={view_data.menu} page={view_data.page} actions={actions} modify={view_data.modify} average_rating={view_data.average_rating}
                manager={view_data.manager} manager_data={view_data.manager_data} owner={view_data.owner}
                auth_user={user}
                onDeleteProfile={actions.onDeleteProfile}
                onEditProfile={actions.onEditProfile} influencers={view_data.influencers}
                onEditProfilePicture={actions.onEditProfilePicture}
                onEditProfileBanner={actions.onEditProfileBanner}
                onAddTypePublicFigure={()=>actions.onUpdateModalStatus('add_type_public_figure', true)}
                onChangePassword={()=>actions.onUpdateModalStatus('change_password', true)}
                onSendInvitationRequest={actions.onSendInvitationRequest} influencer_types={view_data.influencer_types} onRemoveType={actions.onRemoveType} onShowQrCode={()=>actions.onUpdateModalStatus('qr_code', true)}/>
                <div className={classes.content}>
                    {contentView}
                </div>
            </div>  
        </Page>
        
     );
}

const useStyles = makeStyles()(theme => ({
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    content:{
        marginTop:16
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountView);