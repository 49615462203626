import { useState, useEffect } from 'react';
import PublicTopbar from '../../../../../components/Structure/Navigation/PublicTopbar';
import Footer from '../../../../../components/Structure/Navigation/Footer';
import system_translations from '../../../../../texts/system_translations';
import { alpha, Button, CircularProgress, Grid, Typography, Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import {actionTypes} from '../../../../../store/actions'
import useChangeEmail from './useChangeEmail';
import { blueGrey } from '@mui/material/colors';

const ChangeEmail = ({ history, language, user, onUpdateAuthStatus, onUpdateUserData }) => {

    const { classes } = useStyles()
    const content = system_translations[language].views.change_email
    const { system, view_data, actions } = useChangeEmail({ language, content, onUpdateAuthStatus, onUpdateUserData, history })
    const { error } = system

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const onChangePage = (url) => {
        history.push(url)
    }


    const succed_content = (
        <div>
            <Typography variant='h5' style={{ fontWeight: 700 }}>
                {content.title} <Icon>celebration</Icon>
            </Typography>
            <Typography variant='body1' className={classes.message}>
                <strong style={{ color: '#843BA8' }}>{content.message}</strong>
            </Typography>
            <Typography variant='body1' className={classes.message} >
                {content.submessage}
            </Typography>

        </div>
    )

    const error_content = (
        <div>
            <Typography variant='h5' style={{ fontWeight: 700 }}>
                Opps!!
            </Typography>
            <Typography variant='body1' className={classes.message} >
                <strong style={{ color: '#843BA8' }}>{content.error.title}</strong> {content.error.subtitle}
            </Typography>
        </div>
    )


    return (

        <div className={classes.root}>
            <PublicTopbar onChangePage={onChangePage} />
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className={classes.left_container}>
                            <div className={classes.content_data}>
                                {!error ? succed_content : error_content}
                                <div style={{ marginTop: 40 }}>
                                    <Button fullWidth className={classes.button} onClick={actions.onSubmit}>
                                        Finalizar
                                        {system.sending ? <CircularProgress size={32} style={{ marginLeft: 12, color: 'white' }} /> : null}
                                    </Button>
                                    {system.error ? <Typography style={{ marginTop: 16 }} align='center' color='error' variant='subtitle2'>{system.error}</Typography> : null}
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Footer />
            <div className={classes.overlay} />
            <div className={classes.imageContainer}>
                <img src={content.banner} className={classes.image} />
            </div>
        </div>
    );
}

const useStyles = makeStyles()(theme => ({
    message: {
        marginTop: 40,
        fontSize: 20,
    },
    message_coupon: {
        marginTop: 16,
        fontWeight: 400,
        color: blueGrey[900]
    },
    root: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        position: 'relative'
    },
    imageContainer: {
        width: '50%',
        position: 'absolute',
        top: 0, left: '50%',
        width: '50%',
        height: '100vh',
        background: '#EEE',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    overlay: {
        width: '50%',
        position: 'absolute',
        top: 0, left: '50%',
        width: '50%',
        height: '100vh',
        zIndex: 3,
        background: alpha('#000', 0.25),
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    container: {
        width: 1400,
        margin: 'auto',
        height: '100vh',
        //background:'red',
        boxSizing: 'border-box',
        [theme.breakpoints.only('lg')]: {
            width: 1200,
        },
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            padding: '0px 18px'
            //padding:'12px 0px',
            //height:300
        },
    },
    left_container: {
        position: 'relative',
        padding: '24px 60px',
        paddingLeft: 24,
        paddingRight: 100,
        //height:'100vh',
        //background:"#DDD"
        [theme.breakpoints.down('md')]: {
            paddingRight: 24
        },
    },
    content_data: {
        marginTop: 140,
    },
    link: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        textDecoration: `underline ${theme.palette.primary.main}`,
        cursor: 'pointer'

    },
    button: {
        fontSize: 20,
        textTransform: 'none',
        background: theme.palette.primary.main,
        color: 'white',
        fontWeight: 600,
        padding: 12,
        borderRadius: 40,
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
    form: {
        margin: '40px 0px'
    }
}));

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);