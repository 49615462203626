import { Grid, Typography } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const SectionF = ({ content }) => {
    const { classes } = useStyles();

    return (
        <Grid container direction="row" justifyContent="space-between" className={classes.container}>
            <img src={content.backround_image} alt={content.alt} className={classes.backround_image}></img>
            <Grid item xs={0} md={3}>
                <img src={content.image} alt={content.alt} className={classes.image}></img>
            </Grid>
            <Grid container item xs={12} md={8}>
                <Typography variant='h1' className={classes.title}>
                    {content.title}
                </Typography>
                <Grid container direction="column" justifyContent="space-between" item xs={12} md={12}>
                    <Typography>
                        {content.text_1}
                    </Typography>
                    <Typography>
                        {content.text_2._1}
                        <b>{content.text_2._2}</b>
                        {content.text_2._3}
                        <b>{content.text_2._4}</b>
                        {content.text_2._5}
                    </Typography>
                    <Typography className={classes.text_3}>
                        {content.text_3}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SectionF;

const useStyles = makeStyles()(theme => ({
    container: {
        fontFamily: 'Raleway',
        fontWeight: 600,
        position: 'relative',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        padding: '147px 120px 147px 141px',
        fontSize: 28,
        lineHeight: 'normal',
        [theme.breakpoints.down('lg')]: {
            padding: 50,
            fontSize: 20
        },
        zIndex: -1,
        overflow: 'hidden'
    },
    title:{
        fontWeight: 700,
        fontSize: 80,
        fontFamily: 'Raleway',
        [theme.breakpoints.only('xs')]: {
            fontSize: '2.5rem'
        }
    },
    image: {
        [theme.breakpoints.up('md')]: {
            height: 632
        },
        [theme.breakpoints.only('xs')]: {
            width: 200
        },
    },
    backround_image: {
        position: 'absolute',
        width: 719,
        left: 0,
        bottom: 0,
        zIndex: -1,
        maxWidth: '100%'
    },
    text_3: {
        fontStyle: 'italic',
        marginBottom: 20
    }
}));