import { useState, useEffect } from "react"
import { onInitForm } from "../../../shared/utility"
import { isFormValid, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader, onGetErrorMessage } from "../../../shared/utility"
import { private_server } from "../../../config"
import { generic_errors } from "../../../texts/esp/genericErrors"


const useSendInvitationReview = (open, content, language) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [error, setError] = useState(null)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setForm(JSON.parse(JSON.stringify(form_data)))
        }
        
    }, [open])

    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form, origin, content.form)
            
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.post(`/review/me`, data2send)   
                setError(null)
                setSending(false)
                setSuccess('Petición enviada correctamente')
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    const system = { sending, error, success, setSuccess, setError }
    return { actions, form, system }
}

const form_data = {
    reviewer:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'reviewer',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'email',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email',
        }
    },
    business:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'business',
            type:'distance',
            fullWidth: true,
        },
        rules:{
            type:'distance',
        }
    },
}

export default useSendInvitationReview