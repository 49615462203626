import { useState, useEffect } from "react"
import { EVENT_TYPES, private_server, public_server } from "../../../../../config"
import { updateAuthorizationHeader, onGetErrorMessage } from "../../../../../shared/utility"



const usePress = ({ language, user, match, master_view_data}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [data, setData] = useState([]);
    const [press, setPress] = useState(null);

    const [search, setSearch] = useState('');

    const [modals, setModals] = useState({
        add_press:false,
        edit_press:false,

        add_event:false,
        edit_event:false,
        delete_event:false,
    });

    //Events
    const [events, setEvents] = useState([])
    const [event, setEvent] = useState({})

    useEffect(() => {
        
        if(master_view_data.profile){
            actions.onInitModule()
        } 
    }, [master_view_data.profile, user])

    useEffect(()=>{window.scrollTo(0,0)}, [])


    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                await actions.onGetEvents()
             
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
            setLoading(false)
        },
        onGetEvents: async() => {
            try {
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                const events_request = await public_server.get(`/event/all/ns${params}`)
                setEvents(events_request.data.data.events)
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
        },
        ////////////////// MEDIA KIT  //////////////////
        onGetEvent: async() => {
            try {
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                const events_request = await public_server.get(`/event/all/ns${params}`)
                setEvents(events_request.data.data.events)
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
        },
        onEditEvent: async(item) => {
            setEvent(item)
            actions.onUpdateModalStatus('edit_event', true)
        },
        onDeleteEvent: async(item) => {
            setEvent(item)
            actions.onUpdateModalStatus('delete_event', true)
        },
        onSelectPress: (item) => {
            setPress(item)
            actions.onUpdateModalStatus('edit_press', true)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onChangeSearch: (value) => setSearch(value)
    }

    const onGetParams = () => {
        let and_args = [
            {field:'event_type_id', operator:'=', value:EVENT_TYPES.PRESS},
            {field:'user_id', operator:'=', value:master_view_data.profile.id_user},
        ]

        let result = `?filter=${encodeURIComponent(JSON.stringify(
            {'AND':[...and_args]}
        ))}`
        return result
    }

    const system = {loading, sending, error}
    const view_data = {data, search, press, event, events}

    return {system, view_data, actions, modals}
}

export default usePress
