import React, { useState, useEffect } from 'react';
import { alpha, Button, CircularProgress, Grid, Icon, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import InputForm from '../../../components/Forms/InputForm';
import { USER_TYPES } from '../../../config';
import InputSelectForm from '../../../components/Forms/InputSelectForm';

const ProfileSectionAgency = ({content, form, system, actions, view_data}) => {

    const { classes } = useStyles();

    const {REGISTER_STEPS} = content
    const {user_type} = view_data
    const _content = content.profile_section


    const valid =   form.first_name.isValid && form.email.isValid &&
                    form.password.isValid && form.password_confirmation.isValid 

    
    return ( 
        <div>
            <div className={classes.content_data}>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                        <IconButton size='small' className={classes.return_button} onClick={() => actions.onGoToView(REGISTER_STEPS.PERSONAL_INFORMATION)}
                        ><Icon fontSize='large'>arrow_back_ios_new</Icon></IconButton>
                    </Grid> 
                    <Grid item xs>
                        <Typography variant='h4' style={{fontWeight:800, fontSize:36}}>
                            {_content.title}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' style={{marginBottom:8}}>¿Cómo te llamas?</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.first_name} onChange={actions.onChangeForm}/>
                                </Grid>
                            </Grid>
                            <Typography variant='body2'  style={{marginTop:4}}>* Este nombre será el administrador de tu suscripción</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' style={{marginBottom:8}}>¿Cúal es tu correo electrónico?</Typography>
                            <InputForm data={form.email} onChange={actions.onChangeForm}/>
                            <Typography variant='body2'  style={{marginTop:4}}>* Te recomendamos utilizar el correo de tu empresa</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' style={{marginBottom:8}}>Confirma el correo electrónico</Typography>
                            <InputForm data={form.email_confirmation} onChange={actions.onChangeForm}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.password} onChange={actions.onChangeForm}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.password_confirmation} onChange={actions.onChangeForm}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' style={{marginBottom:8}}>¿En qué ciudad estás ubicado?</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <InputSelectForm data={form.country_id} onChange={actions.onChangeForm}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.zone} onChange={actions.onChangeForm}/>
                                </Grid>
                            </Grid>
                            {/* <Typography variant='body2'  style={{marginTop:4}}>* Este nombre será el administrador de tu suscripción</Typography> */}
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Button disabled={!valid} className={classes.submit_button} fullWidth 
                    onClick={ !system.send ? actions.onCheckMail : actions.onGoToView(REGISTER_STEPS.CREATE_YOUR_USERNAME) }>
                        {_content.next_button}
                        {system.sending ? <CircularProgress size={32} style={{marginLeft:12, color:'white'}}/> : null}
                    </Button>
                    {system.error ? <Typography style={{marginTop:16}} align='center' color='error' variant='subtitle1'>{system.error}</Typography> : null}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles()(theme => ({
    content_data:{
        marginTop:25,
    },
    form:{
        
        margin:'60px auto',
        //width:400,
        //paddingRight:24,
        boxSizing:'border-box',
    },
    card:{
        width:'100%',
        paddingTop:'118%',
        border:`2px solid ${grey[300]}`,
        borderRadius:9,
        cursor:'pointer',
        transition:'all 0.1s linear',
        fontFamily:theme.typography.fontFamily,
        fontWeight:600,
        '&:hover':{
            transition:'all 0.4s linear',
            background:alpha("#000",0.01),
            border:`2px solid ${theme.palette.primary.main}`,
        },
        position:'relative'
    },
    card_selected:{
        border:`2px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main
        //background:'red'
    },
    card_content:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    return_button:{
        color:theme.palette.primary.main,
        '&:hover':{
            background:'none'
        }
    },
    label:{
        color:'#6B6B6B',
        fontSize:18,
        fontWeight:700,
        marginBottom:12
    },
    submit_button:{
        fontSize:18,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
        '&:disabled':{
            background:grey[300]
        }
    },
}));

export default ProfileSectionAgency;