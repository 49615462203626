import React from 'react';
import {
    alpha,
    AppBar,
    Button,
    Grid,
    Icon,
    IconButton,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
} from '@mui/material';
import useAuth from '../../../shared/useAuth';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { actionTypes } from '../../../store/actions';
import { onClearMemory } from '../../../shared/utility';
import UserButton from '../../../components/Actions/UserButton';
import { DASHBOARD_SETTINGS } from '../../../config';
import InfluencersSearch from './InfluencersSearch';

const Topbar = ({user, content, history, onUpdateUserData, drawer_status, onUpdateDrawerStatus, onUpdateModal}) => {

    const { classes, cx } = useStyles()
    const {authenticated} = useAuth({onUpdateUserData, history})
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('md'))
    const trigger = useScrollTrigger({disableHysteresis:true, threshold:100})


    const onItemSelected = async(url) => {
        switch (url) {
            case '/logout':
                onUpdateUserData(null)
                onClearMemory()
                history.push('/signin')
                break;
            default:
                history.push(url)
                break;
        }
    }

    const current_path = history.location.pathname


    let _trigger = trigger || current_path !== '/'
    return (
        <AppBar className={cx({
            [classes.appbar]:true,
            //[classes.appbarShift]:drawer_status && ismobile
        })}>
            <div className={classes.root}>
                <Grid container spacing={2} alignItems='center'>
                    {ismobile ? <Grid item>
                        <IconButton
                            color="primary"
                            onClick={() => onUpdateDrawerStatus(!drawer_status)}
                            size="large">
                           <Icon>menu</Icon>
                       </IconButton>
                    </Grid> : null}
                    <Grid item>
                        { user ? <img src={ismobile ? content.logo_mobile : content.logo} className={ismobile ? classes.logo_mobile : classes.logo}/> :
                        <a href='/'><img src={ismobile ? content.logo_mobile : content.logo} className={ismobile ? classes.logo_mobile : classes.logo}/></a>}        
                    </Grid>
                    <Grid item xs></Grid>
                    {!user ? <Grid item>
                        <CustomButton mono={false} noborder={true} onClick={() => history.push('/signup')}>
                            {content.register}
                        </CustomButton>
                    </Grid> : null}
                    {!user && !ismobile ? <Grid item>
                        <CustomButton mono={false} onClick={() => history.push('/signin')}>
                            {content.login}
                        </CustomButton>
                    </Grid> : null}
                    {user ? <Grid item>
                        <UserButton ismobile={ismobile} user={user} content={content.user_button} onItemSelected={onItemSelected}  onUpdateModal={onUpdateModal}/>
                    </Grid> : null}
                </Grid>
                {ismobile ? null : <div className={classes.searchbar}>
                    {/* <Grid container alignItems='center'>
                        <Grid item>
                            <Icon style={{marginRight:8, color:grey[500]}}>search</Icon>
                        </Grid>
                        <Grid item xs>
                            <InputBase placeholder='Buscar influencers, agencias y más...' style={{fontSize:14}} fullWidth/>
                        </Grid>
                    </Grid> */}
                    <InfluencersSearch mono={!_trigger} both={true} ismobile={ismobile} user={user} history={history} />
                </div>}
            </div>
        </AppBar>
    );
}

const useStyles = makeStyles()(theme => ({
    root:{
        //width:1400,
        position:'relative',
        width:'100%',
        padding:'16px 80px',
        margin:'auto',
        boxSizing:'border-box',
        position:'relative',
        boxShadow:'none',
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            padding:'16px 16px'
        },
    },
    button:{
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        textTransform:'none',
        padding:'8px 16px',
        borderRadius:32,
        fontWeight:700
    },
    appbar:{
        background:'white',
        color:'black',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    appbar_mono:{
        background:'transparent',
        boxShadow:'none',
    },
    logo:{
        width:120
    },
    logo_mobile:{
        width:60
    },
    searchbar:{
        position:'absolute',
        top:16,
        //top:'50%',
        left:'50%',
        //transform:'translate(-50%, -50%)',
        transform:'translate(-50%, 0)',
        //minWidth:350,
        width:350,
        //background:'red',
        /* borderRadius:40,
       
        padding:'8px 16px',
        alignItems:'center', */

    },
    /* appbar:{
        background:'white',
        color:'black',
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        //boxSizing:'border-box',
        //padding:'0px 8px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    }, */
    appbarShift:{
        background:'white',
        color:'black',
        width: `calc(100% - ${DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH}px)`,
        marginLeft: DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const mapStateToProps = state => {
    return {
        user:state.user,
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)( Topbar));

const CustomButton = ({children, onClick, mono, noborder}) => {

    const { classes, cx } = useCustomButtonStyles()

    return(
        <Button onClick={onClick} className={cx({
            [classes.button]:true,
            [classes.button_mono]:mono
        })} style={noborder ? {border:`1px solid ${alpha('#FFF',0.0001)}`} : undefined}>
            {children}
        </Button>
    )
}

const useCustomButtonStyles = makeStyles()(theme => ({
    button:{
        background:'transparent',
        color:theme.palette.primary.main,
        textTransform:'none',
        fontFamily:theme.typography.fontFamily,
        fontSize:16,
        fontWeight:700,
        border:`1px solid ${theme.palette.primary.main}`,
        borderRadius:32,
        padding:'8px 24px'
    },
    button_mono:{
        color:'white',
        border:`1px solid white`,
    }
}));
