import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { connect } from 'react-redux';
import AddButton from '../../../components/Actions/AddButton';
import GenericSearchBar from '../../../components/Platform/GenericSearchBar';
import NoContent from '../../../components/Platform/NoContent';
import ReviewCard from '../../../components/Platform/ReviewCard';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import Page from '../../../components/Structure/Layouts/Page';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import SendReviewInvitationModal from './modals/SendReviewInvitationModal/SendReviewInvitationModal';
import useReviewsView from './useReviewsView';

const ReviewsView = (props) => {

    const {user, language} = props
    const { classes } = useStyles()
    
    const {system, view_data, actions, modals} = useReviewsView({...props})

    let contentView = <NoContent />

    if(view_data.reviews && view_data.reviews.length){
        contentView = (
            <div style={{marginTop:32}}>
                <Grid container spacing={3}>
                    {view_data.reviews.map(item => {
                        return (
                            <Grid item xs={12} key={`reviews-${item.id_user}`}>
                                <ReviewCard {...item} onApprove={actions.onApproveReview}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        )
    }

    return ( 
        <Page >
            <div>
                <SendReviewInvitationModal open={modals.send_review_invitation} language={language} owner={user} onCompleted={actions.onInitModule}
                onClose={()=>actions.onUpdateModalStatus('send_review_invitation', false)} master_actions={actions} />
                <Grid container alignItems='center' spacing={2}>
                    <Grid item>
                        <Typography variant='h6'>Mis evaluaciones</Typography>
                    </Grid>
                    <Grid item >
                        <AddButton onClick={()=>actions.onUpdateModalStatus('send_review_invitation', true)}/>
                    </Grid>
                </Grid>
                <Typography variant='body1' style={{fontSize:20,}}>En esta sección podrás encontrar el resultado de las evaluaciones de tus clientes</Typography>
                <div style={{marginTop:16}}>
                    <GenericSearchBar onRequestSearch={actions.onRequestSearch}/>
                </div>
                <LoadingContainer loading={system.loading}>
                    <div className={classes.content}>
                        {contentView}
                    </div>
                </LoadingContainer>
                
            </div>  
        </Page>
        
     );
}

const useStyles = makeStyles()(theme => ({
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    content:{
        marginTop:16
    },
    strongword:{
        color:theme.palette.primary.main
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsView);