import React from 'react';
import { alpha, Grid, Icon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import CategoryChip from './CategoryChip';

const MAX_CATEGORIES = 3

const ProfileCategories = ({categories, modify, onAddCategory, onRemoveCategory}) => {

    const { classes } = useStyles()

    const addCategoryButton = (
        modify
         ?
        <div className={classes.add_button} onClick={onAddCategory}>
            <Icon fontSize='small' style={{marginTop:2}} >add</Icon> Agregar
        </div>
        :null 
    )
    
    return ( 
        <div className={classes.root}>
            <Grid container spacing={2}>
                {categories.map(item => {
                    return(
                        <Grid item>
                            <CategoryChip editable={modify} {...item} onRemove={() => onRemoveCategory(item)}/>
                        </Grid>
                    )
                })}
                {categories.length === 0
                ? <>
                    <Grid item>
                        {addCategoryButton}
                    </Grid>
                    <Grid item>
                        {addCategoryButton}
                    </Grid>
                    <Grid item>
                        {addCategoryButton}
                    </Grid>
                  </>
                : null}
                {categories.length === 1
                ? <>
                    <Grid item>
                        {addCategoryButton}
                    </Grid>
                    <Grid item>
                        {addCategoryButton}
                    </Grid>
                  </>
                : null}
                {categories.length === 2
                ? <Grid item>
                    <Grid item>
                        {addCategoryButton}
                    </Grid>
                  </Grid>
                : null}
                {categories.length === 3
                ? null
                : null}
            </Grid>
        </div>
     );
}



const useStyles = makeStyles()(theme => ({
    root:{
        marginTop:16
    },
    tag:{
        background:grey[300],
        padding:'6px 16px',
        color:grey[700],
        borderRadius:20
    },
    add_button:{
        position:'relative',
        display:'flex',
        alignItems:'center',
        border:`1px dashed ${theme.palette.primary.main}`,
        padding:'4px 8px',
        borderRadius:32,
        color:theme.palette.primary.main,
        fontWeight:600,
        cursor:'pointer',
        transition:'all 0.4s linear',
        '&:hover':{
            background:alpha(theme.palette.primary.main,0.2),
            transition:'all 0.4s linear'
        }
    },
    addCategoryButton:{
        display:'flex',
        flexDirection:'row'
    }
}));

export default ProfileCategories;