import { useState, useEffect } from 'react';
import { public_server, private_server } from "../config"

const useUserFilters = () => {
    const [filters, setFilters] = useState({});

    const getFilterOption = async () => {
        const category_request = await public_server.get("/category/all/ns");
        const _categories = category_request.data.data.categories;

        const type_request = await public_server.get("/typefigure/all/ns");
        const _types = type_request.data.data.types;

        const countries_request = await private_server.get("/user/countries");
        const _countries = countries_request.data.data.countries;

        const influencersizes_request = await private_server.get("/user/influencersizes");
        const _influencersizes = influencersizes_request.data.data.influencer_sizes;

        const genders_request = await private_server.get("/user/genders");
        const _genders = genders_request.data.data.user_genders;

        setFilters({
            'categories': _categories.map((e) => ({ id: e.id_category, name: e.name })),
            'types': _types.map((e) => ({ id: e.id_influencer_type, name: e.name })),
            'countries': _countries.map((e) => ({ id: e.id_country, name: e.name })),
            'sizes': _influencersizes.map((e) => ({ id: e.id_influencer_size, name: e.name })),
            'genders': _genders.map((e) => ({ id: e.id_gender, name: e.name }))
        });


    }

    useEffect(() => {
        try {
            getFilterOption();
        }
        catch (error) {
            console.error(error);
        }
    }, [])

    return { filters }
}

export default useUserFilters;