import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleCard from '../Structure/Cards/SimpleCard';
import ProfileAvatar from '../Structure/DisplayData/ProfileAvatar';
import { Rating } from '@mui/material';
import moment from 'moment-timezone'
import SmallMenuButton from '../Actions/SmallMenuButton';
import catalogs from '../../texts/esp/catalogs';
const ReviewCard = (props) => {

    const { classes } = useStyles()
    const {id_review, email, reviewer, date, approved,
    response_time, professionalism, price_quality, customer_support, general_satisfaction,
    comment, onApprove, public_view, isOverview } = props
    const total_evaluation = 
    response_time ? parseFloat(response_time) : 0 + 
    professionalism ? parseFloat(professionalism) : 0 + 
    price_quality ? parseFloat(price_quality) : 0 + 
    customer_support ? parseFloat(customer_support) : 0 + 
    general_satisfaction ? parseFloat(general_satisfaction) : 0

    const average_evaluation = (total_evaluation / 5 ) * 5

    const onInnerChange = (data) => {
        const data2send = {approved: data.value === 1 ? 1 : 0}
        onApprove(id_review, data2send)
    }

    const pending = !moment(date).isValid()
    const _date = moment(moment.utc(date).format()).format('LLL')

    const content = pending ? 'No se ha realizado la evaluación aún' : (Boolean(comment) ? comment : 'Sin comentarios')

    return ( 
        <div>
            <SimpleCard>
                <div className={classes.header}>
                    <Grid container spacing={2} wrap='nowrap'>
                        <Grid item>
                            <ProfileAvatar />
                        </Grid>
                        <Grid item xs >
                            <Grid container>
                                <Grid item xs>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' style={{lineHeight:1}}>
                                                {reviewer}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                        </Grid>
                                            <Rating readOnly={public_view} value={pending ? 0 : average_evaluation} disabled={pending}/>
                                    </Grid>
                                </Grid>
                                { isOverview ?
                                    ''
                                : 
                                    <Grid item>
                                        <SmallMenuButton value={approved} options={catalogs.review_approval_status} onChange={onInnerChange}/> 
                                    </Grid>
                                }
                            </Grid>
                            
                        </Grid>
                    </Grid>

                </div>
                <div className={classes.comment_container}>
                    {!public_view ? <Typography variant='caption' color='textSecondary'>
                        {email} - {_date}
                    </Typography> : (
                        <Typography variant='caption' color='textSecondary'>
                            {_date}
                    </Typography>
                    )}
                    <Typography variant='body2'>
                        <i>{content}</i>
                    </Typography>
                </div>
            </SimpleCard>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    header:{

    },
    comment_container:{
        marginTop:8
    }
}));

export default ReviewCard;

