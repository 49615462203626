import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Producto',
    message:'Por favor ingrese los datos solicitados para agregar el contenido',
    add_button:'Agregar',
    edit_button:'Guardar',
    form:{
        name:{placeholder:'Nombre', label:'Nombre', helperText:'Campo requerido'},
        link:{placeholder:'URL del sitio', label:'URL del sitio', helperText:'Url no válida'},
        description:{placeholder:'Descripción', label:'Descripción', helperText:'Campo requerido'},
        price:{placeholder:'Precio', label:'Precio', helperText:'Campo requerido'},
    },
    nopicture:nopicture
}

export default translations