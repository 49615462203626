import { makeStyles } from 'tss-react/mui';
import { Button, CircularProgress, Typography, Grid } from "@mui/material";
import { grey } from '@mui/material/colors';
import { USER_TYPES } from '../../../config';
import EditProfilePicture from '../../../views/Account/AccountView/modals/EditProfilePicture/EditProfilePicture';
import EditSocialMediaDataModal from '../../../views/Account/AccountView/modals/EditSocialMediaDataModal/EditSocialMediaDataModal';
import AddCategoryModal from '../../../views/Account/AccountView/modals/AddCategoryModal/AddCategoryModal';
import AddTypeFigureModal from '../../../views/Account/AccountView/modals/AddTypeFigureModal/AddTypeFigureModal';
import useAccountView from '../../../views/Account/AccountView/useAccountView';

const AdditionalInformation = ({ content, actions: master_actions, view_data: test }) => {
    const {system, view_data, actions, modals} = useAccountView({user: test.user})

    const { classes } = useStyles();

    const { REGISTER_STEPS } = content;
    const _content = content.additional_information_section;
    const { user_type } = test;
    const valid = true;

    return (
        <div className={classes.content_data}>
            <AddCategoryModal open={modals.add_category} language={'es'} master_actions={actions}
            user_id={test?.user?.id_user} owner={view_data.owner} user_categories={view_data.influencer_categories}
            onClose={()=>actions.onUpdateModalStatus('add_category', false)}/>
            <AddTypeFigureModal open={modals.add_type_public_figure} onClose={()=>actions.onUpdateModalStatus('add_type_public_figure', false)} user_types={view_data.influencer_types} user_id={test?.user?.id_user} owner={view_data.owner} master_actions={actions}/>
            <EditSocialMediaDataModal  open={modals.edit_social_media} onClose={()=>actions.onUpdateModalStatus('edit_social_media', false)} language={'es'} 
            origin={test?.user} user_id={test?.user?.id_user} onCompleted={()=>actions.onUpdateModalStatus('edit_social_media', false)} master_actions={actions} onUpdateMasterUser={() => console.log('updated links')} integration = {view_data.integration} />
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: 800, fontSize: 36 }}>
                        {_content.title}
                    </Typography>
                    <Typography variant='h4' style={{ fontWeight: 700, fontSize: 36 }}>
                        {_content.subtitle}
                    </Typography>
                </Grid>
                <Grid container spacing={3} alignItems='center' direction='column' className={classes.form}>
                    <Grid item>
                        <Button className={classes.button} onClick={()=>actions.onUpdateModalStatus('add_category', true)}> Añadir {user_type === USER_TYPES.MANAGER ? 'especialidad' : ' categorias '} de contenido</Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.button} onClick={()=>actions.onUpdateModalStatus('add_type_public_figure', true)}> Añadir tipo de figura publica</Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.button} onClick={()=>actions.onUpdateModalStatus('edit_social_media', true)}> Agregar links de redes sociales</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Button disabled={!valid} className={classes.submit_button} fullWidth onClick={() => master_actions.onGoToView(REGISTER_STEPS.OVERVIEW)}>
                {_content.next_button}
                {system.sending ? <CircularProgress size={32} style={{ marginLeft: 12, color: 'white' }} /> : null}
            </Button>
            {system.error ? <Typography style={{ marginTop: 16 }} align='center' color='error' variant='subtitle1'>{system.error}</Typography> : null}
        </div>
    )
}


const useStyles = makeStyles()(theme => ({
    content_data: {
        marginTop: 25,
    },
    submit_button: {
        fontSize: 18,
        textTransform: 'none',
        background: theme.palette.primary.main,
        color: 'white',
        fontWeight: 600,
        padding: 12,
        borderRadius: 40,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        '&:disabled': {
            background: grey[300]
        }
    },
    form:{
        margin: 15
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.light,
        width: 350,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
        },
    }
}));

export default AdditionalInformation;