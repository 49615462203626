import { useState, useEffect } from "react"
import { private_server, public_server } from "../../../../../config"
import { updateAuthorizationHeader, onGetErrorMessage } from "../../../../../shared/utility"



const useBusiness = ({ language, user, match, master_view_data}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [search, setSearch] = useState('');

    const [modals, setModals] = useState({
        add:false,
        edit:false,
        delete:false,
        add_product:false,
        edit_product:false,
        delete_product:false
    });


    const [businesses, setBusinesses] = useState([])
    const [business, setBusiness] = useState(null)
    const [products, setProducts] = useState([])

    useEffect(() => {
        
        if(master_view_data.profile){
            actions.onInitModule()
        } 
    }, [master_view_data.profile, user])

    useEffect(()=>{window.scrollTo(0,0)}, [])


    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                await actions.onGetBusinesses()
             
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
            setLoading(false)
        },
        onGetBusinesses: async() => {
            try {
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                const request = await public_server.get(`/business/all/ns${params}`)
                setBusinesses(request.data.data.businesses)
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
        },
        onGetProducts: async(_id) => {
            let _products = []
            try {
                //updateAuthorizationHeader(private_server)
                const params = onGetProductParams(_id)
                const request = await public_server.get(`/product/all/ns${params}`)
                _products = request.data.data.products
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }

            return _products
        },
        ////////////////// MEDIA KIT  //////////////////
        /* onGetEvent: async() => {
            try {
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                const events_request = await public_server.get(`/event/all/ns${params}`)
                setEvents(events_request.data.data.events)
            } catch (error) {
                setError(onGetErrorMessage(error, language))
            }
        }, */
        onEditBusiness: async(item) => {
            setBusiness(item)
            actions.onUpdateModalStatus('edit', true)
        },
        onDeleteBusiness: async(item) => {
            setBusiness(item)
            actions.onUpdateModalStatus('delete', true)
        },
        onAddProduct: async(item) => {
            setBusiness(item)
            actions.onUpdateModalStatus('add_product', true)
        },
        /* onSelectBusiness: (item) => {
            setEvent(item)
            actions.onUpdateModalStatus('edit_Event', true)
        }, */
        onUpdateProducts: (temp) => setProducts(temp),
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onChangeSearch: (value) => setSearch(value)
    }

    const onGetParams = () => {
        let and_args = [
            {field:'user_id', operator:'=', value:master_view_data.profile.id_user},
        ]

        let result = `?filter=${encodeURIComponent(JSON.stringify(
            {'AND':[...and_args]}
        ))}`
        return result
    }

    const onGetProductParams = (_id) => {
        let and_args = [
            {field:'business_id', operator:'=', value:_id},
            //{field:'user_id', operator:'=', value:master_view_data.profile.id_user},
        ]

        let result = `?filter=${encodeURIComponent(JSON.stringify(
            {'AND':[...and_args]}
        ))}`
        return result
    }

    const system = {loading, sending, error}
    const view_data = {search, businesses, business, products}

    return {system, view_data, actions, modals}
}

export default useBusiness
