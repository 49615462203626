import { useEffect, useState } from "react"
import { private_server } from '../../../../../config'
import {  onGetErrorMessage,  updateAuthorizationHeader } from '../../../../../shared/utility'


const useCancelInvitationModal = ({open, invitation, master_actions, content, onCompleted, onClose, catalogs}) => {

    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    

    useEffect(() => {
        if(!open){
            setLoading(false)
            setSending(false)
            setError(null)
        }else{
            actions.onInitModule()
        }
    }, [open])
 
    
    const actions = {
        onInitModule: async() => {     
        },
        onSubmit: async() => {
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.post(`/invitation/client/me/${invitation.id_invitation}/cancel`)
                await master_actions.onUpdateData()  
                setError(null)
                setSending(false)
                onClose()
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            
        }
    }

    const system = {loading, sending, error}
    
    return { system, actions} 
}


export default useCancelInvitationModal

const form_data = {
    reviewer: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'reviewer',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    email: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'email'}
    },
    business: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'business',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
}