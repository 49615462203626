import { Button, Grid, Icon, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import useStyles from "./useStyles";
import CarrouselContent from "./components/CarrouselContent";
import useSectionB from "./useSectionB";
import { grey } from "@mui/material/colors";

const SectionB = ({content}) => {

    const { classes } = useStyles()
    const theme = useTheme();
    //const content = translations_esp.sectionB
    const { view_data, actions } = useSectionB()

    //const q_extralarge = useMediaQuery(theme.breakpoints.up('lg'));
    //const q_large = useMediaQuery(theme.breakpoints.up('md')); 
    const q_large = useMediaQuery('(min-width:1024px)'); 
    const q_med = useMediaQuery(theme.breakpoints.only('md'));
    const q_small = useMediaQuery(theme.breakpoints.down('lg'));

    let size = 'large'
    if(q_large ){
        size = 'large'
    }
    if(!q_large && q_med && q_small){
        size='med'
    }
    if(!q_large && !q_med && q_small){
        size='small'
    }

    return(
        <div className={classes.moreRoot}>
            <div className={classes.root}>
                <div className={classes.backgroundPositioner}>
                    <div className={classes.background} style={{width:'1500px', height:'1500px'}}>
                        <div className={classes.background} style={{width:'75%', height:'75%'}}>
                            <div className={classes.background} style={{width:'70%', height:'70%'}}></div>
                        </div>
                    </div>
                </div> 
                <div className={classes.title_container}>
                    <Typography color='primary' className={classes.title}>{content.title}</Typography>
                </div>
                <Grid container alignItems="flex-end" className={classes.contentContainer}>
                    <Grid item xs={0} className={classes.subtitleContainer}>
                        <Typography className={classes.subtitle}>{content.subtitle}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.imageContainer}>
                        <img src={content.image} className={classes.image}></img>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default SectionB