import { Link } from "react-router-dom";
import { Grid, Icon } from '@mui/material'
import logo from '../../../assets/logo-white.png';
import social_trenders_logo from '../../../assets/podcast/social_trenders_logo.png'
import { Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ChangeImageModal from './ChangeImageModal';
import { useState } from "react";

const PodcastCard = ({ title, creator, description, image, number, edit_permission, onCompleted, onEdit }) => {
    const props = { image_: image }
    const { classes } = useStyles({ props })

    const [openModal, setOpenModal] = useState(false);

    const onEditProfilePicture = (number) => setOpenModal(true)

    return (
        <div className={classes.root}>
            <ChangeImageModal open={openModal} onClose={() => setOpenModal(false)} onCompleted={onCompleted} number={number} language={'es'} />
            {edit_permission &&
                <Icon className={classes.editIcon} onClick={onEdit} >edit</Icon>
            }
            <div className={classes.leftContainer}>
                <div className={classes.leftContainerPadding}>
                    {edit_permission &&
                        <div className={classes.edit_image} onClick={() => onEditProfilePicture(number)}>
                            <div className={classes.edit_image_content}>
                                <Grid container direction='column' alignItems='center'>
                                    <Grid item>
                                        <Icon>photo_camera</Icon>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body2'> Editar </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                    <img src={logo} className={classes.logo}></img>
                    <img src={social_trenders_logo} className={classes.social_trenders_logo}></img>
                    <Button component={Link} to={`/podcasts/${number}`} relative="path" className={classes.button}> EP. {number} </Button>
                </div>
            </div>
            <div className={classes.rightContainer}>
                <Typography variant='h1' className={classes.title}> {title}</Typography>
                <Typography variant='caption' className={classes.tag}> {creator} </Typography>
                <Typography variant='subtitle1' className={classes.subtitle}> {description} </Typography>
            </div>
        </div>
    );
};

const useStyles = makeStyles()((theme, { props }) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontFamily: theme.typography.fontFamily,
        margin: 'auto',
        width: 1200,
        maxWidth: '70%',
        borderRadius: 50,
        border: '2px solid #E3E3E3',
        background: 'rgba(132, 59, 168, 0.00)',
        marginBottom: 100,
        [theme.breakpoints.down('lg')]: {
            width: '80%',
            borderRadius: 20,
        }
    },
    leftContainer: {
        position: 'relative',
        borderRadius: 35,
        [theme.breakpoints.down('lg')]: {
            borderRadius: 15,
            aspectRatio: '6 / 5',
            height: 'auto'
        },
        [theme.breakpoints.down('xl')]: {
            width: '40%',
            height: 'auto',
            aspectRatio: '6 / 5',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        background: `url(${props.image_}), lightgray 50%`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        maxWidth: '100%',
        width: 500,
        height: 'auto',
        aspectRatio: '6 / 5',
        padding: 20,
        overflow: 'hidden'
    },
    leftContainerPadding: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 144,
        objectFit: 'contain',
        [theme.breakpoints.down('lg')]: {
            top: 2,
            width: 100,
        }
    },
    social_trenders_logo: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 224,
        objectFit: 'contain',
        [theme.breakpoints.down('lg')]: {
            width: 100,
        }
    },
    button: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 277,
        height: 85,
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Raleway',
        fontSize: 36,
        fontWeight: 800,
        borderRadius: 50,
        border: '2px solid #FFF',
        background: 'rgba(132, 59, 168, 0.00)',
        padding: '32px 18px',
        gap: 10,
        [theme.breakpoints.down('lg')]: {
            fontSize: 25,
            height: 'auto',
            width: '65%',
            padding: '10px 20px',
            margin: 'auto'
        },
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
        maxWidth: 700,
        width: '45%',
        fontFamily: 'Raleway',
        [theme.breakpoints.down('lg')]: {
            width: 400
        },
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 36,
        [theme.breakpoints.down('lg')]: {
            fontSize: 20,
        },
        fontWeight: 600,
    },
    tag: {
        fontSize: 24,
        fontWeight: 800,
        margin: '20px 0px'
    },
    subtitle: {
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',
        fontSize: 20,
        fontWeight: 400,
        lineHeight: 'normal',
        overflow: 'hidden',
        [theme.breakpoints.up('xl')]: {
            fontSize: 28,
            WebkitLineClamp: 7,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 15,
        },
    },
    edit_image: {
        position: 'absolute',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.short,
        }),
        '&:hover': {
            opacity: 0.8
        }
    },
    edit_image_content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
        height: 100,
        border: '2px solid #FFFFFF',
        borderRadius: '100vh',
        color: '#FFFFFF',
        background: 'rgba(200,200,200,0.4)'
    },
    editIcon: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer'
    }
}));

export default PodcastCard;
