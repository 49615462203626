import { Fade } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { useState } from "react"
import nopicture from '../../assets/image_placeholder.png'
import { CONFIG_SETTINGS } from "../../config"
import { isValidDocument } from "../../shared/utility"

const Image = ({src, className}) => {

    const { classes } = useStyles()

    const [error, setError] = useState(false)

    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let selectedImage = nopicture
    if(src){
        const isValid = isValidDocument(src)
        selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}/${src}` : nopicture
        //selectedImage = src
    }

    return(
        <div  >
            <Fade in={!error}>
                <img className={className} src={selectedImage} 
                    onLoad={onImageLoad} 
                    onError={onError} />
                
            </Fade>
            {error ? <img className={className} src={nopicture}  /> : null}
        </div>
        
    )
}

export default Image

const useStyles = makeStyles()(theme => ({
    root:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    }
}));