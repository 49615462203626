import React from 'react'
import SimpleModal from '../../../components/Modals/SimpleModal'
import translations from '../../../texts/system_translations'
import { Icon, Grid, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import deleteSvg from '../../../assets/delete.svg'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import RoundedButton from '../../../components/Actions/RoundedButton'
import { actionTypes } from '../../../store/actions'
import system_translations from '../../../texts/system_translations'
import { useEffect } from 'react'


const DeleteManagerModal = ({open, onClose, language, actions, sending, success, error, setSuccess}) => {

    const { classes } = useStyles()
    const content = translations[language].modals.delete_manager

    useEffect( () => {
        setSuccess(null)
    }, [open])
    
  return (
    <SimpleModal open={open} onClose={onClose}>
        <Grid container xs={12} className={classes.container}>
            <SVGIcon src={deleteSvg} size={60}/>

            <Grid container xs={12} className={classes.title}>
                <Typography variant='h6'>{content.title}</Typography>
            </Grid>

            {success && 
                <Grid xs={12} className={classes.alert}>
                    <Typography color='primary' variant='subtitle2'>{success}</Typography>
                </Grid>
            }

            {error && 
                <Grid xs={12} className={classes.alert}>
                    <Typography color='red' variant='subtitle2'>{error}</Typography>
                </Grid>
            }

            <Grid xs={12} className={classes.button}>
                <RoundedButton onClick={() => actions.onDeleteManager()} loading={sending}>
                    {content.delete_button}
                </RoundedButton>
            </Grid>
        </Grid>
    </SimpleModal>
  )
}

export default DeleteManagerModal

const useStyles = makeStyles()(theme => ({
    container:{
        justifyContent:'center',
    },
    title:{
        textAlign:'center',
        marginTop:'30px'
    },
    button:{
        marginTop:'30px',
        textAlign:'center',
    },
    alert:{
        textAlign:'center',
        marginTop:'15px'
    }
  }));