import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useResetPassword from './useResetPassword';
import ResetPasswordForm from './components/ResetPasswordForm'


const ResetPassword = (props) => {

    const {user, history, language, match} = props

    const {system, view_data, actions, modals} = useResetPassword({history})

    return ResetPasswordForm(view_data, actions, system, history);

}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);