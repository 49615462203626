import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_SETTINGS } from '../../../config';
import FormView from './FormView'
import SuccessView from './SuccessView';
import ErrorModal from '../../../components/Modals/ErrorModal'

const ResetPasswordForm = (view_data, actions, system, history) => {
    const { classes } = useStyles()

    let contentView = (
        <FormView form={view_data.form} onChange={actions.onChangeForm} isValid={view_data.isValid} loading={system.loading} error={system.error}
        onSubmit={actions.onSubmit} history={history}/>
    )

    if(view_data.isPasswordRecover){
        contentView = <SuccessView onReturn={()=> history.push('/signin')}/>
    }


    return(
        <div className={classes.extraroot}>
            <ErrorModal open={Boolean(system.error)} message={system.error} onClose={actions.onClearError}/>
            <div className={classes.root}>
                <div className={classes.card}>
                    {contentView}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles()(theme => ({
    root:{
        padding:'100px 32px',
        
    },
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        padding:40,
        borderRadius:24,
        width:700,
        margin:'auto',
        [theme.breakpoints.down('md')]:{
            //margin:'0x 32px',
            width:'100%',
            boxSizing:'border-box'
        }
    },
    content:{
        marginTop:16
    },
    strongword:{
        color:theme.palette.primary.main
    }
}));

export default ResetPasswordForm;