import React, { useEffect } from 'react';
import PublicTopbar from '../../components/Structure/Navigation/PublicTopbar';
import Footer from '../../components/Structure/Navigation/Footer';
import system_translations from '../../texts/system_translations';
import { alpha, Button, CircularProgress, Grid, Icon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useReviewInvitationLayout from './useReviewInvitationLayout';
import InputForm from '../../components/Forms/InputForm';
import { blueGrey, green } from '@mui/material/colors';
import InputRating from '../../components/Forms/InputRating';

const ReviewInvitationLayout = ({history, language, user,  onUpdateAuthStatus, onUpdateUserData}) => {

    const { classes } = useStyles()
    const content = system_translations[language].layouts.account_verification
    const {system, view_data, actions, form} = useReviewInvitationLayout({language, content,  onUpdateAuthStatus, onUpdateUserData, history})
    const {error} = system

    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    const onChangePage = (url) => {
        history.push(url)
    }

    
    const succed_content = (
        <div>
            <Typography variant='h5' style={{fontWeight:700}}>
                ¡Tu evaluación ha sido registrada con éxito!
            </Typography>
            <Typography variant='body1' className={classes.message} >
                <strong style={{color:'#843BA8'}}>Muchas gracias!</strong> Tu opinión es muy importante para nosotros.
            </Typography>
            <div className={classes.icon_container}>
                <Icon className={classes.icon}>done</Icon>
            </div>
            <div style={{marginTop:40}}>
                <Button fullWidth className={classes.button} onClick={() => history.push('/')}>
                    Finalizar
                    {system.sending ? <CircularProgress size={32} style={{marginLeft:12, color:'white'}}/> : null}
                </Button>
                {system.error ? <Typography style={{marginTop:16}} align='center' color='error' variant='subtitle2'>{system.error}</Typography> : null}
            </div>
        </div>
    )

    const error_content = (
        <div>
            <Typography variant='h5' style={{fontWeight:700}}>
                Opps!! 
            </Typography>
            <Typography variant='body1' className={classes.message} >
                <strong style={{color:'#843BA8'}}>¡Hubo un error!</strong> Al parecer el periodo de evaluación expiró o ya fue realizada.
            </Typography>
            <div style={{marginTop:40}}>
                <Button fullWidth className={classes.button} onClick={() => history.push('/')}>
                    Finalizar
                    {system.sending ? <CircularProgress size={32} style={{marginLeft:12, color:'white'}}/> : null}
                </Button>
                {system.error ? <Typography style={{marginTop:16}} align='center' color='error' variant='subtitle2'>{system.error}</Typography> : null}
            </div>
        </div>
    )

    const form_content = (
        <div>
            <Typography variant='h6' style={{fontWeight:700}}>
            <strong style={{color:'#843BA8', fontSize:36}}>¡Hola!</strong> <br></br>¡Has sido invitado a realizar una evaluación de <strong style={{color:'#843BA8'}}>{view_data.user}</strong>!
            </Typography>
            <div style={{paddingTop:16}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <InputRating data={form.response_time} onChange={actions.onChangeForm}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputRating data={form.professionalism} onChange={actions.onChangeForm}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputRating data={form.price_quality} onChange={actions.onChangeForm}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputRating data={form.customer_support} onChange={actions.onChangeForm}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputRating data={form.general_satisfaction} onChange={actions.onChangeForm}/>
                    </Grid>

                    <Grid item xs={12}>
                        <InputForm data={form.comment} onChange={actions.onChangeForm}/>
                    </Grid>
                </Grid>
            </div>
            <div style={{marginTop:40}}>
                <Button fullWidth className={classes.button} onClick={actions.onSubmit}>
                    Enviar
                    {system.sending ? <CircularProgress size={32} style={{marginLeft:12, color:'white'}}/> : null}
                </Button>
                {system.error ? <Typography style={{marginTop:16}} align='center' color='error' variant='subtitle2'>{system.error}</Typography> : null}
            </div>
        </div>
    )

    let general_content = form_content

    if(view_data.view === 2){
        // cambiar la vista
        general_content = !error ? succed_content : error_content
    }


    return ( 

        <div className={classes.root}>
            <PublicTopbar content={content.topbar} onChangePage={onChangePage}/>
                <div className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div className={classes.left_container}>
                                <div className={classes.content_data}>
                                    {/*!error ? succed_content : error_content*/ general_content}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            <Footer content={content.footer}/>
            <div className={classes.overlay}/>
            <div className={classes.imageContainer}>
                <img src={content.banner} className={classes.image}/>
            </div>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    message:{
        marginTop:40,
        fontSize:20,
    },
    message_coupon:{
        marginTop:16,
        fontWeight:400,
        color:blueGrey[900]
    },
    icon_container:{
        border:`2px solid ${green[700]}`,
        width:100, height:100,
        position:'relative',
        borderRadius:'50%',
        margin:'auto',
        marginTop:32,
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        color:green[700],
        fontSize:64
    },
    root:{
        position:'relative',
        fontFamily:theme.typography.fontFamily,
        position:'relative'
    },
    imageContainer:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        height:'100%',
        background:'#EEE',
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    overlay:{
        width:'50%',
        position:'absolute',
        top:0, left:'50%',
        width:'50%',
        minHeight:'100vh',
        zIndex:3,
        background: alpha('#000',0.25),
        [theme.breakpoints.down('md')]:{
            display:'none'
        },
    },
    container:{
        width:1400,
        margin:'auto',
        minHeight:'100vh',
        //background:'red',
        boxSizing:'border-box',
        [theme.breakpoints.only('lg')]:{
            width:1200,
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            padding:'0px 18px'
            //padding:'12px 0px',
            //height:300
        },
    },
    left_container:{
        position:'relative',
        padding:'24px 60px',
        paddingLeft:24,
        paddingRight:100,
        //height:'100vh',
        //background:"#DDD"
        [theme.breakpoints.down('md')]:{
            paddingRight:24
        },
    },
    content_data:{
        marginTop:140,
    },
    link:{
        color:theme.palette.primary.main,
        fontWeight:600,
        textDecoration:`underline ${theme.palette.primary.main}`,
        cursor:'pointer'

    },
    button:{
        fontSize:20,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
    },
    form:{
        margin:'40px 0px'
    }
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewInvitationLayout);