import { useState, useEffect } from "react"
import {  private_server, public_server } from '../../../config'
import { updateAuthorizationHeader } from '../../../shared/utility'


const MAX_CATEGORIES = 100

const useCategoryFilterModal = ({ open, master_actions, filtered_categories}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [categories, setCategories] = useState([])
    const [selected_categories, setSelectedCategories] = useState([])

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
        }
        
    }, [open])
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                // Request all available categories
                const category_request = await public_server.get("/category/all/ns")
                updateAuthorizationHeader(private_server)
                const _categories = category_request.data.data.categories
                let modified_categories = []
                setSelectedCategories([...filtered_categories])
                for(let i=0; i<_categories.length; i++){
                    const temp = _categories[i]
                    let data2add = {...temp, selected:false}
                    const elCat = filtered_categories.find(el => el.id === temp.id_category && el.type==='category_filter')
                    data2add.category_id = temp.id_category // add to match in ids
                    if(elCat) data2add.selected = true
                    modified_categories.push(data2add)
                }
                modified_categories.sort((a,b)=>{
                    if(a.name > b.name) return 1
                    if(a.name < b.name) return -1
                    return 0
                })
                setCategories(modified_categories)

            } catch (error) {
            }
            setLoading(false)
        },
        onCategorySelected: (item) => {
            let _categories = [...categories]
            let _selectedCategories = [...selected_categories]
            const posItem = categories.findIndex(el => el.id_category === item.id_category)
            if(item.selected){
                //Remove itemx
                _categories[posItem].selected = false
                const _sCat = selected_categories.findIndex(el => el.id === item.id_category || el.id === item.id_category)
                _selectedCategories.splice(_sCat, 1)
            }else{
                //Add item
                if(selected_categories.length < MAX_CATEGORIES){
                    _selectedCategories.push({
                        id:item.id_category,
                        name:item.name, 
                        type:'category_filter'
                    })
                    _categories[posItem].selected = true
                }
                
            }
            setCategories(_categories)
            setSelectedCategories(_selectedCategories)
        },
        onRemoveCategory: (data) => {
            let _categories = [...categories]
            let _selectedCategories = [...selected_categories]
            //Remove from category
            const posItem = categories.findIndex(el => 
                el.category_id === data.id_category 
                || el.id_category === data.category_id 
                || el.name === data.name
            )
            _categories[posItem].selected = false
            //remove from selected_Catagory
            const _sCat = selected_categories.findIndex(el => el.id === data.id || el.id === data.id_category)
            _selectedCategories.splice(_sCat, 1)
            setCategories(_categories)
            setSelectedCategories(_selectedCategories)
        },
        onResetError: () => {
            setError(null)
        },
        onSubmit: async() => {

            let data2send = []
            selected_categories.forEach(item=>{
                data2send.push(item)
            })
            master_actions.onUpdateFilters(data2send, 'category_filter')
            master_actions.onUpdateModalStatus('category_filter', false)


        },
        onGetCurrentCategories:() =>{

        },
       

    }

    const view_data = {categories, selected_categories}
    const system = {loading, sending, error}

    return {system, actions, view_data}
}

export default useCategoryFilterModal