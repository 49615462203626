import { Grid, Typography, IconButton, Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { connect } from 'react-redux';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import InfluencerCard from '../../../../../components/Platform/InfluencerCard';
import MediaKitCard from '../../../../../components/Platform/MediaKitCard';
import NoContent from '../../../../../components/Platform/NoContent';
import ProfilePicturesCard from '../../../../../components/Platform/ProfilePicturesCard';
import ReviewCard from '../../../../../components/Platform/ReviewCard';
import LoadingContainer from '../../../../../components/Structure/Layouts/LoadingContainer';
import { actionTypes } from '../../../../../store/actions';
import AddMediaKitModal from '../../modals/AddMediaKitModal/AddMediaKitModal';
import AddUserImageModal from '../../modals/AddUserImageModal/AddUserImageModal';
import DeleteMediaKitModal from '../../modals/DeleteMediaKitModal';
import EditDescriptionDataModal from '../../modals/EditDescriptionDataModal/EditDescriptionDataModal';
import EditMediaKitModal from '../../modals/EditMediaKitModal/EditMediaKitModal';
import EditPersonalDataModal from '../../modals/EditPersonalDataModal/EditPersonalDataModal';
import EditUserImage from '../../modals/EditUserImage';
import SeeAllUserImagesModal from '../../modals/SeeAllImages/SeeAllUserImagesModal';
import SpecificDescriptionCard from './components/SpecificDescriptionCard';
import useOverview from './useOverview';
import Scrollbars from 'react-custom-scrollbars-2';
import AddCategoryModal from '../../modals/AddCategoryModal/AddCategoryModal';


const Overview = (props) => {

    const { classes, cx } = useStyles()

    const {language, content, master_view_data, user, onRemoveCategory, master_actions, onAddCategory} = props

    const {system, view_data, actions, modals } = useOverview({...props})

    return (
        <div>
            <AddCategoryModal open={modals.add_category} language={language} master_actions={master_actions} overview_actions={actions}
                user_id={view_data?.profile?.id_user} owner={master_view_data.owner} user_categories={master_view_data.influencer_categories}
                onClose={()=>actions.onUpdateModalStatus('add_category', false)}/>
            <EditPersonalDataModal  open={modals.edit_description} onClose={()=>actions.onUpdateModalStatus('edit_description', false)} language={language} 
                origin={master_view_data.profile} onCompleted={actions.onInitModule} master_actions={actions} owner={master_view_data.owner}/>
            <EditDescriptionDataModal  open={modals.edit_specific_description} onClose={()=>actions.onUpdateModalStatus('edit_specific_description', false)} language={language} 
                origin={master_view_data.profile} onCompleted={actions.onInitModule} master_actions={actions} owner={master_view_data.owner}/>
            <AddMediaKitModal open={modals.add_media_kit} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} 
            onClose={()=>actions.onUpdateModalStatus('add_media_kit', false)} master_actions={actions} />
            <EditMediaKitModal open={modals.edit_media_kit} actions={actions} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.mediakit}
            onClose={()=>actions.onUpdateModalStatus('edit_media_kit', false)} master_actions={actions} />
            <DeleteMediaKitModal open={modals.delete_media_kit} language={language} 
            profile={master_view_data.profile} owner={master_view_data.owner} origin={view_data.mediakit}
            onClose={()=>actions.onUpdateModalStatus('delete_media_kit', false)} master_actions={actions} />
            <AddUserImageModal open={modals.add_image} actions={actions} language={language} profile={master_view_data.profile} 
            owner={master_view_data.owner} onClose={()=>actions.onUpdateModalStatus('add_image', false)} master_actions={actions} />
            <EditUserImage  open={modals.edit_image} language={language} actions={actions} profile={master_view_data.profile} 
            owner={master_view_data.owner} onClose={()=>actions.onUpdateModalStatus('edit_image', false)} image={view_data.image} />

            <SeeAllUserImagesModal open={modals.see_all_images} language={language} onClose={()=>actions.onUpdateModalStatus('see_all_images', false)}
            images={view_data.images} onAdd={()=>actions.onUpdateModalStatus('add_image', true)} onEdit={actions.onEditImage} modify={master_view_data.modify}/>

            <LoadingContainer loading={system.loading}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className={classes.container}>
                                   
                                    <div className={cx({[classes.subcontainer2]:true, [classes.subcontainerMoved]:view_data.ismoved})}>
                                        <div className={cx({[classes.description_container2]:true, [classes.description_containerMoved2]:view_data.ismoved})}>  

                                                    <SpecificDescriptionCard modify={master_view_data.modify} {...master_view_data.profile} content={content.description} manager={master_view_data.manager}
                                                        onEdit={()=>actions.onUpdateModalStatus('edit_specific_description', true)} categories={master_view_data.influencer_categories} onAddCategory={onAddCategory} onRemoveCategory={onRemoveCategory} />
                                                            
                                        </div>
                                        <div className={cx({[classes.pictures_container2]:true, [classes.pictures_containerMoved2]:view_data.ismoved})}>
                                            
                                                    <ProfilePicturesCard images={view_data.images} onEdit={actions.onEditImage} onAdd={()=>actions.onUpdateModalStatus('add_image', true)}
                                                    onSeeAll={()=>actions.onUpdateModalStatus('see_all_images', true)} modify={master_view_data.modify} />
                                                
                                        </div>
                                        <div className={cx({[classes.button_container2]:true, [classes.button_container_moved2]:view_data.ismoved})}>
                                            <IconButton className={classes.button} onClick={actions.onMove} size="large"><Icon>navigate_next</Icon></IconButton>
                                        </div>
                                    </div>
                                </div>
                                
                            </Grid>

                            {(master_view_data.manager && master_view_data.influencers.length !== 0) || (master_view_data.manager && master_view_data.profile && user && master_view_data.profile.id_user === user.id_user) ? 
                            <Grid item xs={12}>
                                <div style={{marginTop:32}}>
                                    <Typography variant='h6'>Nuestros talentos</Typography>
                                    <div style={{marginTop:8}}>
                                        <Scrollbars autoHeight autoHeightMax={420}>
                                            <div style={{paddingBottom:16, padding:16}}>
                                                <Grid container spacing={2} wrap='nowrap'>
                                                    {master_view_data.influencers.map(item => {
                                                        return(
                                                            <Grid item >
                                                                <div className={classes.influencer_card}>
                                                                    <InfluencerCard {...item} onSelectedUser={actions.onSelectedInfluencer}/>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </div>
                                        </Scrollbars>
                                        {master_view_data.influencers.length === 0 ? (
                                                <div style={{paddingBottom:32, }}>
                                                    <Grid container justifyContent='center'>
                                                        <Grid item xs={12}>
                                                            <NoContent />
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Grid> : null}
                            {view_data.mediakits.length !== 0 || (master_view_data.profile && user && master_view_data.profile.id_user === user.id_user) ? (
                            <Grid item xs={12}>
                                <div style={{marginTop:32}}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant='h6'>{master_view_data.manager ? 'Portafolio' : 'Portafolio' }</Typography>
                                        </Grid>
                                        {master_view_data.modify ? <Grid item>
                                            <RoundedButton size='small' onClick={() => actions.onUpdateModalStatus('add_media_kit', true)}>Agregar</RoundedButton>
                                        </Grid> : null}

                                    </Grid>

                                    <div style={{marginTop:8}}>
                                        <Grid container spacing={2}>
                                            {view_data.mediakits.map(item => {
                                                return(
                                                    <Grid item xs={12} md={6}>
                                                        <MediaKitCard {...item} key={item.id_event} modify={master_view_data.modify}
                                                        onEdit={() => actions.onEditMediaKit(item)} 
                                                        onDelete={() => actions.onDeleteMediaKit(item)}
                                                        onDownload={() => actions.onDownloadMediaKit(item)}/>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        {view_data.mediakits.length === 0 ? (
                                                <div style={{paddingBottom:32}}>
                                                    <Grid container justifyContent='center'>
                                                        <Grid item xs={12}>
                                                            <NoContent />
                                                        </Grid>
                                                        {master_view_data.modify ? <Grid item>
                                                            <RoundedButton onClick={()=>actions.onUpdateModalStatus('add_media_kit', true)} size='small'>
                                                                <Icon>add_icon</Icon>&nbsp;Agregar
                                                            </RoundedButton>
                                                        </Grid> : null}
                                                    </Grid>
                                                    
                                                </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Grid>
                            ) : null}
                            {view_data.reviews.length !== 0 || (master_view_data.profile && user && master_view_data.profile.id_user === user.id_user) ? (
                            <Grid item xs={12}>
                                <div style={{marginTop:32}}>
                                    <Typography variant='h6'>Comentarios</Typography>
                                </div>
                                <div style={{marginTop:8}}>
                                    <Grid container spacing={2}>
                                        {view_data.reviews.map(item => {
                                            return(
                                                <Grid item xs={12} >
                                                    <ReviewCard public_view {...item} onSelectedUser={actions.onSelectedInfluencer} isOverview={true} />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    {view_data.reviews.length === 0 ? (
                                            <div style={{paddingBottom:32}}>
                                                <Grid container justifyContent='center'>
                                                    <Grid item xs={12}>
                                                        <NoContent message_2={true} />
                                                    </Grid>
                                                </Grid>
                                                
                                            </div>
                                    ) : null}
                                </div>
                            </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>     
            </LoadingContainer>
            
        </div>
    );
}

const useStyles = makeStyles()(theme => ({
    subcontainer:{
        position:'absolute',
        top:0,
        right:'-70%',
        height:'100%',
        left:-20,
        overflowX:'hidden',
        display:'flex',
    },
    subcontainerMoved:{
        left:0,
        position:'relative',
    },

    //my headache
    subcontainer2:{
        position:'relative',
        overflowX:'hidden',
        width:800,
        height:560,
        [theme.breakpoints.down('md')]:{
            width:'100%'
        }
    },
    description_container2:{
        width:700,
        position:'absolute',
        left:12,
        top:20,
        transition:'all 1.5s ease-in-out',
        [theme.breakpoints.down('md')]:{
            width:'95%'
        }
    },
    pictures_container2:{
        width:500,
        position:'absolute',
        left:732,
        top:20,
        transition:'all 1.5s ease-in-out',
        [theme.breakpoints.down('md')]:{
            left:'105%'
        },
        [theme.breakpoints.down('sm')]:{
            left:'110%'
        }
    },
    button_container2:{
        position:'absolute',
        zIndex:5,
        top:'50%',
        right:24,
        transform:'translate(0px, -50%)',
        [theme.breakpoints.down('md')]:{
            right:12
        }
    },
    description_containerMoved2:{
        transform:'translate(-640px, 0px)',
        
    },
    pictures_containerMoved2:{
        transform:'translate(-640px, 0px)',
        
    },
    button_container_moved2:{
        transform:'rotate(180deg) translate(0px, 50%)'
    },

    description_container:{
        width:800,
        marginRight:60,
        marginLeft:20,
        
        
    },
    description_containerMoved:{
        position:'absolute',
        right:500,
        
    },
    pictures_container:{
        width:500,
        marginRight:20,
    },
    pictures_containerMoved:{
        position:'absolute',
        right:-20
    },
    button_container:{
        position:'absolute',
        zIndex:3,
        top:'50%',
        right:-24,
        transform:'translate(0px, -50%)',
        [theme.breakpoints.down('md')]:{
            right:12
        }
    },
    button:{
        background:'white',
        boxShadow:'0px 0px 10.1224px rgba(0, 0, 0, 0.2)',
        color:theme.palette.primary.main,
        '&:hover':{
            background:'white',
        }
    },
    influencer_card:{
        width:230
    },
    fullpage:{
        display: 'none',
        position: 'absolute',
        zIndex: 9999,
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat no-repeat",
        backgroundPosition:" center center",
        backgroundColor: "black",
    }
    
}));

const mapStateToProps = (state) => {
    return {
        user:state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);