import nopicture from '../../../assets/image_placeholder.png'

const translations = {
    title:'Facebook / Instagram',
    message:'Por favor seleccione una página para Facebook y una página para Instagram que quiera vincular con InPager',
    add_button:'Sincronizar',
    edit_button:'Guardar',
    form:{
        facebook_page_id:{placeholder:'Página de facebook', label:'Página de facebook', helperText:'Campo requerido'},
        instagram_page_id:{placeholder:'Página de instragram', label:'FPágina de instragram', helperText:'Campo requerido'},
    },
    nopicture:nopicture
}

export default translations