import React, { useEffect, useState } from 'react';
import { Grid, Typography, Icon, IconButton, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_SETTINGS } from '../../../../../config';
import useCardSocialMedia from './useCardSocialMedia';
import { useHistory } from 'react-router';
import RoundedButton from '../../../../DealHunter/components/RoundedButton';
import FilterButton from './components/FilterButton';
import { grey } from '@mui/material/colors';
import AnalyticPointData from '../../../../../components/Platform/AnalyticPointData';
import FacebookLocationChart from './components/FacebookLocationChart';
import FacebookAgeChart from './components/FacebookAgeChart';
import FacebookGenderChart from './components/FacebookGenderChart';
import errorIcon from '../../../../../assets/icons/error.png';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import InputForm from '../../../../../components/Forms/InputForm';

const formatFollowers = (numberFollowers) => {
  if(numberFollowers >= 1100000){
    return (numberFollowers/ 1000000).toFixed(1) + ' M'
  }
  if(numberFollowers >= 1000000){
    return (numberFollowers/ 1000000).toFixed(0) + ' M'
  }
  if(numberFollowers > 1000)
  {
    return (numberFollowers/ 1000).toFixed(0) + ' K'
  }
  return numberFollowers
}


const CardSocialMedia = ({type, followers, vinculation_name, user, json_links, profile, just_redirect=false, owner=null}) => {
  const { classes } = useStyles()
  const {actions, system, form} = useCardSocialMedia({language:'es', id_user: profile.id_user})
  const history = useHistory()

  const [icon, setIcon] = useState('')
  const [name, setName] = useState('')
  const [width, setWidth] = useState('')
  const [hidden, setHidden] = useState(true)
  const [socialMediaData, setSocialMediaData] = useState({})
  const [socialMediaDataType, setSocialMediaDataType] = useState({})
  const [socialMediaDataSelected, setSocialMediaDataSelected] = useState({})
  const [optionType, setOptionType] = useState('total')
  const [optionTime, setOptionTime] = useState('day')
  const [sendRequest, setSendRequest] = useState(false)
  const [extendedVersion, setExtendedVersion] = useState(false)
  const [optionAudience, setOptionAudience] = useState('age')
  const [editFollowers, setOpenEditFollowers] = useState(false)

  useEffect( () => {
    setIcon(actions.getCardIcon(type))
    setName(actions.getCardName(type))
    setWidth(actions.getIconWidth(type))
  }, [])

  useEffect( () => {
    setSendRequest(false)
    setSocialMediaData({})
  }, [followers])
  

  const listOptionsType = [
    {value: 'stories', label: 'Historias'},
    {value: 'profile', label: 'Perfil'},
    {value: 'posts', label: 'Publicaciones'},
    {value: 'reels', label: 'Reels'},
    {value: 'total', label: 'Total'},
    {value: 'lives', label: 'Vivos'},
  ]

  const listOptionsTime = [
    {value: 'day', label: 'Último día'},
    {value: 'week', label: 'Última semana'},
    {value: 'month', label: 'Último mes'},
  ]

  const listOptionsAudience = [
    {value: 'gender', label: 'Sexo de audiencia'},
    {value: 'age', label: 'Rango de edades'},
    {value: 'location', label: 'Principales ciudades'},
  ]

  const changeOption = (val) => {
    setOptionType(val)
    socialMediaData?.metrics?.map( data => data.type === val ? setSocialMediaDataType(data) : null)
  }
  
  useEffect(() =>{
    changeOptionTime('day')
  },[socialMediaDataType])

  const changeOptionTime = (val) => {
    setOptionTime(val)
    Object.keys(socialMediaDataType).map( key => key === val ? setSocialMediaDataSelected({engagement: socialMediaDataType[key].engagement, impressions: socialMediaDataType[key].impressions, interactions: socialMediaDataType[key].interactions, scopes: socialMediaDataType[key].scopes, demographic_data: socialMediaDataType.demographic}) : null)
  }

  const link = (json_links && json_links[type+'_link']) ? json_links[type + '_link'] : '' 

  let listOptionsType_to_use = listOptionsType
  let listOptionsTime_to_use = listOptionsTime
  if(type == 'tiktok'){
    listOptionsType_to_use = [
      {value: 'reels', label: 'Videos'}
    ]
    listOptionsTime_to_use = [
      {value: 'day', label: 'Último día'},
      {value: 'week', label: 'Última semana'},
      {value: 'month', label: 'Último mes'},
      {value:'lifetime',label:'De por vida'}
    ]
  }
  if(type == 'instagram'){
    listOptionsType_to_use = [
      {value: 'stories', label: 'Historias'},
      {value: 'posts', label: 'Publicaciones'},
      {value: 'reels', label: 'Reels'},
      {value: 'total', label: 'Total'},
    ]
  }
  if(type == 'facebook'){
    listOptionsType_to_use = [
      {value: 'posts', label: 'Publicaciones'},
      {value: 'reels', label: 'Reels'},
      {value: 'total', label: 'Total'},
    ]
  }
  return (
    <div className={classes.container} >
      <Grid md={12} container className={classes.headerContainer} >
        <Grid xs={2} md={1} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <img src={icon} alt='' width={width}/>
        </Grid>

        <Grid xs={4} md={2} className={classes.name} style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
          <Typography  variant='h6'>{name}</Typography>
        </Grid>

        <Grid xs={6} md={3} style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
          <Grid md={7} style={{display: 'flex', flexDirection: 'column', justifyContent:'center', marginLeft: 50}}>
            {hidden ?
              <RoundedButton disabled={link ? false : true} onClick = {() => {window.open(link,'_blank')}} size='small'><Typography variant='body2'>Ver perfil</Typography></RoundedButton>:
              <Typography variant='body2'>{vinculation_name}</Typography>
            }
          </Grid>
        </Grid>
        {followers != null ?
          <Grid xs={10} md={5}  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {hidden
              ? 
              <Grid md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'right', marginRight: 20 }}>
                <Typography className={classes.follower_count}>{

                  formatFollowers(followers)

                } seguidores</Typography>
              </Grid>
              : <>{user && <Grid md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Grid container style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                </Grid>
              </Grid>}</>
            }
          </Grid> :
          <Grid xs={10} md={5}  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}/>
        }
      {!just_redirect ?
      <><Grid xs={1} md={1} style={{textAlign: 'center'}}>
          <IconButton
            color='primary'
            onClick={async () => {
              setHidden(!hidden)
              if(user && !sendRequest && Object.keys(socialMediaData).length === 0) {
                setSendRequest(true)

                try {
                  const data = await actions.getSocialMediaData(type, profile.id_user)
                  setSocialMediaData(data)
                  if(type == 'tiktok'){
                    setOptionType('reels')
                    data.metrics.map( item => item.type === 'reels' ? setSocialMediaDataType(item) : null)
                    data.metrics.map( item => item.type === 'reels' ? setSocialMediaDataSelected({engagement: item.day.engagement, impressions: item.day.impressions, interactions: item.day.interactions, scopes: item.day.scopes, demographic_data: item.demographic}) : null)
                  }else{
                    data.metrics.map( item => item.type === 'total' ? setSocialMediaDataType(item) : null)
                    data.metrics.map( item => item.type === 'total' ? setSocialMediaDataSelected({engagement: item.day.engagement, impressions: item.day.impressions, interactions: item.day.interactions, scopes: item.day.scopes, demographic_data: item.demographic}) : null)
                  }
                 
                } catch (error) {
                  console.log(error)
                }
              }
   
              if(hidden) {
                setExtendedVersion(false)
              }
              
              }}
            size="large">
            {hidden
            ? <Icon style={{fontSize: '30px'}}>expand_circle_down</Icon>
            : <Icon style={{fontSize: '30px'}}>expand_less_outlined</Icon>
            }
          </IconButton>
        </Grid>
          
        {!user && hidden &&
          <Grid md={9}>
            <div className={classes.transparentGrid}>
              <RoundedButton size='small' fullWidth={false} color={'white'} onClick={() => setHidden(!hidden)}>Ver estadísticas completadas</RoundedButton>
            </div>
          </Grid>
        }
      </>:<>
            {owner != null &&
              <>
                <Grid md={1} style={{ textAlign: 'center',display:'flex',flexDirection:'column',justifyContent:'center' }}>
                  <IconButton
                    color='primary'
                    onClick={() => setOpenEditFollowers(!editFollowers)}
                    size="large">
                    <Icon style={{ fontSize: '20px' }}>edit</Icon>
                  </IconButton>
                </Grid>
                <SimpleModal open={editFollowers} onClose={() => setOpenEditFollowers(false)}>
                  <Grid container direction='column' justifyContent='space-around'>
                    <Typography variant='subtitle2'>Cambiar cantidad de seguidores</Typography>
                    <Grid container direction='row' justifyContent='space-around'>
                      <InputForm data={form.follower_count} onChange={actions.onChangeForm} />
                      <RoundedButton size='small' fullWidth={false} color={'white'} onClick={() => actions.onSubmit(type)}>Guardar</RoundedButton>
                    </Grid>
                  </Grid>
                </SimpleModal>
              </>
            }</>
      }
      </Grid>

      {!just_redirect && <>
      {!user && !hidden &&

        <Grid md={12} container className={classes.transparentContainer2}>
            <div style={{width: '95%', background: grey[400], height:1, marginTop: 10, position: 'relative', left: '2.5%'}} />
            <Grid container style={{marginTop: 15, marginLeft: 20, marginRight: 20}}>
              <Grid item container style={{justifyContent: 'space-between'}}>
                <FilterButton selected={optionType} options={listOptionsType_to_use} onChange={(val) => changeOption(val)}/>
                <FilterButton selected={optionTime} options={listOptionsTime_to_use} onChange={(val) => changeOptionTime(val)}/>
              </Grid>
            </Grid>

            <Grid md={12} container style={{marginTop: 15}}>
              <Grid md={4} style={{textAlign: 'center', marginBottom: 15}}>
                <AnalyticPointData title='Seguidores' icon='people_icon' type='number' value={100}/>
              </Grid>

              <Grid md={4} style={{textAlign: 'center', marginBottom: 15}}>
                <AnalyticPointData title='Engagement Rate' icon='favorite_icon' type='percentage' value={1.05}/>
              </Grid>

              <Grid md={4} style={{textAlign: 'center', marginBottom: 15}}>
                <AnalyticPointData title='Impresiones promedio' icon='touch_app_icon' type='number' value={400}/>
              </Grid>

              <Grid md={4} style={{textAlign: 'center', marginBottom: 15}}>
                <AnalyticPointData title='Alcances promedio' icon='leaderboard_icon' type='number' value={325}/>
              </Grid>

              <Grid md={4} style={{textAlign: 'center', marginBottom: 15}}>
                <AnalyticPointData title='Vistas promedio' icon='visibility_icon' type='number' value={257}/>
              </Grid>
            </Grid>

            <Grid md={12} style={{textAlign: 'right', marginRight: 32}}>
              <RoundedButton size='small' onClick={() => setExtendedVersion(true)}>Ver más</RoundedButton>
            </Grid>

          <div className={classes.transparentContainer}>
            <div style={{textAlign: 'center', marginTop: '6.5%'}}>
              <img src={errorIcon} alt=''/>
              <Typography variant='subtitle2'>Acceda a todas las estadísticas de los creadores de contenido<br></br>iniciando sesión o registrándote completamente GRATIS.</Typography>
              <div style={{marginTop: 15, display:'flex', justifyContent: 'space-between', padding: '0px 17%'}}>
                <RoundedButton onClick={() => history.push('/signup')}><Typography variant='subtitle1'>Únete ahora</Typography></RoundedButton>
                <RoundedButton color='white'><Typography variant='subtitle1' onClick={() => history.push('/signin')}>Iniciar sesión</Typography></RoundedButton>
              </div>
            </div>
          </div>
        </Grid>

      }
      
      {user && system.loading && !hidden &&
        <Grid style={{textAlign: 'center', marginTop: 30, marginBottom: 30}}>
          <CircularProgress size={50}></CircularProgress>
        </Grid>
      }

      {user && !system.loading && !hidden && 
        <Grid md={12} container>
          <div style={{width: '95%', background: grey[400], height:1, marginTop: 10, position: 'relative', left: '2.5%'}} />
          <Grid container style={{marginTop: 15, marginLeft: 20, marginRight: 20}}>
            <Grid item container style={{justifyContent: 'space-between'}}>
              <FilterButton selected={optionType} options={listOptionsType_to_use} onChange={(val) => changeOption(val)}/>
              <FilterButton selected={optionTime} options={listOptionsTime_to_use} onChange={(val) => changeOptionTime(val)}/>
            </Grid>
          </Grid>

          <Grid md={12} container  style={{marginTop: 15}}>
            <Grid xs={8} md={4} style={{margin: '0px 0px 15px 25px'}}>
              <AnalyticPointData title='Seguidores' icon='people_icon' type='number' value={followers}/>
            </Grid>

            <Grid xs={8} md={4} style={{margin: '0px 0px 15px 25px'}}>
              <AnalyticPointData title='Engagement Rate' icon='favorite_icon' type='percentage' value={socialMediaDataSelected.engagement}/>
            </Grid>

            <Grid xs={8} md={4} style={{margin: '0px 0px 15px 25px'}}>
              <AnalyticPointData title='Impresiones promedio' icon='touch_app_icon' type='number' value={socialMediaDataSelected.impressions}/>
            </Grid>
            {type != 'tiktok' ? <Grid xs={8} md={4} style={{margin: '0px 0px 15px 25px'}}>
              <AnalyticPointData title='Alcances promedio' icon='leaderboard_icon' type='number' value={socialMediaDataSelected.scopes}/>
            </Grid> : null}

            <Grid xs={8} md={4} style={{margin: '0px 0px 15px 25px'}}>
              <AnalyticPointData title='Vistas promedio' icon='visibility_icon' type='number' value={socialMediaDataSelected.interactions}/>
            </Grid>

            {extendedVersion
            ? <Grid md={12} style={{marginLeft: 25, marginRight: 25, marginTop: 10}}>
                <Grid md={12} container style={{justifyContent: 'space-between', alignItems: 'center', marginBottom: 15}}>
                  <Typography variant='subtitle1'>Demografía de audiencia</Typography>
                  <FilterButton selected={optionAudience} options={listOptionsAudience} onChange={(val) => setOptionAudience(val)}/>
                </Grid>

                {optionAudience === 'age' ? <FacebookAgeChart data={socialMediaDataSelected.demographic_data.page_fans_gender_age}/> : null}
                {optionAudience === 'gender' ? <FacebookGenderChart data={socialMediaDataSelected.demographic_data.page_fans_gender_age }/> : null}
                {optionAudience === 'location' ? <FacebookLocationChart data={socialMediaDataSelected.demographic_data}/> : null}
              </Grid>
            : <Grid md={12} style={{textAlign: 'right', marginRight: 20}}>
                {type != 'tiktok' ? <RoundedButton size='small' onClick={() => setExtendedVersion(true)}>Ver más</RoundedButton> : null}
                
              </Grid>
            }
          </Grid>
        </Grid>
      }
    </>}
    </div>
  );
}

export default CardSocialMedia;

const useStyles = makeStyles()(theme => ({
  container: {
    background: 'white',
    boxShadow: DASHBOARD_SETTINGS.BOXSHADOW,
    marginBottom: '10px',
    marginTop: '5px',
    padding: '15px 15px',
    borderRadius: '15px',
    marginLeft: '10px',
    width: '93%',
  },
  transparentContainer2:
  {
    position:'relative'
  },
  transparentGrid: {
    marginLeft: 200,
    marginTop: -52,
    background: 'transparent',
    width: '87%',
    height: '48px',
    backdropFilter: 'blur(5px)',
    textAlign: 'right',
    padding: '7px 0px'
  },
  transparentContainer: {
    position: 'absolute',
    background: 'transparent',
    width: '102%',
    height: '104%',
    backdropFilter: 'blur(5px)',
    textAlign: 'center',
  },
  name: {
    textAlign: 'center',
    verticalAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: 20
    }
  },
  headerContainer:{
    [theme.breakpoints.down('lg')]: {
      height: 135
    }
  },
  follower_count:{
    fontWeight: 700,
    fontSize: '1.5em',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.2em'
    }
  }
}));