import React, { useState, useEffect } from "react"
import { updateAuthorizationHeader } from "../../../shared/utility"
import { private_server, public_server } from "../../../config"
import { onGetErrorMessage } from "../../../shared/utility"
import { USER_TYPES } from "../../../config"
import queryString from 'query-string'


const useInvitations = (user) => {
  const [invitations, setInvitations] = useState([])
  const [hidden, setHidden] = useState(true)
  const [change, setChange] = useState(true)
  const [loading, setLoading] = useState(false)
  const [modals, setModals] = useState({
    send_invitation: false
  })
  const [isInfluencer, setIsInfluencer] = useState(false)
  const [invitationUser, setInvitationUser] = useState('')
  const [invitationUserTypes, setInvitationUserTypes] = useState([])
  const [invitationUserCategories, setInvitationUserCategories] = useState([])
  const [invitationUserReview, setInvitationUserReview] = useState(0.0)
  const [filter, setFilter] = useState({
    limit:20, offset:0, order:1, order_by:'invitation_date'})

  useEffect(() => {
    if(user) actions.onInitModule()
  }, [user])

  const actions = {
    onInitModule: async() => {
      setLoading(true)
      try {
        setIsInfluencer(user.user_type_id === USER_TYPES.INFLUENCER)
        await actions.onUpdateData()
            
      } catch (error) {
        console.log('Hubo un error', error)
      }
      setLoading(false)
    },
    onUpdateData: async() => {
      const args = actions.onGetParams()
      const _isinfluencer = user.user_type_id === USER_TYPES.INFLUENCER
      
      const url = _isinfluencer ? `/invitation/influencer/me${args}` : `/invitation/client/me${args}`
      updateAuthorizationHeader(private_server)
      const request_invitations = await private_server.get(url)
      setInvitations(request_invitations.data.data.invitations)
    },
    onGetParams: () => {
      let response = ''
      let filt = [{field:'invitation_status_id', operator:'=', value:1}]
      filt = {"AND":filt}
      response = `?${queryString.stringify(filter)}&filter=${encodeURIComponent(JSON.stringify(filt))}`
      return response
    },
    changeHidden: (value) => {
      setHidden(value)
    },
    onUpdateModalStatus: (_key, _value) => {
      setModals({...modals, [_key]:_value})
    },
  }

  const system = { loading, hidden, modals, setHidden, setChange, change }
  const view_data = { invitations, invitationUser, invitationUserTypes, invitationUserCategories, invitationUserReview }
  return { actions, system, view_data }
}

export default useInvitations