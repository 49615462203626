import React, { useState, useEffect } from 'react';
import { Grid, Typography,Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import translations from '../../../../../texts/system_translations';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import {  private_server } from "../../../../../config"

const HideMetricsModal = (props) => {

    const { classes } = useStyles()
    const {language, open, onClose, profile, master_actions, owner, origin, onHideMetrics} = props
    const actions = {
        onSubmit : async () => {
            let request_url = '/user/hidemetrics'
            let response = await private_server.post(request_url,{hide:!profile.hide_metrics})
            master_actions.onUpdateModalStatus('hide_metrics',false)
            onHideMetrics()
        }
    }
    const content =  translations[language].modals.edit_profile_social_media 
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <div style={{marginBottom:16,display:'flex',justifyContent:'center'}}>
                <Icon color = {!profile.hide_metrics ? '' : 'primary'} style={{fontSize:'50px'}}  fontSize='large' >
                {!profile.hide_metrics ? 'visibility_off_icon' : 'visibility_on_icon'}
                    </Icon>
                </div>

                <Typography variant='h6' style={{marginBottom:16,display:'flex',justifyContent:'center'}}>On/Off</Typography>
                <Typography variant='subtitle2' style={{textAlign:'center'}}>
                    {!profile.hide_metrics ? 'Con la función OFF quitarás las estadisticas de tus redes sociales a la vista de tus posibles clientes. ¿Seguro que quieres ocultarlas?' : 'Con la función ON pondrás las estadisticas de tus redes sociales a la vista de tus posibles clientes'}
                    </Typography>
                <div className={classes.container}>
                    <Grid style={{display:'flex',justifyContent:'center',marginTop:'24px'}}>
                    <RoundedButton color = {!profile.hide_metrics ? 'grey' : 'primary'}
                    loading={false} onClick={actions.onSubmit} disabled={false}>
                        {!profile.hide_metrics ? 'Ocultar estadisticas' : 'Mostrar estadisticas'}
                        </RoundedButton>

                    </Grid>
                </div>                
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles()(theme => ({
    content:{
        marginTop: 16,
        maxHeight:400,
        paddingRight:16
    },
    container:{
        //height:400
    }
}));



export default HideMetricsModal;