import { useMemo, useState } from 'react';
import { public_server } from '../../config';

const usePodcasts = () => {
    const [podcasts, setPodcasts] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editModalMode, setEditModalMode] = useState(false);
    const [querySerch, setQuerySearch] = useState('');

    const nomalizeStrings = (text) => {
        return text.toString().normalize('NFD')
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize().toLowerCase();
    }
    const podcastsFiltered = useMemo(() => {
        return podcasts.filter(p => {
            const { title, description, number, creator } = p;
            return (
                nomalizeStrings(title).includes(querySerch) ||
                nomalizeStrings(description).includes(querySerch) ||
                nomalizeStrings(number).includes(querySerch) ||
                nomalizeStrings(creator).includes(querySerch)
            )
        })
    }, [podcasts, querySerch])

    const actions = {
        onInitModule: async () => {
            const pocasts_response = await public_server.get(`podcast/all`);
            const podcasts_temp = pocasts_response.data.data.podcasts
            podcasts_temp.forEach((element, index) => {
                try {
                    podcasts_temp[index].creator_links = JSON.parse(element.creator_links)
                }
                catch (e) {
                    podcasts_temp[index].creator_links = [{links: '', tag: '', type: ''}]
                }
            });
            setPodcasts(podcasts_temp);
        },
        setModalOpen: (state) => setModalOpen(state),
        onCompletedEditPicture: () => {
            this.onInitModule();
        },
        onCloseModal: () => {
            setModalOpen(false);
            setEditModalMode(false);
        },
        setModalEditMode: (state) => setEditModalMode(state),
        onRequestSearch: (queryText) => {
            if (queryText !== undefined && queryText !== null) setQuerySearch(nomalizeStrings(queryText))
        }
    }
    return { actions, podcastsFiltered, modalOpen, editModalMode }
}

export default usePodcasts;