import { Button, Grid, Icon, IconButton, Typography,Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import React, { useState, useEffect } from 'react';
import GoogleButton from '../../../components/Actions/GoogleButton';
import FacebookButton from '../../../components/Actions/FacebookButton';

const RegisterMethodSection = ({content, history, actions}) => {

    const { classes } = useStyles();

    const {REGISTER_STEPS} = content
    const _content = content.register_method_section

    const openModalRegister = (pre_data = false) => {
        actions.onChangeForwardStep(REGISTER_STEPS.USER_TYPE_SELECTION)
        actions.onUpdateModalStatus(true)
        actions.onSetPreData(pre_data)
    }

    return ( 
        <>
            <div className={classes.content_data}>
                    <Typography variant='h4'>
                        {_content.title}
                    </Typography>
                <div className={classes.form}>
                    <Grid container spacing={3}>
 
                        <Grid item xs={12}>
                            <Button fullWidth className={classes.button} onClick={() => openModalRegister()}>
                                Correo electrónico
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid  item xs={12}>
                            <GoogleButton actions={actions} openModal = {openModalRegister} />
                        </Grid>
                        <Grid  item xs={12}>
                            <FacebookButton actions={actions} openModal = {openModalRegister} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <div style={{position:'relative'}}>
                                <div style={{
                                    position:'absolute', top:14,
                                    borderTop:`1px solid grey`,
                                    width:'100%',
                                    zIndex:-1
                                }}/>
                                <Grid container justifyContent='center'>
                                    <Grid item>
                                    <Typography align='center' style={{background:'white'}}>o</Typography>

                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item  xs={12}>
                            <Button disabled fullWidth className={classes.social_button}>

                                <img src={_content.google_logo} width={24} style={{marginRight:8}}/>
                                Google <Typography variant='body2' style={{marginLeft:8}}>  (Próximamente)</Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button disabled fullWidth className={classes.social_button}>
                                <img src={_content.facebook_logo} width={24} style={{marginRight:8}}/>
                                Facebook <Typography variant='body2' style={{marginLeft:8}}>  (Próximamente)</Typography>
                            </Button>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Typography color='textSecondary' align='center'>
                                Al continuar con tu registro, aceptas nuestras
                            </Typography>
                            <Typography variant='subtitle2' align='center'>
                                {/* <a target='_blank' href='https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'> Condiciones de uso </a> , 
                                <a target='_blank' href='https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'> Política de datos</a> y 
                                <a target='_blank' href='https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'> Política de cookies</a> */}
                                <a target='_blank' href='/terms-and-conditions'> Condiciones de uso </a> , 
                                <a target='_blank' href='/noticeprivacy'> Política de datos</a> y 
                                <a target='_blank' href='/cookies-policies'> Política de cookies</a>
                            </Typography>
                        </Grid>
                    </Grid>
                    

                    
                </div>
                
                
                <div className={classes.bottom_section}>
                    <Typography align='center'>{_content.noaccount}</Typography>
                    <div className={classes.link} onClick={() => history.push('/signin')}
                    style={{marginTop:8, textAlign:'center', textDecoration:'none'}}>
                        {_content.joinus}
                    </div>
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles()(theme => ({
    content_data:{
        marginTop:100,
    },
    link:{
        color:theme.palette.primary.main,
        fontWeight:600,
        textDecoration:`underline ${theme.palette.primary.main}`,
        cursor:'pointer'

    },
    button:{
        fontSize:18,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        }
    },
    social_button:{
        fontSize:18,
        textTransform:'none',
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:disabled':{
            opacity:0.7,
            borderColor:grey[300],
            cursor:'not-allowed'
        }
    },
    form:{
        
        margin:'40px 0px',
        //width:400,
        //[theme.breakpoints.down('sm')]:{
        //    width:'auto'
        //},
    },
    bottom_section:{
        marginTop:40, 
        paddingBottom:100,
        [theme.breakpoints.down('md')]:{
            marginTop:60, 
            paddingBottom:50,
        },
    }
}));

export default RegisterMethodSection;