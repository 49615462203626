import principal from '../../assets/homeView/signup.png'
import logo from '../../assets/logo.png'
import logoWhite from '../../assets/logo-white.png'
import moment from 'moment'
import facebook_logo from '../../assets/icons/facebook.svg'
import google from '../../assets/icons/google.svg'

import influencer_icon from '../../assets/signup/influencer.svg'
import manager_icon from '../../assets/signup/manager.svg'
import brand_icon from '../../assets/signup/brand.svg'
import agency_icon from '../../assets/signup/agency.svg'

import facebook from '../../assets/icons/contact/facebook.png'
import instagram from '../../assets/icons/contact/instagram.png'
import tiktok from '../../assets/icons/contact/tiktok.png'
import twitter from '../../assets/icons/contact/twitter.png'
import youtube from '../../assets/icons/contact/youtube.png'

const translations = {
    REGISTER_STEPS: {
        METHOD_SELECTION:1,
        USER_TYPE_SELECTION:2,
        PERSONAL_INFORMATION:3,
        CREATE_YOUR_PROFILE:4,
        CREATE_YOUR_USERNAME: 5,
        ADDITIONAL_INFORMATION:6,
        OVERVIEW:10
    },
    join:'Unirse',
    register_method_section:{
        title:'Elige tu forma de registro',
        forgotten_password:'¿Olvidaste tu contraseña?',
        login_button:'Iniciar sesión',
        noaccount:'¿Ya tienes cuenta?',
        joinus:'Inicia sesión',
        facebook_logo:facebook_logo,
        google_logo:google,
        banner:principal,
    },
    user_type_section:{
        title:'¿Qué usuario eres?',
        user_types:[
            {id:6, label:'Influencer', icon:influencer_icon, available:true},
            {id:3, label:'Manager', icon:manager_icon, available:true},
            {id:4, label:'Marca', icon:brand_icon, available:true},
            {id:5, label:'Agencia', icon:agency_icon, available:true},
        ],
    },
    personal_information_section:{
        title:'Información personal',
        form:{
            birth_date:{placeholder:'Fecha de nacimiento', label:'', helperText:'Campo requerido'},
            gender_id:{placeholder:'Género', label:'', helperText:'Campo requerido'},
        },
        form_agency:{
            birth_date:{placeholder:'¿Cuándo fue creada tu marca o empresa?', label:'', helperText:'Campo requerido'},
            gender_id:{placeholder:'Género (persona que administra la cuenta)', label:'', helperText:'Campo requerido'},
        },
        next_button:'Siguiente'
    },
    create_username:{
        title: 'Ya casi terminamos',
        subtitle: 'Ingresa tu nobre de usuario y contacto',
        form:{
            username:{placeholder:'Usuario', label:'Usuario', helperText:'Campo requerido'},
            email_public:{placeholder:'public@inpager.co', label:'Correo electrónico de contacto', helperText:'Correo no válido'},
            quote:{placeholder:'Ej: Todo es Posible', label:'Frase del perfil (opcional)', helperText:'Texto'},
            description:{placeholder:'Descripción del perfil', label:'Descripción (opcional)', helperText:'Texto'},
            mobile:{placeholder:'5555817621', label:'Teléfono (opcional)', helperText:'Numero de telefono'},
            website_link:{placeholder:'https:inpager.app', label:'URL pagina web (opcional)', helperText:'Debe incluir https://'}
        },
        next_button:'Continuar'
    },
    profile_section:{
        title:'Crea tu perfil',
        form:{
            first_name:{placeholder:'Nombre(s)', label:'Nombre(s)', helperText:'Campo requerido'},
            last_name:{placeholder:'Apellido(s)', label:'Apellido(s)', helperText:'Campo requerido'},
            email:{placeholder:'test@inpager.co', label:'Correo electrónico', helperText:'Correo no válido'},
            username:{placeholder:'Usuario', label:'Usuario', helperText:'Campo requerido'},
            email_confirmation:{placeholder:'test@inpager.co', label:'Confirma tu correo electrónico', helperText:'Los correos no coinciden'},
            password:{placeholder:'Contraseña', label:'Contraseña', helperText:'La contraseña debe ser de al menos 10 caractéres'},
            password_confirmation:{placeholder:'Confirmar contraseña', label:'Confirmar contraseña', helperText:'Las contraseñas no coinciden'},
            coupon:{placeholder:'Código de descuento', label:'Código de descuento', helperText:'Código no válido'},
            reference_code:{placeholder:'Código de referencia', label:'Código de referencia', helperText:'Campo requerido'},
            country_id:{placeholder:'País', label:'País', helperText:'Campo requerido'},
            zone:{placeholder:'Ciudad', label:'Ciudad', helperText:'Campo requerido'},
        },
        next_button:'Unirme'
    },
    additional_information_section:{
        title:'Se ha creado tu cuenta!',
        subtitle: 'Agrega tus datos',
        next_button: 'Unirme'
    },
    overview_section:{
        title:'¡Felicidades!',
        message:'Tu cuenta ha sido creada exitósamente',
        submessage:'En breve te enviaremos un correo de confirmación a', 
        submessage_admin:'Puedes ver y editar el perfil ',
        button: 'aqui',
        next_button:'Finalizar'
    },
    banner:principal,
    form:{
        email:{placeholder:'Email', label:'Email', helperText:'Campo requerido'},
        password:{placeholder:'Contraseña', label:'Contraseña', helperText:'Campo requerido'},
    },
    topbar:{
        logo:logo,
        register:'Únete ahora',
        login:'Iniciar sesión'
    },
    bottombar:{
        logo:logoWhite,
        media:[
            {id:1, name:'facebook', icon:null},
            {id:2, name:'twitter', icon:null},
            {id:3, name:'instagram', icon:null},
        ],
        first_column:[
            {id:2, label:'Acerca de', link:'/aboutus'},
            {id:1, label:'Contacto', link:'/', modal:true, ref:'contact'},
            {id:3, label:'Prensa', link:'/'},
        ],
        second_column:[
            {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
            {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
            {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
            {id:4, label:'Ayuda', link:'/'},
        ],
        third_column:[
            {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
            {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
            {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
            {id:4, label:'Ayuda', link:'/'},
        ],
        copyright: `Copyright @ ${moment().year()} InPager`
    },
    footer:{
        logo:logoWhite,
        media:[
            {id:1, color:'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)', url:'https://www.instagram.com/inpager_/', name:'instagram', icon:instagram},
            {id:2, color:'#3b5998', url:'https://www.facebook.com/InPager', name:'facebook', icon:facebook},
            {id:3, color:'#00acee', url:'https://twitter.com/InPager', name:'twitter', icon:twitter},
            {id:4, color:'#212124', url:'https://www.tiktok.com/@inpager ', name:'tiktok', icon:tiktok},
            {id:5, color:'#F00', url:'https://www.youtube.com/channel/UCdFLIRzk1RyymX9iZfQ1LVQ', name:'youtube', icon:youtube},
        ],
        links:{
            /* first_column:[
                {id:2, label:'Acerca de', link:'/'},
                {id:1, label:'Contacto', link:'/', modal:true, ref:'contact'},
                {id:3, label:'Prensa', link:'/'},
            ], */
            first_column:[
                {id:2, label:'Acerca de', link:'/aboutus', target:'_self'},
                {id:1, label:'Contacto', link:'/', modal:true, ref:'contact'},
                {id:3, label:'Dealhunter Marcas', link:'/dealhuntermarcas', target:'_self'},
                {id:4, label:'Dealhunter Influencers', link:'/dealhunterinfluencers', target:'_self'},
                //{id:4, label:'Blog', link:'/blog'},
            ],
            second_column:[
                {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
                {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
                {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
                {id:4, label:'Ayuda', link:'/'},
            ],
            third_column:[
                {id:1, label:'Términos y condiciones', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/TerminosyCondiciones.pdf'},
                {id:2, label:'Aviso de privacidad', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/AvisoPrivacidad.pdf'},
                {id:3, label:'Políticas de cookies', target:'_blank', link:'https://inpager-dev-public.s3.amazonaws.com/businessdocuments/PoliticasDeCookies.pdf'},
                {id:4, label:'Ayuda', link:'/'},
            ],
        },
        copyright: `Copyright @ ${moment().year()} InPager`
    },
    
    
}

export default translations