import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import SVGIcon from '../../components/Structure/DisplayData/SVGIcon';
import { DASHBOARD_SETTINGS } from '../../config';
import CustomTooltip from '../Structure/DisplayData/CustomTooltip';


const ProfileContactInfo = ({data}) => {

    const { classes } = useStyles()
    return ( 
        <div>
            <Typography variant='subtitle1'>Colaboremos juntos...</Typography>
            <div>
                <Grid container spacing={1}>
                    {data.map(item => {
                        if (item.value && item.value !== null && item.value !== '') {
                            return (
                                <Grid item key={item.label} >
                                    <a href={`${item.prefix}${item.value}`} target={item.target}>
                                        <CustomTooltip title={item.value} >
                                            <div className={classes.button}>
                                                <div className={classes.icon}>
                                                    <SVGIcon src={item.icon} size={24} />
                                                </div>
                                            </div>
                                        </CustomTooltip>
                                    </a>
                                </Grid>
                            )
                        }
                    })}
                </Grid>
            </div>
        </div>
     );
}

const useStyles = makeStyles()(theme => ({
    root:{

    },
    container:{

    },
    button:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        width:32,
        height:32,
        padding:4,
        borderRadius:'50%',
        position:'relative',
        cursor:'pointer'
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    }
}));

export default ProfileContactInfo;