import { useState, useEffect } from "react"
import { private_server } from "../../../../../config"
import { onClearMemory, onGetErrorMessage, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"
import { useHistory } from "react-router-dom";

const useDeleteProfileDataModal = ({ open, language, content, profile, auth_user, origin, onUpdateUserData}) => {

    const [loading, ] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [ , setImage] = useState(null)
    const [preview, setPreview] = useState(null)
    const [file, setFile] = useState(null)

    const history = useHistory();

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setSending(false)
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
            setImage(null)
            setPreview(null)
            setFile(null)
        }
    }, [open])

    const isFormValid = (form) => {
        let errors = []
        if (form) {
            if(form['confirm_delete'].value !== 'eliminar_perfil') errors.push('confirm_delete')
        }
        return errors
    }
    
    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form, origin, content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSubmit: async() => {

            const errors = isFormValid(form)
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INVALID_FORM)
                return
            }

            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                
                await private_server.delete(auth_user?.user_type_id === 1 ? `/user/${profile.id_user}` : `/user/delete/me` )
                setError(null)
                setSending(false)
                onUpdateUserData(null) 
                await onClearMemory()
                history.push("/");
                
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }        

        }
    }

    const view_data = {preview, file}
    const system = {loading, sending, error}

    return {system, form, actions, view_data}
}

export default useDeleteProfileDataModal

const form_data = {
    confirm_delete:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'confirm_delete',
            type:'text',
            fullWidth: true,
        },
        rules:{}
    }
}
