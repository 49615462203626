import { useState } from "react"


const useSectionB = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [view, setView] = useState(0)
    const [views, setViews] = useState(1)


    const actions = {
        onGoingNext: () => {
            if(view < views - 1){
                setView(view +1) 
            }
        },
        onGoingBack: () => {
            if(view !== 0){
                setView(view -1)
            }
        },
        onSetView: (_view) =>{
            setView(_view)
        },
        onSetViews: (_views) =>{
            setViews(_views)
        }
    }

    const system = {loading, sending, error}
    const view_data = {view, views}

    return {system, view_data, actions}
}

export default useSectionB