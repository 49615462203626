//import nopicture from '../../assets/image_placeholder.png'



const translations = {
    menu : [
        {id:1, label:'Inicio', isclient:true},
        {id:2, label:'Redes sociales', isclient:true},
        {id:3, label:'Prensa', isclient:true},
        {id:4, label:'Conferencias', isclient:true},
        {id:5, label:'Eventos', isclient:true},
        {id:6, label:'Empresas', isclient:true},
    ],
    views:{
        overview:{
            description:{
                title:'Descripción',
                update:'Actualizado'
            },
            gallery:'Fotos'
        },
        

    }
    
    
}

export default translations