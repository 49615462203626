import { useState, useEffect } from "react"
import { private_server, public_server } from "../../../../../config"
import queryString from 'query-string'

const useChangeEmail = ({ language, content, onUpdateAuthStatus, onUpdateUserData, history }) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [referal_code, setReferalCode] = useState('asdfas')
    const [user_type, setUserType] = useState()

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async () => {
            try {
                const urlArgs = queryString.parse(history.location.search)
                let data2send = {}
                if (urlArgs.token_change) {
                    data2send = {
                        token: urlArgs.token_change,
                        device: 'web'
                    }
                }
                if (urlArgs.token_revoke) {
                    data2send = {
                        token: urlArgs.token_revoke,
                        device: 'web'
                    }
                }

                const confirmation_request = await public_server.post('/user/change_mail', data2send)
                const _user = confirmation_request.data
                try{
                    const user_req = await public_server.get(`/user/url/public/ns?url=${_user.url}`)
                    const user = user_req.data.data.user
                    setReferalCode(user.referal_code)
                    setUserType(user.user_type_id)
                }catch (error){
                    console.error(error)
                }
                setError(false)

            } catch (error) {
                setError(true)
            }
        },
        onKeyPress: (event) => {
            if (event.key === 'Enter') {
                actions.onSubmit()
            }
        },
        onSubmit: async () => {

            history.push('/')

        }
    }
    const view_data = { referal_code, user_type }

    const system = { loading, sending, error }

    return { system, actions, view_data }
}

export default useChangeEmail