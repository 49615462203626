import React, { useState } from "react"
import { updateAuthorizationHeader } from "../../../shared/utility"
import { private_server } from "../../../config"
import { onGetErrorMessage } from "../../../shared/utility"


const useReviews = (master_actions) => {
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [sending, setSending] = useState(false)
    const [review, setReview] = useState(null)

    const actions = {
        onDeleteReview: async (deleteId) => {
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                const url = `/review/${deleteId}/me`
                await private_server.delete(url)
                await master_actions.onUpdateData()
                setSuccess('Reseña eliminada correctamente')
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setSending(false)
            master_actions.onUpdateModalStatus('delete_review', false)
        },
        onApproveReview: async (idReview) => {
            const data2send = {
                approved: 1
            }

            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                const url = `/review/${idReview}`
                await private_server.patch(url, data2send)
                await master_actions.onUpdateData()
            } catch (error) {
            }
            setSending(false)
        },
    }

    const system = { error, success, sending, setSuccess, setError, setReview }
    return { actions, system }
}

export default useReviews