import React, { useState } from 'react';
import { alpha, Grid, Icon, IconButton, Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DASHBOARD_SETTINGS } from '../../config';
import { grey } from '@mui/material/colors';

const FilterButton = (props) => {

    const { classes } = useStyles()
    const [anchorEl, setAnchorEl] = useState()
    const {options, onSelected} = props

    const onInnerClose = () => {
        setAnchorEl(null)
    }

    const onInnerSelected = (item) => {
        onSelected(item)
        setAnchorEl(null)
    }

    return (
        <div>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onInnerClose} classes={{paper:classes.paper}}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                <div className={classes.popper_container}>
                    <Grid container>
                        {options.map(item => {
                            return(
                                <Grid item xs={12} key={item.id}>
                                    <div className={classes.item} onClick={() => onInnerSelected(item)}>
                                        {item.label}
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </Popover>
            <IconButton
                className={classes.button}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                size="large">
                <Icon>filter_list</Icon>
            </IconButton>
        </div>
    );
}

const useStyles = makeStyles()(theme => ({
    button:{
        background:'white',
        color:theme.palette.primary.main,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    paper:{
        marginTop:4,
        borderRadius:12,
        width:180,
        //boxShadow:DASHBOARD_SETTINGS.BOXSHADOW
    },
    popper_container:{
        padding:'0px 0px'
    },
    item:{
        fontFamily:theme.typography.fontFamily,
        fontSize:16,
        padding:'12px 16px',
        borderBottom:`1px solid ${grey[300]}`,
        cursor:'pointer',
        '&:hover':{
            background:alpha(theme.palette.primary.light, 0.5),
            fontWeight:600
        }
    }
}));

export default FilterButton;