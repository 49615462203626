import SimpleModal from '../../../../src/components/Modals/SimpleModal';
import RoundedButton from '../../../components/Actions/RoundedButton';
import { Icon, IconButton, Button, Typography, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InputForm from '../../../components/Forms/InputForm';
import { useState } from 'react';

const PodcastModal = ({ open, onClose, actions, form, error, sending, creatorLinks }) => {
    const { classes } = useStyles()

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <Typography variant='subtitle2'>Title</Typography>
            <InputForm data={form.title} onChange={actions.onChangeForm} />
            <Typography variant='subtitle2'>Episode Number</Typography>
            <InputForm data={form.number} onChange={actions.onChangeForm} />
            <Typography variant='subtitle2'>Creator</Typography>
            <InputForm data={form.creator} onChange={actions.onChangeForm} />
            <Typography variant='subtitle2'>Description</Typography>
            <InputForm data={form.description} onChange={actions.onChangeForm} />
            <Typography variant='subtitle2'>URL Youtube</Typography>
            <InputForm data={form.youtube_url} onChange={actions.onChangeForm} />
            <Typography variant='subtitle2'>URL Spotify</Typography>
            <InputForm data={form.spotify_url} onChange={actions.onChangeForm} />
            <Typography variant='subtitle2'>URL Apple Podcast</Typography>
            <InputForm data={form.apple_url} onChange={actions.onChangeForm} />
            <Typography variant='subtitle2'>Creator Links</Typography>
            <Button className={classes.icon} onClick={actions.addChangeCreatorLink}><Icon>add</Icon></Button>
            {creatorLinks.map((element, index) => {
                return (
                    <div key={index} className={classes.inputContainer}>
                        <div className={classes.iconButton}><Button className={classes.icon} onClick={() => actions.removeChangeCreatorLink(index)}><Icon>delete</Icon></Button></div>
                        <TextField value={element.link} onChange={(event) => actions.onChangeCreatorLink(index, 'link', event)} variant='outlined' className={classes.input} InputProps={{className: classes.input}} placeholder='https://socialmedia.com/user' label='link'/>
                        <TextField value={element.tag} onChange={(event) => actions.onChangeCreatorLink(index, 'tag', event)} variant='outlined' className={classes.input} InputProps={{className: classes.input}} placeholder='@user' label='tag'/>
                    </div>
                )
            })}

            <div className={classes.button}>
                <RoundedButton item loading={sending} onClick={actions.onSubmit}>
                    <Typography variant='subtitle2'>Guardar</Typography>
                </RoundedButton>
            </div>
            {error !== null &&
                <Typography variant='subtitle2'>{error}</Typography>
            }
        </SimpleModal>
    )
}

export default PodcastModal;

const useStyles = makeStyles()(theme => ({
    button: {
        marginTop: 20
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginBottom: 20,
        textAlign: 'left',
        alignItems: 'center'
    },
    input: {
        borderRadius: 12
    },
    iconButton: {
        width: '10%',
        height: '100%'
    },
    icon: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.dark,
        '&:hover':{
            color: theme.palette.primary.dark
        }
    }
}));