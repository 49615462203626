import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import useAddCategoryModal from './useAddCategoryModal';
import translations from '../../../../../texts/system_translations';
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import CategoryChip from '../../../../../components/Platform/CategoryChip';
import MultiSelectItem from '../../components/MultiSelectItem';
import MultiSelectOption from '../../components/MultiSelectOption';

const AddCategoryModal = ({language, open, onClose, user_id, master_actions, owner, user_categories}) => {

    const content =  translations[language].modals.add_category

    const {system, actions, view_data} = useAddCategoryModal({open, language, content, user_id, master_actions, owner, user_categories})
 
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Typography variant='h6'>{content.title}</Typography>
                <Typography>{content.message}</Typography>
                <div>
                    <Grid container spacing={1}>
                        {view_data.selected_categories.map(item =>{
                            return(
                                <Grid item>
                                    <CategoryChip {...item}  editable
                                    onRemove={() => actions.onRemoveCategory(item)}/>
                                </Grid>

                            )
                        })}
                    </Grid>
                </div>
                <MultiSelectOption system={system} items={view_data.categories} onSelected={actions.onCategorySelected}/>
                <div style={{marginTop:32}}>
                    <ActionModalBar btnLabel={content.add_button} loading={system.sending} error={system.error} onSubmit={actions.onSubmit} onCloseError={actions.onResetError}  />
                </div>

            </div>
        </SimpleModal>
     );
}





export default AddCategoryModal;
